import { HospitalColumnDTO } from "src/app/models/hidpharmacy/hospital-list/hospital-column-dto";
import { HospitalOutputDTO } from "src/app/models/hidpharmacy/hospital-list/hospital-output-dto";
import { HospitalListActionTypes } from "./hospital-list.actions";

export interface hospitalListState{
    hospitalList: HospitalOutputDTO,
    columns: HospitalColumnDTO[]
}


export const initialState = 
{
    hospitalList: null,
    columns: null,
    validationErrors: null,
    hospitalDetail: null
}



export function HospitalListReducer(state = initialState, action: any): hospitalListState {

    switch(action.type){

        case HospitalListActionTypes.GET_HOSPITAL_LIST_RES: {
            return {...state, hospitalList: action.hospitalList}
        }

        case HospitalListActionTypes.SET_HOSPITAL_LIST_COLUMNS: {
            return {...state, columns: action.columns}
        }

        default:{
            return state;
        }
    }
}

