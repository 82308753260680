import { GetPAPCorpGroupSummaryList, PAPActionTypes } from './pap.actions';
import { SafeUrl } from '@angular/platform-browser';
import { PAPCoveredEntity } from '../models/pap-covered-entity';
import { PAPBillingSetup } from '../models/pap-billing-setup';
import { PAPBillToHID } from '../models/pap-bill-to-hid';
import { PAPBillingType } from '../models/pap-billing-type';
import { PAPCorpGroupSummary, PAPCorpGroupDetails, PAPCorpGroupMemberHIDs, PAPCorpGroupHIDsInsert, papSessionData, PAPCorpGroupHIDsUpdate } from '../models/pap';

export interface PAPState {
  papCoveredEntityList: PAPCoveredEntity[],
  papBillingSetupList: PAPBillingSetup[],
  papBillingSetupBillToHIDList: PAPBillToHID[],
  papBillingSetupBillingTypesList: PAPBillingType[],
  papCorpGroupSummaryList: PAPCorpGroupSummary[],
  papCorpGroupDetails: PAPCorpGroupDetails[],
  papCorpGroupHIDs: PAPCorpGroupHIDsUpdate[],
  papCorpGroupMemberHIDs: PAPCorpGroupMemberHIDs[],
  PAPCorpGroupHIDsInsert: PAPCorpGroupHIDsInsert[],
  userpapdata: papSessionData,
  PAPCorpGroupHIDsUpdate: PAPCorpGroupHIDsUpdate[],
}

export const initialState =
{
  papCoveredEntityList: null,
  papBillingSetupList: null,
  papBillingSetupBillToHIDList: null,
  papBillingSetupBillingTypesList: null,
  papCorpGroupSummaryList: null,
  papCorpGroupDetails: null,
  papCorpGroupHIDs: null,
  papCorpGroupMemberHIDs: null,
  PAPCorpGroupHIDsInsert: null,
  userpapdata: null,
  PAPCorpGroupHIDsUpdate: null
}

/* export interface PAPCoveredEntityOutput {
  data: PAPCoveredEntity[];
} */

/* export interface GetListResponse {
  data: PAPCoveredEntity[],
} */

export function PAPReducer(state = initialState, action: any): PAPState {
  switch (action.type) {
    case PAPActionTypes.GET_COVERED_ENTITY_LIST_RES: {
      return { ...state, papCoveredEntityList: action.papCoveredEntityList }
    }
    case PAPActionTypes.GET_BILLING_SETUP_LIST_RES: {
      return { ...state, papBillingSetupList: action.papBillingSetupList }
    }
    case PAPActionTypes.GET_BILLING_SETUP_BILL_TO_HID_LIST_RES: {
      return { ...state, papBillingSetupBillToHIDList: action.papBillingSetupBillToHIDList }
    }
    case PAPActionTypes.GET_BILLING_SETUP_BILLING_TYPES_LIST_RES: {
      return { ...state, papBillingSetupBillingTypesList: action.papBillingSetupBillingTypesList }
    }
    case PAPActionTypes.UPDATE_BILLING_TYPE_BILLING_HID_RES: {
      return { ...state, papBillingSetupList: action.saveData }
    }
    case PAPActionTypes.GET_CORPORATE_GROUP_SUMMARY_LIST_RES: {
      return { ...state, papCorpGroupSummaryList: action.papCorpGroupSummaryList }
    }
    case PAPActionTypes.GET_CORPORATE_GROUP_DETAILS_RES: {
      return { ...state, papCorpGroupDetails: action.papCorpGroupDetails }
    }
    case PAPActionTypes.ADD_CORPORATE_GROUP_DETAILS_RES: {
      return { ...state, papCorpGroupDetails: action.papCorpGroupDetails }
    }
    case PAPActionTypes.UPDATE_CORPORATE_GROUP_DETAILS_RES: {
      return { ...state, papCorpGroupDetails: action.saveData }
    }
    case PAPActionTypes.GET_CORPORATE_GROUP_HIDS_RES: {
      return { ...state, papCorpGroupHIDs: action.papCorpGroupHids }
    }
    case PAPActionTypes.GET_CORPORATE_GROUP_MEMBER_HIDS_RES: {
      return { ...state, papCorpGroupMemberHIDs: action.papCorpGroupMemberHids }
    }
    case PAPActionTypes.INSERT_CORPORATE_GROUP_MEMBER_HIDS_RES: {
      return { ...state, PAPCorpGroupHIDsInsert: action.saveData }
    }
    case PAPActionTypes.SELECTED_USER_DATA:{
      return { ...state, userpapdata: action.selectedData}
    }
    case PAPActionTypes.UPDATE_CORPORATE_GROUP_MEMBER_HIDS_RES: {
      return { ...state, PAPCorpGroupHIDsUpdate: action.saveData }
    }
    default: {
      return state;
    }
  }
}
