import { createAction, props } from "@ngrx/store";
import { PharmacyColumnDTO } from "src/app/models/hidpharmacy/pharmacy-list/pharmacy-column-dto";
import { PharmacyOutputDTO } from "src/app/models/hidpharmacy/pharmacy-list/pharmacy-output-dto";




export enum PharmacyListActionTypes{

    GET_PHARMACY_LIST = '[Rest Call] [PharmacyList] GetPharmacyList',
    GET_PHARMACY_LIST_RES = '[Rest Result] [PharmacyList] GetPharmacyListResponse',
    GET_PHARMACY_LIST_ERR = '[Rest Error] [PharmacyList] GetPharmacyListError',

    SET_PHARMACY_LIST_COLUMNS = '[Rest Call] [PharmacyList] SetPharmacyListColumns',
    SET_PHARMACY_LIST_COLUMNS_RES = '[Rest Result] [PharmacyList] SetPharmacyListColumnsResponse',
    SET_PHARMACY_LIST_COLUMNS_ERR = '[Rest Error] [PharmacyList] SetPharmacyListColumnsError',

}

export const GetPharmacyList = createAction(PharmacyListActionTypes.GET_PHARMACY_LIST, props<{HID: number}>());
export const GetPharmacyListResults = createAction(PharmacyListActionTypes.GET_PHARMACY_LIST_RES, props<{pharmacyList: PharmacyOutputDTO}>());
export const GetPharmacyListError = createAction(PharmacyListActionTypes.GET_PHARMACY_LIST_ERR, props<{message: string}>())

export const SetPharmacyListColumns = createAction(PharmacyListActionTypes.SET_PHARMACY_LIST_COLUMNS, props<{columns: PharmacyColumnDTO[]}>());
export const SetPharmacyListColumnsResults = createAction(PharmacyListActionTypes.SET_PHARMACY_LIST_COLUMNS_RES, props<{ columns: PharmacyColumnDTO[] }>())
export const SetPharmacyListColumnsError = createAction(PharmacyListActionTypes.SET_PHARMACY_LIST_COLUMNS_ERR, props<{ message: string }>())


