<div class="change-pharmacy-section">
  <div *ngIf="userSession" class="select-dropdown-container displayPid">
    <mat-label [ngSwitch]="(applications$ | async).Selected.appID === 5 || (applications$ | async).Selected.appID === 8 ? 'Change Health System' : 'Change Pharmacy'" class="pid-information">
      <span *ngSwitchCase="'Change Pharmacy'">
        <span *ngIf="userSession.pharmType===0">
          <span [ngClass]="userSession.pharmActive === 1 ? 'active-hospital' : 'inactive-product-icon'">H</span>
        </span>
        <span *ngIf="userSession.pharmType===1">
          <span [ngClass]="userSession.pharmActive === 1 ? 'active-retail' : 'inactive-product-icon'">R</span>
        </span>
        <span *ngIf="userSession.isSpecialty === 1">
          <span [ngClass]="'slash-split'"> / </span>
          <span [ngClass]="'active-specialty'">S</span>
        </span>
  
        <span [ngClass]="'slash-split'" *ngIf="userSession.isGateway === 1"> / </span>
        <span *ngIf="userSession.isGateway === 1" [ngClass]="'active-gateway'">G</span>
        <span [ngClass]="'slash-split'" *ngIf="userSession.isPAP === 1"> / </span>
        <span *ngIf="userSession.isPAP===1" [ngClass]="'active-pap'">P</span>
        <span [ngClass]="'slash-split'" *ngIf="userSession.isNDCA===1"> / </span>
        <span *ngIf="userSession.isNDCA===1" [ngClass]="'active-ndca'">N</span>
        <span [ngClass]="'slash-split'" *ngIf="userSession.isFeasibility===1"> / </span>
        <span *ngIf="userSession.isFeasibility===1" [ngClass]="'active-feasibility'">F</span>
  
        &nbsp; {{userSession.pharmName}} &nbsp;
        <!--| titlecase -->
  
      </span>
      <span *ngSwitchCase="'Change Health System'">
        <span *ngIf="isCorp"> {{userSession.corpName}} </span>
        <span *ngIf="!isCorp"> {{userSession.chainGroupName}} </span>
      </span>
      
    </mat-label>
  </div>
  <div [ngSwitch]="(applications$ | async).Selected.appID === 5 || (applications$ | async).Selected.appID === 8 ? 'Change Health System' : 'Change Pharmacy'">
    <span *ngSwitchCase="'Change Pharmacy'" class="hospital-information">
      <div>PID: {{userSession.pid}}</div>&nbsp;&nbsp;
      <div>HID: {{userSession.hid}}</div> &nbsp;&nbsp;
      <div class="hospital-name">{{userSession.hospitalName}}</div> &nbsp;&nbsp;&nbsp;
      <!--| titlecase-->
      <div *ngIf="isCorp" #tooltip="matTooltip" matTooltipClass="custom-tooltip" matTooltip={{userSession.corpName}}>Corp ID:
        {{userSession.corpID}}</div>
      <div *ngIf="!isCorp" #tooltip="matTooltip" matTooltipClass="custom-tooltip" matTooltip={{userSession.chainGroupName}}>Chain Group ID:
          {{userSession.chainGroupId}}</div>
      &nbsp;&nbsp;&nbsp;
  
      <div>Recalculated: {{userSession.recalculated | date:'short'}}</div>&nbsp;
  
      <button class="pharmacy-button" (click)="openDialog()" #tooltip="matTooltip" matTooltip="Change Pharmacy"
        [matTooltipPosition]="position.value" matTooltipHideDelay="100">Change Pharmacy</button>
    </span>

    <span *ngSwitchCase="'Change Health System'" class="uam-information">
      <div *ngIf="isCorp">Corp ID: {{userSession.corpID}}</div>
      <div *ngIf="!isCorp">Chain Group ID: {{userSession.chainGroupId}}</div>    
      <button *ngIf="showClientSelectionBtn"  class="selectBtn" type="submit" color="secondary" (click)="handleClientSelection() "> Change
        Health System</button>
    </span>

  </div>
</div>