import { Injectable } from '@angular/core';
import { BaseMhService } from './basemh.service';
import { Observable, pipe, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { UserState } from '../store/user.reducer';
import { SubmitResponse } from '../models/submit-response';
import { UserSession } from '../models/user';
import { Session } from 'protractor';
import { environment } from '../../environments/environment';
import { SelectionModel } from '@angular/cdk/collections';
import { AppSelected } from '../store/application-list.actions';
import { PAPCoveredEntity } from '../models/pap-covered-entity';
import { PAPBillingSetup } from '../models/pap-billing-setup';
import { PAPBillToHID } from '../models/pap-bill-to-hid';
import { PAPBillingType } from '../models/pap-billing-type';
import { PAPCorpGroupSummary, PAPCorpGroupDetails, PAPCorpGroupMemberHIDs, PAPCorpGroupHIDsInsert, PAPCorpGroupHIDsUpdate } from '../models/pap'
import { hidTypeIdhanged } from 'src/app/models/pap-billing-type'

@Injectable({
  providedIn: 'root'
})

export class PAPService extends BaseMhService 
{

  constructor(protected http: HttpClient, protected store: Store<{ userState: UserState }>) {
    super(http, store);
  }


  public getPAPCoveredEntityList() 
  {
    //const session: UserSession = this.getStoreUserSession();
    return this.http
      .get(environment.apiBaseUrl + 'pap/v1/pap/getPapCoveredEntityList', { headers: this.getHeaders() })
      .pipe(
        map((response: PAPCoveredEntity[]) => response),
        // catchError(this.errorHandler)
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving PAP Covered Entity data" }))
      );
  }


  public getPAPBillingSetupList(hid: string) 
  {
    //const session: UserSession = this.getStoreUserSession();
    return this.http
      .get(environment.apiBaseUrl + 'pap/v1/pap/getPapBillingPharmacyList?HID=' + hid, { headers: this.getHeaders() })
      .pipe(
        map((response: PAPBillingSetup[]) => response),
        // catchError(this.errorHandler)
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving PAP Billing Setup data" }))
      );
  }

  public getPAPBillingSetupBillToHIDList(hid: number, corpGroupID: number) 
  {
    //const session: UserSession = this.getStoreUserSession();
    const parmeters = { hid: hid, corpGroupID: corpGroupID };
    return this.http
      .get(environment.apiBaseUrl + 'pap/v1/pap/getPAPBillingHIDs?HID=' + hid + '&CorpGroupID=' + corpGroupID, { headers: this.getHeaders() })
      //.get(environment.apiBaseUrl + 'pap/v1/pap/getPAPBillingHIDs?HID=' + parmeters, { headers: this.getHeaders() })
      .pipe(
        map((response: PAPBillToHID[]) => response),
        // catchError(this.errorHandler)
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving PAP Billing Setup data" }))
      );
  }

  public getPAPBillingSetupBillingTypesList() 
  {
    //const session: UserSession = this.getStoreUserSession();
    return this.http
      .get(environment.apiBaseUrl + 'pap/v1/pap/getPAPBillingTypes', { headers: this.getHeaders() })
      .pipe(
        map((response: PAPBillingType[]) => response),
        // catchError(this.errorHandler)
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving PAP Billing Setup data" }))
      );
  }
  
  public savePAPBillingSetup(saveData: hidTypeIdhanged[]) 
  {

      return this.http
      .put(environment.apiBaseUrl + 'pap/v1/pap/putPAPBillingSave', saveData, { headers: this.getHeaders() })
      .pipe(
        map((response: SubmitResponse) => {
          if (response.status === '200')
            return response;
          else
            throwError(response.message);
        }),
        catchError(error => this.errorHandler({ error, operation: "Error while saving PAP Billing data" }))
      );
  }

  /************ Corporate Group Section ************/
  
  public getPAPCorpGroupSummaryList() 
  {
    //const session: UserSession = this.getStoreUserSession();
    return this.http
      .get(environment.apiBaseUrl + 'pap/v1/pap/GetPAPCorporateGroupSummary', { headers: this.getHeaders() })
      .pipe(
        map((response: PAPCorpGroupSummary[]) => response),
        // catchError(this.errorHandler)
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving PAP Corporate Group Summary data" }))
      );
  }
  
  public getPAPCorpGroupDetails(corpGroupID: number)
  {
    const parmeters = {corpGroupID: corpGroupID };
    return this.http
    .get(environment.apiBaseUrl + 'pap/v1/pap/GetPAPCorporateGroupDetail?CorpGroupID=' + corpGroupID, { headers: this.getHeaders() })
    .pipe(
      map((response: PAPCorpGroupDetails[]) => response),
      catchError(error => this.errorHandler({ error, operation: "Error while retrieving PAP Corporate Group Details data" }))
      );
  }

  public getPAPCorporateGroupDetailMemberHIDs(corpGroupID: number)
  {
    const parmeters = {corpGroupID: corpGroupID };
    return this.http
    .get(environment.apiBaseUrl + 'pap/v1/pap/getPAPCorporateGroupDetailMemberHIDs?CorpGroupID=' + corpGroupID, { headers: this.getHeaders() })
    .pipe(
      map((response: PAPCorpGroupHIDsUpdate[]) => response),
      catchError(error => this.errorHandler({ error, operation: "Error while retrieving PAP Corporate Group HIDs data" }))
      );
  }

  
  public savePAPCorporateGroupDetails_Add(CorpGroupName: string, AddedBy: string)
  {
    return this.http
      .get(environment.apiBaseUrl + 'pap/v1/pap/GetPAPCorporateGroupDetail_AddNew?CorpGroupName=' + CorpGroupName + '&AddedBy=' + AddedBy, { headers: this.getHeaders() })
      .pipe(
        map((response: PAPCorpGroupDetails[]) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while saving new corporate group data" }))
      );
  }

  public savePAPCorporateGroupDetails(saveData: PAPCorpGroupDetails[]) 
  {

      return this.http
      .put(environment.apiBaseUrl + 'pap/v1/pap/putPAPCorporateGroupDetailSave', saveData, { headers: this.getHeaders() })
      .pipe(
        map((response: SubmitResponse) => {
          if (response.status === '200')
            return saveData;
          else
            throwError(response.message);
        }),
        catchError(error => this.errorHandler({ error, operation: "Error while saving PAP Corporate Group Details data" }))
      );
  }

  public getPAPCorporateGroupMemberHIDs(corpID: number)
  {
    const parmeters = {corpID: corpID };
    return this.http
    .get(environment.apiBaseUrl + 'pap/v1/pap/GetPAPCorporateGroupHID?CorpGroupID=' + corpID, { headers: this.getHeaders() })
    .pipe(
      map((response: PAPCorpGroupMemberHIDs[]) => response),
      catchError(error => this.errorHandler({ error, operation: "Error while retrieving PAP Corporate Group member HID data" }))
      );
  }

  public insertPAPCorporateGroupMemberHIDs(saveData: PAPCorpGroupHIDsInsert[])
  {
    return this.http
      .put(environment.apiBaseUrl + 'pap/v1/pap/putPAPCorporateGroupHIDSave', saveData, { headers: this.getHeaders() })
      .pipe(
        map((response: SubmitResponse) => {
          if (response.status === '200')
            return response;
          else
            throwError(response.message);
        }),
        catchError(error => this.errorHandler({ error, operation: "Error while saving HID to Corp IDs" }))
      );
  }

  public updatePAPCorporateGroupMemberHIDs(saveData: PAPCorpGroupHIDsUpdate[])
  {
    return this.http
      .put(environment.apiBaseUrl + 'pap/v1/pap/putPAPCorporateGroupDetailMemberHIDsSave', saveData, { headers: this.getHeaders() })
      .pipe(
        map((response: SubmitResponse) => {
          if (response.status === '200')
            return response;
          else
            throwError(response.message);
        }),
        catchError(error => this.errorHandler({ error, operation: "Error while saving HID details" }))
      );
  }
}
