import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Role, RoleRequest } from "../models/User-Access-Management/roles";
import { PermissionResponse } from "../models/User-Access-Management/permissions";
import { UserState } from "../store/user.reducer";
import { BaseMhService } from "./basemh.service";
import { UserRequest, ValidateUserDetailRequest, FileUploadResponse } from "../models/User-Access-Management/user";
import { ClientResponse } from "../models/User-Access-Management/client";
import { EntityGroupRequest, EntityGroupResponse, EntityGroups, EntityGroupsDTO, UpdateDeleteResponse, } from "../models/User-Access-Management/entity-groups";
import { EntityGroupsHospitalDTO } from "../models/User-Access-Management/entity-group-hospital";
import { EntityGroupsPharmacyDTO } from "../models/User-Access-Management/entity-group-pharmacy";
import { UserGroupsDTO, UserGroupRequest, UserDetails } from "../models/User-Access-Management/user-groups";
import { UserEntityRoleItemResponse, UserEntityRoleRequest } from "../models/User-Access-Management/user-entity-role";
import { throwError } from "rxjs/internal/observable/throwError";
import { CorporateParent } from "../models/corpid-list/corporateparent";
import { CorporateParentUAMDTO } from "../models/User-Access-Management/corporate-parent";
import { ChainGroupUAMDTO } from "../models/User-Access-Management/chain-group";
import { UserClientAccess, UserClientAccessRequest } from "../models/User-Access-Management/user-client-access";
import { ClientFeatureRequestById, ClientProductFeatureRequest, ProductClientFeature, ProductClientFeatureModel } from "../models/User-Access-Management/product-client-features";
import { BulkUserErrorDetailResponse, BulkUserUploadListItem, BulkUserUploadListResponse } from "../models/User-Access-Management/bullk-user-upload";
import { UserSession } from "../models/user";
import { TempAccess, TempAccessRequest } from "../models/temp-access";
import { UserGroupHistoryMember } from "../models/User-Access-Management/user-group-history-response";
import { EntityGroupHistory } from "../models/User-Access-Management/entity-group-history";
import { UserHistory } from "../models/User-Access-Management/user-history-response";
import { RolesHistory } from "../models/User-Access-Management/roles-history";

@Injectable({
  providedIn: 'root'
})

export class UAMService extends BaseMhService {

  constructor(protected http: HttpClient, protected store: Store<{ userState: UserState }>) {
    super(http, store);
  }

  getRoles() {
    return this.http
      .get(environment.apiBaseUrl + 'uam/v1/UAManagementApi/GetRoles', { headers: this.getHeaders() })
      .pipe(
        map((response: Role[]) => response),
        catchError(this.errorHandler)
      );
  }

  deleteRoles(roleId: number) {
    return this.http
      .delete(environment.apiBaseUrl + 'uam/v1/UAManagementApi/DeleteRole?roleId=' + roleId, { headers: this.getHeaders() })
      .pipe(
        map((response: UpdateDeleteResponse) => response),
        catchError(this.errorHandler)
      );
  }

  getPermissions() {
    return this.http
      .get(environment.apiBaseUrl + 'uam/v1/UAManagementApi/GetPermissions', { headers: this.getHeaders() })
      .pipe(
        map((response: PermissionResponse) => response),
        catchError(this.errorHandler)
      );
  }

  getPermissionsByRole(roleId: number) {
    return this.http
      .get(environment.apiBaseUrl + 'uam/v1/UAManagementApi/GetPermissionsByRole?roleId=' + roleId, { headers: this.getHeaders() })
      .pipe(
        map((response: PermissionResponse) => response),
        catchError(this.errorHandler)
      );
  }

  updateRole(role: RoleRequest) {
    return this.http
      .post(environment.apiBaseUrl + 'uam/v1/UAManagementApi/UpdateRole', role, { headers: this.getHeaders() })
      .pipe(
        map((response: number) => response),
        catchError(this.errorHandler)
      );
  }

  // Calls for users - Start
  getUsers() {
    return this.http
      .get(environment.apiBaseUrl + 'uam/v1/UAManagementApi/GetUsers', { headers: this.getHeaders() })
      .pipe(
        map((response: any) => response.data),
        catchError(this.errorHandler)
      );
  }

  addUpdateUser(user: UserRequest) {
    return this.http
      .post(environment.apiBaseUrl + 'uam/v1/UAManagementApi/AddUpdateUser', user, { headers: this.getHeaders() })
      .pipe(
        map((response: any) => response),
        catchError(error => this.errorHandler({ error, operation: error?.error?.ErrorMessage }))
      );
  }

  getAllUsers() {
    return this.http
      .get(environment.apiBaseUrl + 'uam/v1/UAManagementApi/GetAllUsers', { headers: this.getHeaders() })
      .pipe(
        map((response: any) => response.data),
        catchError(this.errorHandler)
      );
  }
  // Calls for users - End

  getEntityGroups() {
    return this.http
      .get(environment.apiBaseUrl + 'uam/v1/UAManagementApi/GetEntityGroups', { headers: this.getHeaders() })
      .pipe(
        map((response: EntityGroupsDTO) => response),
        catchError(this.errorHandler)
      );
  }

  getClients() {
    return this.http
      .get(environment.apiBaseUrl + 'uam/v1/UAManagementApi/SearchCorpsChainGroups?keyword=' + '&searchType=1', { headers: this.getIdHeaders() })
      .pipe(
        map((response: ClientResponse) => response),
        catchError(this.errorHandler)
      );
  }

  deleteEntityGroups(entityGroupId: number) {
    return this.http
      .delete(environment.apiBaseUrl + 'uam/v1/UAManagementApi/DeleteEntityGroup?entityGroupId=' + entityGroupId, { headers: this.getHeaders() })
      .pipe(
        map((response: UpdateDeleteResponse) => response),
        catchError(error => this.errorHandler({ error: error, operation: "Error while deleting entity group" }))
      );
  }


  deleteUserGroup(userGroupId: number) {
    return this.http
      .delete(environment.apiBaseUrl + 'uam/v1/UAManagementApi/DeleteUserGroup?userGroupId=' + userGroupId, { headers: this.getHeaders() })
      .pipe(
        map((response: UpdateDeleteResponse) => response),
        catchError(error => this.errorHandler({ error: error, operation: "Error while deleting user group" }))
      );
  }


  getHospitalEntityGroups() {
    return this.http
      .get(environment.apiBaseUrl + 'uam/v1/UAManagementApi/GetHospitals', { headers: this.getHeaders() })
      .pipe(
        map((response: EntityGroupsHospitalDTO) => response),
        catchError(this.errorHandler)
      );
  }

  addEntityGroupMembers(addEntityGroupdMemData: EntityGroupRequest) {
    return this.http
      .post(environment.apiBaseUrl + 'uam/v1/UAManagementApi/AddEntityGroup', addEntityGroupdMemData, { headers: this.getHeaders() })
      .pipe(
        map((response: any) => response),
        catchError(this.errorHandler)
      );
  }

  getPharmacyEntityGroups() {
    return this.http
      .get(environment.apiBaseUrl + 'uam/v1/UAManagementApi/GetPharmacies', { headers: this.getHeaders() })
      .pipe(
        map((response: EntityGroupsPharmacyDTO) => response),
        catchError(this.errorHandler)
      );
  }


  getUserGroups() {
    return this.http
      .get(environment.apiBaseUrl + 'uam/v1/UAManagementApi/GetUserGroups', { headers: this.getHeaders() })
      .pipe(
        map((response: UserGroupsDTO) => response),
        catchError(this.errorHandler)
      );
  }

  entityNameValidation(entityName: string) {
    return this.http
      .get(environment.apiBaseUrl + 'uam/v1/UAManagementApi/EntityNameDuplicateCheck?entityName=' + entityName, { headers: this.getHeaders() })
      .pipe(
        map((response: boolean) => response),
        catchError(this.errorHandler)
      );
  }

  searchCorpsChainGroups(keyword: string, searchType: number) {
    let headers: HttpHeaders;
    let authEndpoint: string;
    const userSession: UserSession = this.getStoreUserSession();
    // use b2c header endpoint on intial load, auth header afterwords since b2c expires in an hour
    if (userSession == null) {
      headers = this.getIdHeaders();
      authEndpoint = '/SearchCorpsChainGroups';
    }
    else {
      headers = this.getHeaders();
      authEndpoint = '/SearchCorpChainAfterLogin'
    }

    return this.http
      .get(environment.apiBaseUrl + 'uam/v1/UAManagementApi' + authEndpoint + '?keyword=' + keyword + '&searchType=' + searchType, { headers: headers })
      .pipe(
        map((response: ClientResponse) => response),
        catchError(this.errorHandler)
      );
  }

  getEntityGroupByID(entityGroupId: number) {
    return this.http
      .post(environment.apiBaseUrl + 'uam/v1/UAManagementApi/GetEntityGroupsByID?entityGroupId=' + entityGroupId, null, { headers: this.getHeaders() })
      .pipe(
        map((response: EntityGroupResponse) => response),
        catchError(this.errorHandler)
      );
  }

  updateUserGroup(userGroupRequest: UserGroupRequest) {
    return this.http
      .post(environment.apiBaseUrl + 'uam/v1/UAManagementApi/UpdateUserGroup', userGroupRequest, { headers: this.getHeaders() })
      .pipe(
        map((response: UserGroupRequest) => response),
        catchError(this.errorHandler)
      );
  }

  getUserGroupByID(userGroupId: number) {
    return this.http
      .post(environment.apiBaseUrl + 'uam/v1/UAManagementApi/GetUserGroupByID?userGroupId=' + userGroupId, null, { headers: this.getHeaders() })
      .pipe(
        map((response: UserGroupRequest) => response),
        catchError(this.errorHandler)
      );
  }

  roleNameValidation(roleName: string) {
    return this.http
      .get(environment.apiBaseUrl + 'uam/v1/UAManagementApi/RoleNameDuplicateCheck?roleName=' + roleName, { headers: this.getHeaders() })
      .pipe(
        map((response: boolean) => response),
        catchError(this.errorHandler)
      );
  }

  getUserDetails(userId: number) {
    return this.http
      .get(environment.apiBaseUrl + 'uam/v1/UAManagementApi/GetUserDetails?userId=' + userId, { headers: this.getHeaders() })
      .pipe(
        map((response: UserDetails) => response),
        catchError(this.errorHandler)
      );
  }

  userGroupNameValidation(userGroupName: string) {
    return this.http
      .get(environment.apiBaseUrl + 'uam/v1/UAManagementApi/UserGroupsNameDuplicateCheck?userGroupName=' + userGroupName, { headers: this.getHeaders() })
      .pipe(
        map((response: boolean) => response),
        catchError(this.errorHandler)
      );
  }

  getUserEntityRoles() {
    return this.http
      .get(environment.apiBaseUrl + 'uam/v1/UAManagementApi/GetUserEntityRoles', { headers: this.getHeaders() })
      .pipe(
        map((response: UserEntityRoleItemResponse) => response),
        catchError(this.errorHandler)
      );
  }

  deleteUserGroupById(userGroupMemberId: number) {
    return this.http
      .delete(environment.apiBaseUrl + 'uam/v1/UAManagementApi/DeleteUserGroupById?userGroupMemberId=' + userGroupMemberId, { headers: this.getHeaders() })
      .pipe(
        map((response: UpdateDeleteResponse) => response),
        catchError(error => this.errorHandler({ error: error, operation: "Error while deleting group member" }))
      );
  }

  addUserEntityRole(userEntityRole: UserEntityRoleRequest) {
    return this.http
      .post(environment.apiBaseUrl + 'uam/v1/UAManagementApi/AddUserEntityRole', userEntityRole, { headers: this.getHeaders() })
      .pipe(
        map((response: any) => response),
        catchError(error => throwError("Error adding new relationship: " + error.error.ErrorMessage)
        )
      );
  }

  deleteUserEntityRole(UserEntityRole: UserEntityRoleRequest[]) {
    return this.http
      .request('delete', environment.apiBaseUrl + 'uam/v1/UAManagementApi/DeleteUserEntityRole', { headers: this.getHeaders(), body: UserEntityRole })
      .pipe(
        map((response: any) => response),
        catchError(error => this.errorHandler({ error: error, operation: "Error while deleting relationship" }))
      );
  }


  getCorporateParentUAM() {
    return this.http
      .get(environment.apiBaseUrl + 'uam/v1/UAManagementApi/GetCorporateParent', { headers: this.getHeaders() })
      .pipe(
        map((response: CorporateParentUAMDTO) => response),
        catchError(this.errorHandler)
      );
  }

  getChainGroupUAM() {
    return this.http
      .get(environment.apiBaseUrl + 'uam/v1/UAManagementApi/GetChainGroups', { headers: this.getHeaders() })
      .pipe(
        map((response: ChainGroupUAMDTO) => response),
        catchError(this.errorHandler)
      );
  }

  getUserClientAccess(userId: number) {
    return this.http
      .get(environment.apiBaseUrl + 'uam/v1/UAManagementApi/GetUserClientAccessList?userId=' + userId, { headers: this.getHeaders() })
      .pipe(
        map((response: UserClientAccess) => response),
        catchError(this.errorHandler)
      );
  }

  updateUserClientAccess(userClientAccessRequest: UserClientAccessRequest) {
    return this.http
      .post(environment.apiBaseUrl + 'uam/v1/UAManagementApi/AddOrUpdateUserAccessData', userClientAccessRequest, { headers: this.getHeaders() })
      .pipe(
        map((response: UserClientAccessRequest) => response),
        catchError(this.errorHandler)
      );
  }

  public validateUserDetails(userDetails: ValidateUserDetailRequest) {
    return this.http
      .post(environment.apiBaseUrl + 'uam/v1/UAManagementApi/ValidateUserDetails', userDetails, { headers: this.getHeaders() })
      .pipe(
        map((response: UserClientAccessRequest) => response),
        catchError(this.errorHandler)
      );
  }
  getProductClientFeaturePermissions(clientFeatureRequestById: ClientFeatureRequestById) {
    return this.http
      .post(environment.apiBaseUrl + 'uam/v1/UAManagementApi/GetProductClientFeatures', clientFeatureRequestById, { headers: this.getHeaders() })
      .pipe(
        map((response: ProductClientFeature) => response),
        catchError(this.errorHandler)
      );
  }
  getNonparentalEntityGroups(entityGroupId: number, IsInternal: boolean) {
    return this.http
      .get(environment.apiBaseUrl + 'uam/v1/UAManagementApi/GetNonParentalEntityGroups?entityGroupId=' + entityGroupId + '&IsInternal=' + String(IsInternal), { headers: this.getHeaders() })
      .pipe(
        map((response: EntityGroupsDTO) => response),
        catchError(this.errorHandler)
      );
  }

  resendUserInvite(userId: number) {
    return this.http
      .get(environment.apiBaseUrl + `uam/v1/UAManagementApi/resendUserInvite/${userId}`, { headers: this.getHeaders() })
      .pipe(
        map((response: string) => response),
        catchError(this.errorHandler)
      );
  }

  userEntityRoleValidation(userEntityRole: UserEntityRoleRequest) {
    return this.http
      .post(environment.apiBaseUrl + 'uam/v1/UAManagementApi/UserEntityRoleDuplicateCheck', userEntityRole, { headers: this.getHeaders() })
      .pipe(
        map((response: any) => response),
        catchError(error => throwError("Error checking relationship " + error.error.ErrorMessage)
        )
      );
  }

  addOrDeleteProductFeaturePermission(productClientFeatures: ClientProductFeatureRequest[]) {
    return this.http
      .post(environment.apiBaseUrl + 'uam/v1/UAManagementApi/AddorDeleteProductFeatures', productClientFeatures, { headers: this.getHeaders() })
      .pipe(
        map((response: ProductClientFeatureModel) => response),
        catchError(this.errorHandler)
      );
  }

  UpdateUserEntityRole(userEntityRole: UserEntityRoleRequest) {
    return this.http
      .post(environment.apiBaseUrl + 'uam/v1/UAManagementApi/UpdateUserEntityRole', userEntityRole, { headers: this.getHeaders() })
      .pipe(
        map((response: any) => response),
        catchError(error => throwError("Error updating relationship: " + error.error.ErrorMessage)
        )
      );
  }

  public fileUploadUsers(postedFile: FormData) {

    //Take content type out of header
    var formHeaders = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Ocp-Apim-Subscription-Key': environment.subcriptionKey,
      Authorization: 'Bearer ' + this.getUserToken(),
    });

    return this.http
      .post(environment.apiBaseUrl + "uam/v1/UAManagementApi/FileUpload", postedFile, { headers: formHeaders })
      .pipe(
        map((response: FileUploadResponse) => response),
        catchError(error =>
          throwError("Error while uploading Bulk Users file: " + error.error.ErrorMessage)
        )

      );
  }

  getBulkUserUploadList() {
    return this.http
      .get(environment.apiBaseUrl + 'uam/v1/UAManagementApi/GetBulkUserUploadData', { headers: this.getHeaders() })
      .pipe(
        map((response: BulkUserUploadListResponse) => response),
        catchError(error => throwError("Error while Retrieving Bulk User Upload Data: " + error.error.ErrorMessage)
        )
      );
  }

  getBulkUserUploadErrorDetail(importId: string) {
    return this.http
      .get(`${environment.apiBaseUrl}uam/v1/UAManagementApi/GetBulkUserUploadErrorResponse/${importId}`, { headers: this.getHeaders() })
      .pipe(
        map((response: BulkUserErrorDetailResponse) => response),
        catchError(error => throwError("Error while Retrieving Bulk User Upload Error Data " + error.error.ErrorMessage)
        )
      );
  }

  getUserGroupsByCorpId(corpId?: number, csgId?: number) {
    return this.http
      .get(environment.apiBaseUrl + `uam/v1/UAManagementApi/GetUserGroupsByCorpId?corpId=${corpId}&csgId=${csgId}`, { headers: this.getHeaders() })
      .pipe(
        map((response: UserGroupsDTO) => response),
        catchError(this.errorHandler)
      );
  }

  getTempAccessData(userGroupId: number) {
    return this.http
      .get(`${environment.apiBaseUrl}uam/v1/UAManagementApi/GetTempAccessData/${userGroupId}`, { headers: this.getHeaders() })
      .pipe(
        map((response: TempAccess[]) => response),
        catchError(error => throwError("Error while Retrieving TempAccess Data " + error.error.ErrorMessage)
        )
      );
  }

  deletebulkUserGroup(userGroupId: number[]) {
    return this.http
      .request('delete', environment.apiBaseUrl + 'uam/v1/UAManagementApi/BulkDeleteUserGroup', { headers: this.getHeaders(), body: userGroupId })
      .pipe(
        map((response: UpdateDeleteResponse) => response),
        catchError(error => this.errorHandler({ error: error, operation: "Error while deleting bulk user groups" }))
      );
  }

  deletebulkEntityGroup(entityGroupId: number[]) {
    return this.http
      .request('delete', environment.apiBaseUrl + 'uam/v1/UAManagementApi/BulkDeleteEntityGroup', { headers: this.getHeaders(), body: entityGroupId })
      .pipe(
        map((response: UpdateDeleteResponse) => response),
        catchError(error => this.errorHandler({ error: error, operation: "Error while deleting bulk entity groups" }))
      );
  }

  requestTempAccess(tempAccess: TempAccessRequest) {
    return this.http
      .post(`${environment.apiBaseUrl}uam/v1/UAManagementApi/RequestAccess`, tempAccess, { headers: this.getHeaders() })
      .pipe(
        map((response: string) => response),
        catchError(error => throwError("Error while Requesting temp Access " + error.error.ErrorMessage)
        )
      );
  }

  deleteBulkRoles(roleIds: number[]) {
    return this.http
      .request('delete', environment.apiBaseUrl + 'uam/v1/UAManagementApi/BulkDeleteUserRoles', { headers: this.getHeaders(), body: roleIds })
      .pipe(
        map((response: UpdateDeleteResponse) => response),
        catchError(error => this.errorHandler({ error: error, operation: "Error while deleting bulk roles" }))
      );
  }

  deleteUser(userId: number) {
    return this.http
      .delete(environment.apiBaseUrl + 'uam/v1/UAManagementApi/DeleteUser?userId=' + userId, { headers: this.getHeaders() })
      .pipe(
        map((response: UpdateDeleteResponse) => response),
        catchError(error => this.errorHandler({ error: error, operation: "Error while deleting user" }))
      );
  }

  getUserGroupHistory(userGroupId: number) {
    return this.http
      .get(environment.apiBaseUrl + 'uam/v1/UAManagementApi/GetUserGroupHistory?groupId=' + userGroupId, { headers: this.getHeaders() })
      .pipe(
        map((response: UserGroupHistoryMember[]) => response),
        catchError(error => this.errorHandler({ error: error, operation: "Error while retrieving user group history" }))
      );
  }

  ViewEntityGroupHistory(groupId: number) {
    return this.http
      .get(environment.apiBaseUrl + 'uam/v1/UAManagementApi/GetEntityGroupHistory?groupId=' + groupId, { headers: this.getHeaders() })
      .pipe(
        map((response: EntityGroupHistory[]) => response),
        catchError(error => this.errorHandler({ error: error, operation: "Error while viewing entity group history" }))
      );
  }

  getUserHistory(userId: number) {
    return this.http
      .get(environment.apiBaseUrl + 'uam/v1/UAManagementApi/GetUserHistory?userId=' + userId, { headers: this.getHeaders() })
      .pipe(
        map((response: UserHistory[]) => response),
        catchError(error => this.errorHandler({ error: error, operation: "Error while retrieving user history" }))
      );
  }

    ViewRolesHistory(roleId: number) {
      return this.http
        .get(environment.apiBaseUrl + 'uam/v1/UAManagementApi/GetRolesHistory?roleId=' + roleId, { headers: this.getHeaders() })
        .pipe(
          map((response: RolesHistory[]) => response),
          catchError(error => this.errorHandler({ error: error, operation: "Error while viewing roles history" }))
        );
    }


}


