import { Inject, Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';
import { HttpClient} from '@angular/common/http';
import { Subscription } from 'rxjs';
import { isIE, b2cPolicies, loginRequest, signUpRequest, msalConfig, apiConfig, tokenRequest, resetPasswordRequest, mfaRequest, forgotPasswordRequest } from '../app-config';
import { CookieService } from 'ngx-cookie-service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UiUser } from '../models/user';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import * as msal from "@azure/msal-browser";
import { EventMessage, EventType, InteractionType } from '@azure/msal-browser';
import { error } from 'protractor';

@Injectable({
  providedIn: 'root'
})

export class B2cLoginService {

  public subscriptions: Subscription[] = [];
  public idToken: string;
  public isIframe: boolean = false;
  public loggedIn: boolean =false;
  b2cAgent: msal.PublicClientApplication;
  public isLandingPage: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(protected http: HttpClient
        , private authService: MsalService, private cookieService: CookieService
        , private router: Router, private route: ActivatedRoute
        , @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
        , private msalBroadcastService: MsalBroadcastService) {
  }

  createB2cUser(){
    const jwtHelper = new JwtHelperService();
    let user: UiUser = new UiUser();
    let b2cToken = this.getB2cIdToken();
    user.jwtString = b2cToken;
    this.parseB2cJWT(user);
    this.createCookie('token', user.jwtString, jwtHelper.getTokenExpirationDate(user.jwtString) );
  }

  public hasB2cToken(): boolean {
    let exp:boolean = false;
    let tk: string;

    tk= this.getB2cIdToken();

    if(tk) {
      let jwtHelper = new JwtHelperService();
      exp = jwtHelper.isTokenExpired(tk)
    }

    let state = (!exp && !!tk);

    return state;
  }

  public removeSubscription(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup()
          .subscribe(() => this.hasB2cToken());
      } else {
        this.authService.loginPopup()
          .subscribe(() => this.hasB2cToken());
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect();
      } else {
        this.authService.loginRedirect()
      }
    }
  }

public LogoutCleanup(){
    localStorage.clear();
    sessionStorage.clear();
    this.cookieService.deleteAll();
    this.removeSubscription();
  }

  public logout() {
    this.LogoutCleanup();
    this.authService.logout();
  }

  public ResetPassword() {
    this.authService.loginRedirect(resetPasswordRequest);
  }

  public ResetMFA() {
    this.authService.loginRedirect(mfaRequest);
  }

  public ForgotPassword() {
    this.authService.loginRedirect(forgotPasswordRequest);
  }

  public signupredirect(idTokenHint){
    const someExtraQueryParameters = {
      id_token_hint: idTokenHint,
    };

    const authParams = {
      authority : b2cPolicies.authorities.signup.authority,
      extraQueryParameters:someExtraQueryParameters,
      scopes: signUpRequest.scopes,
    };

    this.authService.loginRedirect(authParams);
  }

  public connectSignIn() {
    const someExtraQueryParameters = {
      domain_hint: 'mckessonconnect.com',
    };

    const authParams = {
      authority : b2cPolicies.authorities.oldSignUpSignIn.authority,
      extraQueryParameters:someExtraQueryParameters,
      scopes: signUpRequest.scopes,
    };

    this.authService.loginRedirect(authParams)
        .toPromise()
        .then(response => { console.log(response); })
        .catch(error => {
          console.log(error);
          this.refeshConnectLogin();
        });
  }

  refeshConnectLogin() {

    const someExtraQueryParameters = {
      domain_hint: 'mckessonconnect.com',
    };

    const authParams = {
      authority : b2cPolicies.authorities.oldSignUpSignIn.authority,
      extraQueryParameters:someExtraQueryParameters,
      scopes: signUpRequest.scopes,
    };
    
    this.LogoutCleanup();

    this.authService.loginRedirect(authParams);
  }

  private parseB2cJWT( user: UiUser ) {
    user.jwtToken = jwt_decode(user.jwtString);
    user.userId = user.jwtToken.uid;
    user.userEmail = user.jwtToken.email;
    user.expiration = new Date(user.jwtToken.exp * 1000);
  }

  public createCookie(name, value, expiration) {
    let expires;
    if (expiration) {
      expires = '; expires=' + expiration.toUTCString();
    } else {
      expires = '';
    }
    document.cookie = name + '=' + value + expires + '; path=/';
  }

  public readCookie(name): string {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    // for (let i = 0; i < ca.length; i++) {
      for (let c of ca) {
      // let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }

  setB2cIdToken(idToken:any) {
    const jwtHelper = new JwtHelperService();

    if(idToken != null) {
      if(isIE) {
        this.createCookie('msal.idtoken', idToken.toString(), jwtHelper.getTokenExpirationDate(idToken.toString()) );
      }
      else {
        localStorage.setItem('msal.idtoken', idToken.toString());
      }
    }
  }

  getB2cIdToken(): any {
    if(isIE) {
      return this.readCookie('msal.idtoken');
    }
    else {
      return localStorage.getItem('msal.idtoken');
    }
  }

  getdomain(): string {

    let domain: string;
    let usertype: string;

    let tk = this.getB2cIdToken();
    const jwtHelper = new JwtHelperService();

    if(tk) {

      domain = jwtHelper.decodeToken(tk).tid;
      usertype = jwtHelper.decodeToken(tk).usrtype;

      if(usertype === "1") {
        return "mckesson.com";
      }
      else if (usertype === "2") {
        return "mckessonconnect.com";
      }
    }

    return null;
  }

  IsB2cTokenExpired(): boolean {

    let tk= this.getB2cIdToken();

    if(tk) {
      let jwtHelper = new JwtHelperService();
      return jwtHelper.isTokenExpired(tk)
    }

    return true;
  }

  IsConnectUserFlow(): boolean {
    let tk= this.getB2cIdToken();
    if(tk && !this.IsB2cTokenExpired()) {
      let jwtHelper = new JwtHelperService();
      let domainhint:string = jwtHelper.decodeToken(tk)?.tid;

      return (domainhint && domainhint.trim().toLocaleLowerCase() == "mckessonconnect.com");

    }
    return false;
  }

}
