import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Holiday } from "../models/education";
import { UserState } from "../store/user.reducer";
import { BaseMhService } from "./basemh.service";

@Injectable({
  providedIn: 'root'
})
  
export class EducationService extends BaseMhService {

  constructor(protected http: HttpClient, protected store: Store<{ userState: UserState }>) {
    super( http, store);
  }

  getHolidays() {
    return this.http
      .get(environment.apiBaseUrl+'api/shell/v1/education/holidays', {headers: this.getHeaders()})
      .pipe(
        map((response: Holiday[]) => response),
        catchError(this.errorHandler)
      );
  }

  addHoliday(holiday: Holiday) {
    return this.http
      .post(environment.apiBaseUrl+'api/shell/v1/education/holiday', holiday, {headers: this.getHeaders()})
      .pipe(
        map((response: Holiday) => response),
        catchError(this.errorHandler)
      );
  }

  updateHoliday(updatedHoliday: Holiday) {
    return this.http
      .put(environment.apiBaseUrl+'api/shell/v1/education/holiday', updatedHoliday, {headers: this.getHeaders()})
      .pipe(
        map((response: Holiday) => response),
        catchError(this.errorHandler)
      );
  }

  deleteHoliday(id: number) {
    return this.http
      .put(environment.apiBaseUrl+'api/shell/v1/education/deleteholiday', id, {headers: this.getHeaders()})
      .pipe(
        map(response => response),
        catchError(this.errorHandler)
      );
  }

}