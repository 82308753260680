import { catchError, map, switchMap } from "rxjs/operators";
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { AddCorporateParent, AddCorporateParentError, AddCorporateParentResults, ExportData, ExportDataError, ExportDataResults, GetAccountManagerList, GetAccountManagerListError, GetAccountManagerListResults, GetContactSearch, GetContactSearchError, GetContactSearchResults, GetCorpIdList, GetCorpIdListError, GetCorpIdListResults, IsValidCorpName, IsValidCorpNameError, IsValidCorpNameResults, ViewCorporateParent, ViewCorporateParentError, ViewCorporateParentResults } from "./corpid-list.actions";
import { corpIdListService } from "src/app/service/corpid-list.service";

@Injectable()
export class corpIdStoreEffects {

  constructor(private corpIdService: corpIdListService, private actions$: Actions) {
  }
  GetcorpIdListEffects$ = createEffect(() => this.actions$.pipe(
    ofType(GetCorpIdList),
    switchMap(action =>
      this.corpIdService.getCorporateParentList()
        .pipe(
          map(
            data => {
              return GetCorpIdListResults({ corpIdList: data })
            }
          ),
          catchError(
            error => observableOf(GetCorpIdListError({ message: error }))
          )
        )

    )
  ))

  AddCorporateParentEffect$ = createEffect(() => this.actions$.pipe(
    ofType(AddCorporateParent),
    switchMap(action =>
      this.corpIdService.addUpdateCorporateParent(action.newCorporateParent)
        .pipe(
          map(
            data => {
              return AddCorporateParentResults({ result: data })
            }
          ),
          catchError(
            error => observableOf(AddCorporateParentError({ message: error }))
          )
        )
    )
  ))
  ViewCorporateParentEffect$ = createEffect(() => this.actions$.pipe(
    ofType(ViewCorporateParent),
    switchMap(action =>
      this.corpIdService.viewCorporateParent(action.corpId)
        .pipe(
          map(
            data => {
              return ViewCorporateParentResults({ result: data })
            }
          ),
          catchError(
            error => observableOf(ViewCorporateParentError({ message: error }))
          )
        )
    )
  ))


  GetContactSearchEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetContactSearch),
    switchMap(action =>
      this.corpIdService.getContactSearch(action.contactSearch, action.corpId)

        .pipe(
          map(
            data => GetContactSearchResults({ contactSearch: data, })

          ),
          catchError(
            error => observableOf(GetContactSearchError({ message: error }))
          )

        )
    )
  ));

  IsValidCorpName$ = createEffect(() => this.actions$.pipe(
    ofType(IsValidCorpName),
    switchMap(action =>
      this.corpIdService.isValidCorpName(action.corpname)
        .pipe(
          map(
            data => {
              return IsValidCorpNameResults({ result: data.data.validationData });
            }
          ),
          catchError(
            error => observableOf(IsValidCorpNameError({ message: error }))
          )
        )
    )
  ));
  getAccountManagers$ = createEffect(() => this.actions$.pipe(
    ofType(GetAccountManagerList),
    switchMap(action =>
      this.corpIdService.getAccountManager()
        .pipe(
          map(
            data => {
              return GetAccountManagerListResults({ accountManager: data })
            }

          ),
          catchError(
            error => observableOf(GetAccountManagerListError({ message: error }))
          )

        )
    )
  ));

  ExportDataEffects$ = createEffect(() => this.actions$.pipe(
    ofType(ExportData),
    switchMap(action =>
      this.corpIdService.exportData(action.corpId, action.pageName)
        .pipe(
          map(
            data => {
              return ExportDataResults({ result: data })
            }
          ),
          catchError(
            error => observableOf(ExportDataError({ message: error }))
          )
        )
    )
  ));

}