<!-- Help => Submit Ticket Dialog -->
 <div class="submit-salesforce-ticket-dialog">
  <div mat-dialog-title class="dialog-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <h3 cdkDragHandle>Submit Ticket</h3>
  </div>
  <div>
    <form #submitSalesForceTicketForm="ngForm" (change)="formChanged()">
      <div class="dialog-body">
        <!-- HTML code for phone number input -->
        <mat-form-field appearance="" class="phone-number">
          <mat-label>Phone Number</mat-label>
          <input type="tel"
                 matInput
                 placeholder="012-345-6789"
                 name="phoneNumber"
                 #phoneNumber="ngModel"
                 [(ngModel)]="newSalesForceTicket.phoneNumber"
                 required
                 [pattern]="phoneNumberPattern"
                 minlength=10
                 [textMask]="{mask: phoneMask}">
          <mat-error *ngIf="phoneNumber.hasError('required')">Phone number is required</mat-error>
          <mat-error *ngIf="phoneNumber.hasError('pattern')">Phone number is invalid. Phone number should be 10 digits</mat-error>
        </mat-form-field>

        <!-- HTML code for category selection dropdown -->
        <mat-form-field appearance="" class="category" required>
          <mat-label>Category</mat-label>
          <mat-select
            class="form-control" [class.has-error]="selectedCategory.invalid || (selectedCategory.invalid && selectedCategory.touched)"
            name="selectedCategory"
            [(ngModel)]="newSalesForceTicket.categoryId"
            [(value)]="newSalesForceTicket.categoryId"
            #selectedCategory="ngModel"
            placeholder="Select a category.."
            required
            matInput >
              <mat-option *ngFor="let category of ( shellState$ | async ).sfCategories" [value]="category.categoryID">
                {{category.category}}
              </mat-option>
          </mat-select>
          <mat-error *ngIf="selectedCategory.hasError('required')">Category is required, please choose a category</mat-error>
        </mat-form-field>

        <mat-form-field appearance="" class="ticket-subject">
          <mat-label>Subject</mat-label>
          <input
          type="text"
                  matInput
                  placeholder="Ticket Subject"
                  class="form-control"
                  name="ticketSubject"
                  #ticketSubject="ngModel"
                  [(ngModel)]="newSalesForceTicket.subject"
                  required
                  size="40">
          <mat-error *ngIf="ticketSubject.hasError('required')">Subject is required</mat-error>
        </mat-form-field>

        <mat-form-field appearance="" class="ticket-body">
          <mat-label>Description</mat-label>
          <textarea
          type="text"
                  matInput
                  placeholder="Ticket Description"
                  class="form-control"
                  name="description"
                  #description="ngModel"
                  [(ngModel)]="newSalesForceTicket.body"
                  required
                  matTextareaAutosize
                  matAutosizeMinRows=5></textarea>
          <mat-error *ngIf="description.hasError('required')">Description is required</mat-error>
        </mat-form-field>
      </div>
      <div class="dialog-footer">
        <button mat-flat-button type="submit" color="primary" class="submit-btn" (click)="onSubmitClick()" [disabled]="submitSalesForceTicketForm.form.invalid || isNullOrWhitespace()">Submit</button>
        <button mat-stroked-button type="button" class="discard-btn" (click)="onCloseClick()">Cancel</button>
      </div>
    </form>
  </div>
</div>
