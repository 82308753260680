import { createAction, props } from "@ngrx/store";
import { BranchCodeColumnDto, BranchCodeListUploadErrorReponseOutputDto, BranchCodeOutputDto, BranchCodeUploadColumnsDTO } from "src/app/models/branchcode-list/branchcode-output-dto";
import { HosiptalBranchCodeByIdDto } from "src/app/models/branchcode-list/hosiptal-branch-code-dto";
import { BranchCodeListUploadOutputDto } from "src/app/models/branchcode-list/hosiptal-branch-output-dto";
import { PrescriberListUploadOutputDto } from "src/app/models/prescriber-list/prescriber-list-upload-output";
import { SubmitResponse } from "src/app/models/submit-response";

export enum BranchCodeIdActionTypes {

  GET_BRANCHCODEID_LIST = "[Rest Call] [branchCodeList] GetBranchCodesFromList",
  GET_BRANCHCODEID_LIST_RES = "[Rest Result] [branchCodeList] GetBranchCodesFromListResults",
  GET_BRANCHCODEID_LIST_ERR = "[Rest Error] [branchCodeList] GetBranchCodesFromListError",

  SET_BRANCHCODE_COLUMNS = "[Rest Call] [branchCodeList] SetBranchCodeColumns",
  SET_BRANCHCODE_COLUMNS_RES = "[Rest Result] [branchCodeList] SetBranchCodeColumnsResults",
  SET_BRANCHCODE_COLUMNS_ERR = "[Rest Error] [branchCodeList] SetBranchCodeColumnsError",

  GET_BRANCHCODES_TO_LIST = "[Rest Call] [branchCodeList] GetBranchCodesToList",
  GET_BRANCHCODES_TO_LIST_RES = "[Rest Result] [branchCodeList] GetBranchCodesToListResults",
  GET_BRANCHCODES_TO_LIST_ERR = "[Rest Error] [branchCodeList] GetBranchCodesToListError",

  GET_HOSPITAL_NAME_LIST = "[Rest Call] [branchCodeList] GetHospitalNameList",
  GET_HOSPITAL_NAME_LIST_RES = "[Rest Result] [branchCodeList] GetHospitalNameListResults",
  GET_HOSPITAL_NAME_LIST_ERR = "[Rest Error] [branchCodeList] GetHospitalNameListError",

  GET_HOSPITAL_BRANCH_CODE_LIST = "[Rest Call] [branchCodeList] GetHospitalBranchCodeList",
  GET_HOSPITAL_BRANCH_CODE_LIST_RES = "[Rest Result] [branchCodeList] GetHospitalBranchCodeListResults",
  GET_HOSPITAL_BRANCH_CODE_LIST_ERR = "[Rest Error] [branchCodeList] GetHospitalBranchCodeListError",

  GET_HOSPITAL_BRANCH_CODE_SEARCH_LIST = "[Rest Call] [branchCodeList] GetHospitalBranchCodeSearchList",
  GET_HOSPITAL_BRANCH_CODE_SEARCH_LIST_RES = "[Rest Result] [branchCodeList] GetHospitalBranchCodeSearchListResults",
  GET_HOSPITAL_BRANCH_CODE_SEARCH_LIST_ERR = "[Rest Error] [branchCodeList] GetHospitalBranchCodeSearchListError",

  GET_HOSPITAL_BRANCH_CODE_TO_HID_LIST = "[Rest Call] [branchCodeList] GetHospitalBranchCodeToHidList",
  GET_HOSPITAL_BRANCH_CODE_TO_HID_LIST_RES = "[Rest Result] [branchCodeList] GetHospitalBranchCodeToHidListResults",
  GET_HOSPITAL_BRANCH_CODE_TO_HID_LIST_ERR = "[Rest Error] [branchCodeList] GetHospitalBranchCodeToHidListError",


  GET_PHARMACY_NAME_LIST = "[Rest Call] [branchCodeList] GetPharmacyNameList",
  GET_PHARMACY_NAME_LIST_RES = "[Rest Result] [branchCodeList] GetPharmacyNameListResults",
  GET_PHARMACY_NAME_LIST_ERR = "[Rest Error] [branchCodeList] GetPharmacyNameListError",

  GET_PHARMACY_BRANCH_CODE_LIST = "[Rest Call] [branchCodeList] GetPharmacyBranchCodeList",
  GET_PHARMACY_BRANCH_CODE_LIST_RES = "[Rest Result] [branchCodeList] GetPharmacyBranchCodeListResults",
  GET_PHARMACY_BRANCH_CODE_LIST_ERR = "[Rest Error] [branchCodeList] GetPharmacyBranchCodeListError",

  GET_PHARMACY_BRANCH_CODE_SEARCH_LIST = "[Rest Call] [branchCodeList] GetPharmacyBranchCodeSearchList",
  GET_PHARMACY_BRANCH_CODE_SEARCH_LIST_RES = "[Rest Result] [branchCodeList] GetPharmacyBranchCodeSearchListResults",
  GET_PHARMACY_BRANCH_CODE_SEARCH_LIST_ERR = "[Rest Error] [branchCodeList] GetPharmacyBranchCodeSearchListError",

  GET_PHARMACY_BRANCH_CODE_TO_PID_LIST = "[Rest Call] [branchCodeList] GetPharmacyBranchCodeToPidList",
  GET_PHARMACY_BRANCH_CODE_TO_PID_LIST_RES = "[Rest Result] [branchCodeList] GetPharmacyBranchCodeToPidListResults",
  GET_PHARMACY_BRANCH_CODE_TO_PID_LIST_ERR = "[Rest Error] [branchCodeList] GetPharmacyBranchCodeToPidListError",


  ADD_UPDATE_BRANCH_CODE = "[Rest Call] [branchCodeList] AddUpdateBranchCode",
  ADD_UPDATE_BRANCH_CODE_RES = "[Rest Result] [branchCodeList] AddUpdateBranchCodeResults",
  ADD_UPDATE_BRANCH_CODE_ERR = "[Rest Result] [branchCodeList] AddUpdateBranchCodeError",

  EXPORT_DATA = "[Rest Call] [branchCodeList] ExportData",
  EXPORT_DATA_RES = "[Rest Result] [branchCodeList] ExportDataResults",
  EXPORT_DATA_ERR = "[Rest Result] [branchCodeList] ExportDataError",

  UPLOAD_BRANCHCODE_LIST = '[Rest Call] [branchCodeList] UploadBranchCodeList',
  UPLOAD_BRANCHCODE_LIST_RES = '[Rest Result] [branchCodeList] UploadBranchCodeListResults',
  UPLOAD_BRANCHCODE_LIST_ERR = '[Rest Error] [branchCodeList] UploadBranchCodeListError',

  GET_BRANCHCODE_LIST_UPLOAD = '[Rest Call] [branchCodeList] GetBranchCodeListUpload',
  GET_BRANCHCODE_LIST_UPLOAD_RES = '[Rest Result] [branchCodeList] GetBranchCodeListUploadResults',
  GET_BRANCHCODE_LIST_UPLOAD_ERR = '[Rest Error] [branchCodeList] GetBranchCodeListUploadError',

  GET_BRANCH_CODES_UPLOAD_ERROR_RESPONSE = '[Rest Call] [branchCodeList] GetBranchCodesUploadErrorResponse',
  GET_BRANCH_CODES_UPLOAD_ERROR_RESPONSE_RES = '[Rest Result] [branchCodeList] GetBranchCodesUploadErrorResponseResults',
  GET_BRANCH_CODES_UPLOAD_ERROR_RESPONSE_ERR = '[Rest Error] [branchCodeList] GetBranchCodesUploadErrorResponseError',

  GET_BRANCHCODE_PHAR_LIST = '[Rest Call] [branchCodeList] GetBranchCodePharmacyList',
  GET_BRANCHCODE_PHAR_LIST_RES = '[Rest Result] [branchCodeList] GetBranchCodePharmacyListResults',
  GET_BRANCHCODE_PHAR_LIST_ERR = '[Rest Error] [branchCodeList] GetBranchCodePharmacyListError',
}

export const GetBranchCodesFromList = createAction(BranchCodeIdActionTypes.GET_BRANCHCODEID_LIST, props<{}>());
export const GetBranchCodesFromListResults = createAction(BranchCodeIdActionTypes.GET_BRANCHCODEID_LIST_RES, props<{ branchCodeIdListData: any }>());
export const GetBranchCodesFromListError = createAction(BranchCodeIdActionTypes.GET_BRANCHCODEID_LIST_ERR, props<{ message: string }>());

export const SetBranchCodeColumns = createAction(BranchCodeIdActionTypes.SET_BRANCHCODE_COLUMNS, props<{ columns: BranchCodeColumnDto[] }>());
export const SetBranchCodeColumnsResults = createAction(BranchCodeIdActionTypes.SET_BRANCHCODE_COLUMNS_RES, props<{ columns: BranchCodeColumnDto[] }>());
export const SetBranchCodeColumnsError = createAction(BranchCodeIdActionTypes.SET_BRANCHCODE_COLUMNS_ERR, props<{ message: string }>());


export const GetBranchCodesToList = createAction(BranchCodeIdActionTypes.GET_BRANCHCODES_TO_LIST, props<{ id: number, branchCodeType: string }>());
export const GetBranchCodesToListResults = createAction(BranchCodeIdActionTypes.GET_BRANCHCODES_TO_LIST_RES, props<{ brachCodeTypeList: BranchCodeOutputDto }>());
export const GetBranchCodesToListError = createAction(BranchCodeIdActionTypes.GET_BRANCHCODES_TO_LIST_ERR, props<{ message: string }>());

export const GetHospitalNameList = createAction(BranchCodeIdActionTypes.GET_HOSPITAL_NAME_LIST, props<{ Id: number, dataType: string }>());
export const GetHospitalNameListResults = createAction(BranchCodeIdActionTypes.GET_HOSPITAL_NAME_LIST_RES, props<{ hospitalNameList: any }>());
export const GetHospitalNameListError = createAction(BranchCodeIdActionTypes.GET_HOSPITAL_NAME_LIST_ERR, props<{ message: string }>());

export const GetHospitalBranchCodeList = createAction(BranchCodeIdActionTypes.GET_HOSPITAL_BRANCH_CODE_LIST, props<{ Id: number, dataType: string, skip?: any, take?: any }>());
export const GetHospitalBranchCodeListResults = createAction(BranchCodeIdActionTypes.GET_HOSPITAL_BRANCH_CODE_LIST_RES, props<{ hospitalBranchCodeList: any }>());
export const GetHospitalBranchCodeListError = createAction(BranchCodeIdActionTypes.GET_HOSPITAL_BRANCH_CODE_LIST_ERR, props<{ message: string }>());

export const GetHospitalBranchCodeSearchList = createAction(BranchCodeIdActionTypes.GET_HOSPITAL_BRANCH_CODE_SEARCH_LIST, props<{ Id: number, dataType: string, skip?: any, take?: any, searchText: string }>());
export const GetHospitalBranchCodeSearchListResults = createAction(BranchCodeIdActionTypes.GET_HOSPITAL_BRANCH_CODE_SEARCH_LIST_RES, props<{ hospitalBranchCodeList: any }>());
export const GetHospitalBranchCodeSearchListError = createAction(BranchCodeIdActionTypes.GET_HOSPITAL_BRANCH_CODE_SEARCH_LIST_ERR, props<{ message: string }>());

export const GetHospitalBranchCodeToHidList = createAction(BranchCodeIdActionTypes.GET_HOSPITAL_BRANCH_CODE_TO_HID_LIST, props<{ Id: number, dataType: string }>());
export const GetHospitalBranchCodeToHidListResults = createAction(BranchCodeIdActionTypes.GET_HOSPITAL_BRANCH_CODE_TO_HID_LIST_RES, props<{ hospitalBranchCodeToHidList: any }>());
export const GetHospitalBranchCodeToHidListError = createAction(BranchCodeIdActionTypes.GET_HOSPITAL_BRANCH_CODE_TO_HID_LIST_ERR, props<{ message: string }>());

export const GetPharmacyNameList = createAction(BranchCodeIdActionTypes.GET_PHARMACY_NAME_LIST, props<{ Id: number, dataType: string }>());
export const GetPharmacyNameListResults = createAction(BranchCodeIdActionTypes.GET_PHARMACY_NAME_LIST_RES, props<{ pharmacyNameList: any }>());
export const GetPharmacyNameListError = createAction(BranchCodeIdActionTypes.GET_PHARMACY_NAME_LIST_ERR, props<{ message: string }>());

export const GetPharmacyBranchCodeList = createAction(BranchCodeIdActionTypes.GET_PHARMACY_BRANCH_CODE_LIST, props<{ Id: number, dataType: string, skip?: any, take?: any }>());
export const GetPharmacyBranchCodeListResults = createAction(BranchCodeIdActionTypes.GET_PHARMACY_BRANCH_CODE_LIST_RES, props<{ pharmacyBranchCodeList: any }>());
export const GetPharmacyBranchCodeListError = createAction(BranchCodeIdActionTypes.GET_PHARMACY_BRANCH_CODE_LIST_ERR, props<{ message: string }>());

export const GetPharmacyBranchCodeSearchList = createAction(BranchCodeIdActionTypes.GET_PHARMACY_BRANCH_CODE_SEARCH_LIST, props<{ Id: number, dataType: string, skip?: any, take?: any, searchText: string }>());
export const GetPharmacyBranchCodeSearchListResults = createAction(BranchCodeIdActionTypes.GET_PHARMACY_BRANCH_CODE_SEARCH_LIST_RES, props<{ pharmacyBranchCodeList: any }>());
export const GetPharmacyBranchCodeSearchListError = createAction(BranchCodeIdActionTypes.GET_PHARMACY_BRANCH_CODE_SEARCH_LIST_ERR, props<{ message: string }>());

export const GetPharmacyBranchCodeToPidList = createAction(BranchCodeIdActionTypes.GET_PHARMACY_BRANCH_CODE_TO_PID_LIST, props<{ Id: number, dataType: string }>());
export const GetPharmacyBranchCodeToPidListResults = createAction(BranchCodeIdActionTypes.GET_PHARMACY_BRANCH_CODE_TO_PID_LIST_RES, props<{ pharmacyBranchCodeToPidList: any }>());
export const GetPharmacyBranchCodeToPidListError = createAction(BranchCodeIdActionTypes.GET_PHARMACY_BRANCH_CODE_TO_PID_LIST_ERR, props<{ message: string }>());


export const AddUpdateBranchCode = createAction(BranchCodeIdActionTypes.ADD_UPDATE_BRANCH_CODE, props<{ addUpdateBranchCode: HosiptalBranchCodeByIdDto }>());
export const AddUpdateBranchCodeResults = createAction(BranchCodeIdActionTypes.ADD_UPDATE_BRANCH_CODE_RES, props<{ result: any }>());
export const AddUpdateBranchCodeError = createAction(BranchCodeIdActionTypes.ADD_UPDATE_BRANCH_CODE_ERR, props<{ message: string }>());

export const ExportData = createAction(BranchCodeIdActionTypes.EXPORT_DATA, props<{ Id: number, dataType: string }>());
export const ExportDataResults = createAction(BranchCodeIdActionTypes.EXPORT_DATA_RES, props<{ result: any }>());
export const ExportDataError = createAction(BranchCodeIdActionTypes.EXPORT_DATA_ERR, props<{ message: string }>());

export const GetBranchCodeListUpload = createAction(BranchCodeIdActionTypes.GET_BRANCHCODE_LIST_UPLOAD, props<{}>());
export const GetBranchCodeListUploadResults = createAction(BranchCodeIdActionTypes.GET_BRANCHCODE_LIST_UPLOAD_RES, props<{ branchcodeListUpload: BranchCodeListUploadOutputDto }>())
export const GetBranchCodeListUploadError = createAction(BranchCodeIdActionTypes.GET_BRANCHCODE_LIST_UPLOAD_ERR, props<{ message: string }>())

export const UploadBranchCodeList = createAction(BranchCodeIdActionTypes.UPLOAD_BRANCHCODE_LIST, props<{branchcodeFile: FormData}>());
export const UploadBranchCodeListResults = createAction(BranchCodeIdActionTypes.UPLOAD_BRANCHCODE_LIST_RES, props<{response: SubmitResponse}>());
export const UploadBranchCodeListError = createAction(BranchCodeIdActionTypes.UPLOAD_BRANCHCODE_LIST_ERR, props<{message: string}>());

export const GetBranchCodesUploadErrorResponse = createAction(BranchCodeIdActionTypes.GET_BRANCH_CODES_UPLOAD_ERROR_RESPONSE, props<{ importId: string }>());
export const GetBranchCodesUploadErrorResponseResults = createAction(BranchCodeIdActionTypes.GET_BRANCH_CODES_UPLOAD_ERROR_RESPONSE_RES, props<{ branchCodesUploadErrorResponse: BranchCodeListUploadErrorReponseOutputDto }>())
export const GetBranchCodesUploadErrorResponseError = createAction(BranchCodeIdActionTypes.GET_BRANCH_CODES_UPLOAD_ERROR_RESPONSE_ERR, props<{ message: string }>())

export const GetBranchCodePharmacyList = createAction(BranchCodeIdActionTypes.GET_BRANCHCODE_PHAR_LIST, props<{ importFileType: string, pIDValue: number }>());
export const GetBranchCodePharmacyListResults = createAction(BranchCodeIdActionTypes.GET_BRANCHCODE_PHAR_LIST_RES, props<{ branchCodePharList: any }>())
export const GetBranchCodePharmacyListError = createAction(BranchCodeIdActionTypes.GET_BRANCHCODE_PHAR_LIST_ERR, props<{ message: string }>())
