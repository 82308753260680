import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { HIdOutputDto, ValidateNameRequest, saveHID, ChainStoreResponse, UserAccessResponse, MoveHIDRequest, HospitalOutputDTO, AddHidResponse, PrescriberListUploadResponse } from "../models/hId-list/hId-output-dto";
import { UserSession } from "../models/user";
import { UserState } from "../store/user.reducer";
import { BaseMhService } from "./basemh.service";
import { throwError} from 'rxjs';
import { ValidationNameResponse } from "../models/submit-response";

@Injectable({
    providedIn: 'root'
})

export class hIdListService extends BaseMhService {
  constructor(protected http: HttpClient, protected store: Store<{ userState: UserState }>) {
    super(http, store);
  }

  public getHIdList(corpId: number) {
    return this.http.get(environment.apiBaseUrl + 'v1/CorporateParent/GetHospitalByCorpId/?CorpId=' + corpId, { headers: this.getHeaders() })
      .pipe(
        map((response: HIdOutputDto) => { return response }),
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving Hospital data" }))
      );
  }

  public getHIdById(hid: number) {
    return this.http.get(environment.apiBaseUrl + 'v1/Hospital/GetHositalByHID/?HID=' + hid, { headers: this.getHeaders() })
      .pipe(
        map((response: HospitalOutputDTO) => {  return response }),
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving Hospital data" }))
      );
  }

  public verifyWrittenLocation(hid: number) {
    return this.http.get(environment.apiBaseUrl + 'v1/Hospital/GetVerifyWrittenLocations/?HID=' + hid, { headers: this.getHeaders() })
      .pipe(
        map((response: any) => {  return response }),
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving GetVerifyWrittenLocations" }))
      );
  }

  public addHId(saveData: saveHID) {
    const session: UserSession = this.getStoreUserSession();
    saveData.uid = session.uid;
    return this.http
      .post(environment.apiBaseUrl + 'v1/Hospital/AddUpdateHospital', saveData, { headers: this.getHeaders() })
      .pipe(
        map((response: AddHidResponse) => {
          if (!response.status.hasError){
            response.saveData = saveData;
            return response;
          }
          else
            throwError(response.status.errorMessage);
        }),       
        catchError(error => this.errorHandler({ error, operation: "Error while Adding HID" }))
      );
  }

  public prescriberListUpload(saveData: saveHID) {
    return this.http
      .post(environment.apiBaseUrl + 'v1/Hospital/PrescriberListUpload', saveData, { headers: this.getHeaders() })
      .pipe(
        map((response: PrescriberListUploadResponse) => {
          if (!response.status.hasError)
            return response;
          else
            throwError(response.status.errorMessage);
        }),       
        catchError(error => this.errorHandler({ error, operation: "Error during Prescriber List Upload" }))
      );
  }

  public validateName(validateNameData: ValidateNameRequest) {
    return this.http
      .post(environment.apiBaseUrl + 'v1/Common/ValidateName', validateNameData, { headers: this.getHeaders() })
      .pipe(
        map((response: ValidationNameResponse) => {
          if (response.data.validationData.id == 0)
            return response;
          else if(response.data.validationData.id == 1)
            return response;
          else
            throwError(response.data.validationData.result);
        }),       
        catchError(error => this.errorHandler({ error, operation: "Error while searching the hospital name" }))
      );
  }

  public getChainStoreList() {
    const session: UserSession = this.getStoreUserSession();
    return this.http
      .get(environment.apiBaseUrl + 'v1/common/GetChainStoreList' ,{ headers: this.getHeaders() })
      .pipe(
        map((response: ChainStoreResponse) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving chain store List Data" }))
      );
  }
  
  public getChainStoreListByHid(hid: number) {
    const session: UserSession = this.getStoreUserSession();
    return this.http
      .get(environment.apiBaseUrl + 'v1/common/GetChainStoreListByHid/?hid=' + hid ,{ headers: this.getHeaders() })
      .pipe(
        map((response: ChainStoreResponse) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving chain store List by HId Data" }))
      );
  }

  public getUserAcess(UId: number,DeptID:number,PageId:number,PermId:number) {
    const session: UserSession = this.getStoreUserSession();
    return this.http.get(environment.apiBaseUrl + 'v1/common/GetUserAcess?UId=' + session.uid +'&&DeptID=' + DeptID + '&&PageId=' + PageId + '&&PermId=' + PermId , { headers: this.getHeaders() })
    .pipe(
        map((response: UserAccessResponse) => response    ),
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving user access data" }))
      );
  }

  public UpdateHospitalsCorpID(moveHIDRequest: MoveHIDRequest) {
    return this.http
      .post(environment.apiBaseUrl + 'v1/Hospital/UpdateHospitalsCorporateID', moveHIDRequest, { headers: this.getHeaders() })
      .pipe(
        map((response: any) => response),        
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving Name data" }))
      );
  }
}