import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/store/user.reducer';
import { GetUserSubmittedSupportTickets } from 'src/app/store/user.actions';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SubmittedSupportTicket } from 'src/app/models/sales-force-ticket';
import { Observable, Subscription } from 'rxjs';
import { TableGetOptions } from 'src/app/models/received-detail';
import { SubmitTicketPopupComponent } from '../../help-button/submit-ticket-popup/submit-ticket-popup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'user-submitted-tickets-table',
  templateUrl: './user-submitted-tickets-table.component.html',
  styleUrls: ['./user-submitted-tickets-table.component.scss']
})
export class UserSubmittedTicketsTableComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('mhtable') mhtable: MatTable<SubmittedSupportTicket>;
  public userSubmittedTicketsEffect$: Observable<SubmittedSupportTicket>;
  public userSubmittedTicketsData: SubmittedSupportTicket[];
  ticketHeaders: string[] = ['ticketID', 'submittedDate','pid', 'pharmacy','category','subject', 'ticketDescription', 'resolutionNotes'];
  userSubmittedTicketsDataSource;
  subs: Subscription = new Subscription;

  constructor(private dialog: MatDialog,
    public store: Store<{ userState: UserState }>) {
    const options: TableGetOptions = new TableGetOptions();
    this.store.dispatch( GetUserSubmittedSupportTickets( { options} ) );}

  ngOnInit(): void {
    this.subs.add(
      this.store.select(state => state.userState.submittedTicketList).subscribe((data) => {
        if(data != null) {
          this.userSubmittedTicketsDataSource = new MatTableDataSource(data);
          this.userSubmittedTicketsDataSource.paginator = this.paginator;
        }
      })
    )
  }

  AddNewTicketClicked() {
    this.dialog.open(SubmitTicketPopupComponent, {disableClose: true, panelClass: ['mh-dialog','submit-ticket-dialog']})
  };

  ngOnDestroy(){
    this.subs.unsubscribe();
  }
}
