<!-- Help => Help Guide Dialog -->
<div class="submit-salesforce-ticket-dialog">
  <div mat-dialog-title class="dialog-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <h3 cdkDragHandle>Help Guide - 340B Architect</h3>
  </div>
  <div>
    <div class="dialog-body">
      <div class="help-guide-body">
        <iframe [src]="helpGuideUrl" height="500px" width="1000" frameborder='0' scrolling='yes'></iframe>
      </div>
    </div>
    <div class="dialog-footer">
      <button mat-stroked-button type="button" class="discard-btn" (click)="onCloseClick()">Close</button>
    </div>
  </div>
</div>
