import { createAction, props } from '@ngrx/store';
import { Holiday } from '../models/education';


export enum EducationActionTypes {
  HOLIDAYS_GET = '[Rest Call] [Education] HolidaysGet',
  HOLIDAYS_GET_RES = '[Rest Result] [Education] HoldaysRes',
  HOLIDAYS_GET_ERR = '[Rest Error] [Education] HolidaysErr',

  HOLIDAYS_UPDATE = '[Rest Call] [Education] HolidaysUpdate',
  HOLIDAYS_UPDATE_RES = '[Rest Result] [Education] HolidaysUpdateRes',
  HOLIDAYS_UPDATE_ERR = '[Rest Error] [Education] HolidaysUpdateErr',

  HOLIDAYS_ADD = '[Rest Call] [Education] HolidaysAdd',
  HOLIDAYS_ADD_RES = '[Rest Result] [Education] HoldaysAddRes',
  HOLIDAYS_ADD_ERR = '[Rest Error] [Education] HolidaysAddErr',

  HOLIDAYS_DELETE = '[Rest Call] [Education] HolidaysDelete',
  HOLIDAYS_DELETE_RES = '[Rest Result] [Education] HoldaysDeleteRes',
  HOLIDAYS_DELETE_ERR = '[Rest Error] [Education] HolidaysDeleteErr',
}


export const GetHolidays = createAction(EducationActionTypes.HOLIDAYS_GET);
export const GetHolidaysResults = createAction(EducationActionTypes.HOLIDAYS_GET_RES, props<{data: Holiday[]}>());
export const GetHolidaysError = createAction(EducationActionTypes.HOLIDAYS_GET_ERR, props<{errorMessage: string}>());

export const UpdateHolidays = createAction(EducationActionTypes.HOLIDAYS_UPDATE, props<{holiday: Holiday}>());
export const UpdateHolidaysResults = createAction(EducationActionTypes.HOLIDAYS_UPDATE_RES, props<{holiday: Holiday}>());
export const UpdateHolidaysError = createAction(EducationActionTypes.HOLIDAYS_UPDATE_ERR, props<{errorMessage: string}>());

export const AddHolidays = createAction(EducationActionTypes.HOLIDAYS_ADD, props<{holiday: Holiday}>());
export const AddHolidaysResults = createAction(EducationActionTypes.HOLIDAYS_ADD_RES, props<{holiday: Holiday}>());
export const AddHolidaysError = createAction(EducationActionTypes.HOLIDAYS_ADD_ERR, props<{errorMessage: string}>());

export const DeleteHolidays = createAction(EducationActionTypes.HOLIDAYS_DELETE, props<{id: number}>());
export const DeleteHolidaysResults = createAction(EducationActionTypes.HOLIDAYS_DELETE_RES);
export const DeleteHolidaysError = createAction(EducationActionTypes.HOLIDAYS_DELETE_ERR, props<{errorMessage: string}>());