import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap } from "rxjs/operators";
import { of as observableOf } from 'rxjs';
import { PharmacyListService } from "src/app/service/hid-pharmacy/pharmacy-list.service";
import { GetPharmacyList, GetPharmacyListError, GetPharmacyListResults } from "./pharmacy-list-actions";



@Injectable()
export class PharmacyStoreEffects {

    constructor(private pharmacyListSerivce: PharmacyListService, private actions$: Actions ){

    }


    GetPharmacyListEffect$ = createEffect( () => this.actions$.pipe(
        ofType(GetPharmacyList),
        switchMap(action => 
            this.pharmacyListSerivce.getPharmacyList(action.HID)
            .pipe(
                map(
                    data => {
                        return GetPharmacyListResults( {pharmacyList: data} ) 
                    }
                ),
                catchError(
                    error => observableOf(GetPharmacyListError({message: error}))
                )
            )
        )
    ));



}