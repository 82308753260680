import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ApplyQualRuleResponse, ApplyQualRules, QualificationRulePharmacy, QualificationRulesResponse, SelectedQualRules } from "../models/qualification-rules/qualification-rules-reponse";
import { UserSession } from "../models/user";
import { UserState } from "../store/user.reducer";
import { BaseMhService } from "./basemh.service";

@Injectable({
  providedIn: 'root'
})

export class QualRulesListService extends BaseMhService {

  constructor(protected http: HttpClient, protected store: Store<{ userState: UserState }>) {
    super(http, store);
  }

  public getQualRulesList() {
    const session: UserSession = this.getStoreUserSession();
    return this.http
      .get(environment.apiBaseUrl + 'bulkupdatequalrules/v1/BulkUpdateQualRules/GetQualRules', { headers: this.getHeaders() })
      .pipe(
        map((response: QualificationRulesResponse[]) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Qualification Rules Data" }))
      );
  }

  public getPharmaciesByUser() {
    const session: UserSession = this.getStoreUserSession();

    return this.http
      .get(environment.apiBaseUrl + "bulkupdatequalrules/v1/BulkUpdateQualRules/GetPharmaciesByUser?userId=" + session.uid + "&corpId=" + session.corpID, { headers: this.getHeaders() })
      .pipe(
        map((response: QualificationRulePharmacy[]) => response),
        catchError(this.errorHandler)
      );
  }

  public applyQualRule(qualRules: SelectedQualRules[]) {
    const session: UserSession = this.getStoreUserSession();
    const data: ApplyQualRules = { rules: qualRules, UID: session.uid }
    return this.http
      .post(environment.apiBaseUrl + "bulkupdatequalrules/v1/BulkUpdateQualRules/ApplyUpdate", data, { headers: this.getHeaders() })
      .pipe(
        map((response: ApplyQualRuleResponse) => response),
        catchError(this.errorHandler)
      );
  }
}