import { createAction, props } from "@ngrx/store";
import { HIdColumnDto } from "src/app/models/hId-list/hid-column-dto";
import { AddHidResponse, ChainStoreResponse, HIdOutputDto, HospitalOutputDTO, saveHID, UserAccessResponse, ValidateNameRequest } from "src/app/models/hId-list/hId-output-dto";
import { MoveHIDRequest, MoveHIDResponse} from "src/app/models/hId-list/hId-output-dto";
import { ValidationNameResponse } from "src/app/models/submit-response";

export enum HIdActionTypes{
    GET_HID_LIST='[Rest Call] [hIdList] GetHIdList]',
    GET_HID_LIST_RES='[Rest Result] [hIdList] GetHIdList]',
    GET_HID_LIST_ERR='[Rest Error] [hIdList] GetHIdList]',

    SET_HID_LIST_COLUMNS = '[Rest Call] [hIdList] SetHIdListColumns',
    SET_HID_LIST_COLUMNS_RES = '[Rest Result] [hIdList] SetHIdListColumnsResults',
    SET_HID_LIST_COLUMNS_ERR = '[Rest Error] [hIdList] SetHIdListColumnsError',

    ADD_HID = '[Rest Call] [Order] AddHID',
    ADD_HID_RES = '[Rest Result] [Order] AddHIDResults',
    ADD_HID_ERR = '[Rest Error] [Order] AddHIDError',

    VALIDATE_NAME = '[Rest Call] [nameSearch] ValidateName',
    VALIDATE_NAME_RES = '[Rest Result] [nameSearch] ValidateNameResults',
    VALIDATE_NAME_ERR = '[Rest Error] [nameSearch] ValidateNameError',

    GET_CHAIN_LIST = "[Rest Call] [chainList] GetChainList",
    GET_CHAIN_RES = "[Rest Result] [chainList] GetChainList",
    GET_CHAIN_ERR = "[Rest Error] [chainList] GetChainList",

    GET_CHAIN_LISTBYHId = "[Rest Call] [chainList] GetChainListByHid",
    GET_CHAINBYHId_RES = "[Rest Result] [chainList] GetChainListByHid",
    GET_CHAINBYHId_ERR = "[Rest Error] [chainList] GetChainListByHid",

    GET_USERACCESS_LIST = "[Rest Call] [userAccessList] GetUserAccessList",
    GET_USERACCESS_RES = "[Rest Result] [userAccessList] GetUserAccessList",
    GET_USERACCESS_ERR = "[Rest Error] [userAccessList] GetUserAccessList",
    
    GET_MGMT_USERACCESS_LIST = "[Rest Call] [mgmtUserAccessList] GetMgmtUserAccessList",
    GET_MGMT_USERACCESS_RES = "[Rest Result] [mgmtUserAccessList] GetMgmtUserAccessList",
    GET_MGMT_USERACCESS_ERR = "[Rest Error] [mgmtUserAccessList] GetMgmtUserAccessList",

    GET_IMP_USERACCESS_LIST = "[Rest Call] [impUserAccessList] GetImpUserAccessList",
    GET_IMP_USERACCESS_RES = "[Rest Result] [impUserAccessList] GetImpUserAccessList",
    GET_IMP_USERACCESS_ERR = "[Rest Error] [impUserAccessList] GetImpUserAccessList",

    UPDATE_HOSPITAL_CORPORATEID = '[Rest Call] [corpIdList] UpdateHospitalsCorpID',
    UPDATE_HOSPITAL_CORPORATEID_RES = '[Rest Result] [corpIdList] UpdateHospitalsCorpIDResults',
    UPDATE_HOSPITAL_CORPORATEID_ERR = '[Rest Error] [corpIdList] UpdateHospitalsCorpIDError',

    VIEW_HOSPITAL_CORPORATEID = '[Rest Call] [hid] GetHIdById',
    VIEW_HOSPITAL_CORPORATEID_RES = '[Rest Result] [hid] GetHIdById',
    VIEW_HOSPITAL_CORPORATEID_ERR = '[Rest Error] [hid] GetHIdById',

    VERIFY_WRITTEN_LOCATIONS = '[Rest Call] [hid] VerifyWrittenLocations',
    VERIFY_WRITTEN_LOCATIONS_RES = '[Rest Result] [hid] VerifyWrittenLocations',
    VERIFY_WRITTEN_LOCATIONS_ERR = '[Rest Error] [hid] VerifyWrittenLocations',

    PRESCRIBER_LIST_UPLOAD = '[Rest Call] [hid] PrescriberListUpload',
    PRESCRIBER_LIST_UPLOAD_RES = '[Rest Result] [hid] PrescriberListUpload',
    PRESCRIBER_LIST_UPLOAD_ERR = '[Rest Error] [hid] PrescriberListUpload',
}

export const GetHIdList = createAction(HIdActionTypes.GET_HID_LIST, props<{corpId: number}>());
export const GetHIdListResults = createAction(HIdActionTypes.GET_HID_LIST_RES, props<{ hIdList: HIdOutputDto }>());
export const GetHIdListError = createAction(HIdActionTypes.GET_HID_LIST_ERR, props<{ message: string }>());

export const SetHIdColumns = createAction(HIdActionTypes.SET_HID_LIST_COLUMNS, props<{ columns: HIdColumnDto[] }>())
export const SetHIdColumnsResults = createAction(HIdActionTypes.SET_HID_LIST_COLUMNS_RES, props<{ columns: HIdColumnDto[] }>())
export const SetHIdColumnsError = createAction(HIdActionTypes.SET_HID_LIST_COLUMNS_ERR, props<{ message: string }>())

export const AddHId = createAction(HIdActionTypes.ADD_HID, props<{saveData: saveHID}>());
export const AddHIdResults = createAction(HIdActionTypes.ADD_HID_RES, props<{addHidResponse: AddHidResponse }>());
export const AddHIdError = createAction(HIdActionTypes.ADD_HID_ERR, props<{ message: string }>());

export const ValidateName = createAction(HIdActionTypes.VALIDATE_NAME, props<{validateNameData: ValidateNameRequest}>());
export const ValidateNameResults = createAction(HIdActionTypes.VALIDATE_NAME_RES, props<{ validateNameResponse: ValidationNameResponse }>());
export const ValidateNameError = createAction(HIdActionTypes.VALIDATE_NAME_ERR, props<{ message: string }>());

export const GetChainList = createAction(HIdActionTypes.GET_CHAIN_LIST,props<{}>());
export const GetChainResults = createAction(HIdActionTypes.GET_CHAIN_RES,props<{ chainList: ChainStoreResponse }>());
export const GetChainError = createAction(HIdActionTypes.GET_CHAIN_ERR,props<{ message: string }>());

export const GetChainListByHid = createAction(HIdActionTypes.GET_CHAIN_LISTBYHId,props<{hid: number }>());
export const GetChainListByHidResults = createAction(HIdActionTypes.GET_CHAINBYHId_RES,props<{ chainListByHId: ChainStoreResponse }>());
export const GetChainListByHidError = createAction(HIdActionTypes.GET_CHAINBYHId_ERR,props<{ message: string }>());

export const GetImpUserAccess = createAction(HIdActionTypes.GET_IMP_USERACCESS_LIST,props<{uId: number,deptID:number,pageId:number,permId:number}>());
export const GetImpUserAccessResults = createAction(HIdActionTypes.GET_IMP_USERACCESS_RES,props<{ userAccessResponse: UserAccessResponse }>());
export const GetImpUserAccessError = createAction(HIdActionTypes.GET_IMP_USERACCESS_ERR,props<{ message: string }>());

export const GetMgmtUserAccess = createAction(HIdActionTypes.GET_MGMT_USERACCESS_LIST,props<{uId: number,deptID:number,pageId:number,permId:number}>());
export const GetMgmtUserAccessResults = createAction(HIdActionTypes.GET_MGMT_USERACCESS_RES,props<{ userAccessResponse: UserAccessResponse }>());
export const GetMgmtUserAccessError = createAction(HIdActionTypes.GET_MGMT_USERACCESS_ERR,props<{ message: string }>());
export const GetUserAccess = createAction(HIdActionTypes.GET_USERACCESS_LIST,props<{uId: number,deptID:number,pageId:number,permId:number}>());
export const GetUserAccessResults = createAction(HIdActionTypes.GET_USERACCESS_RES,props<{ userAccessResponse: UserAccessResponse }>());
export const GetUserAccessError = createAction(HIdActionTypes.GET_USERACCESS_ERR,props<{ message: string }>());

export const UpdateHospitalsCorporateID =createAction(HIdActionTypes.UPDATE_HOSPITAL_CORPORATEID, props<{ moveHIDRequest: MoveHIDRequest  }>());
export const UpdateHospitalsCorporateIDResults =createAction(HIdActionTypes.UPDATE_HOSPITAL_CORPORATEID_RES, props<{moveHIDResponse: MoveHIDResponse }>());
export const UpdateHospitalsCorporateIDError = createAction(HIdActionTypes.UPDATE_HOSPITAL_CORPORATEID_ERR, props<{ message: string }>());

export const EditHidParent = createAction(HIdActionTypes.UPDATE_HOSPITAL_CORPORATEID,props<{ hid: number }>());
export const ViewHideParentResults = createAction(HIdActionTypes.UPDATE_HOSPITAL_CORPORATEID_RES, props<{ result: any }>());
export const ViewHidParentError = createAction(HIdActionTypes.UPDATE_HOSPITAL_CORPORATEID_ERR, props<{ message: string }>());

export const ViewHid = createAction(HIdActionTypes.VIEW_HOSPITAL_CORPORATEID,props<{ hid: number }>());
export const ViewHidResults = createAction(HIdActionTypes.VIEW_HOSPITAL_CORPORATEID_RES, props<{ result: any }>());
export const ViewHidError = createAction(HIdActionTypes.VIEW_HOSPITAL_CORPORATEID_ERR, props<{ message: string }>());

export const VerifyLocations = createAction(HIdActionTypes.VERIFY_WRITTEN_LOCATIONS,props<{ hid: number }>());
export const VerifyLocationsResults = createAction(HIdActionTypes.VERIFY_WRITTEN_LOCATIONS_RES, props<{ result: any }>());
export const VerifyLocationsError = createAction(HIdActionTypes.VERIFY_WRITTEN_LOCATIONS_ERR, props<{ message: string }>());

export const PrescriberListUpload = createAction(HIdActionTypes.PRESCRIBER_LIST_UPLOAD,props<{ prescriberListUpload: saveHID }>());
export const PrescriberListUploadResults = createAction(HIdActionTypes.PRESCRIBER_LIST_UPLOAD_RES, props<{ result: any }>());
export const PrescriberListUploadError = createAction(HIdActionTypes.PRESCRIBER_LIST_UPLOAD_ERR, props<{ message: string }>());