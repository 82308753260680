import { ContactSearchOutputDto } from "src/app/models/corpid-list/contactsearch"
import { CorpIdColumnDto } from "src/app/models/corpId-list/corpId-column-dto"
import { CorpIdOutputDto } from "src/app/models/corpId-list/corpId-output-dto"
import { AccountManagerOutput, CorporateParent, ValidationData } from "src/app/models/corpid-list/corporateparent"
import { CorpIdActionTypes } from "./corpid-list.actions"


//State to hold all corpId List data
export interface CorpIdState {
  corpIdList: CorpIdOutputDto
  columns: CorpIdColumnDto[],
  corporateParentDetails: CorporateParent[],
  contactSearch: ContactSearchOutputDto[]
  isValidCorpName: ValidationData,
  accountManager: AccountManagerOutput,
  corpSaveResult: any,
  corpDetailsData: any,
  treeview: any;
  exportData: any,
}

//Default initial state
export const initialState =
{
  corpIdList: null,
  columns: null,
  validationErrors: null,
  corpIdDetails: null,
  corporateParentDetails: null,
  contactSearch: null,
  isValidCorpName: null,
  accountManager: null,
  corpSaveResult: null,
  corpDetailsData: null,
  treeview: null,
  exportData: null
}

export function corpIdListReducer(state = initialState, action: any): CorpIdState {

  switch (action.type) {

    case CorpIdActionTypes.GET_CORPID_LIST_RES: {
      return { ...state, corpIdList: action.corpIdList }
    }

    case CorpIdActionTypes.SET_CORPID_LIST_COLUMNS: {
      return { ...state, columns: action.columns }
    }

    case CorpIdActionTypes.ADD_CORPORATE_PARENT: {
      return { ...state, corporateParentDetails: action.newCorporateParent }
    }
    case CorpIdActionTypes.VIEW_CORPORATE_PARENT: {
      return { ...state, corpDetailsData: action.corpId }
    }
    case CorpIdActionTypes.GET_CONTACT_SEARCH_RES: {
      return { ...state, contactSearch: action.contactSearch };
    }
    case CorpIdActionTypes.IS_VALID_CORPNAME_RES: {
      return { ...state, isValidCorpName: action.result };
    }
    case CorpIdActionTypes.ADD_CORPORATE_PARENT_RES: {
      return { ...state, corpSaveResult: action.newCorporateParent };
    }
    case CorpIdActionTypes.GET_ACCOUNT_MANAGER_RES: {
      return {
        ...state, accountManager: action.accountManager,
      };
    }

    case CorpIdActionTypes.EXPORT_DATA_RES: {
      return { ...state, exportData: action.result }
    }

    default: {
      return state;
    }
  }
}