import { NgModule } from "@angular/core";
import { LinkifyPipe } from "./linkify.pipe";

@NgModule({
  imports: [
  ],
  declarations: [ 
    LinkifyPipe
  ],
  exports: [
    LinkifyPipe
  ]
})
export class CustomPipesModule {}