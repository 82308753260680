import { PermissionType, UAMUserApplicationPage, UserApplications, UserSettings } from './../../models/user';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Applications, ApplicationMenuSection, ApplicationMenuItem, ApplicationIds } from '../../models/applications';
import { UiState } from '../../store/ui-state.reducer';
import { NavigationEnd, Router } from '@angular/router';
import { UserState } from 'src/app/store/user.reducer';
import { UserSession } from '../../models/user';
import { environment } from '../../../environments/environment';
import { ReferralState } from 'src/app/store/automated-referral.reducer';
import { OrderState } from 'src/app/store/order.reducer';
import { AnalyticsState, ReportPage } from '../../models/analytics';
import { ChangeReportPage } from '../../store/analytics.actions';
import { GetBackupPage, PageSelected } from 'src/app/store/application-list.actions';
import { Pages } from 'src/app/app-pages';
import { HealthSystemValidationResponse } from 'src/app/models/health-system-view/health-system';
import { GetCorporateParentValidationStatus } from 'src/app/store/user.actions';
import { Pharmacy } from 'src/app/models/pharmacy';
import { SharedDataService } from 'src/app/service/shared-data.service';
import { GatewayState } from 'src/app/store/gateway/gateway.reducer';
import { filter } from 'rxjs/operators';
import { UAMService } from 'src/app/service/uam.service';
import { GetChainSettings } from 'src/app/store/gateway/gateway.actions';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})

export class LeftMenuComponent implements OnInit, OnDestroy {

  public version: string;
  public applications$: Observable<Applications>;
  public userState$: Observable<UserState>;
  public referralState$: Observable<ReferralState>;
  public analyticsState$: Observable<AnalyticsState>;
  public gatewayState$: Observable<GatewayState>;
  public selectedPage: string;
  public menuVisible = false;
  public expandedSection: string;
  private userDisplayHeight = 450;
  disableCSMenu: boolean = true;
  csDataLoaded: boolean = false;
  currentPage: number = -1;
  allowedPages: UAMUserApplicationPage[];
  appSections: ApplicationMenuSection[];
  filteredSections: ApplicationMenuSection[];
  analyticsReportPages: ReportPage[];
  selectedReportPage: string;
  lastApp: number;
  lastPidRedirectFlag: number;
  userSettings: UserSettings;
  userSession: UserSession;
  userPharmacies: Pharmacy[];
  isMHIEmployee: boolean;
  showAutomatedReferrals: boolean;
  showDispenseFeeManagement: boolean = false;
  menuItemInvisible: number = 0;
  menuItemDisabled: number = 1;
  menuItemEnabled: number = 2;
  displayMap = {};
  healthSystemValidationResponse: HealthSystemValidationResponse;
  validation: boolean;
  currentApp: number;
  hasHealthSystemView: boolean;
  defaultPageFlag: boolean = true;
  userApps: UserApplications;
  subs: Subscription = new Subscription;

  constructor(private store: Store<{ uiState: UiState, applications: Applications, userState: UserState, referralState: ReferralState, orderState: OrderState, analyticsState: AnalyticsState, gatewayState: GatewayState }>,
    public router: Router, public sharedService: SharedDataService, private service: UAMService) {
    this.hasHealthSystemView = (this.service.getPermissionType("HEALTHSYSTEMVIEW") !== PermissionType.NoAccess);
    this.version = environment.appVersion;

    this.subs.add(
      this.store.select(state => state.uiState.MenuVisible).subscribe((val) => {
        if (val !== this.menuVisible) {
          setTimeout(() => {
            this.menuVisible = val;
          });
        }
      })
    )

    this.subs.add(
      this.store.select(state => state.userState).subscribe(data => {
        if (data.settings != null) {
          this.userDisplayHeight = data.settings.displayHeight;
          this.userSettings = data.settings;
          this.isMHIEmployee = data.settings.isMHIUser;
        }
        if (data.currentApp) {
          this.currentApp = data.currentApp;
        }
        if (data.healthSystemValidationResponse){
          this.healthSystemValidationResponse= data.healthSystemValidationResponse;
          this.validation = this.healthSystemValidationResponse?.data?.healthSystemViewValidationData?.findIndex(x=>x.daysFromValidation===null || x.daysFromValidation>=80)!==-1
        }
        if (data.userAppPages){
          this.allowedPages = data.userAppPages;
        }
        if (data.userApps){
          this.userApps = data.userApps;
        }
      })
    )

    this.subs.add(
      // extra measure to tackle scenario where user browses via updating the url directly
      this.router.events.subscribe((val) => {
        if (val instanceof NavigationEnd) {
          if (this.allowedPages.length > 0) {
            // try to find requested page url in the users list of accessible pages and within our custom page list
            // sorting by pageID to handle Standard Reports, which exists in 340b Architect and NDCA with the same url
            let requestedPage = this.allowedPages.sort((a, b) => a.pageID - b.pageID).find(p => val.urlAfterRedirects.includes(p.ngPageURL));
            let customPage = Object.entries(Pages).map(p => p[1].ngPageURL).find(x => val.urlAfterRedirects.includes(x));
            // check user permissions, send to default page if trying to access illegally
            let shouldSendToDefault = false;
            if (!customPage) {
              if (!requestedPage) {
                shouldSendToDefault = true;
              }
              else {
                let checkDisplayMap = this.filterSections(true);
                if (checkDisplayMap[requestedPage.pageID] != this.menuItemEnabled) {
                  shouldSendToDefault = true;
                }
                if (requestedPage.appID == ApplicationIds.ArchitectNDC && (this.userSession.isNDCA === 0 || this.userSession.pharmType === 1)) {
                  shouldSendToDefault = true;
                }
              }
              if (shouldSendToDefault) {
                this.sendToDefaultPage();
              }
            }
          }
        }
      })
    )

  }

  ngOnInit() {
    this.applications$ = this.store.select(state => state.applications);
    this.userState$ = this.store.select(state => state.userState);
    this.referralState$ = this.store.select(state => state.referralState);
    this.gatewayState$ = this.store.select(state => state.gatewayState);

    this.store.dispatch(GetCorporateParentValidationStatus({}));

    this.subs.add(
      //Check if Referral Matching menu option should show for current PID
      this.referralState$.subscribe(data => {
        this.showAutomatedReferrals = data.referralMenu;
      })
    );

    //Check if Gateway Dispense Fee Management menu option should show for current CSGID
    this.subs.add(
      this.store.select((store) => store.gatewayState.chainData).pipe(filter((chainData) => chainData !== null)).subscribe((chainData) => {
          this.store.dispatch(GetChainSettings({ chainId: chainData.data.chainID }));
        })
    );

    this.subs.add(
      this.store.select((store) => store.gatewayState.chainSettings).pipe(filter((chainSettings => chainSettings !== null))).subscribe((chainSettings) => {
        if (chainSettings.dispenseFeeMonitoringFlag == 'YES')
          this.showDispenseFeeManagement = true;
        else
          this.showDispenseFeeManagement = false;

        if (this.appSections && this.csDataLoaded && this.allowedPages)
          this.displayMap = this.filterSections(false);
      })
    );

    this.subs.add(
      this.store.select(state => state.orderState.centralsplitgrps).subscribe((csdata) => {
        this.disableCSMenu = !csdata;
        this.csDataLoaded = true;
      })
    )

    this.subs.add(
      this.applications$.subscribe(data => {
        if (data.Menu.Sections) {
          this.appSections = data.Menu.Sections;
          if (this.userSession) {
            this.displayMap = this.filterSections(false);
          }
        }
        if (data.Menu.Selected?.pageID) {
          this.currentPage = data.Menu.Selected.pageID;
          this.expandedSection = data.Menu.Selected.sectionName;
        }
      })
    )

    this.subs.add(
      this.store.select(state => state.applications.backupPage).subscribe(page =>{
        if (page){
          this.setPageAndNavigate(page);
        }
      })
    )

    this.subs.add(
      this.store.select(state => state.analyticsState.Pages).subscribe(data => {
        this.analyticsReportPages = data;
        if (data?.length > 0) {
          if (this.selectedReportPage == null || data.find(p => p.name == this.selectedReportPage) == null)
            this.selectedReportPage = data[0].name;
        }
      })
    )

    this.subs.add(
      this.store.select(state => state.userState.session).subscribe(data =>{
        if (data && this.userSettings) {
          this.lastApp = this.userApps.lastApp;
          this.userSession = data;
          if (this.appSections && this.csDataLoaded && this.allowedPages) {
            //this.allowedPages = data.userAppPages;
            this.displayMap = this.filterSections(false);
            // MH-13786 custom redirection to claims analysis page IF the pid has access
            if ((this.currentApp ?? this.lastApp) === 3 && this.lastPidRedirectFlag !== data.pid) {
              if ((data.ndcPharmacyInfo?.finalBillingInstall === 1 || data.ndcPharmacyInfo?.finalBillingInstall === 2) && this.allowedPages.map(p => p.pageID).includes(Pages.CLAIM_ANALYSIS_MENU_ITEM_NDC.pageID)) {
                this.setPageAndNavigate(Pages.CLAIM_ANALYSIS_MENU_ITEM_NDC);
              } else {
                this.sendToDefaultPage();
              }
            }
            //send to dashboard if the user doesn't have access to the page
            else if (this.currentPage > -1 && this.displayMap[this.currentPage] != this.menuItemEnabled) {
              this.sendToDefaultPage();
            }
          }
          if (data.ndcPharmacyInfo) {
            this.lastPidRedirectFlag = data.pid;
          }
        }
      })
    )
  }

  pageSelected(item: ApplicationMenuItem) {
    this.store.dispatch(PageSelected({ page: item }));
    this.currentPage = item.pageID;
    this.sharedService.triggerHealthSystemView(item.pageID);
  }

  filterSections(useAllUserAppPages: boolean) {
    this.filteredSections = [];
    let returnMap = {};
    let allowedPageIds = this.allowedPages.map(p => p.pageID);

    let appPages = useAllUserAppPages ? allowedPageIds : this.appSections.map(s => s.items).reduce((prev, curr) => prev.concat(curr)).map(p => p.pageID);

    appPages.forEach(page => {
      if (allowedPageIds.includes(page)) {
        returnMap[page] = this.menuItemEnabled;
      }
      else returnMap[page] = this.menuItemInvisible;

      // special rules, 340B
      // Ordering
      if (page === 42) {
        if (this.userSession.pharmActive === 0)
          returnMap[page] = this.menuItemDisabled;
        if (this.userSession.vrrEnabled === 1)
          returnMap[page] = this.menuItemInvisible;
      }

      // Maintenence
      if ((page === 45 || page === 46 || page === 47) && this.userSession.pharmType === 1) {
        returnMap[page] = this.menuItemInvisible;
      }

      if (page === 71 && this.userSession.pharmType === 0) {
        returnMap[page] = this.menuItemInvisible;
      }

      //Referral Matching
      if (page === 70 && (!this.showAutomatedReferrals)) {
        returnMap[page] = this.menuItemInvisible;
      }

      // 340B monitor
      if (page === 58 && (this.userSession.pharmType === 1)) {
        returnMap[page] = this.menuItemInvisible;
      }

      // special rules, NDC arc
      // dashboard
      if (page === 59 && (this.userSession.ndcPharmacyInfo?.finalBillingInstall === 1
        || this.userSession.ndcPharmacyInfo?.finalBillingInstall === 2)) {
        returnMap[page] = this.menuItemInvisible;
      }
      // Charges Work Queue
      if (page === 61 && (this.userSession.ndcPharmacyInfo?.finalBillingInstall !== 0)) {
        returnMap[page] = this.menuItemInvisible;
      }
      // Claim Analysis
      if (page === 62 && !(this.userSession.ndcPharmacyInfo?.finalBillingInstall === 1 ||
        this.userSession.ndcPharmacyInfo?.finalBillingInstall === 2)) {
        returnMap[page] = this.menuItemInvisible;
      }
      // Drug Catalog
      if ((page === 63 || page === 64 || page === 67)
        && (this.userSession.ndcPharmacyInfo?.piD340B < 0 || this.userSession.ndcPharmacyInfo?.piD340B === null)) {
        returnMap[page] = this.menuItemInvisible;
      }
      // Import Analysis
      if (page === 65 && !this.isMHIEmployee) {
        returnMap[page] = this.menuItemInvisible;
      }

      // 340 Central Split under Ordering
      if (page === 75 && this.disableCSMenu) {
        returnMap[page] = this.menuItemDisabled;
        if (this.userSession.pharmType == 1 && this.userSession.ceOwned != 1) {
          returnMap[page] = this.menuItemInvisible;
        }
      }

      // Prescription Assistance Program
      if (page == 81 && !this.userSettings.isMHIUser) {
        returnMap[page] = this.menuItemInvisible;
      }

      if (page == 82 && !this.userSettings.isMHIUser) {
        returnMap[page] = this.menuItemInvisible;
      }

      // Gateway Dispense Fee Management
      if (page == 113 && !this.showDispenseFeeManagement) {
        returnMap[page] = this.menuItemInvisible;
      }

    })

    this.appSections.forEach(section => {
      if (section.items.some(i => returnMap[i.pageID] === 2)) {
        this.filteredSections.push(section);
      }
    });

    return returnMap;
  }

  setAppHeight() {
    switch (this.userDisplayHeight) {
      case 450:
        return 99;
      case 500:
        return 99;
      case 550:
        return 99;
      case 600:
        return 99;
      case 650:
        return 99;
      case 700:
        return 99;
      case 750:
        return 99;
      case 800:
        return 100;
    }
  }

  updateReport(name: string) {
    this.selectedReportPage = name;
    this.currentPage = Pages.ANALYTICS_MENU_ITEM.pageID;
    this.store.dispatch(PageSelected({ page: Pages.ANALYTICS_MENU_ITEM }))
    this.router.navigate([Pages.ANALYTICS_MENU_ITEM.ngPageURL], { state: { menuItem: Pages.ANALYTICS_MENU_ITEM } });
    this.store.dispatch(ChangeReportPage({ pageName: name }));
  }

  setPageAndNavigate(appMenu: ApplicationMenuItem) {
    this.currentPage = appMenu.pageID;
    this.expandedSection = appMenu.sectionName;
    this.store.dispatch(PageSelected({ page: appMenu }));
    this.router.navigate([appMenu.ngPageURL], { state: { menuItem: appMenu } });
    this.defaultPageFlag = true;
  }

  sendToDefaultPage() {
    if (this.defaultPageFlag){
      // mark this flag false so that we dont wind up navigating a bunch of times during the user state sub (changing pid/corp)
      this.defaultPageFlag = false;
      // grab default pageid from list of allowed pages within the app, default menu item based on that
      let checkDisplayMap = this.filterSections(true);
      let allowedAppPages = this.allowedPages?.filter(x => x.appID == (this.currentApp ?? this.lastApp)).map(x => x.pageID);
      let appMenuItems = this.appSections.map(s => s.items).reduce((prev, curr) => prev.concat(curr));
      let favoritePages = [this.userSettings.architect340BQuickAccessPageID, this.userSettings.macroManagerQuickAccessPageID, this.userSettings.gatewayQuickAccessPageID];
      // default user to favorite page, if none default to first page in list
      let potentialDefaultMenuItems = appMenuItems.filter(x => allowedAppPages.includes(x.pageID) && checkDisplayMap[x.pageID] == this.menuItemEnabled);
      let defaultMenuItem = potentialDefaultMenuItems.find(x => favoritePages.includes(x.pageID)) || potentialDefaultMenuItems[0];
      if (!defaultMenuItem){
        //if nothing exists in the app send them to backup page (a page we know they have access to in some app)
        let potentialBackupPages = Object.keys(checkDisplayMap).filter(x => checkDisplayMap[x] == this.menuItemEnabled && this.allowedPages.map(x => x.pageID).includes(Number(x)));
        // look for favorite page first, if none default to first page in list
        let backupPageId = Number(potentialBackupPages.find(x => favoritePages.includes(Number(x))) || potentialBackupPages[0]);
        let backupAppId = this.allowedPages.find(x => x.pageID == backupPageId).appID;
        this.store.dispatch(GetBackupPage({pageId: backupPageId, appId: backupAppId}));
      }
      else this.setPageAndNavigate(defaultMenuItem);
    }

    this.sharedService.disableHSV();
  }

  ngOnDestroy(){
    this.subs.unsubscribe();
  }
}
