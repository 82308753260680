import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { UiState } from '../../store/ui-state.reducer';
import { Observable } from 'rxjs';
import { ErrHide } from '../../store/ui-state.actions';
import { Applications } from '../../models/applications';

import { UserState } from 'src/app/store/user.reducer';
import { NotificationItem, NotificationItemHistory } from 'src/app/models/user';
import { GetUserLogout } from 'src/app/store/user.actions';
import { MatDialog } from '@angular/material/dialog';

import {TooltipPosition} from '@angular/material/tooltip';
import { FormControl } from '@angular/forms';
import { B2cLoginService } from 'src/app/service/b2clogin.service';


@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})



export class TopNavComponent implements OnInit {
  public errVisible$: Observable<boolean>;
  public errMessage$: Observable<string>;
  public applications$: Observable<Applications>;

  notificationList: NotificationItem[];
  notificationHistoryList: NotificationItemHistory[];

  positionOptions: TooltipPosition[] = ['below', 'above', 'left', 'right'];
  position = new FormControl(this.positionOptions[0]);

  actions$: any;
  dialogRef: any;
  isInternalUser: boolean;

  constructor( private store: Store<{ uiState: UiState,
    applications: Applications,
    userState: UserState }>,
    private dialog: MatDialog,
    private b2cService: B2cLoginService){
  }

  public dismissError() {
    this.store.dispatch( ErrHide() );
  }

  ngOnInit() {
    this.errVisible$ = this.store.select(state => state.uiState.ErrorVisible );
    this.errMessage$ = this.store.select(state => state.uiState.ErrorMessage );
    this.applications$ =this.store.select(state => state.applications);
  }

  signOut(){
    //blacklist access token
    this.store.dispatch( GetUserLogout() );
    //cookie, identity token cleanup
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    this.b2cService.LogoutCleanup();
    this.b2cService.logout();
  }

}
