import { ChainStoresGroups, ChainStoresGroupsResponse } from "src/app/models/gateway/chain/chain-stores-groups-response";
import { GatewayStateActionTypes } from "./gateway.actions";
import { ChainSettings } from "src/app/models/gateway/chain/chain-settings-response";
import { ChainResponse } from "src/app/models/gateway/chain/chain-response";
import { FormularyCurrentResponse, FormularySetupResponse, FormularyUploadsResponse } from "src/app/models/gateway/formulary/formulary-response";
import { FormularySetupCSGIDsResponse } from "src/app/models/gateway/formulary/formulary-setup-csgids-response";
import { CoveredEntityResponse, DispenseFeeScheduleHistoryResponse, DispenseFeeScheduleResponse } from "src/app/models/gateway/dispense-fee/dispense-fee-schedule-response";

export interface GatewayState {
  gatewayResponse: ChainStoresGroupsResponse,
  treeview: any,
  chainSettings: ChainSettings,
  chainData: ChainResponse,
  formularySetupData: FormularySetupResponse,
  formularySetupCSGIDs: FormularySetupCSGIDsResponse,
  dispenseFeeScheduleResponse: DispenseFeeScheduleResponse,
  dispenseFeeScheduleHistoryResponse: DispenseFeeScheduleHistoryResponse,
  formularyCurrentResponse: FormularyCurrentResponse,
  formularyUploadsResponse: FormularyUploadsResponse,
  coveredEntityResponse: CoveredEntityResponse,
  selectedChainGroup: ChainStoresGroups
}

export const initialState =
{
  gatewayResponse: null,
  treeview: null,
  chainSettings: null,
  chainData: null,
  formularySetupData: null,
  formularySetupCSGIDs: null,
  dispenseFeeScheduleResponse: null,
  dispenseFeeScheduleHistoryResponse: null,
  formularyCurrentResponse: null,
  formularyUploadsResponse: null,
  coveredEntityResponse: null,
  selectedChainGroup: null
}

export function gatewayReducer(state = initialState, action: any): GatewayState {

  switch (action.type) {
    case GatewayStateActionTypes.GET_GATEWAY_CHAIN_STORES_GROUPS_RES: {
      return { ...state, gatewayResponse: action.chainList };
    }

    case GatewayStateActionTypes.GET_GATEWAY_TREE_RES: {
      return { ...state, treeview: action.treeview }
    }

    case GatewayStateActionTypes.GET_CHAIN_SETTINGS_RES: {
      return { ...state, chainSettings: action.chainSettings.data }
    }

    case GatewayStateActionTypes.GET_GATEWAY_CHAIN_RES: {
      return { ...state, chainData: action.chainData }
    }

    case GatewayStateActionTypes.GET_DISPENSE_FEE_FORMULARY_SETUP_RES: {
      return { ...state, formularySetupData: action.formularySetupData }
    }

    case GatewayStateActionTypes.GET_DISPENSE_FEE_FORMULARY_SETUP_CSGIDS_RES: {
      return { ...state, formularySetupCSGIDs: action.formularySetupCSGIDs }
    }

    case GatewayStateActionTypes.SET_CHAIN_SETTINGS: {
      return { ...state, chainSettings: action.chainSettingsRequest }
    }

    case GatewayStateActionTypes.GET_DISPENSE_FEE_FORMULARY_SETUP_CLEANUP: {
      return { ...state, formularySetupData: null }
    }

    case GatewayStateActionTypes.GET_GATEWAY_TREE_CLEANUP: {
      return { ...state, treeview: null }
    }

    case GatewayStateActionTypes.GET_GATEWAY_CHAIN_STORES_GROUPS_CLEANUP: {
      return { ...state, gatewayResponse: null }
    }

    case GatewayStateActionTypes.GET_DISPENSE_FEE_SCHEDULE_RES: {
      return { ...state, dispenseFeeScheduleResponse: action.dispenseFeeScheduleResponse }
    }

    case GatewayStateActionTypes.GET_DISPENSE_FEE_SCHEDULE_HISTORY_RES: {
      return { ...state, dispenseFeeScheduleHistoryResponse: action.dispenseFeeScheduleHistoryResponse }
    }

    case GatewayStateActionTypes.GET_DISPENSE_FEE_FORMULARY_CURRENT_RES: {
      return { ...state, formularyCurrentResponse: action.formularyCurrentResponse }
    }

    case GatewayStateActionTypes.GET_DISPENSE_FEE_FORMULARY_UPLOADS_RES: {
      return { ...state, formularyUploadsResponse: action.formularyUploadsResponse }
    }

    case GatewayStateActionTypes.GET_DISPENSE_FEE_FORMULARY_UPLOADS_CLEANUP: {
      return { ...state, formularyUploadsResponse: null }
    }

    case GatewayStateActionTypes.GET_COVERED_ENTITIES_FOR_CSGID_RES: {
      return { ...state, coveredEntityResponse: action.coveredEntityResponse }
    }

    case GatewayStateActionTypes.SELECT_CHAIN_GROUP: {
      return { ...state, selectedChainGroup: action.chainGroup };
    }

    default: {
      return state;
    }
  }
}
