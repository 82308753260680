<div class="general-settings-dialog">
  <div mat-dialog-title class="dialog-header">
    <h3>General Settings</h3>
  </div>
  <div>
    <form #generalSettingsForm="ngForm" (change)="formChanged()">
      <div class="dialog-body">
        <!-- HTML code for Display Height -->
        <mat-form-field class="display-height">
          <mat-label>Display Height: </mat-label>
          <mat-select
            name="displayHeight"
            #displayHeight="ngModel"
            [(ngModel)]="saveValues.displayHeight"
            [(value)]="saveValues.displayHeight"
            placeholder="Display Height: "
            [class.is-invalid]="displayHeight.invalid && displayHeight.touched">
              <mat-option *ngFor="let displayHeightOption of displayHeightOptions" [value]="displayHeightOption.value">
                {{displayHeightOption.viewValue}}
              </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- HTML code for Default Packages Setting -->
        <!-- <mat-form-field class="default-packages">
          <mat-label>Package Setting: </mat-label>
          <mat-select
            class="form-control"
            name="defaultPackagesSetting"
            [(ngModel)]="saveValues.defaultPkgs"
            [(value)]="saveValues.defaultPkgs"
            #defaultPackagesSetting="ngModel"
            placeholder="Package Setting: " >
              <mat-option *ngFor="let packageSettingOption of packageSettingOptions" [value]="packageSettingOption.value">
                {{packageSettingOption.viewValue}}
              </mat-option>
          </mat-select>
        </mat-form-field> -->

        <!-- HTML code for Virtual Scrolling -->
        <!-- <div class="virtual-scrolling">
          <input name="virtualScrolling"
          #virtualScrolling="ngModel"
          [(ngModel)]="saveValues.virtualScrolling"
          [checked]="saveValues.virtualScrolling"
          class="toggle virtual-toggle-btn"
          type="checkbox"/>
          <mat-label>Enable Virtual Scrolling</mat-label>
        </div> -->
      </div>
      <div class="dialog-footer">
        <button mat-flat-button type="submit" color="primary" class="submit-btn" (click)="onSaveClick()" [disabled]="generalSettingsForm.form.invalid">Save</button>
        <button mat-stroked-button type="button" class="discard-btn" (click)="onCloseClick()">Cancel</button>
      </div>
    </form>
  </div>
</div>
