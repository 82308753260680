import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  constructor() { }

  public HandleViewSelectedPopup: Subject<void> = new Subject<void>();
  public HealthSystemViewInd : boolean = false;
  public SelectedHSView : boolean = false;
  public QualRulesAccess : boolean = false;


  public triggerHealthSystemView(pageID){ // created this method to restrict Health System View Popups
    if(pageID && pageID === 86 ){
      this.HealthSystemViewInd = true;
    }
    else{
      this.HealthSystemViewInd = false;
    }
  }

  public disableHSV(){
    this.HealthSystemViewInd = false;
  }

  public triggerCorpIDStatusCheck(){
    this.HandleViewSelectedPopup.next();
  }

}
