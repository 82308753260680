import { Injectable } from '@angular/core';
import { Actions, act, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of as observableOf } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ShellService } from '../service/shell.service';
import { Validate340BService } from '../service/validate340b.service';
import {
  GetManufacturers, GetManufacturerResults, GetManufacturerError,
  AddManufacturer, AddManufacturerResults, AddManufacturerError,
  IsUniqueMName, IsUniqueMNameResults, IsUniqueMNameError,
  IsUniqueMSubkey, IsUniqueMSubkeyResults, IsUniqueMSubkeyError,
  IsUniqueMFolder, IsUniqueMFolderResults, IsUniqueMFolderError,
  GetMViewDetails, GetMViewDetailResults, GetMViewDetailError,
  UpdateManufacturer, UpdateManufacturerResults, UpdateManufacturerError,
  DeleteMContact, DeleteMContactResults, DeleteMContactError,
  GetChainStoreById, GetChainStoreByIdResults, GetChainStoreByIdError,
  GetChainStoreByName, GetChainStoreByNameResults, GetChainStoreByNameError,
  GetChainStoreList, GetChainStoreListResults, GetChainStoreListError,
  UpdateChainStore, UpdateChainStoreResults, UpdateChainStoreError,
  GetOptInInfoByMID, GetOptInInfoByMIDResults, GetOptInInfoByMIDError,
  SubmitOptInData, SubmitOptInDataResults, SubmitOptInDataError,
  GetSubmissionStatus, GetSubmissionStatusResults, GetSubmissionStatusError,
  GetExclusionsInfo, GetExclusionsInfoResults, GetExclusionsInfoError,
  SubmitExclusionsData, SubmitExclusionsDataResults, SubmitExclusionsDataError,
  GetExclusionsWithDrugInfo, GetExclusionsWithDrugInfoResults, GetExclusionsWithDrugInfoError,
  GetEspManufacturers, GetEspManufacturersResults, GetEspManufacturersError, GetPriceVariance,
  GetPriceVarianceResults, GetPriceVarianceError, GetSubmissionHistoryByHID,
  GetSubmissionHistoryByHIDResults, GetSubmissionHistoryByHIDError, GetSubmissionHistoryFile,
  GetSubmissionHistoryFileResults, GetSubmissionHistoryFileError, GetSubmittedClaims,
  GetSubmittedClaimsResults, GetSubmittedClaimsError, GetMckPHSContractFlagVisibility,
  GetMckPHSContractFlagVisibilityError, GetMckPHSContractFlagVisibilityResults,
  GetAutoExclusions, GetAutoExclusionsResults, GetAutoExclusionsError, SaveAutoExclusionsData, SaveAutoExclusionsDataError, SaveAutoExclusionsDataResults,
  GetManufacturersNdcs, GetManufacturersNdcsResults, GetManufacturersNdcsError, SaveNewAutoExclusionsData, SaveNewAutoExclusionsDataError, SaveNewAutoExclusionsDataResults
} from './validate340b.actions';



@Injectable()
export class Validate340BEffects {

  constructor(private validate340bservice: Validate340BService, private actions$: Actions, private store: Store<{}>) {
  }

  GetManufacturersData$ = createEffect(() => this.actions$.pipe(
    ofType(GetManufacturers),
    switchMap(action =>
      this.validate340bservice.getManufacturers(action.options)
        .pipe(
          map(
            data => GetManufacturerResults({ manufacturers: data })
          ),
          catchError(
            error => observableOf(GetManufacturerError({ message: error }))
          )
        )
    )
  ));

  IsUniqueMName$ = createEffect(() => this.actions$.pipe(
    ofType(IsUniqueMName),
    switchMap(action =>
      this.validate340bservice.IsUniqueMName(action.mname)
        .pipe(
          map(
            data => IsUniqueMNameResults({ countval: data })
          ),
          catchError(
            error => observableOf(IsUniqueMNameError({ message: error }))
          )
        )
    )
  ));

  IsUniqueMSubkey$ = createEffect(() => this.actions$.pipe(
    ofType(IsUniqueMSubkey),
    switchMap(action =>
      this.validate340bservice.IsUniqueMSubscription(action.skey)
        .pipe(
          map(
            data => IsUniqueMSubkeyResults({ countval: data })
          ),
          catchError(
            error => observableOf(IsUniqueMSubkeyError({ message: error }))
          )
        )
    )
  ));

  IsUniqueMFolder$ = createEffect(() => this.actions$.pipe(
    ofType(IsUniqueMFolder),
    switchMap(action =>
      this.validate340bservice.IsUniqueMFolder(action.mfolder)
        .pipe(
          map(
            data => IsUniqueMFolderResults({ countval: data })
          ),
          catchError(
            error => observableOf(IsUniqueMFolderError({ message: error }))
          )
        )
    )
  ));

  AddMaufacturer$ = createEffect(() => this.actions$.pipe(
    ofType(AddManufacturer),
    switchMap(action =>
      this.validate340bservice.addManufacturer(action.addData)
        .pipe(
          map(
            data => AddManufacturerResults({ addData: data })
          ),
          catchError(
            error => observableOf(AddManufacturerError({ message: error }))
          )
        )
    )
  ));

  GetManufacturerByID$ = createEffect(() => this.actions$.pipe(
    ofType(GetMViewDetails),
    switchMap(action =>
      this.validate340bservice.getManufacturerByID(action.manId)
        .pipe(
          map(
            data => GetMViewDetailResults({ mdetails: data })
          ),
          catchError(
            error => observableOf(GetMViewDetailError({ errorMessage: error }))
          )
        ))
  ));

  UpdateMaufacturer$ = createEffect(() => this.actions$.pipe(
    ofType(UpdateManufacturer),
    switchMap(action =>
      this.validate340bservice.updateManufacturer(action.updatedtls)
        .pipe(
          map(
            data => UpdateManufacturerResults({ updatedtls: data })
          ),
          catchError(
            error => observableOf(UpdateManufacturerError({ message: error }))
          )
        )
    )
  ));

  DeleteMContact$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteMContact),
    switchMap(action =>
      this.validate340bservice.deleteContact(action.delcontact)
        .pipe(
          map(
            data => DeleteMContactResults({ delcontact: data })
          ),
          catchError(
            error => observableOf(DeleteMContactError({ message: error }))
          )
        )
    )
  ));

  GetChainStoreListData$ = createEffect(() => this.actions$.pipe(
    ofType(GetChainStoreList),
    switchMap(action =>
      this.validate340bservice.getPharmChainStoreList()
      .pipe(
        map(
          data => GetChainStoreListResults({ chainStoreList: data })
        ),
        catchError(
          error => observableOf(GetChainStoreListError({ errorMessage: error}))
        )
      ))
  ));

  GetChainStoreByIdData$ = createEffect(() => this.actions$.pipe(
    ofType(GetChainStoreById),
    switchMap(action =>
      this.validate340bservice.getPharmChainStoreByChainID(action.chainID)
      .pipe(
        map(
          data => GetChainStoreByIdResults({ chainStore: data })
        ),
        catchError(
          error => observableOf(GetChainStoreByIdError({ errorMessage: error}))
        )
      ))
  ));

  GetChainStoreByNameData$ = createEffect(() => this.actions$.pipe(
    ofType(GetChainStoreByName),
    switchMap(action =>
      this.validate340bservice.getPharmChainStoreByName(action.chainName)
      .pipe(
        map(
          data => GetChainStoreByNameResults({ chainStore: data })
        ),
        catchError(
          error => observableOf(GetChainStoreByNameError({ errorMessage: error}))
        )
      ))
  ));

  UpdateChainStoreData$ = createEffect(() => this.actions$.pipe(
    ofType(UpdateChainStore),
    switchMap(action =>
      this.validate340bservice.updatePharmChainstore(action.updateChainStore)
      .pipe(
        map(
          data => UpdateChainStoreResults({ response: data })
        ),
        catchError(
          error => observableOf(UpdateChainStoreError({ errorMessage: error}))
        )
      ))
  ));

  GetOptInDataByMIDEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetOptInInfoByMID),
    switchMap(action =>
      this.validate340bservice.getDataSubmissionByManufacturer(action.mid)
      .pipe(
        map(
          data => GetOptInInfoByMIDResults({ optInData: data })
        ),
        catchError(
          error => observableOf(GetOptInInfoByMIDError({ errorMessage: error}))
        )
      ))
  ));

  SubmitOptInInfoEffect$ = createEffect(() => this.actions$.pipe(
    ofType(SubmitOptInData),
    switchMap(action =>
      this.validate340bservice.submitOptInData(action.selections)
      .pipe(
        map(
          data => SubmitOptInDataResults({ response: data })
        ),
        catchError(
          error => observableOf(SubmitOptInDataError({ errorMessage: error}))
        )
      ))
  ));

  GetSubmissionStatusEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetSubmissionStatus),
    switchMap(action =>
      this.validate340bservice.getSubmissionStatusData()
      .pipe(
        map(
          data => GetSubmissionStatusResults({ statuses: data })
        ),
        catchError(
          error => observableOf(GetSubmissionStatusError({ errorMessage: error}))
        )
      ))
  ));

  GetExclusionsDataEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetExclusionsInfo),
    switchMap(action =>
      this.validate340bservice.getExclusionsData(action.request)
      .pipe(
        map(
          data => GetExclusionsInfoResults({ exclusionsData : data })
        ),
        catchError(
          error => observableOf(GetExclusionsInfoError({ errorMessage: error}))
        )
      ))
  ));

  GetExclusionsDataWithDrugInfoEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetExclusionsWithDrugInfo),
    switchMap(action =>
      this.validate340bservice.getExclusionsDataWithDrugInfo(action.request)
      .pipe(
        map(
          data => GetExclusionsWithDrugInfoResults({ exclusionsData : data })
        ),
        catchError(
          error => observableOf(GetExclusionsWithDrugInfoError({ errorMessage: error}))
        )
      ))
  ));

  SubmitExclusionsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(SubmitExclusionsData),
    switchMap(action =>
      this.validate340bservice.submitExclusionsData(action.selections)
      .pipe(
        map(
          data => SubmitExclusionsDataResults({ response: data })
        ),
        catchError(
          error => observableOf(SubmitExclusionsDataError({ errorMessage: error}))
        )
      ))
  ));

  GetESPManufacturersEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetEspManufacturers),
    switchMap(action =>
      this.validate340bservice.getEspManufacturers(action.ndcsourceone)
        .pipe(
          map(
            data => GetEspManufacturersResults({ espmanData: data })
          ),
          catchError(
            error => observableOf(GetEspManufacturersError({ errorMessage: error }))
          )
        ))
  ));

  GetPriceVariance$ = createEffect(() => this.actions$.pipe(
    ofType(GetPriceVariance),
    switchMap(action =>
      this.validate340bservice.getPriceVariancePercent()
        .pipe(
          map(
            data => GetPriceVarianceResults({ variance: data })
          ),
          catchError(
            error => observableOf(GetPriceVarianceError({ errorMessage: error }))
          )
        ))
  ));

  GetMckPHSContractFlagVisibility$ = createEffect(() => this.actions$.pipe(
    ofType(GetMckPHSContractFlagVisibility),
    switchMap(action =>
      this.validate340bservice.getMckPHSContractFlagVisibility()
        .pipe(
          map(
            data => GetMckPHSContractFlagVisibilityResults({ visible: data })
          ),
          catchError(
            error => observableOf(GetMckPHSContractFlagVisibilityError({ message: error }))
          )
        ))
  ));

  GetSubmissionHistoryByHIDEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetSubmissionHistoryByHID),
    switchMap(action =>
      this.validate340bservice.getSubmissionHistory(action.hid)
      .pipe(
        map(
          data => GetSubmissionHistoryByHIDResults({ historyData: data })
        ),
        catchError(
          error => observableOf(GetSubmissionHistoryByHIDError({ errorMessage: error}))
        )
      ))
  ));

  GetSubmissionHistoryFileEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetSubmissionHistoryFile),
    switchMap(action =>
      this.validate340bservice.getSubmissionFile(action.blobUrl, action.hid)
      .pipe(
        map(
          data => GetSubmissionHistoryFileResults({ file: data })
        ),
        catchError(
          error => observableOf(GetSubmissionHistoryFileError({ errorMessage: error}))
        )
      ))
  ));

  GetSubmittedClaimsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetSubmittedClaims),
    switchMap(action =>
      this.validate340bservice.getSubmittedClaims(action.request)
        .pipe(
          map(
            data => GetSubmittedClaimsResults({ submittedClaims: data })
          ),
          catchError(
            error => observableOf(GetSubmittedClaimsError({ errorMessage: error }))
          )
        ))
  ));

  GetAutoExclusionsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetAutoExclusions),
    switchMap(action =>
      this.validate340bservice.getAutoExclusionsSettingsData()
      .pipe(
        map(
          data => GetAutoExclusionsResults({ autoExclusionsData : data })
        ),
        catchError(
          error => observableOf(GetAutoExclusionsError({ errorMessage: error}))
        )
      ))
  ));

  SaveAutoExclusionsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(SaveAutoExclusionsData),
    switchMap(action =>
      this.validate340bservice.saveAutoExclusionsData(action.selections, action.ops)
      .pipe(
        map(
          data => SaveAutoExclusionsDataResults({ response: data })
        ),
        catchError(
          error => observableOf(SaveAutoExclusionsDataError({ errorMessage: error}))
        )
      ))
  ));

  GetManufacturersNdcsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetManufacturersNdcs),
    switchMap(action =>
      this.validate340bservice.getManufacturersNdcs()
      .pipe(
        map(
          data => GetManufacturersNdcsResults({ response: data})
        ),
        catchError(
          error => observableOf(GetManufacturersNdcsError({ errorMessage: error}))
        )
      )
    )
  )
  );

  SaveNewAutoExclusionsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(SaveNewAutoExclusionsData),
    switchMap(action => 
      this.validate340bservice.saveNewAutoExclusionsData(action.settings)
      .pipe(
        map(
          data => SaveNewAutoExclusionsDataResults({ response: data })
        ),
        catchError(
          error => observableOf(SaveNewAutoExclusionsDataError({errorMessage: error}))
        )
      ))
  ));




}
