
export class VersionTracker {
  applicationVersion: string;
}

export class SaveVersionTracker extends VersionTracker {
    versionID: number;
    notes: string;
    active: number;
    addedBy: number;
    dateAdded: Date;
    updatedBy: number;
    dateUpdated: Date;
}

