import { createAction, props } from '@ngrx/store';
import { SubmitResponse } from '../models/submit-response';
import { PAPCoveredEntity } from '../models/pap-covered-entity';
import { PAPBillingSetup } from '../models/pap-billing-setup';
import { PAPBillToHID } from '../models/pap-bill-to-hid';
import { PAPBillingType } from '../models/pap-billing-type';
import { PAPCorpGroupSummary, PAPCorpGroupDetails, PAPCorpGroupMemberHIDs, PAPCorpGroupHIDsInsert, papSessionData, PAPCorpGroupHIDsUpdate } from '../models/pap';
import { hidTypeIdhanged } from '../models/pap-billing-type';

export enum PAPActionTypes {
  GET_COVERED_ENTITY_LIST = '[Rest Call] [PAPCoveredEntity] GetPAPCoveredEntityList',
  GET_COVERED_ENTITY_LIST_RES = '[Rest Result] [PAPCoveredEntity] GetPAPCoveredEntityListResults',
  GET_COVERED_ENTITY_LIST_ERR = '[Rest Error] [PAPCoveredEntity] GetPAPCoveredEntityListError',

  GET_BILLING_SETUP_LIST = '[Rest Call] [PAPBillingSetup] GetPAPBillingSetupList',
  GET_BILLING_SETUP_LIST_RES = '[Rest Result] [PAPBillingSetup] GetPAPBillingSetupResults',
  GET_BILLING_SETUP_LIST_ERR = '[Rest Error] [PAPBillingSetup] GetPAPBillingSetupListError',

  GET_BILLING_SETUP_BILL_TO_HID_LIST = '[Rest Call] [PAPBillingSetupBillToHID] GetPAPBillingSetupBillToHIDList',
  GET_BILLING_SETUP_BILL_TO_HID_LIST_RES = '[Rest Result] [PAPBillingSetupBillToHID] GetPAPBillingSetupBillToHIDResults',
  GET_BILLING_SETUP_BILL_TO_HID_LIST_ERR = '[Rest Error] [PAPBillingSetupBillToHID] GetPAPBillingSetupBillToHIDListError',

  GET_BILLING_SETUP_BILLING_TYPES_LIST = '[Rest Call] [PAPBillingSetupBillingTypes] GetPAPBillingSetupBillingTypesList',
  GET_BILLING_SETUP_BILLING_TYPES_LIST_RES = '[Rest Result] [PAPBillingSetupBillingTypes] GetPAPBillingSetupBillingTypesResults',
  GET_BILLING_SETUP_BILLING_TYPES_LIST_ERR = '[Rest Error] [PAPBillingSetupBillingTypes] GetPAPBillingSetupBillingTypesListError',

  UPDATE_BILLING_TYPE_BILLING_HID = '[Rest Call] UpdatePAPBillingTypeBillingHID',
  UPDATE_BILLING_TYPE_BILLING_HID_RES = '[Rest Result] UpdatePAPBillingTypeBillingHIDResults',
  UPDATE_BILLING_TYPE_BILLING_HID_ERR = '[Rest Error] UpdatePAPBillingTypeBillingHIDError',

  GET_CORPORATE_GROUP_SUMMARY_LIST = '[Rest Call] [PAPCorporateGroupSummary] GetPapCorporateGroupSummaryList',
  GET_CORPORATE_GROUP_SUMMARY_LIST_RES = '[Rest Result] [PAPCorporateGroupSummary] GetPapCorporateGroupSummaryResults',
  GET_CORPORATE_GROUP_SUMMARY_LIST_ERR = '[Rest Error] [PAPCorporateGroupSummary] GetPapCorporateGroupSummaryListError',

  GET_CORPORATE_GROUP_DETAILS = '[Rest Call] [PAPCorporateGroupDetails] GetPapCorporateGroupDetails',
  GET_CORPORATE_GROUP_DETAILS_RES = '[Rest Result] [PAPCorporateGroupDetails] GetPapCorporateGroupDetailsResults',
  GET_CORPORATE_GROUP_DETAILS_ERR = '[Rest Error] [PAPCorporateGroupDetails] GetPapCorporateGroupDetailsError',

  ADD_CORPORATE_GROUP_DETAILS = '[Silent Rest Call] [PAPCorporateGroupDetails] AddPapCorporateGroupDetails',
  ADD_CORPORATE_GROUP_DETAILS_RES = '[Silent Rest Result] [PAPCorporateGroupDetails] AddPapCorporateGroupDetailsResults',
  ADD_CORPORATE_GROUP_DETAILS_ERR = '[Rest Error] [PAPCorporateGroupDetails] AddPapCorporateGroupDetailsError',

  UPDATE_CORPORATE_GROUP_DETAILS = '[Silent Rest Call] [PAPCorporateGroupDetails] UpdatePapCorporateGroupDetails',
  UPDATE_CORPORATE_GROUP_DETAILS_RES = '[Silent Rest Result] [PAPCorporateGroupDetails] UpdatePapCorporateGroupDetailsResults',
  UPDATE_CORPORATE_GROUP_DETAILS_ERR = '[Rest Error] [PAPCorporateGroupDetails] UpdatePapCorporateGroupDetailsError',

  GET_CORPORATE_GROUP_HIDS = '[Silent Rest Call] [PAPCorporateGroupHids] GetPapCorporateGroupHIDs',
  GET_CORPORATE_GROUP_HIDS_RES = '[Silent Rest Result] [PAPCorporateGroupHids] GetGetPapCorporateGroupHIDsResults',
  GET_CORPORATE_GROUP_HIDS_ERR = '[Silent Rest Error] [PAPCorporateGroupHids] GetGetPapCorporateGroupHIDsError',

  GET_CORPORATE_GROUP_MEMBER_HIDS = '[Silent Rest Call] [PAPCorporateGroupMemberHids] PAPCorporateGroupMemberHids',
  GET_CORPORATE_GROUP_MEMBER_HIDS_RES = '[Silent Rest Result] [PAPCorporateGroupMemberHids] GetPAPCorporateGroupMemberHidsResults',
  GET_CORPORATE_GROUP_MEMBER_HIDS_ERR = '[Silent Rest Error] [PAPCorporateGroupMemberHids] GetPAPCorporateGroupMemberHidsError',

  INSERT_CORPORATE_GROUP_MEMBER_HIDS = '[Rest Call] AddPAPCorporateGroupMemberHids',
  INSERT_CORPORATE_GROUP_MEMBER_HIDS_RES = '[Rest Result] AddPAPCorporateGroupMemberHidsResults',
  INSERT_CORPORATE_GROUP_MEMBER_HIDS_ERR = '[Rest Error] AddPAPCorporateGroupMemberHidsError',

  SELECTED_USER_DATA = 'Update_selected_CorpID',

  UPDATE_CORPORATE_GROUP_MEMBER_HIDS = '[Rest Call] UpdatePAPCorporateGroupMemberHids',
  UPDATE_CORPORATE_GROUP_MEMBER_HIDS_RES = '[Rest Result] UpdatePAPCorporateGroupMemberHidsResults',
  UPDATE_CORPORATE_GROUP_MEMBER_HIDS_ERR = '[Rest Error] UpdatePAPCorporateGroupMemberHidsError',
}

export const GetPAPCoveredEntityList = createAction(PAPActionTypes.GET_COVERED_ENTITY_LIST);
export const GetPAPCoveredEntityResults = createAction(PAPActionTypes.GET_COVERED_ENTITY_LIST_RES, props<{ papCoveredEntityList: PAPCoveredEntity[] }>());
export const GetPAPCoveredEntityError = createAction(PAPActionTypes.GET_COVERED_ENTITY_LIST_ERR, props<{ message: string }>());

export const GetPAPBillingSetupList = createAction(PAPActionTypes.GET_BILLING_SETUP_LIST, props<{ hid : number }>());
export const GetPAPBillingSetupResults = createAction(PAPActionTypes.GET_BILLING_SETUP_LIST_RES, props<{ papBillingSetupList: PAPBillingSetup[] }>());
export const GetPAPBillingSetupError = createAction(PAPActionTypes.GET_BILLING_SETUP_LIST_ERR, props<{ message: string }>());

export const GetPAPBillingSetupBillToHIDList = createAction(PAPActionTypes.GET_BILLING_SETUP_BILL_TO_HID_LIST, props<{ hid : number, corpGroupID: number}>());
export const GetPAPBillingSetupBillToHIDResults = createAction(PAPActionTypes.GET_BILLING_SETUP_BILL_TO_HID_LIST_RES, props<{ papBillingSetupBillToHIDList: PAPBillToHID[] }>());
export const GetPAPBillingSetupBillToHIDError = createAction(PAPActionTypes.GET_BILLING_SETUP_BILL_TO_HID_LIST_ERR, props<{ message: string }>());

export const GetPAPBillingSetupBillingTypesList = createAction(PAPActionTypes.GET_BILLING_SETUP_BILLING_TYPES_LIST);
export const GetPAPBillingSetupBillingTypesResults = createAction(PAPActionTypes.GET_BILLING_SETUP_BILLING_TYPES_LIST_RES, props<{ papBillingSetupBillingTypesList: PAPBillingType[] }>());
export const GetPAPBillingSetupBillingTypesError = createAction(PAPActionTypes.GET_BILLING_SETUP_BILLING_TYPES_LIST_ERR, props<{ message: string }>());

export const UpdatePAPBillingTypeBillingHID = createAction(PAPActionTypes.UPDATE_BILLING_TYPE_BILLING_HID, props<{ saveData: hidTypeIdhanged[]}>())
export const UpdatePAPBillingTypeBillingHIDResults = createAction(PAPActionTypes.UPDATE_BILLING_TYPE_BILLING_HID_RES, props<{  response: SubmitResponse }>())
export const UpdatePAPBillingTypeBillingHIDError = createAction(PAPActionTypes.UPDATE_BILLING_TYPE_BILLING_HID_ERR, props<{ message: string }>());

export const GetPAPCorpGroupSummaryList = createAction(PAPActionTypes.GET_CORPORATE_GROUP_SUMMARY_LIST);
export const GetPAPCorpGroupSummaryResults = createAction(PAPActionTypes.GET_CORPORATE_GROUP_SUMMARY_LIST_RES, props<{ papCorpGroupSummaryList: PAPCorpGroupSummary[] }>());
export const GetPAPCorpGroupSummaryError = createAction(PAPActionTypes.GET_CORPORATE_GROUP_SUMMARY_LIST_ERR, props<{ message: string }>());

export const GetPAPCorpGroupDetails = createAction(PAPActionTypes.GET_CORPORATE_GROUP_DETAILS, props<{ corpGroupID: number}>());
export const GetPAPCorpGroupDetailsResults = createAction(PAPActionTypes.GET_CORPORATE_GROUP_DETAILS_RES, props<{ papCorpGroupDetails: PAPCorpGroupDetails[] }>());
export const GetPAPCorpGroupDetailsError = createAction(PAPActionTypes.GET_CORPORATE_GROUP_DETAILS_ERR, props<{ message: string }>());

export const AddPAPCorpGroupDetails = createAction(PAPActionTypes.ADD_CORPORATE_GROUP_DETAILS, props<{ CorpGroupName: string, AddedBy: string }>());
export const AddPAPCorpGroupDetailsResults = createAction(PAPActionTypes.ADD_CORPORATE_GROUP_DETAILS_RES, props<{ papCorpGroupDetails: PAPCorpGroupDetails[] }>());
export const AddPAPCorpGroupDetailsError = createAction(PAPActionTypes.ADD_CORPORATE_GROUP_DETAILS_ERR, props<{ message: string }>());

export const UpdatePAPCorpGroupDetails = createAction(PAPActionTypes.UPDATE_CORPORATE_GROUP_DETAILS, props<{ saveDetails: PAPCorpGroupDetails[] }>());
export const UpdatePAPCorpGroupDetailsResults = createAction(PAPActionTypes.UPDATE_CORPORATE_GROUP_DETAILS_RES, props<{ saveData: PAPCorpGroupDetails[] }>());
export const UpdatePAPCorpGroupDetailsError = createAction(PAPActionTypes.UPDATE_CORPORATE_GROUP_DETAILS_ERR, props<{ message: string }>());

export const GetPapCorporateGroupHIDs = createAction(PAPActionTypes.GET_CORPORATE_GROUP_HIDS, props<{ corpGroupID: number}>());
export const GetPapCorporateGroupHIDsResults = createAction(PAPActionTypes.GET_CORPORATE_GROUP_HIDS_RES, props<{ papCorpGroupHids: PAPCorpGroupHIDsUpdate[] }>());
export const GetPapCorporateGroupHIDsError = createAction(PAPActionTypes.GET_CORPORATE_GROUP_HIDS_ERR, props<{ message: string }>());

export const GetPapCorporateGroupMemberHIDs = createAction(PAPActionTypes.GET_CORPORATE_GROUP_MEMBER_HIDS, props<{ corpID: number}>());
export const GetPapCorporateGroupMemberHIDsResults = createAction(PAPActionTypes.GET_CORPORATE_GROUP_MEMBER_HIDS_RES, props<{ papCorpGroupMemberHids: PAPCorpGroupMemberHIDs[] }>());
export const GetPapCorporateGroupMemberHIDsError = createAction(PAPActionTypes.GET_CORPORATE_GROUP_MEMBER_HIDS_ERR, props<{ message: string }>());

export const InsertCorpMemberHIDsInfo = createAction(PAPActionTypes.INSERT_CORPORATE_GROUP_MEMBER_HIDS, props<{ saveData: PAPCorpGroupHIDsInsert[]}>())
export const InsertCorpMemberHIDsInfoResults = createAction(PAPActionTypes.INSERT_CORPORATE_GROUP_MEMBER_HIDS_RES, props<{  response: SubmitResponse }>())
export const InsertCorpMemberHIDsInfoError = createAction(PAPActionTypes.INSERT_CORPORATE_GROUP_MEMBER_HIDS_ERR, props<{ message: string }>());

export const UserSessionData = createAction(PAPActionTypes.SELECTED_USER_DATA, props<{selectedData : papSessionData[]}>());

export const UpdateCorpMemberHIDsInfo = createAction(PAPActionTypes.UPDATE_CORPORATE_GROUP_MEMBER_HIDS, props<{ saveData: PAPCorpGroupHIDsUpdate[]}>())
export const UpdateCorpMemberHIDsInfoResults = createAction(PAPActionTypes.UPDATE_CORPORATE_GROUP_MEMBER_HIDS_RES, props<{  response: SubmitResponse }>())
export const UpdateCorpMemberHIDsInfoError = createAction(PAPActionTypes.UPDATE_CORPORATE_GROUP_MEMBER_HIDS_ERR, props<{ message: string }>());