import { createAction, props } from "@ngrx/store";

export enum HealthSystemViewStateActionTypes {

  GET_CORPORATE_PARENT_TREE = '[Rest Call] [HealthSystemView] GetCorporateParentTreeList',
  GET_CORPORATE_PARENT_TREE_RES = '[Rest Result] [HealthSystemView] GetCorporateParentTreeListResults',
  GET_CORPORATE_PARENT_TREE_ERR = '[Rest Error] [HealthSystemView] GetCorporateParentTreeListError',

  UPDATE_CORPORATE_PARENT_VALIDATION_STATUS = '[Rest Call] [HealthSystemView] UpdateCorporateParentValidationStatus',
  UPDATE_CORPORATE_PARENT_VALIDATION_STATUS_RES = '[Rest Result] [HealthSystemView] UpdateCorporateParentValidationStatusResults',
  UPDATE_CORPORATE_PARENT_VALIDATION_STATUS_ERR = '[Rest Error] [HealthSystemView] UpdateCorporateParentValidationStatusError',


}

export const GetCorporateParentTreeList = createAction(HealthSystemViewStateActionTypes.GET_CORPORATE_PARENT_TREE, props<{ corpId: number }>());
export const GetCorporateParentTreeListResults = createAction(HealthSystemViewStateActionTypes.GET_CORPORATE_PARENT_TREE_RES, props<{ treeview: any }>());
export const GetCorporateParentTreeListError = createAction(HealthSystemViewStateActionTypes.GET_CORPORATE_PARENT_TREE_ERR, props<{ message: string }>());

export const UpdateCorporateParentValidationStatus = createAction(HealthSystemViewStateActionTypes.UPDATE_CORPORATE_PARENT_VALIDATION_STATUS, props<{}>());
export const UpdateCorporateParentValidationStatusResults = createAction(HealthSystemViewStateActionTypes.UPDATE_CORPORATE_PARENT_VALIDATION_STATUS_RES, props<{ result: any }>());
export const UpdateCorporateParentValidationStatusError = createAction(HealthSystemViewStateActionTypes.UPDATE_CORPORATE_PARENT_VALIDATION_STATUS_ERR, props<{ message: string }>());