import {createAction, props} from '@ngrx/store';
import {SaveVersionTracker, VersionTracker} from '../models/version-tracker';
import {SalesForceCategory} from '../models/sales-force-category';
import {SalesForceTicket} from '../models/sales-force-ticket';
import { ApplicationMenuItem } from '../models/applications';

export enum ShellActionTypes {
  GET_VERSION = '[Rest Call] [Shell] VersionGet',
  GET_VERSION_RES = '[Rest Result] [Shell] VersionGetResults',
  GET_VERSION_ERR = '[Rest Error] [Shell] VersionGetError',

  UPDATE_VERSION = '[Rest Call] [Shell] VersionUpdate',
  UPDATE_VERSION_RES = '[Rest Result] [Shell] VersionUpdateResults',
  UPDATE_VERSION_ERR = '[Rest Error] [Shell] VersionUpdateError',

  GET_SF_CATEGORIES = '[Rest Call] [Shell] SFCategoriesGet',
  GET_SF_CATEGORIES_RES = '[Rest Result] [Shell] SFCategoriesGetResults',
  GET_SF_CATEGORIES_ERR = '[Rest Error] [Shell] SFCategoriesGetError',

  NEW_SF_TICKET = '[Rest Call] [Shell] NewSFTicket',
  NEW_SF_TICKET_RES = '[Rest Result] [Shell] NewSFTicketResults',
  NEW_SF_TICKET_ERR = '[Rest Error] [Shell] NewSFTicketError',

  GET_APPLICATION_PAGE_LIST =  '[Rest Call] [Shell] GetApplicationPageList',
  GET_APPLICATION_PAGE_LIST_RES =  '[Rest Result] [Shell] GetApplicationPageList',
  GET_APPLICATION_PAGE_LIST_ERR =  '[Rest Error] [Shell] GetApplicationPageList',

  LOG_TRACKING_EVENT =  '[Shell] LogAppInsightsEvent',
}


export const GetVersion = createAction(ShellActionTypes.GET_VERSION);
export const GetVersionResults = createAction(ShellActionTypes.GET_VERSION_RES,  props<{version: VersionTracker}>());
export const GetVersionError = createAction(ShellActionTypes.GET_VERSION_ERR,  props<{message: string}>());

export const UpdateVersion = createAction(ShellActionTypes.UPDATE_VERSION, props<{ saveData: SaveVersionTracker }>()  );
export const UpdateVersionResults = createAction(ShellActionTypes.UPDATE_VERSION_RES,  props<{ version: SaveVersionTracker}>());
export const UpdateVersionError = createAction(ShellActionTypes.UPDATE_VERSION_ERR,  props<{message: string}>());

export const GetSfCategories = createAction(ShellActionTypes.GET_SF_CATEGORIES);
export const GetSfCategoriesResults = createAction(ShellActionTypes.GET_SF_CATEGORIES_RES,  props<{categories: SalesForceCategory[]}>());
export const GetSfCategoriesError = createAction(ShellActionTypes.GET_SF_CATEGORIES_ERR,  props<{message: string}>());

export const NewSfTicket = createAction(ShellActionTypes.NEW_SF_TICKET,  props<{ ticket: SalesForceTicket }>() );
export const NewSfTicketResults = createAction(ShellActionTypes.NEW_SF_TICKET_RES,  props<{ticket: SalesForceTicket}>());
export const NewSfTicketError = createAction(ShellActionTypes.NEW_SF_TICKET_ERR,  props<{message: string}>());

export const GetApplicationPageList = createAction(ShellActionTypes.GET_APPLICATION_PAGE_LIST, props<{appId: number}>());
export const GetApplicationPageListResults = createAction(ShellActionTypes.GET_APPLICATION_PAGE_LIST_RES,
  props<{applicationPageList: ApplicationMenuItem[]}>());
export const GetApplicationPageListError = createAction(ShellActionTypes.GET_APPLICATION_PAGE_LIST_ERR, props<{message: string}>());

export const LogTrackingEvent = createAction(ShellActionTypes.LOG_TRACKING_EVENT, props<{eventName: string}>());
