<div class="mh-dialog" [hidden]="hideCountdown">
    <div class="mh-overlay-backdrop" id="timeout-overlay"></div>
    <div id="timeout-popup" class="mat-dialog-container">
        <div class="dialog-header">
            <h2>Automatic Log Out</h2>
        </div>
        <div class="dialog-body">
            <p>You will automatically be logged out due to inactivity in: <span style="font-weight:bold;">{{counter | date:'mm:ss'}}</span></p>
            <button mat-button (click)="continueClick()">Stay Logged In</button>
        </div>
    </div>
</div>
