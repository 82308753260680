import { Component, ViewChild, ElementRef, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Store } from '@ngrx/store';
import { MatSort } from '@angular/material/sort';
import { GetPharmacies, RegenerateUserToken, ShowClientSelection, UpdateUserSession } from '../../store/user.actions';
import { Pharmacy, UAMPharmacy, PharmacyGetOptions } from '../../models/pharmacy';
import { UserState} from '../../store/user.reducer';
import { Observable } from 'rxjs';
import { UserSettings } from '../../models/user';
import { HttpClient } from '@angular/common/http';
import * as $ from 'jquery';
import * as XLSX from 'xlsx';
import { UiState } from 'src/app/store/ui-state.reducer';
import { SharedDataService } from 'src/app/service/shared-data.service';
import { Router } from '@angular/router';
import { UamState } from 'src/app/store/uam/uam.reducer';
import { DatePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-mat-table',
  templateUrl: './mat-table.component.html',
  styleUrls: ['./mat-table.component.scss']
})
export class MatTableComponent {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('TABLE') table: ElementRef;
  public pharmacies$: Observable<Pharmacy[]>;
  public uiState$: Observable<UiState> = null;
  displayedColumns: string[] = ['space', 'pharmType', 'pid', 'pharmacy', 'location', 'utilCount', 'lastRecalculated'];
  dataSource;
  tableRow;
  userSettings: UserSettings;
  searchValue: string;
  currentPid: number;
  currentCorpId: number;
  currentCSId: number;
  showClientSelectionBtn: boolean;
  currentApp: number;
  dialogData: any;

  public callInProgress: boolean = false;

  constructor(protected http: HttpClient, private dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) private data: any,
      public store: Store<{ userState: UserState, uiState: UiState, uamState: UamState }>, private sharedService: SharedDataService, private router: Router, private datePipe: DatePipe){
        
      this.dialogData = data;
  }

  ngOnInit() {
    this.store.select(state => state.userState.settings).subscribe(val => {
      if (val)
        this.userSettings = val;
    });

    this.store.select(state => state.userState.currentApp).subscribe(val => {
      if (val)
        this.currentApp = val;
    });

    this.store.select(state => state.userState.session).subscribe (val => {
      if (val){
        this.currentPid = val.pid;
        this.currentCorpId = val.corpID;
        this.currentCSId = val.chainGroupId;
      }
    });

    this.store.select(state => state.uamState.initialClientList).subscribe(data => {
      if (data) {
        this.showClientSelectionBtn = data.chains.length + data.corps.length > 1;
      }
    });

    this.store.select(state => state.uiState.CallInProgress ).subscribe( (val) => {
      setTimeout(() => {
        this.callInProgress = val;
      });
    });

    this.store.select(state => state.userState.pharmacies).subscribe((data) => {
      let filteredData = data || [];

      let groupedData = this.sortAndGroupData(filteredData);
      this.dataSource = new MatTableDataSource(groupedData);

      this.dataSource.filterPredicate = (data, filter: string): boolean => {

        if (data.group) {
          return data.group.toString().toLowerCase().includes(filter) ||
            data.hid.toString().toLowerCase().includes(filter) ||
            data.pharmNames.toString().toLowerCase().includes(filter) ||
            data.locations.toString().toLowerCase().includes(filter) ||
            data.pids.toString().toLowerCase().includes(filter);
        }
        else {
          return data.hospitalName.toString().toLowerCase().includes(filter) ||
            data.pharmacy.toString().toLowerCase().includes(filter) ||
            data.pid.toString().includes(filter) ||
            data.location.toString().toLowerCase().includes(filter) ||
            data.hid.toString().toLowerCase().includes(filter);
        }
      };
    });

    this.store.dispatch(GetPharmacies({ searchText: null, appChange: this.dialogData.appChange }));
  }

  handleRowData(row) {
    this.tableRow = row;
  }
  onCloseClick() {
    this.dialogRef.close();
  }
  handleViewSelected() {
    if (this.currentPid !== this.tableRow.pid) {
      this.sharedService.SelectedHSView = true;
      // update app for new Pharmacy selection
      let clientId = this.currentCorpId > 0 ? this.currentCorpId : this.currentCSId;
      this.store.dispatch(RegenerateUserToken({request: { pid: this.tableRow.pid, clientId: clientId, isCorp: this.currentCorpId > 0 }}));
      this.dialogRef.close(this.tableRow.pid);
    } else {
      this.dialogRef.close(null);
    }
  }

  applyFilter(event: KeyboardEvent) {
    this.searchValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = this.searchValue.trim().toLowerCase();
    if (event.key == "Enter") {
      this.refreshSearch();
    }
  }

  refreshSearch(){
    this.store.dispatch(GetPharmacies({ searchText: this.searchValue.trim().toLowerCase(), appChange: this.dialogData.appChange }));
    this.dataSource.filter = this.searchValue.trim().toLowerCase();
  }

  sortAndGroupData(data) {
    data.forEach(el => {
      // cities are upppercase, parse them to correct casing
      if (el.location) {
        let cityState = el.location.split(',');
        let cityParts = cityState[0].trim().split(" ");
        cityParts.forEach((c, i) => {
          cityParts[i] = c[0] + c.substring(1).toLowerCase();
        });
        el.location = cityParts.join(' ') + ', ' + cityState[1];
      }
      // parse utilcount to comma separated
      el.utilCount = el.utilCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    });

    data.sort((a, b) => {
      return b.pharmActive - a.pharmActive || a.pharmType - b.pharmType;
    });

    let groups = data.reduce((r, a) => {
      r[a.hid] = [...r[a.hid] || [], a];
      return r;
    }, {});

    let groupedData = [];
    Object.keys(groups).forEach(key => {
      groupedData.push({
        group: groups[key][0].hospitalName,
        hid: key,
        pharmNames: groups[key].reduce((r, a) => r + a.pharmacy, ""),
        locations: groups[key].reduce((r, a) => r + a.location, ""),
        pids: groups[key].reduce((r, a) => r + a.pid, "")
      }, ...groups[key]);
    });

    //console.log(groupedData);
    return groupedData;
  }

  isGroup(index, item): boolean {
    return item.group;
  }

  collapseRows(hid) {
    let button = $(".button-hid-" + hid);
    if (button.hasClass("group-expanded")) {
      $(".hid-group-" + hid).css("display", "none");
      button.removeClass("group-expanded");
    }
    else {
      $(".hid-group-" + hid).css("display", "table-row");
      button.addClass("group-expanded");
    }
  }

  exportTable() {
    const columnValues: string[] = ['', 'Type', 'PID', 'Pharmacy', 'Location', 'Util Count', 'Last Recalculated'];
    const columnNames: string[] = ['', 'type', 'pid', 'pharmacy', 'location', 'utilCount', 'recalculated'];

    let data = [], ws: XLSX.WorkSheet, dt = new Date();
    this.dataSource.data.forEach((element: any) => { // traversed to each row
      let row = {};
      columnNames.forEach((col) => { // sorted list of cols
        if (element['pid'] == null) {
          if (col == '')
            row[''] = `${element['group']} (HID ${element['hid']})`
        }
        else if (col === 'type') {
          row[col] = '';
          if (element.pharmType === 0)
            row[col] += 'H';
          if (element.pharmType === 1)
            row[col] += 'R';
          if (element.isSpecialty === 1)
            row[col] += '/S';
          if (element.isGateway === 1)
            row[col] += '/G';
          if (element.isPAP === 1)
            row[col] += '/P';
          if (element.isNDCA === 1)
            row[col] += '/N';
          if (element.isFeasibility === 1)
            row[col] += '/F';
        }
        else if (col === 'recalculated' && element[col] != null)
          row[col] = this.datePipe.transform(element[col], "MM/dd/yyyy h:mma 'EST'")
        else
          row[col] = element[col];
      });
      data.push(row);
    });

    let headers = {}
    columnValues.forEach((col: string, index: number) => {
      headers[columnNames[index]] = col;
    });
    data.unshift(headers);

    //Export to Excel
    ws = XLSX.utils.json_to_sheet(data, { skipHeader: true });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'Assiged_Pharmacies_' + new Date().toLocaleDateString() + '.xlsx');
  }

  handleClientSelection() {
    this.store.dispatch(ShowClientSelection());
    this.dialogRef.close();
  }
}
