import { Injectable } from '@angular/core';
import { BaseMhService } from './basemh.service';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Manufacturer, Manufacturer_Details, MName, MSubscription, MFolder, Delete_Contact, PharmacyChainStore, SavePharmacyChainStore, OptInSelection, OptInInfo, SubmissionStatusInfo, ExclusionsRequest, ExclusionsInfo, ExclusionsSelection, ExclusionsSelectionsData, EspManufacturer, SubmissionHistoryInfo, SubmittedClaim, SubmittedClaimsRequest, AutoExclusionSettingsInfo, SaveAutoExclusionModel, ManufacturersNDC, newAutoExcludeSetting } from '../models/manufacturer';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { UserState } from '../store/user.reducer';
import { ApiReponse, SubmitResponse } from '../models/submit-response';
import { environment } from '../../environments/environment';
import { TableGetOptions } from '../models/received-detail';
@Injectable({
  providedIn: 'root'
})

export class Validate340BService extends BaseMhService {

  public loginResponse: string;
  result: boolean;

  constructor(protected http: HttpClient, protected store: Store<{ userState: UserState }>) {
    super(http, store);
  }

  public getManufacturers(options: TableGetOptions) {

    let params: HttpParams = new HttpParams();
    if (options.pageNumber)
      params = params.append('pageNumber', '' + options.pageNumber);
    if (options.rowsPerPage)
      params = params.append('rowsPerPage', '' + options.rowsPerPage);
    if (options.searchText)
      params = params.append('searchText', options.searchText);
    if (options.sortColumn)
      params = params.append('sortColumn', options.sortColumn);
    if (options.sortOrder)
      params = params.append('sortOrder', options.sortOrder);

    return this.http
      .get(environment.apiBaseUrl + 'validate340b/v1/manufacturer',
        { headers: this.getHeaders(), params })
      .pipe(
        map((response: Manufacturer[]) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Manufacturers" }))
      );
  }

  public addManufacturer(addData: Manufacturer_Details) {
    return this.http
      .post(environment.apiBaseUrl + 'validate340b/v1/manufacturer/addmanufacturer', addData, { headers: this.getHeaders() })
      .pipe(
        map((response: SubmitResponse) => {
          if (response.status === '200')
            return addData;
          else
            throwError(response.message);
        }),
        catchError(error => this.errorHandler({ error, operation: "Error while Adding Manufacturer" }))
      );
  }

  public IsUniqueMName(mname: MName) {
    return this.http
      .post(environment.apiBaseUrl + 'validate340b/v1/manufacturer/uniquename', mname, { headers: this.getHeaders() })
      .pipe(
        map((response: number) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Manufacturer Name Validity" }))
      );
  }

  public IsUniqueMSubscription(msub: MSubscription) {
    return this.http
      .post(environment.apiBaseUrl + 'validate340b/v1/manufacturer/uniquesubscription', msub, { headers: this.getHeaders() })
      .pipe(
        map((response: number) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Manufacturer subscription Validity" }))
      );
  }

public IsUniqueMFolder(mfolder: MFolder) {
    return this.http
      .post(environment.apiBaseUrl + 'validate340b/v1/manufacturer/uniquefolder', mfolder, { headers: this.getHeaders() })
      .pipe(
        map((response: number) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Manufacturer Hash Secret Validity" }))
      );
  }

  public getManufacturerByID(manId: number) {
    return this.http
      .get(environment.apiBaseUrl + 'validate340b/v1/manufacturer/viewdetails/' + manId,
        { headers: this.getHeaders() })
      .pipe(
        map((response: Manufacturer_Details) => response),
        catchError(error => this.errorHandler({ error, operation: 'Error while retrieving Manufacturer details by ID' }))
      );
  }

  public updateManufacturer(updatedtls: Manufacturer_Details) {
    return this.http
      .post(environment.apiBaseUrl + 'validate340b/v1/manufacturer/updatemanufacturer', updatedtls, { headers: this.getHeaders() })
      .pipe(
        map((response: SubmitResponse) => {
          if (response.status === '200')
            return updatedtls;
          else
            throwError(response.message);
        }),
        catchError(error => this.errorHandler({ error, operation: "Error while Updating Manufacturer" }))
      );
  }

  public deleteContact(delcontact: Delete_Contact) {
    return this.http
      .post(environment.apiBaseUrl + 'validate340b/v1/manufacturer/delcontact', delcontact, { headers: this.getHeaders() })
      .pipe(
        map((response: SubmitResponse) => {
          if (response.status === '200')
            return delcontact;
          else
            throwError(response.message);
        }),
        catchError(error => this.errorHandler({ error, operation: "Error while deleting Manufacturer Contact" }))
      );
  }

  public getPharmChainStoreList() {
    return this.http
      .get(environment.apiBaseUrl + 'validate340b/v1/pharmacy-chain-setup/get-chain-store-list',
        { headers: this.getHeaders() })
      .pipe(
        map((response: PharmacyChainStore[]) => response),
        catchError(error => this.errorHandler({ error, operation: 'Error while pharmacy chain store list' }))
      );
  }

  public getPharmChainStoreByChainID(chainID: number) {
    return this.http
      .get(environment.apiBaseUrl + 'validate340b/v1/pharmacy-chain-setup/get-chain-store/chainID/' + chainID,
        { headers: this.getHeaders() })
      .pipe(
        map((response: PharmacyChainStore) => response),
        catchError(error => this.errorHandler({ error, operation: 'Error while retrieving pharmacy chain store by ChainID' }))
      );
  }

  public getPharmChainStoreByName(chainName: string) {
    return this.http
      .get(environment.apiBaseUrl + 'validate340b/v1/pharmacy-chain-setup/get-chain-store/chainName/' + chainName,
        { headers: this.getHeaders() })
      .pipe(
        map((response: PharmacyChainStore) => response),
        catchError(error => this.errorHandler({ error, operation: 'Error while retrieving pharmacy chain store by Chain Name' }))
      );
  }

  public updatePharmChainstore(updatePharmChainstore: SavePharmacyChainStore){
    return this.http
      .patch(environment.apiBaseUrl + 'validate340b/v1/pharmacy-chain-setup/update-chain-store', updatePharmChainstore,
        { headers: this.getHeaders() })
      .pipe(
        map((response: ApiReponse) => response),
        catchError(error => this.errorHandler({ error, operation: 'Error while updating pharmacy chain store' }))
      );
  }

  public getDataSubmissionByManufacturer(mid: number) {
    return this.http
      .get(environment.apiBaseUrl + 'validate340b/v1/esp/' + mid,
        { headers: this.getHeaders() })
      .pipe(
        map((response: OptInInfo[]) => response),
        catchError(error => this.errorHandler({ error, operation: 'Error while retrieving opt in data for manufacturer' }))
      );
  }

  public submitOptInData(selections: OptInSelection[]){
    return this.http
      .post(environment.apiBaseUrl + 'validate340b/v1/esp', selections,
        { headers: this.getHeaders() })
      .pipe(
        map((response: ApiReponse) => response),
        catchError(error => this.errorHandler({ error, operation: 'Error while submitting opt in selections' }))
      );
  }

  public getSubmissionStatusData() {
    return this.http
      .get(environment.apiBaseUrl + 'validate340b/v1/esp/submissionstatus',
        { headers: this.getHeaders() })
      .pipe(
        map((response: SubmissionStatusInfo[]) => response),
        catchError(error => this.errorHandler({ error, operation: 'Error while retrieving opt in data for manufacturer' }))
      );
  }

  public getExclusionsData(req: ExclusionsRequest){
    return this.http
      .post(environment.apiBaseUrl + 'validate340b/v1/bulkexclusion/filtereddata', req, { headers: this.getHeaders() })
      .pipe(
        map((response: ExclusionsInfo[]) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving exclusions data" }))
      );
  }

  public getExclusionsDataWithDrugInfo(req: ExclusionsRequest){
    return this.http
      .post(environment.apiBaseUrl + 'validate340b/v1/bulkexclusion/filtereddata/withdruginfo', req, { headers: this.getHeaders() })
      .pipe(
        map((response: ExclusionsInfo[]) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving exclusions data" }))
      );
  }

  public submitExclusionsData(data: ExclusionsSelectionsData){
    return this.http
      .post(environment.apiBaseUrl + 'validate340b/v1/bulkexclusion/saveExclusion', data, { headers: this.getHeaders() })
      .pipe(
        map((response: ApiReponse) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while submitting exclusions data" }))
      );
  }

  public getEspManufacturers(ndcsourceoneOnly: boolean) {
    return this.http
      .get(environment.apiBaseUrl + 'validate340b/v1/bulkexclusion/esp/manufacturers' + (ndcsourceoneOnly ? '/ndcsourceone' : ''), { headers: this.getHeaders() })
      .pipe(
        map((response: EspManufacturer[]) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while getting ESP Manufacturers" }))
      );
  }

  public getPriceVariancePercent() {
    return this.http
      .get(environment.apiBaseUrl + 'validate340b/v1/bulkexclusion/variancepercent', { headers: this.getHeaders() })
      .pipe(
        map((response: string) => response),
        // don't display error to user if this fails
        catchError(() => [])
      );
  }

  public getMckPHSContractFlagVisibility() {
    return this.http
      .get(environment.apiBaseUrl + 'validate340b/v1/bulkexclusion/phscontracttogglevisible', { headers: this.getHeaders() })
      .pipe(
        map((response: boolean) => response),
        // don't display error to user if this fails
        catchError(() => [])
      );
  }

  public getSubmissionHistory(hid: number) {
    return this.http
      .get(environment.apiBaseUrl + 'validate340b/v1/esp/submissionhistory/' + hid, { headers: this.getHeaders() })
      .pipe(
        map((response: SubmissionHistoryInfo[]) => response),
        // don't display error to user if this fails
        catchError((error) => this.errorHandler({ error, operation: "Error while getting submission history." }))
      );
  }

  public getSubmissionFile(filePath: string, hid: number) {
    return this.http
      .post(environment.apiBaseUrl + 'validate340b/v1/esp/submissionhistory/download', { filePath, hid }, { headers: this.getHeaders(), responseType: 'text' })
      .pipe(
        map((response: any) => response),
        catchError((error) => this.errorHandler({ error, operation: "Error while retrieving file." }))
      );
  }

  public getSubmittedClaims(req: SubmittedClaimsRequest) {
    return this.http
      .post(environment.apiBaseUrl + 'validate340b/v1/esp/submittedclaims', req, { headers: this.getHeaders() })
      .pipe(
        map((response: SubmittedClaim[]) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving submitted claims" }))
      );
  }

  public getAutoExclusionsSettingsData(){
    return this.http
      .post(environment.apiBaseUrl + 'validate340b/v1/bulkexclusion/autoExclusionSetttings', null,{ headers: this.getHeaders() })
      .pipe(
        map((response: AutoExclusionSettingsInfo[]) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving exclusions data" }))
      );
  }

  public saveAutoExclusionsData(data: SaveAutoExclusionModel, ops: number){
    return this.http
      .post(environment.apiBaseUrl + 'validate340b/v1/bulkexclusion/saveAutoExclusionSetttings/' +ops, data, { headers: this.getHeaders() })
      .pipe(
        map((response: ApiReponse) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while submitting exclusions data" }))
      );
  }

  public getManufacturersNdcs(){
    return this.http
    .get(environment.apiBaseUrl + 'validate340b/v1/bulkexclusion/esp/manufacturersNdcs', { headers: this.getHeaders() })
    .pipe(
        map((response: ManufacturersNDC[]) => response),
        catchError(error => this.errorHandler({ error, operation: 'Error while retrieving Manufacturers NDCs' }))
    );
  }

  public saveNewAutoExclusionsData(settings: newAutoExcludeSetting[]){
    return this.http
    .post(environment.apiBaseUrl + 'validate340b/v1/bulkexclusion/saveNewExclusionExclusions', settings,
    { headers: this.getHeaders() })
    .pipe(
      map((response: ApiReponse) => response),
      catchError(error => this.errorHandler({ error, operation: 'Error while submitting opt in selections' }))
    );
  }




}
