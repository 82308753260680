<div style="width:700px;" *ngIf="data">
    <div style="font-weight: bold;" mat-dialog-title class="dialog-header" cdkDrag
        cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
        <h3 cdkDragHandle>{{data.pageName}}</h3>
    </div>
    <div class="dialog-body">
        <div class="div-label">Product Disclaimer</div>
        <span class="spn-message">{{data.disclaimerMessage}}</span>
        <div class='btn-footer'>
            <button mat-flat-button type="button" color="primary" (click)="onAcknowledge()"
                class="qualify-btn">Acknowledge</button>
            <button mat-stroked-button type="button" class="discard-btn" style="margin-left:10px;"
                (click)="onGoBack()">Go Back</button>
        </div>
    </div>