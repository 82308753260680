import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { UserState } from "../../store/user.reducer";
import { BaseMhService } from "../basemh.service";
import { ChainStoresGroupsResponse } from "../../models/gateway/chain/chain-stores-groups-response";
import { ChainSettingsRequest } from "../../models/gateway/chain/chain-settings-response";
import { ChainSettingsResponse } from "../../models/gateway/chain/chain-settings-response";
import { UserSession } from "../../models/user";
import { ChainResponse } from "../../models/gateway/chain/chain-response";
import { FormularySetupResponse } from "src/app/models/gateway/formulary/formulary-response";
import { FormularySetupCSGIDsResponse } from "src/app/models/gateway/formulary/formulary-setup-csgids-response";
import { AddDispenseFeeScheduleRequest, DeleteDispenseFeeScheduleRequest, UpdateDispenseFeeScheduleRequest } from "src/app/models/gateway/dispense-fee/dispense-fee-schedule-request";
import { CoveredEntityResponse, DispenseFeeScheduleResponse } from "src/app/models/gateway/dispense-fee/dispense-fee-schedule-response";
import { SubmitResponse } from "src/app/models/submit-response";
import { throwError } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class GatewayService extends BaseMhService {

  constructor(protected http: HttpClient, protected store: Store<{ userState: UserState }>) {
    super(http, store);
  }

  public getChainStoresGroupsList() {
    return this.http
      //.get('https://localhost:44387/Gateway/GetChainStoresGroupsList', { headers: this.getHeaders() })
      .get(environment.apiBaseUrl + 'api/gatewayapi/v1/Gateway/GetChainStoresGroupsList', { headers: this.getHeaders() })
      .pipe(
        map((response: ChainStoresGroupsResponse) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Gateway Chain Stores Groups List Data" }))
      );
  }

  public getGatewayTreeView(CSGID: number) {
    return this.http
      //.get('https://localhost:44387/Gateway/GetGatewayTreeViewByCSGID?CSGID=' + CSGID, { headers: this.getHeaders() })
      .get(environment.apiBaseUrl + 'api/gatewayapi/v1/Gateway/GetGatewayTreeViewByCSGID?CSGID=' + CSGID, { headers: this.getHeaders() })
      .pipe(
        map((response: any) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving CSGID Tree View" }))
      );
  }

  public getChainSettings(chainId: number) {
    return this.http
      //.get('https://localhost:44387/Gateway/GetChainSettings?ChainId=' + chainId, { headers: this.getHeaders() })
      .get(environment.apiBaseUrl + 'api/gatewayapi/v1/Gateway/GetChainSettings?ChainId=' + chainId, { headers: this.getHeaders() })
      .pipe(
        map((response: ChainSettingsResponse) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving Chain Settings" }))
      );
  }

  public setChainSettings(chainSettingsRequest: ChainSettingsRequest) {
    const session: UserSession = this.getStoreUserSession();
    chainSettingsRequest.uid = session.uid;
    return this.http
      //.post('https://localhost:44387/Gateway/SetChainSettings', chainSettingsRequest, { headers: this.getHeaders() })
      .post(environment.apiBaseUrl + 'api/gatewayapi/v1/Gateway/SetChainSettings', chainSettingsRequest, { headers: this.getHeaders() })
      .pipe(
        map((response: any) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Updating Chain Settings" }))
      );
  }

  public getGatewayChain(CSGID: number) {
    return this.http
      //.get('https://localhost:44387/Gateway/GetGatewayChainByCSGID?CSGID=' + CSGID, { headers: this.getHeaders() })
      .get(environment.apiBaseUrl + 'api/gatewayapi/v1/Gateway/GetGatewayChainByCSGID?CSGID=' + CSGID, { headers: this.getHeaders() })
      .pipe(
        map((response: ChainResponse) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving Chain by CSGID" }))
      );
  }

  public getDispenseFeeFormularySetup(CSGID: number, effectiveDate: string) {
    return this.http
      //.get('https://localhost:44387/Gateway/GetDispenseFeeFormularySetup?CSGID=' + CSGID + '&effectiveDate=' + effectiveDate, { headers: this.getHeaders() })
      .get(environment.apiBaseUrl + 'api/gatewayapi/v1/Gateway/GetDispenseFeeFormularySetup?CSGID=' + CSGID + '&effectiveDate=' + effectiveDate, { headers: this.getHeaders() })
      .pipe(
        map((response: FormularySetupResponse) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving Dispense Fee Formulary Setup" }))
      );
  }

  public getDispenseFeeFormularySetupCSGIDs() {
    return this.http
      //.get('https://localhost:44387/Gateway/GetDispenseFeeFormularySetupCSGIDs', { headers: this.getHeaders() })
      .get(environment.apiBaseUrl + 'api/gatewayapi/v1/Gateway/GetDispenseFeeFormularySetupCSGIDs', { headers: this.getHeaders() })
      .pipe(
        map((response: FormularySetupCSGIDsResponse) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving Dispense Fee Formulary Setup CSGIDs" }))
      );
  }

  public getDispenseFeeSchedule(CSGID: number) {
    return this.http
      //.get('https://localhost:44387/Gateway/GetDispenseFeeSchedule?CSGID=' + CSGID, { headers: this.getHeaders() })
      .get(environment.apiBaseUrl + 'api/gatewayapi/v1/Gateway/GetDispenseFeeSchedule?CSGID=' + CSGID, { headers: this.getHeaders() })
      .pipe(
        map((response: DispenseFeeScheduleResponse) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving Dispense Fee Schedule" }))
      );
  }

  public addDispenseFeeSchedule(addDispenseFeeScheduleRequest: AddDispenseFeeScheduleRequest) {
    const session: UserSession = this.getStoreUserSession();
    addDispenseFeeScheduleRequest.uid = session.uid;
    return this.http
      //.post('https://localhost:44387/Gateway/AddDispenseFeeSchedule', addDispenseFeeScheduleRequest, { headers: this.getHeaders() })
      .post(environment.apiBaseUrl + 'api/gatewayapi/v1/Gateway/AddDispenseFeeSchedule', addDispenseFeeScheduleRequest, { headers: this.getHeaders() })
      .pipe(
        map((response: SubmitResponse) => {
          if (response.status == '200'){
            return addDispenseFeeScheduleRequest;
          }
          else
            throwError(response.message);
        }),
        catchError(error => this.errorHandler({ error, operation: "Error while Adding Dispense Fee Schedule" }))
      );
  }

  public getDispenseFeeScheduleHistory(CSGID: number, DrugType: string) {
    return this.http
      //.get('https://localhost:44387/Gateway/GetDispenseFeeScheduleHistory?CSGID=' + CSGID + '&DrugType=' + DrugType, { headers: this.getHeaders() })
      .get(environment.apiBaseUrl + 'api/gatewayapi/v1/Gateway/GetDispenseFeeScheduleHistory?CSGID=' + CSGID + '&DrugType=' + DrugType, { headers: this.getHeaders() })
      .pipe(
        map((response: any) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving Dispense Fee Schedule History" }))
      );
  }

  public getDispenseFeeFormularyCurrent(CSGID: number) {
    return this.http
      //.get('https://localhost:44387/Gateway/GetDispenseFeeFormularyCurrent?CSGID=' + CSGID, { headers: this.getHeaders() })
      .get(environment.apiBaseUrl + 'api/gatewayapi/v1/Gateway/GetDispenseFeeFormularyCurrent?CSGID=' + CSGID, { headers: this.getHeaders() })
      .pipe(
        map((response: any) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving Current Formulary for CSGID" }))
      );
  }

  public getDispenseFeeFormularyUploads(CSGID: number) {
    return this.http
      //.get('https://localhost:44387/Gateway/GetDispenseFeeFormularyUploads?CSGID=' + CSGID, { headers: this.getHeaders() })
      .get(environment.apiBaseUrl + 'api/gatewayapi/v1/Gateway/GetDispenseFeeFormularyUploads?CSGID=' + CSGID, { headers: this.getHeaders() })
      .pipe(
        map((response: any) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving Formulary Uploads" }))
      );
  }

  public updateDispenseFeeSchedule(updateDispenseFeeScheduleRequest: UpdateDispenseFeeScheduleRequest) {
    const session: UserSession = this.getStoreUserSession();
    updateDispenseFeeScheduleRequest.uid = session.uid;
    return this.http
      //.post('https://localhost:44387/Gateway/UpdateDispenseFeeSchedule', updateDispenseFeeScheduleRequest, { headers: this.getHeaders() })
      .post(environment.apiBaseUrl + 'api/gatewayapi/v1/Gateway/UpdateDispenseFeeSchedule', updateDispenseFeeScheduleRequest, { headers: this.getHeaders() })
      .pipe(
        map((response: any) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Updating Dispense Fee Schedule" }))
      );
  }

  public deleteDispenseFeeSchedule(deleteDispenseFeeScheduleRequest: DeleteDispenseFeeScheduleRequest) {
    const session: UserSession = this.getStoreUserSession();
    deleteDispenseFeeScheduleRequest.uid = session.uid;
    return this.http
      //.post('https://localhost:44387/Gateway/DeleteDispenseFeeSchedule', deleteDispenseFeeScheduleRequest, { headers: this.getHeaders() })
      .post(environment.apiBaseUrl + 'api/gatewayapi/v1/Gateway/DeleteDispenseFeeSchedule', deleteDispenseFeeScheduleRequest, { headers: this.getHeaders() })
      .pipe(
        map((response: any) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Deleting Dispense Fee Schedule" }))
      );
  }

  public getCoveredEntitiesForCSGID(CSGID: number) {
    return this.http
      //.get('https://localhost:44387/Gateway/GetCoveredEntitiesForCSGID?CSGID=' + CSGID, { headers: this.getHeaders() })
      .get(environment.apiBaseUrl + 'api/gatewayapi/v1/Gateway/GetCoveredEntitiesForCSGID?CSGID=' + CSGID, { headers: this.getHeaders() })
      .pipe(
        map((response: CoveredEntityResponse) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving Covered Entities for CSGID" }))
      );
  }
}
