import { Injectable } from '@angular/core';
import { of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EducationService } from '../service/education.service';
import { AddHolidays, AddHolidaysError, AddHolidaysResults, DeleteHolidays, DeleteHolidaysError, DeleteHolidaysResults, EducationActionTypes, GetHolidays, GetHolidaysError, GetHolidaysResults, UpdateHolidays, UpdateHolidaysError, UpdateHolidaysResults } from './education.actions';

@Injectable()
export class EducationEffects {
  constructor(private educationService: EducationService, private actions$: Actions) {}

  GetHolidaysEffect$ = createEffect(() => this.actions$.pipe(
    ofType( GetHolidays ),
    switchMap(() =>
      this.educationService.getHolidays()
        .pipe(
          map(holidays => GetHolidaysResults( { data: holidays } )),
          catchError(error => {
            return observableOf(GetHolidaysError( { errorMessage: error } ))
          })
        )
    )
  ));

  UpdateHolidaysEffect$ = createEffect(() => this.actions$.pipe(
    ofType( UpdateHolidays ),
    switchMap(action =>
      this.educationService.updateHoliday(action.holiday)
        .pipe(
          map(holiday => UpdateHolidaysResults( { holiday } )),
          catchError(error => {
            return observableOf(UpdateHolidaysError( { errorMessage: error } ))
          })
        )
    )
  ));

  AddHolidaysEffect$ = createEffect(() => this.actions$.pipe(
    ofType( AddHolidays ),
    switchMap(action =>
      this.educationService.addHoliday(action.holiday)
        .pipe(
          map(holiday => AddHolidaysResults( { holiday } )),
          catchError(error => {
            return observableOf(AddHolidaysError( { errorMessage: error } ))
          })
        )
    )
  ));

  DeleteHolidaysEffect$ = createEffect(() => this.actions$.pipe(
    ofType( DeleteHolidays ),
    switchMap(action =>
      this.educationService.deleteHoliday(action.id)
        .pipe(
          map(() => DeleteHolidaysResults()),
          catchError(error => {
            return observableOf(DeleteHolidaysError( { errorMessage: error } ))
          })
        )
    )
  ));

  ModifiedHolidaysResultsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(EducationActionTypes.HOLIDAYS_ADD_RES, EducationActionTypes.HOLIDAYS_UPDATE_RES, EducationActionTypes.HOLIDAYS_DELETE_RES),
    switchMap(action => {
        return observableOf( GetHolidays() )
      }
    )
  ))

}
  
