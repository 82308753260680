<div> 
  <img id="background_helix_image" src="../assets/img/helix-image.svg" alt="Illustration" />
  <div class="accessDeniedPage">
    <div class="accessDeniedHeader">
      <mat-icon class="errorIcon">error</mat-icon>
      <h2>Access Denied</h2>
    </div>
    <p> You do not have permissions to access this page</p>
    <p> Contact your System Administrator</p>
    <div class="exitBtns">
      <button *ngIf="clientCount == 0" mat-flat-button class="btn-txt" (click)="signOut()"> Ok </button>
      <button *ngIf="clientCount > 0" mat-flat-button class="btn-txt" (click)="openClientSelection()"> Change Health System </button>
    </div>
  </div>
</div>
