import { GetUsersPublicationSelections, UpdateUsersPublicationSelections, UpdateUserEmailSettings,
  UpdateUserEmailSettingsError, UpdateUsersPublicationSelectionsResults,
  UpdateUsersPublicationSelectionsError,
  GetUserEmailSettings} from './../../../../../store/user.actions';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UsersPublicationSelection, SaveUserPubicationSelection, UserEmailSettings, UserNotificationSettings, UserSettings } from 'src/app/models/user';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/store/user.reducer';
import { Actions, ofType } from '@ngrx/effects';
import { Observable, Subscription } from 'rxjs';
import { InfoShow, ErrShow } from 'src/app/store/ui-state.actions';
import { FormControl } from '@angular/forms';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'user-notification-settings-tab',
  templateUrl: './user-notification-settings-tab.component.html',
  styleUrls: ['./user-notification-settings-tab.component.scss']
})
export class UserNotificationSettingsTabComponent implements OnInit, OnDestroy {
  public userSettingStore$: Observable<UserSettings>;
  userSettingsInfo: UserSettings;
  emailSettings = new FormControl();
  saveUserNotificationSettings: UserNotificationSettings = new UserNotificationSettings();
  usersPublicationSelectionList: UsersPublicationSelection[];
  saveUsersPublicationSelectionList: SaveUserPubicationSelection[] = [];
  public userStore$: Observable<UserState>;
  saveUserEmailSettings: UserEmailSettings = new UserEmailSettings();
  userSettings: UserSettings = new UserSettings();
  wholeSalerConfirmation = false;
  orderSubmittedToWholesalerNoticesSelected = false;
  orderSubmittedToWholeSalerControl = new FormControl([]);
  orderSubmittedToWholeSalerOptions = ['My Orders Only', 'Auto Orders', 'Order Builder Orders', 'Submitted File Orders'];
  wholesalerConfirmationOptions = [{value: true, viewValue : 'Send Email'}, {value: false, viewValue: 'Do Not Send'}];
  subs: Subscription = new Subscription;

  constructor(private store : Store<{ userState: UserState }>, private actions$: Actions) {
    this.store.dispatch( GetUsersPublicationSelections() );
    this.store.dispatch( GetUserEmailSettings() );
  }

  ngOnInit(): void {
    this.subs.add(
      this.store.select(state => state.userState).subscribe((data) => {
        if (data){
          this.saveUserEmailSettings = data.userEmailSettings;
          this.userSettings = data.settings;
        }
      })
    )

    this.subs.add(
      this.store.select(state => state.userState.userPublicationSelectionList).subscribe((data) => {
        if (data) {
          this.usersPublicationSelectionList = data;
          this.setEmailSettingsPreselections();
        }
      })
    )
    
    this.subs.add(
      this.actions$.pipe(ofType( UpdateUsersPublicationSelectionsResults )).subscribe( (data) => {
        if (data.response.status === '200') {
          this.store.dispatch(InfoShow({ message: 'User Notifications settings is updated successfully!'}));
        }
      })
    )
    this.subs.add(
      this.actions$.pipe(ofType( UpdateUserEmailSettingsError )).subscribe((data) => {
        alert(data.message);
        this.store.dispatch( ErrShow({ message: data.message}) );
      })
    )
    this.subs.add(
      this.actions$.pipe(ofType( UpdateUsersPublicationSelectionsError )).subscribe((data) => {
        alert(data.response.message);
        this.store.dispatch( ErrShow({ message: data.response.message}) );
      })
    )
  }

  SaveProfileClick() {
    this.mappingUserPublicationSelection();
    this.store.dispatch( UpdateUserEmailSettings({ saveData: this.saveUserEmailSettings}));
    this.store.dispatch( UpdateUsersPublicationSelections( {saveUsersPublicationSelections: this.saveUsersPublicationSelectionList}))
  }

  setEmailSettingsPreselections() {
    const emailSettingPreselected: string[] = [];
    if( this.saveUserEmailSettings !== null){
        
      if(this.saveUserEmailSettings.email850MyOrders ) {
        emailSettingPreselected.push('My Orders Only' )
      }
      if ( this.saveUserEmailSettings.email850AutoOrders ) {
        emailSettingPreselected.push('Auto Orders' )
      }
      if ( this.saveUserEmailSettings.email850OrderBuilder ) {
        emailSettingPreselected.push('Order Builder Orders' )
      }
      if ( this.saveUserEmailSettings.email850FileDropOrders ) {
        emailSettingPreselected.push('Submitted File Orders')
      }
      this.orderSubmittedToWholeSalerControl.setValue(emailSettingPreselected);
      this.wholeSalerConfirmation = this.saveUserEmailSettings.email855Confirmation;

      this.usersPublicationSelectionList.forEach(pubSelectionSetting => {
        if(pubSelectionSetting.publicationID === 61) {
          if (this.wholeSalerConfirmation) {
            pubSelectionSetting.selected = 1;
          } else {
            pubSelectionSetting.selected = 0;
          }
        }
      })
    }
  }

  mappingEmailSettingsSelection () {
    this.saveUserEmailSettings.email850MyOrders = false;
    this.saveUserEmailSettings.email850AutoOrders = false;
    this.saveUserEmailSettings.email850OrderBuilder = false;
    this.saveUserEmailSettings.email850FileDropOrders = false;

    this.orderSubmittedToWholeSalerControl.value.forEach(ele => {
      if ( ele === 'My Orders Only') {
        this.saveUserEmailSettings.email850MyOrders = true;
      } else if ( ele === 'Auto Orders') {
        this.saveUserEmailSettings.email850AutoOrders = true;
      } else if ( ele === 'Order Builder Orders') {
        this.saveUserEmailSettings.email850OrderBuilder = true;
      } else if ( ele === 'Submitted File Orders') {
        this.saveUserEmailSettings.email850FileDropOrders = true;
      }
    });
  }

  onChange(publication: UsersPublicationSelection) {
    publication.selected = (publication.selected == 0 ? 1 : 0);
    if(publication.publicationID === 60) {
      if(this.isChecked(publication)) {
        this.orderSubmittedToWholesalerNoticesSelected = false;
        this.orderSubmittedToWholeSalerControl.setValue([])
      } else {
        this.orderSubmittedToWholesalerNoticesSelected = true;
        this.orderSubmittedToWholeSalerControl.setValue(['My Orders Only'])
      }
    }
  }

  isChecked(pub: UsersPublicationSelection){
    if (pub.publicationID === 60){
      return this.orderSubmittedToWholeSalerControl.value.length > 0;
    }
    else return pub.selected;
  }

  mappingUserPublicationSelection() {
    this.saveUsersPublicationSelectionList = [];
    this.usersPublicationSelectionList.forEach(pubSelectionSetting => {
      if(pubSelectionSetting.publicationID === 61) {
        if (pubSelectionSetting.selected ) {
          this.saveUserEmailSettings.email855Confirmation = true;
        } else {
          this.saveUserEmailSettings.email855Confirmation = false;
        }
      }

      if (pubSelectionSetting.publicationID === 60) {
        this.mappingEmailSettingsSelection();
        if(this.orderSubmittedToWholeSalerControl.value.length > 0) {
          this.orderSubmittedToWholesalerNoticesSelected = true;
        } else {
          this.orderSubmittedToWholesalerNoticesSelected = false;
        }
      }
      const saveUsersPubSelection: SaveUserPubicationSelection = new SaveUserPubicationSelection();
      saveUsersPubSelection.uid = this.userSettings.uid;
      saveUsersPubSelection.publicationID = pubSelectionSetting.publicationID;
      saveUsersPubSelection.isSelected = this.isChecked(pubSelectionSetting) ? 1 : 0;
      this.saveUsersPublicationSelectionList.push(saveUsersPubSelection);
    })
  }

  ngOnDestroy(){
    this.subs.unsubscribe();
  }
}
