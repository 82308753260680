import { Injectable } from '@angular/core';
import {BaseMhService} from './basemh.service';
import {ActivatedRouteSnapshot, NavigationEnd, NavigationExtras, ResolveEnd, Router} from '@angular/router';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Store } from '@ngrx/store';
import {Observable, ObservableInput, Subscription} from 'rxjs';
import {filter, take, withLatestFrom} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UiState } from '../store/ui-state.reducer';
import { HttpClient, HttpHandler, HttpResponse, HttpHeaders, HttpRequest, HttpParams} from '@angular/common/http';
import { UserState } from '../store/user.reducer';
import {Application, Applications} from '../models/applications'
import * as Common from "@microsoft/applicationinsights-common";

@Injectable({
  providedIn: 'root'
})
export class AppInsightsService extends BaseMhService {

  private appInsights : ApplicationInsights;
  private routerSubscription: Subscription;
  private isInitialized : boolean;

  private properties :{ [key: string]: any } = {
    UserId : "",
    Pid: "",
    Hid: "",
    Page: "Unknown",
    Application: "Unknown",
    Platform: "Angular",
    ScreenSize: ""
  };

  constructor(private router: Router, protected http: HttpClient, protected store: Store<{userState:UserState, uiState:UiState, applications:Applications }>) {
    super(http, store);
    this.isInitialized = false;
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking : true
       // enableCorsCorrelation : true
      }
    });
  }

  initializeAppInsights(userLogin: string, uId?: string) {
    if (!this.isInitialized) {
      this.appInsights.loadAppInsights();
      this.createPidChangeSubscription();
      this.setUserId(userLogin, uId);
      this.createRouterSubscription();
      this.createUIStateSubscription(uId);
      this.createMenuStateSubscription();
      this.isInitialized = true;
    }
  }

  private setUserId(userId: string, accountId?: string) {
    this.appInsights.setAuthenticatedUserContext(userId, accountId, true);
  }

  private clearUserId() {
    this.appInsights.clearAuthenticatedUserContext();
  }

  public logPageView(name?: string, uri?: string) {
    this.properties.Page = name;
    this.properties.ScreenSize = window.screen.width + "x" + window.screen.height;
    var data : Common.IPageViewTelemetry = {
      properties : this.properties,
      name : name,
      uri : uri
    }
    if (name && uri) {
      this.appInsights.trackPageView(data);
    }
  }

  logTrackingEvent(name: string) : Observable<any>{
    this.logEvent(name);
    return new Observable<string>();
  }

  private logEvent(name: string) {
    this.appInsights.trackEvent({ name: name}, this.properties);
  }

  private logMetric(name: string, average: number ) {
    this.appInsights.trackMetric({ name: name, average: average }, this.properties);
  }

  private logException(name: string, message: string, severityLevel?: number) {

    const err = new Error();
    err.name = name;
    err.message = message;

    this.appInsights.trackException({ exception: err, severityLevel: severityLevel });
  }

  private logTrace(message: string) {
    this.appInsights.trackTrace({ message: message}, this.properties);
  }

  private createPidChangeSubscription() {
    this.store.select( state => state.userState.session ).subscribe( val => {
      const oldval = this.properties.Pid
      this.properties.UserId = ""+val.uid;
      this.properties.Pid = ""+val.pid;
      this.properties.Hid = ""+val.hid;
      if( oldval != "" )
        this.logEvent("PID_Change");
    } );
    this.store.select( state=> state.applications.Selected ).subscribe( val => {
      if( val ) {
        const oldval = this.properties.Application;
        this.properties.Application = val.appName;
        if( oldval != "Unknown" )
          this.logEvent("Application_Change_To_"+val.routePath);
      }
    });
  }

  private createMenuStateSubscription() {
    this.store.select(state => state.uiState.MenuVisible).pipe( withLatestFrom( this.store.select(state => state.uiState )) )
      .subscribe( ([val, uiState]) => {
        if (uiState.InitDone) {
            if( val ) {
                this.logEvent("MH_Menu_State_SHOW");
            } else {
                this.logEvent("MH_Menu_State_HIDE");
            }
        }
      }
    );
  }

  private createUIStateSubscription(UserId: string) {
    this.store.select(state => state.uiState.ErrorVisible )
    .pipe( withLatestFrom( this.store.select(state => state.uiState )) )
    .subscribe( ([flag, uiState]) => {
        if (flag == true) {
            //this.logEvent("RESTCallError", {"message": uiState.ErrorMessage, "callId": uiState.ActionType })
          this.logException("RESTCallError", uiState.ErrorMessage + ":" + uiState.ActionType + "-UserID " + UserId);
        }
      }
    );
  }

  private createRouterSubscription()
  {
    this.routerSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.logPageView(this.getCurrentPage(this.router.getCurrentNavigation().extras), this.router.url );
      });
  }

  private getCurrentPage( extras:NavigationExtras ): string {
    let page = "Unknown"
    try {
      const eventMeta = extras.state;
      //console.log('eventMeta:', eventMeta);
      if (eventMeta && eventMeta.menuItem ) {
        page = eventMeta.menuItem.pageName;
      }
      if(  eventMeta && eventMeta.application ){
        page =  eventMeta.application.appName +" - "+page;
      }
    } catch (e) {}
    return page;
  }



}
