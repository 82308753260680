
import { BranchCodeColumnDto, BranchCodeListUploadErrorReponseOutputDto, BranchCodeOutputDto, BranchCodeUploadColumnsDTO } from "src/app/models/branchcode-list/branchcode-output-dto";
import { HosiptalBranchCodeByIdDto } from "src/app/models/branchcode-list/hosiptal-branch-code-dto";
import { HosiptalBranchCodeIdOutputDto, HosiptalBranchCodeByIdOutputDto, BranchCodeListUploadOutputDto } from "src/app/models/branchcode-list/hosiptal-branch-output-dto";
import { BranchCodeIdActionTypes } from "./branchcode-list.actions"


//State to hold all corpId List data
export interface branchCodeIdState {
  brachCodeTypeList: BranchCodeOutputDto,
  branchCodeIdList: any,
  branchCodeIdListData: any;
  columns: BranchCodeColumnDto[],
  hospitalNameList: HosiptalBranchCodeIdOutputDto[],
  branchcodeListUpload: BranchCodeListUploadOutputDto,
  branchCodesUploadErrorResponse: BranchCodeListUploadErrorReponseOutputDto,
  branchcodeUploadColumns: BranchCodeUploadColumnsDTO[],
  hospitalBranchCodeList: HosiptalBranchCodeByIdOutputDto[],
  hospitalBranchCodeToHidList: HosiptalBranchCodeIdOutputDto[],
  pharmacyNameList: HosiptalBranchCodeIdOutputDto[],
  pharmacyBranchCodeList: HosiptalBranchCodeByIdOutputDto[],
  pharmacyBranchCodeToPidList: HosiptalBranchCodeIdOutputDto[],
  addUpdateBranchCode: HosiptalBranchCodeByIdDto[],
  exportData: any,
  branchCodePharList: any,
}

//Default initial state
export const initialState =
{
  brachCodeTypeList: null,
  branchCodeIdList: null,
  branchCodeIdListData: null,
  columns: null,
  hospitalNameList: null,
  branchcodeListUpload:null,
  branchCodesUploadErrorResponse:null,
  branchcodeUploadColumns:null,
  hospitalBranchCodeList: null,
  hospitalBranchCodeToHidList: null,
  pharmacyNameList: null,
  pharmacyBranchCodeList: null,
  pharmacyBranchCodeToPidList: null,
  addUpdateBranchCode: null,
  exportData: null,
  branchCodePharList: null,
}

export function branchCodeListReducer(state = initialState, action: any): branchCodeIdState {
  switch (action.type) {

    case BranchCodeIdActionTypes.GET_BRANCHCODEID_LIST_RES: {
      return { ...state, branchCodeIdListData: action.branchCodeIdListData }
    }

    case BranchCodeIdActionTypes.GET_BRANCHCODES_TO_LIST_RES: {
      return { ...state, brachCodeTypeList: action.brachCodeTypeList }
    }

    case BranchCodeIdActionTypes.GET_HOSPITAL_NAME_LIST_RES: {
      return { ...state, hospitalNameList: action.hospitalNameList }
    }

    case BranchCodeIdActionTypes.GET_HOSPITAL_BRANCH_CODE_LIST_RES: {
      return { ...state, hospitalBranchCodeList: action.hospitalBranchCodeList }
    }

    case BranchCodeIdActionTypes.GET_HOSPITAL_BRANCH_CODE_TO_HID_LIST_RES: {
      return { ...state, hospitalBranchCodeToHidList: action.hospitalBranchCodeToHidList }
    }
    case BranchCodeIdActionTypes.GET_PHARMACY_NAME_LIST_RES: {
      return { ...state, pharmacyNameList: action.pharmacyNameList }
    }

    case BranchCodeIdActionTypes.GET_PHARMACY_BRANCH_CODE_LIST_RES: {
      return { ...state, pharmacyBranchCodeList: action.pharmacyBranchCodeList }
    }

    case BranchCodeIdActionTypes.GET_PHARMACY_BRANCH_CODE_TO_PID_LIST_RES: {
      return { ...state, pharmacyBranchCodeToPidList: action.pharmacyBranchCodeToPidList }
    }

    case BranchCodeIdActionTypes.ADD_UPDATE_BRANCH_CODE_RES: {
      return { ...state, addUpdateBranchCode: action.result }
    }

    case BranchCodeIdActionTypes.EXPORT_DATA_RES: {
      return { ...state, exportData: action.result }
    }

    case BranchCodeIdActionTypes.GET_BRANCHCODE_LIST_UPLOAD_RES:{
      return {...state, branchcodeListUpload: action.branchcodeListUpload}
    }

    case BranchCodeIdActionTypes.GET_BRANCH_CODES_UPLOAD_ERROR_RESPONSE_RES:{
      return {...state, branchCodesUploadErrorResponse: action.branchCodesUploadErrorResponse}
    }

    case BranchCodeIdActionTypes.GET_BRANCHCODE_PHAR_LIST_RES:{
      return {...state, branchCodePharList: action.branchCodePharList}
    }

    default: {
      return state;
    }
  }
}