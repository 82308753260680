import {Injectable} from '@angular/core';
import {BaseMhService} from './basemh.service';
import {Observable, throwError} from 'rxjs';
import {SaveVersionTracker, VersionTracker} from '../models/version-tracker';
import {catchError, map} from 'rxjs/operators';
import {SalesForceCategory} from '../models/sales-force-category';
import {SalesForceTicket} from '../models/sales-force-ticket';
import {UserSession} from '../models/user';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {UserState} from '../store/user.reducer';
import {SubmitResponse} from '../models/submit-response';
import {Application, ApplicationMenuItem} from '../models/applications';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})


export class ShellService extends BaseMhService {

  constructor(protected http: HttpClient, protected store: Store<{userState:UserState}>) {
    super( http, store);
  }


  public getLastestApplicationVersion(): Observable<VersionTracker> {
    return this.http
      .get(environment.apiBaseUrl+'api/shell/v1/version-tracker', {headers: this.getHeaders()})
      .pipe(
        map((response: VersionTracker) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Application Version" }))
      );
  }

  public getSalesForceCategory(): Observable<SalesForceCategory[]> {
    return this.http
      .get(environment.apiBaseUrl+'api/shell/v1/salesforce/categories', {headers: this.getHeaders()})
      .pipe(
        map((response: SalesForceCategory[]) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Sales Force Category" }))
      );
  }

  public submitSalesForceTicket(newSalesForceTicket: SalesForceTicket) {
    const session: UserSession = this.getStoreUserSession();
    newSalesForceTicket.uid = session.uid;
    newSalesForceTicket.pid = session.pid;
    return this.http
      .post(environment.apiBaseUrl+'api/shell/v1/salesforce/ticket', newSalesForceTicket, { headers: this.getHeaders()})
      .pipe(
        map((response: SubmitResponse) => {
          if( response.status === '200' )
            return newSalesForceTicket;
          else
            throwError(response.message);
        }),
        catchError(error => this.errorHandler({ error, operation: "Error while Submitting Sales Force Ticket" }))
      );
  }

  public updateNewVersionTracker(newVersionTracker: SaveVersionTracker) {
    newVersionTracker.addedBy = +this.getStoreUiUser().userId;
    return this.http
      .post(environment.apiBaseUrl+'api/shell/v1/version-tracker', newVersionTracker, { headers: this.getHeaders()})
      .pipe(
        map((response: SubmitResponse) => {
          if( response.status === '200' )
            return newVersionTracker;
          else
            throwError(response.message);
        }),
        catchError(error => this.errorHandler({ error, operation: "Error while Updating new Version Tracker" }))
      );
  }

  public getBackupPage(appId: number, pageId: number) {
    return this.http
    .get(environment.apiBaseUrl +'api/shell/v1/application-page/get-single-page/appId/' + appId
    + '/pageId/' + pageId, {headers: this.getHeaders()})
    .pipe(
      map((response: ApplicationMenuItem) => response),
      catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Application Page" }))
    );
  }

  public getApplicationPageList(appId: number){
    return this.http
    .get(environment.apiBaseUrl + 'api/shell/v1/application-page/get-page-list/appId/' + appId, {headers: this.getHeaders()})
    .pipe(
      map((response: ApplicationMenuItem[]) => response),
      catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Application Page List" }))
    )
  }

  public getApplications(){
    return this.http
    .get(environment.apiBaseUrl + 'api/shell/v1/application/applications', {headers: this.getHeaders()})
    .pipe(
      map((response: Application[]) => {return this.applyAppRules(response)} ),
      catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Application List" }))
    )
  }

  private applyAppRules(apps: Application[]): Application[] {
    const userApplications = this.getStoreUserApplications().applicationList;
    
    // make sure app is active and exists in user's available applications
    return apps.filter((app) => app.active == 1 && userApplications.includes(app.appID));
  }

}
