   <!-- Help => About Dialog -->
<div class="about-dialog">
  <div mat-dialog-title class="dialog-header">
    <h3>About</h3>
  </div>
  <div>
    <div id="logo" class="logo">
      <div class="application-version-display" *ngIf="!this.edit && (versionStore$ | async)" >
        <img id="mh-logo" src="../../../assets/img/MH_Logo-Color.png" alt="Macro Helix" /> <br/>
        <mat-label class="application-name">{{ (applications$ | async).Selected != null ? (applications$ | async).Selected.appName : '' }}</mat-label> <br/>
        <mat-label class="view-application-version">Version {{ (versionStore$ | async).version ? (versionStore$ | async).version.applicationVersion : '' }} </mat-label>
        <mat-label class="view-application-version">v{{version}}</mat-label>
      </div>
    </div>

    <form #updateVersionTrackerForm="ngForm" (change)="formChanged()">
      <div class="dialog-body">
        <ng-container *ngIf="this.edit && (versionStore$ | async)" >
          <mat-form-field appearance="" class="application-version">
            <mat-label>Application Version</mat-label>
            <input type="text"
                   matInput
                   placeholder="{{ (versionStore$ | async).version.applicationVersion }}"
                   name="ApplicationVersion"
                   #applicationVersion="ngModel"
                   [(ngModel)]="saveVersionTracker.applicationVersion"
                   required>
            <mat-error *ngIf="applicationVersion.hasError('required')">Application version is required</mat-error>
          </mat-form-field>

          <mat-form-field appearance="" class="notes">
            <mat-label>Notes</mat-label>
            <textarea
            type="text"
                    matInput
                    placeholder="Enter your notes"
                    class="form-control"
                    name="notes"
                    #notes="ngModel"
                    [(ngModel)]="saveVersionTracker.notes"
                    required
                    matTextareaAutosize
                    matAutosizeMinRows=5></textarea>
            <mat-error *ngIf="notes.hasError('required')">Notes is required</mat-error>
          </mat-form-field>
        </ng-container>
      </div>

      <div class="dialog-footer">
        <ng-container *ngIf="!this.edit && this.showEdit" >
          <button mat-flat-button color="primary" class="edit-btn" (click)="enableEditMode()">Edit</button>
          <button mat-stroked-button class="close-btn" (click)="onCloseClick()">Close</button>
        </ng-container>
        <ng-container *ngIf="this.edit">
          <button mat-flat-button type="submit" color="primary" class="save-btn" (click)="onSaveClick()" [disabled]="updateVersionTrackerForm.form.invalid">Save</button>
          <button mat-stroked-button type="button" class="cancel-btn" (click)="disableEditMode()">Cancel</button>
        </ng-container>
        <ng-container *ngIf="!this.showEdit && !this.edit">
          <button mat-stroked-button class="close-btn-only" (click)="onCloseClick()">Close</button>
        </ng-container>
      </div>
    </form>
  </div>
</div>
