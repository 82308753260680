import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, map } from 'rxjs/operators';
import { PharmacyOutputDTO } from 'src/app/models/hidpharmacy/pharmacy-list/pharmacy-output-dto';
import { UserSession, UserSettings } from 'src/app/models/user';
import { UserState } from 'src/app/store/user.reducer';
import { environment } from 'src/environments/environment';
import { BaseMhService } from '../basemh.service';

@Injectable({
  providedIn: 'root'
})
export class PharmacyListService extends BaseMhService{

  constructor(protected http: HttpClient, protected store: Store<{ userState: UserState }>) { 
    super(http, store);
  }

  public getPharmacyList(HID: number){
    const session: UserSession = this.getStoreUserSession();
    return this.http
    .get(environment.apiBaseUrl + 'v1/Pharmacy/GetPharmacyListByHID?HID=' + HID, { headers: this.getHeaders() })
    .pipe(
      map((response: PharmacyOutputDTO) => response),
      catchError(error => this.errorHandler({ error, operation: "Error while retrieving Pharmacy data" }))
    );
  }
}
