import { Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import { switchMap } from "rxjs/operators"
import { AddUpdateBranchCode, AddUpdateBranchCodeError, AddUpdateBranchCodeResults, ExportData, ExportDataError, ExportDataResults, GetBranchCodesFromList, GetBranchCodesFromListError, GetBranchCodesFromListResults, GetBranchCodesToList, GetBranchCodesToListError, GetBranchCodesToListResults, GetHospitalBranchCodeList, GetHospitalBranchCodeListError, GetHospitalBranchCodeListResults, GetHospitalBranchCodeSearchList, GetHospitalBranchCodeSearchListError, GetHospitalBranchCodeSearchListResults, GetHospitalBranchCodeToHidList, GetHospitalBranchCodeToHidListError, GetHospitalBranchCodeToHidListResults, GetHospitalNameList, GetHospitalNameListError, GetHospitalNameListResults, GetPharmacyBranchCodeList, GetPharmacyBranchCodeListError, GetPharmacyBranchCodeListResults, GetPharmacyBranchCodeSearchList, GetPharmacyBranchCodeSearchListError, GetPharmacyBranchCodeSearchListResults, GetPharmacyBranchCodeToPidList, GetPharmacyBranchCodeToPidListError, GetPharmacyBranchCodeToPidListResults, GetPharmacyNameList, GetPharmacyNameListError, GetPharmacyNameListResults
,UploadBranchCodeList,UploadBranchCodeListResults,UploadBranchCodeListError, GetBranchCodeListUpload, GetBranchCodeListUploadError, GetBranchCodeListUploadResults,GetBranchCodesUploadErrorResponse,GetBranchCodesUploadErrorResponseResults, GetBranchCodePharmacyList, GetBranchCodePharmacyListError, GetBranchCodePharmacyListResults } from "./branchcode-list.actions"
import { catchError, map } from "rxjs/operators";
import { of as observableOf } from 'rxjs';
import { BranchCodeService } from "src/app/service/branch-code.service";

@Injectable()
export class branchCodeStoreEffects {

  constructor(private branchCodeService: BranchCodeService, private actions$: Actions) {
  }

  GetBranchCodeListEffects$ = createEffect(() => this.actions$.pipe(
    ofType(GetBranchCodesFromList),
    switchMap(action =>
      this.branchCodeService.getBranchCodeList()
        .pipe(
          map(
            data => {
              return GetBranchCodesFromListResults({ branchCodeIdListData: data })
            }
          ),
          catchError(
            error => observableOf(GetBranchCodesFromListError({ message: error }))
          )
        )
    )
  ));

  GetBranchCodeToListEffects$ = createEffect(() => this.actions$.pipe(
    ofType(GetBranchCodesToList),
    switchMap(action =>
      this.branchCodeService.getBranchCodeToList(action.id, action.branchCodeType)
        .pipe(
          map(
            data => {
              return GetBranchCodesToListResults({ brachCodeTypeList: data })
            }
          ),
          catchError(
            error => observableOf(GetBranchCodesToListError({ message: error }))
          )
        )

    )

  ));

  GetHospitalNameListEffects$ = createEffect(() => this.actions$.pipe(
    ofType(GetHospitalNameList),
    switchMap(action =>
      this.branchCodeService.getHospitalOrPharmacyNameList(action.Id, action.dataType)
        .pipe(
          map(
            data => {
              return GetHospitalNameListResults({ hospitalNameList: data })
            }
          ),
          catchError(
            error => observableOf(GetHospitalNameListError({ message: error }))
          )
        )
    )


  ))

  GePharmacyNameListEffects$ = createEffect(() => this.actions$.pipe(
    ofType(GetPharmacyNameList),
    switchMap(action =>
      this.branchCodeService.getHospitalOrPharmacyNameList(action.Id, action.dataType)
        .pipe(
          map(
            data => {
              return GetPharmacyNameListResults({ pharmacyNameList: data })
            }
          ),
          catchError(
            error => observableOf(GetPharmacyNameListError({ message: error }))
          )
        )
    )

  ));

  GetHospitalBranchCodeListEffects$ = createEffect(() => this.actions$.pipe(
    ofType(GetHospitalBranchCodeList),
    switchMap(action =>
      this.branchCodeService.getHospitalOrPharmacyBranchCodeList(action.Id, action.dataType, action.skip, action.take)
        .pipe(
          map(
            data => {
              return GetHospitalBranchCodeListResults({ hospitalBranchCodeList: data })
            }
          ),
          catchError(
            error => observableOf(GetHospitalBranchCodeListError({ message: error }))
          )
        )
    )


  ))

  GetHospitalBranchCodeSearchListEffects$ = createEffect(() => this.actions$.pipe(
    ofType(GetHospitalBranchCodeSearchList),
    switchMap(action =>
      this.branchCodeService.getHospitalOrPharmacyBranchCodeSearchList(action.Id, action.dataType, action.skip, action.take, action.searchText)
        .pipe(
          map(
            data => {
              return GetHospitalBranchCodeListResults({ hospitalBranchCodeList: data })
            }
          ),
          catchError(
            error => observableOf(GetHospitalBranchCodeListError({ message: error }))
          )
        )
    )


  ))

  GetHospitalBranchCodeToHidListEffects$ = createEffect(() => this.actions$.pipe(
    ofType(GetHospitalBranchCodeToHidList),
    switchMap(action =>
      this.branchCodeService.getHospitalOrPharmacyNameList(action.Id, action.dataType)
        .pipe(
          map(
            data => {
              return GetHospitalBranchCodeToHidListResults({ hospitalBranchCodeToHidList: data })
            }
          ),
          catchError(
            error => observableOf(GetHospitalBranchCodeToHidListError({ message: error }))
          )
        )
    )
  ))

  GetPharmacyBranchCodeListEffects$ = createEffect(() => this.actions$.pipe(
    ofType(GetPharmacyBranchCodeList),
    switchMap(action =>
      this.branchCodeService.getHospitalOrPharmacyBranchCodeList(action.Id, action.dataType, action.skip, action.take)
        .pipe(
          map(
            data => {
              return GetPharmacyBranchCodeListResults({ pharmacyBranchCodeList: data })
            }
          ),
          catchError(
            error => observableOf(GetPharmacyBranchCodeListError({ message: error }))
          )
        )
    )


  ))

  GetPharmacyBranchCodeSearchListEffects$ = createEffect(() => this.actions$.pipe(
    ofType(GetPharmacyBranchCodeSearchList),
    switchMap(action =>
      this.branchCodeService.getHospitalOrPharmacyBranchCodeSearchList(action.Id, action.dataType, action.skip, action.take, action.searchText)
        .pipe(
          map(
            data => {
              return GetPharmacyBranchCodeListResults({ pharmacyBranchCodeList: data })
            }
          ),
          catchError(
            error => observableOf(GetPharmacyBranchCodeListError({ message: error }))
          )
        )
    )


  ))

  GetPharmacyBranchCodeToPIdListEffects$ = createEffect(() => this.actions$.pipe(
    ofType(GetPharmacyBranchCodeToPidList),
    switchMap(action =>
      this.branchCodeService.getHospitalOrPharmacyNameList(action.Id, action.dataType)
        .pipe(
          map(
            data => {
              return GetPharmacyBranchCodeToPidListResults({ pharmacyBranchCodeToPidList: data })
            }
          ),
          catchError(
            error => observableOf(GetPharmacyBranchCodeToPidListError({ message: error }))
          )
        )
    )


  ))

  // GetBranchCodeByIdEffects$ = createEffect(() => this.actions$.pipe(
  //   ofType(GetBranchCodeById),
  //   switchMap(action =>
  //     this.branchCodeService.getHospitalOrPharmacyBranchCodeList(action.Id, action.dataType)
  //       .pipe(
  //         map(
  //           data => {
  //             return GetBranchCodeByIdResults({ branchCodeByIds: data })
  //           }
  //         ),
  //         catchError(
  //           error => observableOf(GetBranchCodeByIdError({ message: error }))
  //         )
  //       )
  //   )


  // ))

  AddUpdateBranchCodeEffect$ = createEffect(() => this.actions$.pipe(
    ofType(AddUpdateBranchCode),
    switchMap(action =>
      this.branchCodeService.addUpdateBranchCode(action.addUpdateBranchCode)
        .pipe(
          map(
            data => {
              return AddUpdateBranchCodeResults({ result: data })
            }
          ),
          catchError(
            error => observableOf(AddUpdateBranchCodeError({ message: error }))
          )
        )
    )
  ))

  ExportDataEffects$ = createEffect(() => this.actions$.pipe(
    ofType(ExportData),
    switchMap(action =>
      this.branchCodeService.exportData(action.Id, action.dataType)
        .pipe(
          map(
            data => {
              return ExportDataResults({ result: data })
            }
          ),
          catchError(
            error => observableOf(ExportDataError({ message: error }))
          )
        )
    )


  ))

 GetBranchCodeListUploadEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetBranchCodeListUpload),
    switchMap(action =>
      this.branchCodeService.getBranchCodeListUploadDetails()
        .pipe(
          map(
            data => {
              return GetBranchCodeListUploadResults({branchcodeListUpload:data })
            }
          ),
          catchError(
            error => observableOf(GetBranchCodeListUploadError({ message: error }))
          )
        )
    )
  ))

  UploadBranchCodeListEffect$ = createEffect(() => this.actions$.pipe(
    ofType(UploadBranchCodeList),
    switchMap(action =>
      this.branchCodeService.uploadBranchCodeList(action.branchcodeFile)
      .pipe(
        map(
          data => {
            return UploadBranchCodeListResults({response: data})
          }
        ),
        catchError(
          error => observableOf(UploadBranchCodeListError({message: error}))
        )
      )

    )
  ))

  GetBranchCodesUploadErrorResponseEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetBranchCodesUploadErrorResponse),
    switchMap(action =>
      this.branchCodeService.getBranchCodeListUploadErrorResponse(action.importId)
        .pipe(
          map(
            data => {
              return GetBranchCodesUploadErrorResponseResults({branchCodesUploadErrorResponse:data })
            }
          ),
          catchError(
            error => observableOf(GetBranchCodeListUploadError({ message: error }))
          )
        )
    )
  ))

  GetBranchCodesPharmacyListEffects$ = createEffect(() => this.actions$.pipe(
    ofType(GetBranchCodePharmacyList),
    switchMap(action =>
      this.branchCodeService.getBranchCodesPharmacyListResponse(action.importFileType, action.pIDValue)
        .pipe(
          map(
            data => {
              return GetBranchCodePharmacyListResults({ branchCodePharList: data })
            }
          ),
          catchError(
            error => observableOf(GetBranchCodePharmacyListError({ message: error }))
          )
        )
    )
  ))
}
