import { ApplyQualRuleResponse, QualificationRulePharmacyResponse, QualificationRulesResponse } from "src/app/models/qualification-rules/qualification-rules-reponse";
import { QualRulesActionTypes } from "./qual-rules.actions";

export interface QualRulesState {
    qualRulesResult: QualificationRulesResponse;
    qualRulePharmacy: QualificationRulePharmacyResponse;
    applyQualRuleResult: ApplyQualRuleResponse

}

export const initialState =
{
    qualRulesResult: null,
    qualRulePharmacy: null,
    applyQualRuleResult: null
}

export function qualRulesListReducer(state = initialState, action: any): QualRulesState {
    switch (action.type) {
        case QualRulesActionTypes.GET_QUAL_RULES_LIST_RES: {
            return { ...state, qualRulesResult: action.qualRules }
        }

        case QualRulesActionTypes.PHARMACY_GET_RES: {
            return { ...state, qualRulePharmacy: action.qualRulePharmacy };
        }

        case QualRulesActionTypes.SET_APPLY_RULE_RES: {
            return { ...state, qualRulePharmacy: action.applyQualRuleResult };
        }

        default: {
            return state;
        }
    }
}