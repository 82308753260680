import { createAction, props } from '@ngrx/store';
import { ErrorResponse } from '../models/prescriber-list/error-response';
import { Prescriber } from '../models/prescriber-list/prescriber';
import { PrescriberColumnDTO, PrescriberUploadColumnsDTO } from '../models/prescriber-list/prescriber-column-dto';
import { PrescriberListUploadErrorReponseOutputDto } from '../models/prescriber-list/prescriber-list-upload-error-response-output';
import { PrescriberListUploadOutputDto } from '../models/prescriber-list/prescriber-list-upload-output';
import { PrescriberOutputDto } from '../models/prescriber-list/prescriber-output-dto';
import { SubmitResponse } from '../models/submit-response';
import { PrescriberHistory } from '../models/prescriber-list/prescriber-history';
import { AllOtherPrescriberOutputDto } from '../models/prescriber-list/all-other-prescriber-output';

export enum PrescriberActionTypes {
  GET_PRESCRIBER_LIST = '[Rest Call] [PresList] GetPrescriberList',
  GET_PRESCRIBER_LIST_RES = '[Rest Result] [PresList] GetPrescriberList',
  GET_PRESCRIBER_LIST_ERR = '[Rest Error] [PresList] GetPrescriberList',

  SET_PRESCRIBER_LIST_COLUMNS = '[Rest Call] [PresList] SetPrescriberListColumns',
  SET_PRESCRIBER_LIST_COLUMNS_RES = '[Rest Result] [PresList] SetPrescriberListColumnsResults',
  SET_PRESCRIBER_LIST_COLUMNS_ERR = '[Rest Error] [PresList] SetPrescriberListColumnsError',

  ADD_PRESCRIBER = '[Rest Call] [PresList] AddPrescriber',
  ADD_PRESCRIBER_RES = '[Rest Result] [PresList] AddPrescriberResults',
  ADD_PRESCRIBER_ERR = '[Rest Error] [PresList] AddPrescriberError',

  VALIDATE_PRESCRIBER = '[Rest Call] [PresList] ValidatePrescriber',
  VALIDATE_PRESCRIBER_RES = '[Rest Result] [PresList] ValidatePrescriberResults',
  VALIDATE_PRESCRIBER_ERR = '[Rest Error] [PresList] ValidatePrescriberError',


  VIEW_PRESCRIBER = '[Rest Call] [PresList] ViewPrescriber',
  VIEW_PRESCRIBER_RES = '[Rest Result] [PresList] ViewPrescriberResults',
  VIEW_PRESCRIBER_ERR = '[Rest Error] [PresList] ViewPrescriberError',

  UPDATE_PRESCRIBER = '[Rest Call] [PresList] UpdatePrescriber',
  UPDATE_PRESCRIBER_RES = '[Rest Result] [PresList] UpdatePrescriberResults',
  UPDATE_PRESCRIBER_ERR = '[Rest Error] [PresList] UpdatePrescriberError',

  GET_PRESCRIBER_LIST_UPLOAD = '[Rest Call] [PresList] GetPrescriberListUpload',
  GET_PRESCRIBER_LIST_UPLOAD_RES = '[Rest Result] [PresList] GetPrescriberListUploadResults',
  GET_PRESCRIBER_LIST_UPLOAD_ERR = '[Rest Error] [PresList] GetPrescriberListUploadError',

  UPLOAD_PRESCRIBER_LIST = '[Rest Call] [PresList] UploadPrescriberList',
  UPLOAD_PRESCRIBER_LIST_RES = '[Rest Result] [PresList] UploadPrescriberListResults',
  UPLOAD_PRESCRIBER_LIST_ERR = '[Rest Error] [PresList] UploadPrescriberListError',

  GET_PRESCRIBER_LIST_UPLOAD_ERROR_RESPONSE = '[Rest Call] [PresList] GetPrescriberListUploadErrorResponse',
  GET_PRESCRIBER_LIST_UPLOAD_ERROR_RESPONSE_RES = '[Rest Result] [PresList] GetPrescriberListUploadErrorResponseResults',
  GET_PRESCRIBER_LIST_UPLOAD_ERROR_RESPONSE_ERR = '[Rest Error] [PresList] GetPrescriberListUploadErrorResponseError',

  SET_PRESCRIBER_LIST_UPLOAD_COLUMNS = '[Rest Call] [PresList] SetPrescriberListUploadColumns',
  SET_PRESCRIBER_LIST_UPLOAD_COLUMNS_RES = '[Rest Result] [PresList] SetPrescriberListUploadColumnsResults',
  SET_PRESCRIBER_LIST_UPLOAD_COLUMNS_ERR = '[Rest Error] [PresList] SetPrescriberListUploadColumnsError',

  VIEW_PRESCRIBER_HISTORY = '[Rest Call] [PresList] ViewPrescriberHistory',
  VIEW_PRESCRIBER_HISTORY_RES = '[Rest Result] [PresList] ViewPrescriberHistoryResults',
  VIEW_PRESCRIBER_HISTORY_ERR = '[Rest Error] [PresList] ViewPrescriberHistoryError',

  GET_ALL_PRESCRIBER_DETAILS = '[Rest Call] [PresList] GetAllPrescriberDetails',
  GET_ALL_PRESCRIBER_DETAILS_RES = '[Rest Result] [PresList] GetAllPrescriberDetailsResults',
  GET_ALL_PRESCRIBER_DETAILS_ERR = '[Rest Error] [PresList] GetAllPrescriberDetailsError',

  DEACTIVATE_PRESCRIBER = '[Rest Call] [PresList] DeactivatePrescriber',
  DEACTIVATE_PRESCRIBER_RES = '[Rest Result] [PresList] DeactivatePrescriberResults',
  DEACTIVATE_PRESCRIBER_ERR = '[Rest Error] [PresList] DeactivatePrescriberError',
}

export const GetPrescriberList = createAction(PrescriberActionTypes.GET_PRESCRIBER_LIST, props<{}>());
export const GetPrescriberListResults = createAction(PrescriberActionTypes.GET_PRESCRIBER_LIST_RES, props<{ prescriberList: PrescriberOutputDto }>());
export const GetPrescriberListError = createAction(PrescriberActionTypes.GET_PRESCRIBER_LIST_ERR, props<{ message: string }>());

export const SetPrescriberColumns = createAction(PrescriberActionTypes.SET_PRESCRIBER_LIST_COLUMNS, props<{ columns: PrescriberColumnDTO[] }>())
export const SetPrescriberColumnsResults = createAction(PrescriberActionTypes.SET_PRESCRIBER_LIST_COLUMNS_RES, props<{ columns: PrescriberColumnDTO[] }>())
export const SetPrescriberColumnsError = createAction(PrescriberActionTypes.SET_PRESCRIBER_LIST_COLUMNS_ERR, props<{ message: string }>())

export const AddPrescriber = createAction(PrescriberActionTypes.ADD_PRESCRIBER, props<{ newPrescriber: Prescriber }>())
export const AddPrescriberResults = createAction(PrescriberActionTypes.ADD_PRESCRIBER_RES, props<{ newPrescriber: Prescriber }>())
export const AddPrescriberError = createAction(PrescriberActionTypes.ADD_PRESCRIBER_ERR, props<{ message: string }>())

export const ValidatePrescriber = createAction(PrescriberActionTypes.VALIDATE_PRESCRIBER, props<{ newPrescriber: Prescriber }>())
export const ValidatePrescriberResults = createAction(PrescriberActionTypes.VALIDATE_PRESCRIBER_RES, props<{ response: ErrorResponse }>())
export const ValidatePrescriberError = createAction(PrescriberActionTypes.VALIDATE_PRESCRIBER_ERR, props<{ message: string }>())

export const ViewPrescriber = createAction(PrescriberActionTypes.VIEW_PRESCRIBER, props<{ docid: number }>())
export const ViewPrescriberResults = createAction(PrescriberActionTypes.VIEW_PRESCRIBER_RES, props<{ prescriberDetails: Prescriber }>())
export const ViewPrescriberError = createAction(PrescriberActionTypes.VIEW_PRESCRIBER_ERR, props<{ message: string }>())

export const UpdatePrescriber = createAction(PrescriberActionTypes.UPDATE_PRESCRIBER, props<{ newPrescriber: Prescriber }>())
export const UpdatePrescriberResults = createAction(PrescriberActionTypes.UPDATE_PRESCRIBER_RES, props<{ newPrescriber: Prescriber }>())
export const UpdatePrescriberError = createAction(PrescriberActionTypes.UPDATE_PRESCRIBER_ERR, props<{ message: string }>())

export const GetPrescriberListUpload = createAction(PrescriberActionTypes.GET_PRESCRIBER_LIST_UPLOAD, props<{}>());
export const GetPrescriberListUploadResults = createAction(PrescriberActionTypes.GET_PRESCRIBER_LIST_UPLOAD_RES, props<{ prescriberListUpload: PrescriberListUploadOutputDto }>())
export const GetPrescriberListUploadError = createAction(PrescriberActionTypes.GET_PRESCRIBER_LIST_UPLOAD_ERR, props<{ message: string }>())

export const UploadPrescriberList = createAction(PrescriberActionTypes.UPLOAD_PRESCRIBER_LIST, props<{ prescriberFile: FormData }>());
export const UploadPrescriberListResults = createAction(PrescriberActionTypes.UPLOAD_PRESCRIBER_LIST_RES, props<{ response: SubmitResponse }>());
export const UploadPrescriberListError = createAction(PrescriberActionTypes.UPLOAD_PRESCRIBER_LIST_ERR, props<{ message: string }>());

export const GetPrescriberListUploadErrorResponse = createAction(PrescriberActionTypes.GET_PRESCRIBER_LIST_UPLOAD_ERROR_RESPONSE, props<{ importId: string }>());
export const GetPrescriberListUploadErrorResponseResults = createAction(PrescriberActionTypes.GET_PRESCRIBER_LIST_UPLOAD_ERROR_RESPONSE_RES, props<{ prescriberListUploadErrorResponse: PrescriberListUploadErrorReponseOutputDto }>())
export const GetPrescriberListUploadErrorResponseError = createAction(PrescriberActionTypes.GET_PRESCRIBER_LIST_UPLOAD_ERROR_RESPONSE_ERR, props<{ message: string }>())

export const SetPrescriberUploadColumns = createAction(PrescriberActionTypes.SET_PRESCRIBER_LIST_UPLOAD_COLUMNS, props<{ prescriberUploadColumns: PrescriberUploadColumnsDTO[] }>())
export const SetPrescriberUploadColumnsResults = createAction(PrescriberActionTypes.SET_PRESCRIBER_LIST_UPLOAD_COLUMNS_RES, props<{ prescriberUploadColumns: PrescriberUploadColumnsDTO[] }>())
export const SetPrescriberUploadColumnsError = createAction(PrescriberActionTypes.SET_PRESCRIBER_LIST_UPLOAD_COLUMNS_ERR, props<{ message: string }>())

export const ViewPrescriberHistory = createAction(PrescriberActionTypes.VIEW_PRESCRIBER_HISTORY, props<{ docid: number }>())
export const ViewPrescriberHistoryResults = createAction(PrescriberActionTypes.VIEW_PRESCRIBER_HISTORY_RES, props<{ prescriberHistory: PrescriberHistory }>())
export const ViewPrescriberHistoryError = createAction(PrescriberActionTypes.VIEW_PRESCRIBER_HISTORY_ERR, props<{ message: string }>())

export const GetAllPrescriberDetails = createAction(PrescriberActionTypes.GET_ALL_PRESCRIBER_DETAILS, props<{}>());
export const GetAllPrescriberDetailsResults = createAction(PrescriberActionTypes.GET_ALL_PRESCRIBER_DETAILS_RES, props<{ allPrescriberListUpload: AllOtherPrescriberOutputDto }>())
export const GetAllPrescriberDetailsError = createAction(PrescriberActionTypes.GET_ALL_PRESCRIBER_DETAILS_ERR, props<{ message: string }>())

export const DeactivatePrescriber = createAction(PrescriberActionTypes.DEACTIVATE_PRESCRIBER, props<{ npi: string }>())
export const DeactivatePrescriberResults = createAction(PrescriberActionTypes.DEACTIVATE_PRESCRIBER_RES, props<{ response: SubmitResponse }>())
export const DeactivatePrescriberError = createAction(PrescriberActionTypes.DEACTIVATE_PRESCRIBER_ERR, props<{ message: string }>())