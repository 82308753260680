<div class="vendor-label">
    <mat-toolbar>
      <mat-toolbar-row class="tab-label">
        <span>Vendor Logins</span>
      </mat-toolbar-row>
      <mat-toolbar-row class='actionBar' style="font-size: 14pt;">
        <div style="border-right: 1px solid white;margin-top: 5px; margin-bottom: 5px;
        line-height: 22px; padding-right: 8px; ">
          <button class="add-new-login-btn" mat-button
          (click)="AddNewLoginNammeClicked()">
          Add New Login Name
          </button>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
</div>
<div class="info-display">
  <div class="example-container mat-elevation-z8">
    <table mat-table [dataSource]="userAlternateLoginNameDataSource" class="mat-elevation-z8" matSort matSortActive="" matSortDirection="asc">

      <ng-container matColumnDef="vendorName">
        <th mat-header-cell *matHeaderCellDef class="text-align-right"> Vendor Name </th>
        <td mat-cell *matCellDef="let userAlternateLogin" class="text-align-right"> {{userAlternateLogin.vendorName}} </td>
      </ng-container>

      <ng-container matColumnDef="loginName">
        <th mat-header-cell *matHeaderCellDef class="text-align-right"> Login Name </th>
        <td mat-cell *matCellDef="let userAlternateLogin" class="text-align-right"> {{userAlternateLogin.loginName}} </td>
      </ng-container>

      <ng-container matColumnDef="dateAdded">
        <th mat-header-cell *matHeaderCellDef class="text-align-right"> Date Added </th>
        <td mat-cell *matCellDef="let userAlternateLogin" class="text-align-right"> {{userAlternateLogin.dateAdded | date:"MM/dd/yyyy h:mma 'EST'" }} </td>
      </ng-container>

      <ng-container matColumnDef="space">
        <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
        <td mat-cell *matCellDef="let element"></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="tableHeaders; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: tableHeaders"></tr>
    </table>
    <div class="no-records" *ngIf="userAlternateLoginNameDataSource?.filteredData?.length===0">No Match Found</div>
  </div>
  <mat-paginator class="table-pagination" [pageSizeOptions]="[10, 20, 75, 100, 150]" showFirstLastButtons></mat-paginator>
</div>
