import { Location } from '@angular/common';
import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { SubmitResponse } from 'src/app/models/submit-response';
import { DisclaimerItem } from 'src/app/models/user';
import { AcknowledgeDisclaimer } from 'src/app/store/user.actions';
import { UserState } from 'src/app/store/user.reducer';

@Component({
    selector: 'disclaimer',
    templateUrl: './disclaimer.component.html',
    styleUrls: ['./disclaimer.component.scss']
})

export class DisclaimerComponent {

    disclaimer: DisclaimerItem;

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        private location: Location,
        @Optional() private dialogRef: MatDialogRef<DisclaimerComponent>,
        private store: Store<{ userState: UserState }>) {
        this.store.select(state => state.userState.acknowledgeDisclaimerResponse).subscribe((result: SubmitResponse) => {
            if (result && result.status == "200") {
                this.dialogRef?.close();
            }
        });
    }

    onAcknowledge() {
        this.store.dispatch(AcknowledgeDisclaimer({ disclaimerId: this.data.disclaimerID }));
    }

    onGoBack() {
        this.location.back();
    }
}