import { UpdateUserClass, UpdateUserClassResults, UpdateUserClassErrors, UpdateUserMHISettings,
  UpdateUserMHISettingsResults, UpdateUserMHISettingsError, GetUserSettings, 
  UpdateUserApplicationAndAuthorize} from './../../../../../store/user.actions';
import { SaveUserClass, SaveUserMHISettings } from './../../../../../models/user';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/store/user.reducer';
import { UserSettings } from 'src/app/models/user';
import { Subscription } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { InfoShow, ErrShow } from 'src/app/store/ui-state.actions';
import { B2cLoginService } from 'src/app/service/b2clogin.service';
import { Pages } from 'src/app/app-pages';
import { ApplicationIds } from 'src/app/models/applications';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'view-profile',
  templateUrl: './view-profile.component.html',
  styleUrls: ['./view-profile.component.scss']
})

export class ViewProfileComponent implements OnInit, OnDestroy {
  userSettingsInfo: UserSettings = new UserSettings();
  saveUserSettingsInfo: SaveUserClass = new SaveUserClass();
  saveUserMHISettingsInfo: SaveUserMHISettings = new SaveUserMHISettings();
  public phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  phoneNumberPattern = '[0-9]{3}-[0-9]{3}-[0-9]{4}';
  emailPattern = '[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,4}';
  hideMfaPreference = true;
  showSaveButtonMacroHelixTab = false;
  showResetPassword = false;
  packageSettingOptions = [{value: 0, viewValue: '0'},
                           {value: 1, viewValue: '1'},
                           {value: 2, viewValue: 'max'},];
  mfaPreferenceOption = [{value: 'cellphone', viewValue: 'Cell Phone'},
                         {value: 'email', viewValue: 'Email'}]
  isMHUserOption = [{value: true, viewValue: 'Yes'},
                    {value: false, viewValue: 'No'}]
  mhiTierOption = [{value: 1, viewValue: 'Executive'},
                   {value: 2, viewValue: 'Department Head'},
                   {value: 3, viewValue: 'Employee'},];
  isEmployee : boolean;
  subs: Subscription = new Subscription;

  constructor(public store: Store<{ userState: UserState}>, private actions$: Actions, private b2cService: B2cLoginService) {
  }

  ngOnInit(): void {
    this.subs.add(
      this.store.select(state => state.userState.currentApp).subscribe((data) => {
        if(data == null) {
          this.store.dispatch(UpdateUserApplicationAndAuthorize({ appId: ApplicationIds.Architect340B, pid: null }));
        }
      })
    )
    this.subs.add(
      this.store.select(state => state.userState.settings).subscribe((data)=>{
        if(data != null) {
          this.userSettingsInfo = data;
          this.userSettingsInfo.phoneNumber = this.formatNumber(data.phoneNumber);
          this.userSettingsInfo.cellPhoneNumber = this.formatNumber(data.cellPhoneNumber);
          this.userSettingsInfo.faxNumber = this.formatNumber(data.faxNumber);
          //this.isEmployee = this.userSettingsInfo.isMHIUser;
          if ((this.userSettingsInfo.isMHIUser) || (!this.userSettingsInfo.isMHIUser && (this.userSettingsInfo.email.toLowerCase().endsWith("macrohelix.com") || this.userSettingsInfo.email.toLowerCase().endsWith("mckesson.com")))) {
            this.isEmployee = true;
          }
        }
        else {
          this.store.dispatch(GetUserSettings());
        }
      })
    )

    this.subs.add(
      this.store.select(store => store.userState.userClassInfo).subscribe(data => {
        if(data != null) {
          this.userSettingsInfo.phoneNumber = this.formatNumber(data.phoneNumber);
          this.userSettingsInfo.cellPhoneNumber = this.formatNumber(data.cellPhoneNumber);
          this.userSettingsInfo.faxNumber = this.formatNumber(data.faxNumber);
          this.userSettingsInfo.fullName = data.lastName + ', ' + data.firstName;
          this.userSettingsInfo.defaultPkgs = data.defaultPkgs;
          this.userSettingsInfo.jobTitle = data.jobTitle;
          this.userSettingsInfo.email = data.email;
          this.userSettingsInfo.profileUpdated = data.profileUpdated;
          this.userSettingsInfo.mfaPreference = data.mfaPreference;
          this.userSettingsInfo.lastLogin = data.lastLogin;
          this.userSettingsInfo.firstName = data.firstName;
          this.userSettingsInfo.lastName = data.lastName;
        }
      })
    )

    this.subs.add(
      this.actions$.pipe(ofType(UpdateUserClassResults)).subscribe(() => {
        this.store.dispatch(InfoShow({message: "User class info updated successfully!"}))
      })
    )
    this.subs.add(
      this.actions$.pipe(ofType(UpdateUserClassErrors)).subscribe(res => {
        this.store.dispatch(InfoShow({message: res.response.message}))
      })
    )
    this.subs.add(
      this.actions$.pipe(ofType(UpdateUserMHISettingsResults)).subscribe(() => {
        this.store.dispatch(InfoShow({message: 'User MHI Settings is updated successfully!'}))
      })
    )
    this.subs.add(
      this.actions$.pipe(ofType(UpdateUserMHISettingsError)).subscribe(res => {
        this.store.dispatch(ErrShow({ message: res.response.message}) );
      })
    )

  }



  SaveProfileClick() {
    this.mappingSaveUserData(this.userSettingsInfo);
    this.store.dispatch(UpdateUserClass({ saveData: this.saveUserSettingsInfo}));
  }

  SaveMacroHelixTabClick() {
    if (this.userSettingsInfo.isMHIUser) {
      this.store.dispatch(UpdateUserMHISettings({ saveUserMHISettings: this.saveUserMHISettingsInfo}));
    }
  }

  private mappingSaveUserData(userSettings: UserSettings) {
    this.saveUserSettingsInfo.uid = userSettings.uid;
    this.saveUserSettingsInfo.email = userSettings.email;
    this.saveUserSettingsInfo.firstName = userSettings.firstName;
    this.saveUserSettingsInfo.lastName = userSettings.lastName;
    this.saveUserSettingsInfo.phoneNumber = userSettings.phoneNumber.replace(/[-)(\s]/g, '');
    this.saveUserSettingsInfo.cellPhoneNumber = userSettings.cellPhoneNumber.replace(/[-)(\s]/g, '');
    this.saveUserSettingsInfo.faxNumber = userSettings.faxNumber.replace(/[-)(\s]/g, '');
    this.saveUserSettingsInfo.jobTitle = userSettings.jobTitle;
    this.saveUserSettingsInfo.lastApp = userSettings.lastApp;
    this.saveUserSettingsInfo.lastPid = userSettings.lastPID;
    this.saveUserSettingsInfo.loginCount = userSettings.loginCount;
    this.saveUserSettingsInfo.lastLogin = userSettings.lastLogin;
    this.saveUserSettingsInfo.prevLogin = userSettings.prevLogin;
    this.saveUserSettingsInfo.isMHIUser = userSettings.isMHIUser;
    this.saveUserSettingsInfo.defaultPkgs = userSettings.defaultPkgs;
    this.saveUserSettingsInfo.displayHeight = userSettings.displayHeight;
    this.saveUserSettingsInfo.virtualScrolling = userSettings.virtualScrolling;
    this.saveUserSettingsInfo.eid = userSettings.eid;
    this.saveUserSettingsInfo.active = userSettings.active;
    let dt = new Date();
    this.saveUserSettingsInfo.profileUpdated = this.userSettingsInfo.profileUpdated = new Date(dt.getTime()-(dt.getTimezoneOffset()*60*1000));
    this.saveUserSettingsInfo.mfaPreference = userSettings.mfaPreference;
  }

  private formatNumber(f)
  {
    let result = '';
    const fValue = f.replace(/[-)(\s]/g, '');
    if (fValue != null && !(fValue.length === 0)) {
      result = '(' + fValue.slice(0,3) +') ' +fValue.slice(3,6) +'-' +fValue.slice(6);
    }
    return result;
  }

  emailLengthCalculate() {
    if (this.userSettingsInfo.email && this.userSettingsInfo.email.length > 25) {
        return this.userSettingsInfo.email.length * 15 ;
    } else {
      return 300;
    }
  }

  ResetB2cOptions(option:number){
    if(option == 1) {
      this.b2cService.ResetPassword();
    }

    if(option == 2) {
      this.b2cService.ResetMFA();
    }
  }

  IsConnectUser(): boolean {
    //console.log(this.b2cService.IsConnectUserFlow());
    return this.b2cService.IsConnectUserFlow();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
