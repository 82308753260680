import { UserState } from 'src/app/store/user.reducer';
import { Component, OnInit, HostListener, ViewChild, OnDestroy } from '@angular/core';
import { SalesForceTicket, SubmittedSupportTicket } from 'src/app/models/sales-force-ticket';
import { MatDialogRef  } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ShellState } from '../../../../../store/shell.reducer';
import { GetSfCategories, NewSfTicket, NewSfTicketError, NewSfTicketResults } from '../../../../../store/shell.actions';
import { Observable, Subscription } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { InfoShow } from '../../../../../store/ui-state.actions';
import { TableGetOptions } from 'src/app/models/received-detail';
import { GetUserSubmittedSupportTickets } from 'src/app/store/user.actions';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'help-submit-ticket-popup',
  templateUrl: './submit-ticket-popup.component.html',
  styleUrls: ['./submit-ticket-popup.component.scss']
})

export class SubmitTicketPopupComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  public shellState$: Observable<ShellState>;
  public newSalesForceTicket: SalesForceTicket = new SalesForceTicket();
  public phoneMask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  phoneNumberPattern = '[0-9]{3}-[0-9]{3}-[0-9]{4}';
  public submittedSupportTicketList: SubmittedSupportTicket[];
  subs: Subscription = new Subscription;

  constructor(private dialogRef: MatDialogRef<SubmitTicketPopupComponent>,
              private store : Store<{ shellState: ShellState, userState: UserState }>,
              private actions$: Actions,
              ) { this.store.dispatch(GetSfCategories());
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.shellState$ = this.store.select(state => state.shellState );

    this.subs.add(
      this.actions$.pipe(ofType(NewSfTicketResults)).subscribe((ticket) => {
        if (ticket != null) {
          this.store.dispatch(InfoShow({message: 'Submitted New Salesforce Ticket Successfully'}));
          this.getSubmittedTicketList();
          this.dialogRef.close(this.submittedSupportTicketList);
        }
      })
    )

    this.subs.add(
      this.actions$.pipe( ofType(NewSfTicketError)).subscribe((data) => {
        alert(data.message);
        this.dialogRef.close(this.submittedSupportTicketList);
      })
    )
    
    this.subs.add(
      this.store.select(state => state.userState.submittedTicketList).subscribe(
        (data) => {
          if (data != null) {
            this.submittedSupportTicketList = data;
          }
      })
    )
  }

  onSubmitClick() {
    this.newSalesForceTicket.phoneNumber = this.newSalesForceTicket.phoneNumber.replace(/[-)(\s]/g, '');
    this.store.dispatch(NewSfTicket( {ticket:this.newSalesForceTicket}) );
  }

  onCloseClick(){
    this.dialogRef.close();
  }

  getSubmittedTicketList() {
    const options: TableGetOptions = new TableGetOptions();
    this.store.dispatch( GetUserSubmittedSupportTickets( { options} ) );
  }

  formChanged() {
  }

  isNullOrWhitespace() {
    if (typeof this.newSalesForceTicket.subject === 'undefined' || this.newSalesForceTicket.subject == null
    || typeof this.newSalesForceTicket.body === 'undefined' || this.newSalesForceTicket.body == null) return true;
    return this.newSalesForceTicket.subject.replace(/\s/g, '').length < 1 || this.newSalesForceTicket.body.replace(/\s/g, '').length < 1;
  }

  ngOnDestroy(){
    this.subs.unsubscribe();
  }
}
