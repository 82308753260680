import { Injectable } from '@angular/core';
import { BaseMhService } from './basemh.service';
import { catchError, map } from 'rxjs/operators';
import { Observable, pipe, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserState } from '../store/user.reducer';
import { UserSession } from '../models/user';
import { environment } from '../../environments/environment';
import { PrescriberOutputDto } from '../models/prescriber-list/prescriber-output-dto';
import { PrescriberColumnDTO, PrescriberUploadColumnsDTO } from '../models/prescriber-list/prescriber-column-dto';
import { Prescriber } from '../models/prescriber-list/prescriber';
import { SubmitResponse } from '../models/submit-response';
import { ErrorResponse } from '../models/prescriber-list/error-response';
import { PrescriberListUploadOutputDto } from '../models/prescriber-list/prescriber-list-upload-output';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PrescriberListUploadErrorReponseOutputDto } from '../models/prescriber-list/prescriber-list-upload-error-response-output';
import { PrescriberHistory } from '../models/prescriber-list/prescriber-history';
import { AllOtherPrescriberOutputDto } from '../models/prescriber-list/all-other-prescriber-output';


@Injectable({
  providedIn: 'root'
})

export class PrescriberListService extends BaseMhService {

  constructor(protected http: HttpClient, protected store: Store<{ userState: UserState }>) {
    super(http, store);
  }

  public getPrescriberList() {
    const session: UserSession = this.getStoreUserSession();
    return this.http
      .get(environment.apiBaseUrl + 'api/prescriberlist/v1/prescriberlist/getprescriberlist?HID=' + session.hid, { headers: this.getHeaders() })
      .pipe(
        map((response: PrescriberOutputDto) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Prescriber List Data" }))
      );
  }

  public setPrescriberListColumns(columns: PrescriberColumnDTO[]) {
    const saveData = { prescriberData: columns };
    return this.http
      .put(environment.apiBaseUrl + 'api/prescriberlist/v1/prescriberlist/setPrescriberListColumns', saveData, { headers: this.getHeaders() })
      .pipe(
        map((response: PrescriberColumnDTO[]) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while settings grid columns" }))
      );
  }

  public addPrescriber(newPrescriber: Prescriber) {
    const session: UserSession = this.getStoreUserSession();
    newPrescriber.HID = session.hid;
    newPrescriber.PID = session.pid;
    return this.http
      .post(environment.apiBaseUrl + 'api/prescriberlist/v1/prescriberlist/addPrescriber', newPrescriber, { headers: this.getHeaders() })
      .pipe(
        map((response: SubmitResponse) => newPrescriber),
        catchError(error => this.errorHandler({ error, operation: "Error while adding new prescriber" }))
      );
  }

  public validatePrescriber(newPrescriber: Prescriber) {
    const session: UserSession = this.getStoreUserSession();
    newPrescriber.HID = session.hid;
    return this.http
      .post(environment.apiBaseUrl + 'api/prescriberlist/v1/prescriberlist/validatePrescriber', newPrescriber, { headers: this.getHeaders() })
      .pipe(
        map((response: ErrorResponse) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while validating prescriber" }))
      );
  }

  public viewPrescriber(docid: number) {
    return this.http.get(environment.apiBaseUrl + 'api/prescriberlist/v1/prescriberlist/GetPrescriberByDocID?DOCID=' + docid, { headers: this.getHeaders() })
      .pipe(
        map((response: Prescriber) => { return response }),
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving prescriber" }))
      );
  }

  public updatePrescriber(saveData: Prescriber) {
    const session: UserSession = this.getStoreUserSession();
    saveData.HID = session.hid;
    saveData.PID = session.pid;
    return this.http
      .put(environment.apiBaseUrl + 'api/prescriberlist/v1/prescriberlist/UpdatePrescriber', saveData, { headers: this.getHeaders() })
      .pipe(
        map((response: SubmitResponse) => {
          if (response.status === '200')
            return saveData;
          else
            throwError(response.message);
        }),
        catchError(error => this.errorHandler({ error, operation: "Error while updating prescriber details" }))
      );
  }

  public getPrescriberListUploadDetails() {
    const session: UserSession = this.getStoreUserSession();
    return this.http
      .get(environment.apiBaseUrl + 'api/prescriberlist/v1/prescriberFileUpload/getprescriberlistuploaddetails?HID=' + session.hid, { headers: this.getHeaders() })
      .pipe(
        map((response: PrescriberListUploadOutputDto) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Prescriber List Upload Data" }))
      );
  }

  public uploadPrescriberList(prescriberFile: FormData) {
    const session: UserSession = this.getStoreUserSession();
    //Take content type out of header
    var formHeaders = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Ocp-Apim-Subscription-Key': environment.subcriptionKey,
      Authorization: 'Bearer ' + this.getUserToken(),
    });
    return this.http
      .post(environment.apiBaseUrl + 'api/prescriberlist/v1/prescriberFileUpload/upload?HID=' + session.hid, prescriberFile, { headers: formHeaders })
      // .post('https://localhost:44376/' + 'prescriberFileUpload/upload?HID=' + session.hid, prescriberFile, { headers: formHeaders })
      .pipe(
        map((response: SubmitResponse) => {
          if (response.status === '200')
            return response;
          else
            throwError(response.message);
        }),
        catchError(error => this.errorHandler({ error, operation: "Error while uploading prescriber file" }))
      );
  }

  public getPrescriberListUploadErrorResponseData(importId: String) {
    const session: UserSession = this.getStoreUserSession();
    return this.http
      .get(environment.apiBaseUrl + 'api/prescriberlist/v1/prescriberFileUpload/getPrescriberListUploadErrorResponse?HID=' + session.hid + '&importID=' + importId, { headers: this.getHeaders() })
      .pipe(
        map((response: PrescriberListUploadErrorReponseOutputDto) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Prescriber List Upload Error Response Data" }))
      );
  }

  public setPrescriberListUploadColumns(prescriberUploadColumns: PrescriberUploadColumnsDTO[]) {
    const saveData = { prescriberData: prescriberUploadColumns };
    return this.http
      .put(environment.apiBaseUrl + 'api/prescriberlist/v1/prescriberFileUpload/setPrescriberListUploadColumns', saveData, { headers: this.getHeaders() })
      .pipe(
        map((response: PrescriberUploadColumnsDTO[]) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while settings grid columns" }))
      );
  }
  public viewPrescriberHistory(docid: number) {
    return this.http.get(environment.apiBaseUrl + 'api/prescriberlist/v1/prescriberlist/GetPrescriberHistoryByDocID?DOCID=' + docid, { headers: this.getHeaders() })
      .pipe(
        map((response: PrescriberHistory) => { return response }),
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving prescriber history" }))
      );
  }

  public getAllPrescriberListDetails() {
    const session: UserSession = this.getStoreUserSession();
    let baseURL = environment.apiBaseUrl + 'api/prescriberlist/v1/allPrescribersData/getallprescriberlistdetails?PID=' + session.pid;

    return this.http
      .get(baseURL, { headers: this.getHeaders() })
      .pipe(
        map((response: AllOtherPrescriberOutputDto) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving All Other Prescriber List  Data" }))
      );
  }

  public deactivatePrescriber(npi: string) {
    const session: UserSession = this.getStoreUserSession();
    const url = `${environment.apiBaseUrl}api/prescriberlist/v1/prescriberlist/DeactivatePrescriber?PID=${session.pid}&HID=${session.hid}&NPI=${npi}`;
    return this.http
      .delete(url, { headers: this.getHeaders() })
      .pipe(
        map((response: SubmitResponse) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while deactivating a prescriber" }))
      );
  }
}


