<div mat-dialog-title class="dialog-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane"
  cdkDragBoundary=".cdk-overlay-container">
  <h3 cdkDragHandle>Change Pharmacy</h3>
</div>

<!-- <br /> -->
<mat-dialog-actions class="button-search-container">
  <mat-form-field class="search-bar">
    <mat-label>Search</mat-label>
    <input type="text" matInput (keyup)="applyFilter($event)">
  </mat-form-field> &nbsp;
  <button mat-flat-button color="primary" class="search-btn" (click)="refreshSearch() ">Search</button>

    <span class="selectView">
      <button *ngIf="showClientSelectionBtn" mat-flat-button type="submit" color="secondary" class="select-btn" (click)="handleClientSelection() "> Change
        Health System</button> &nbsp;
      <button mat-flat-button type="submit" color="primary" class="submit-btn" (click)="handleViewSelected() "> View
        Selected</button> &nbsp;
      <button mat-flat-button type="button" class="export-btn" (click)="exportTable() ">Export</button> &nbsp;
      <button mat-stroked-button type="button" class="discard-btn" (click)="onCloseClick()">Cancel</button>
    </span>
  </mat-dialog-actions>


<mat-dialog-content class="example-container mat-elevation-z8" #TABLE>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="space">
      <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
      <td mat-cell *matCellDef="let element"></td>
    </ng-container>

    <!-- Position Column -->
    <ng-container matColumnDef="pharmType">
      <th mat-header-cell *matHeaderCellDef> Type </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.pharmType===0">
          <span [ngClass]="element.pharmActive === 1 ? 'active-hospital' : 'inactive-product-icon'">H</span>
        </span>
        <span *ngIf="element.pharmType===1">
          <span [ngClass]="element.pharmActive === 1 ? 'active-retail' : 'inactive-product-icon'">R</span>
        </span>
        <span *ngIf="element.isSpecialty === 1">
          <span [ngClass]="'slash-split'"> / </span>
          <span [ngClass]="'active-specialty'">S</span>
        </span>
        <span [ngClass]="'slash-split'" *ngIf="element.isGateway===1"> / </span>
        <span *ngIf="element.isGateway===1" [ngClass]="'active-gateway'">G</span>
        <span [ngClass]="'slash-split'" *ngIf="element.isPAP===1"> / </span>
        <span *ngIf="element.isPAP===1" [ngClass]="'active-pap'">P</span>
        <span [ngClass]="'slash-split'" *ngIf="element.isNDCA===1"> / </span>
        <span *ngIf="element.isNDCA===1" [ngClass]="'active-ndca'">N</span>
        <span [ngClass]="'slash-split'" *ngIf="element.isFeasibility===1"> / </span>
        <span *ngIf="element.isFeasibility===1" [ngClass]="'active-feasibility'">F</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="pid">
      <th mat-header-cell *matHeaderCellDef class="text-align-right"> PID </th>
      <td mat-cell *matCellDef="let element" class="text-align-right"> {{element.pid}} </td>
    </ng-container>

    <ng-container matColumnDef="pharmacy">
      <th mat-header-cell *matHeaderCellDef> Pharmacy </th>
      <td mat-cell *matCellDef="let element" class="pharm-width-control"> {{element.pharmacy}} </td>
    </ng-container>

    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef> Location </th>
      <td mat-cell *matCellDef="let element"> {{element.location }} </td>
    </ng-container>

    <ng-container matColumnDef="utilCount">
      <th mat-header-cell *matHeaderCellDef class="text-align-right"> Util Count </th>
      <td mat-cell *matCellDef="let element" class="text-align-right"> {{element.utilCount}} </td>
    </ng-container>

    <ng-container matColumnDef="lastRecalculated">
      <th mat-header-cell *matHeaderCellDef class="text-align-right"> Last Recalculated </th>
      <td mat-cell *matCellDef="let element" class="text-align-right">
        {{element.recalculated | date:"MM/dd/yyyy h:mma 'EST'" }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="hid-group-{{row.hid}}"
      [ngClass]="{'selected-pid': tableRow?.pid == row.pid}" (click)="handleRowData(row)"></tr>

    <ng-container matColumnDef="groupHeader">
      <td colspan="999" mat-cell *matCellDef="let groupBy" style="padding-left: 0px">
        <button style="background-color: transparent" mat-button (click)="collapseRows(groupBy.hid)"><span
            class="mat-collapse-icon group-expanded button-hid-{{groupBy.hid}}"></span></button>
        <strong>{{groupBy.group}} (HID {{groupBy.hid}})</strong> <!--| titlecase-->
      </td>
    </ng-container>

    <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"> </tr>

  </table>
  <div class="loading-data" *ngIf="callInProgress"> </div> <!--Data is loading...-->
  <div class="no-records" *ngIf="dataSource?.filteredData?.length===0 && callInProgress === false">No Match Found</div>
</mat-dialog-content>
<!--<mat-paginator class="pagination-pharmacy"[pageSizeOptions]="[250,500,750,1000, 1500]" showFirstLastButtons></mat-paginator>-->