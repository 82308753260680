<div class="notification-history-dialog">
  <div class="dialog-header">
    <div mat-dialog-title class="dialog-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
      <h3 cdkDragHandle> Notification History 
        <button class="notification-close-button" mat-button (click)="onCloseNotificationClick()">Close</button>
      </h3>
    </div>
  </div>
  <div class="dialog-body" style="background-color:#005489;">
    <div class="example-container-history" #TABLE>
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="notification">
          <th mat-header-cell *matHeaderCellDef> Notification</th>
          <td mat-cell *matCellDef="let element" [innerHtml]="element.notification | linkify"></td>
        </ng-container>
        <ng-container matColumnDef="posted">
          <th mat-header-cell *matHeaderCellDef> Posted</th>
          <td mat-cell *matCellDef="let element"> {{element.posted | date:'MM/dd/yyyy'}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>  
      </table>
    </div>
  </div>     
</div>


