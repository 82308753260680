import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { UiState } from '../../store/ui-state.reducer';
import { Applications } from '../../models/applications';
import { B2cLoginService } from 'src/app/service/b2clogin.service';
import { CloseClientSelection, GetUserLogout } from 'src/app/store/user.actions';
import { UserState } from 'src/app/store/user.reducer';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'blank-page',
  templateUrl: './blank-page.component.html',
  styleUrls: ['./blank-page.component.scss']
})

export class BlankPageComponent implements OnInit {

  clientPage: boolean = false;
  accessDenied: boolean = false;
  @Input() showClientPage?: boolean = false;
  @Input() showAccessDenied?: boolean = false;
  public clientIdCookie: string;

  constructor(private userService: UserService, private store: Store<{ uiState: UiState, applications: Applications, userState: UserState }>, private b2cService: B2cLoginService) {
    this.clientIdCookie = this.userService.readCookie("ClientId");
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.clientPage = this.showClientPage;
    this.accessDenied = this.showAccessDenied;
  }

  signOut() {
    //blacklist access token
    this.store.dispatch( GetUserLogout() );
    //cookie, identity token cleanup
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    this.b2cService.LogoutCleanup();
    this.b2cService.logout();
  }

  closeScreen() {
    this.store.dispatch(CloseClientSelection());
  }

}
