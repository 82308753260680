import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PrescriberListService } from '../service/prescriber-list.service';
import {
  AddPrescriber, AddPrescriberError, AddPrescriberResults, GetPrescriberList, GetPrescriberListError, GetPrescriberListResults, SetPrescriberColumns,
  SetPrescriberColumnsError, SetPrescriberColumnsResults, ValidatePrescriber, ValidatePrescriberError, ValidatePrescriberResults, ViewPrescriber, ViewPrescriberError,
  ViewPrescriberResults, UpdatePrescriber, UpdatePrescriberError, UpdatePrescriberResults, GetPrescriberListUpload, GetPrescriberListUploadResults, GetPrescriberListUploadError,
  GetPrescriberListUploadErrorResponse, GetPrescriberListUploadErrorResponseResults, GetPrescriberListUploadErrorResponseError, UploadPrescriberList, UploadPrescriberListResults, UploadPrescriberListError,
  SetPrescriberUploadColumns, SetPrescriberUploadColumnsError, SetPrescriberUploadColumnsResults, ViewPrescriberHistory, ViewPrescriberHistoryError, ViewPrescriberHistoryResults, GetAllPrescriberDetails, GetAllPrescriberDetailsResults, GetAllPrescriberDetailsError, DeactivatePrescriberResults, DeactivatePrescriberError, DeactivatePrescriber
} from './prescriber-list.actions';

@Injectable()
export class PrescriberStoreEffects {

  constructor(private prescriberService: PrescriberListService, private actions$: Actions, private store: Store<{}>) {
  }

  GetPrescriberListEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetPrescriberList),
    switchMap(action =>
      this.prescriberService.getPrescriberList()
        .pipe(
          map(
            data => {
              return GetPrescriberListResults({ prescriberList: data })
            }
          ),
          catchError(
            error => observableOf(GetPrescriberListError({ message: error }))
          )
        )
    )
  ))

  SetPrescriberListColumnsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(SetPrescriberColumns),
    switchMap(action =>
      this.prescriberService.setPrescriberListColumns(action.columns)
        .pipe(
          map(
            data => {
              return SetPrescriberColumnsResults({ columns: data })
            }
          ),
          catchError(
            error => observableOf(SetPrescriberColumnsError({ message: error }))
          )
        )
    )
  ))

  AddPrescriberEffect$ = createEffect(() => this.actions$.pipe(
    ofType(AddPrescriber),
    switchMap(action =>
      this.prescriberService.addPrescriber(action.newPrescriber)
        .pipe(
          map(
            data => {
              return AddPrescriberResults({ newPrescriber: data })
            }
          ),
          catchError(
            error => observableOf(AddPrescriberError({ message: error }))
          )
        )
    )
  ))

  ValidatePrescriberEffect$ = createEffect(() => this.actions$.pipe(
    ofType(ValidatePrescriber),
    switchMap(action =>
      this.prescriberService.validatePrescriber(action.newPrescriber)
        .pipe(
          map(
            data => {
              return ValidatePrescriberResults({ response: data })
            }
          ),
          catchError(
            error => observableOf(ValidatePrescriberError({ message: error }))
          )
        )
    )
  ))

  ViewPrescriberEffect$ = createEffect(() => this.actions$.pipe(
    ofType(ViewPrescriber),
    switchMap(action =>
      this.prescriberService.viewPrescriber(action.docid)
        .pipe(
          map(
            data => {
              return ViewPrescriberResults({ prescriberDetails: data })
            }
          ),
          catchError(
            error => observableOf(ViewPrescriberError({ message: error }))
          )
        )
    )
  ))

  UpdatePrescriberEffect$ = createEffect(() => this.actions$.pipe(
    ofType(UpdatePrescriber),
    switchMap(action =>
      this.prescriberService.updatePrescriber(action.newPrescriber)
        .pipe(
          map(
            data => {
              return UpdatePrescriberResults({ newPrescriber: data })
            }
          ),
          catchError(
            error => observableOf(UpdatePrescriberError({ message: error }))
          )
        )
    )
  ))

  GetPrescriberListUploadEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetPrescriberListUpload),
    switchMap(action =>
      this.prescriberService.getPrescriberListUploadDetails()
        .pipe(
          map(
            data => {
              return GetPrescriberListUploadResults({ prescriberListUpload: data })
            }
          ),
          catchError(
            error => observableOf(GetPrescriberListUploadError({ message: error }))
          )
        )
    )
  ))

  UploadPrescriberListEffect$ = createEffect(() => this.actions$.pipe(
    ofType(UploadPrescriberList),
    switchMap(action =>
      this.prescriberService.uploadPrescriberList(action.prescriberFile)
        .pipe(
          map(
            data => {
              return UploadPrescriberListResults({ response: data })
            }
          ),
          catchError(
            error => observableOf(UploadPrescriberListError({ message: error }))
          )
        )
    )
  ))

  GetPrescriberListUploadErrorResponseEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetPrescriberListUploadErrorResponse),
    switchMap(action =>
      this.prescriberService.getPrescriberListUploadErrorResponseData(action.importId)
        .pipe(
          map(
            data => {
              return GetPrescriberListUploadErrorResponseResults({ prescriberListUploadErrorResponse: data })
            }
          ),
          catchError(
            error => observableOf(GetPrescriberListUploadErrorResponseError({ message: error }))
          )
        )
    )
  ))

  SetPrescriberListUploadColumnsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(SetPrescriberUploadColumns),
    switchMap(action =>
      this.prescriberService.setPrescriberListUploadColumns(action.prescriberUploadColumns)
        .pipe(
          map(
            data => {
              return SetPrescriberUploadColumnsResults({ prescriberUploadColumns: data })
            }
          ),
          catchError(
            error => observableOf(SetPrescriberUploadColumnsError({ message: error }))
          )
        )
    )
  ))

  ViewPrescriberHistoryEffect$ = createEffect(() => this.actions$.pipe(
    ofType(ViewPrescriberHistory),
    switchMap(action =>
      this.prescriberService.viewPrescriberHistory(action.docid)
        .pipe(
          map(
            data => {
              return ViewPrescriberHistoryResults({ prescriberHistory: data })
            }
          ),
          catchError(
            error => observableOf(ViewPrescriberHistoryError({ message: error }))
          )
        )
    )
  ))

  GetAllPrescriberListEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetAllPrescriberDetails),
    switchMap(action =>
      this.prescriberService.getAllPrescriberListDetails()
        .pipe(
          map(
            data => {
              return GetAllPrescriberDetailsResults({ allPrescriberListUpload: data })
            }
          ),
          catchError(
            error => observableOf(GetAllPrescriberDetailsError({ message: error }))
          )
        )
    )
  ))

  DeactivatePrescriberEffect$ = createEffect(() => this.actions$.pipe(
    ofType(DeactivatePrescriber),
    switchMap(action =>
      this.prescriberService.deactivatePrescriber(action.npi)
        .pipe(
          map(
            data => {
              return DeactivatePrescriberResults({ response: data })
            }
          ),
          catchError(
            error => observableOf(DeactivatePrescriberError({ message: error }))
          )
        )
    )
  ))
}
