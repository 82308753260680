<mat-toolbar color="primary">

  <button mat-button [matMenuTriggerFor]="menu" class="mh-menu-button"> {{ (applications$ | async).Selected != null ?
    (applications$ | async).Selected.appName : '' }} <mat-icon>expand_more</mat-icon> </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item *ngFor="let item of (applications$ | async).Items; index as i"
      (click)="applicationSelected(item)" [disabled]="isApplicationDisabled(item.appID)">{{item.appName}}</button>
  </mat-menu>
  <mat-divider [vertical]="true" style="width: 4px;"></mat-divider>

  <div id="menu-toggle">
    <button id="unpin" (click)="menuHide()" *ngIf="menuVisible" mat-button>
      <mat-icon>menu_open</mat-icon> Menu
    </button>
    <button id="hamburger" (click)="menuShow()" *ngIf="!menuVisible" mat-button>
      <mat-icon>menu</mat-icon> Menu
    </button>
  </div>

  <div *ngIf="(applications$ | async).Selected && (applications$ | async).Selected.appID !== 5" class="page-name">
    {{selectedPageName}}
  </div>
  <ng-container
  *ngIf="(applications$ | async).Selected && (applications$ | async).Selected.favoriteButtonVisible && selectedPage.pageID !== -1 && (applications$ | async).Selected.appID != 8">
    <div class="breadcrumbs">
      <button id="favorite" (click)="toggleFavorite()"
        [ngClass]="{'favorite': favoritePageSelected, 'unfavorite': !favoritePageSelected}" mat-icon-button
        matTooltip="Set as default landing page" matTooltipPosition="right">
        <mat-icon>star</mat-icon>
      </button>
    </div>
  </ng-container>

  <div id="right-button-group">
    <mh-navbar-buttons></mh-navbar-buttons>
  </div>

  <disclaimer *ngIf="showDisclaimer"></disclaimer>

</mat-toolbar>