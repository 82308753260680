import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { GeneralSettingsComponent } from '../top-nav/navbar-buttons/general-settings/general-settings.component';
import { SubmitTicketPopupComponent } from 'src/app/shared/top-nav/navbar-buttons/help-button/submit-ticket-popup/submit-ticket-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { Store } from '@ngrx/store';
import { UiState } from '../../store/ui-state.reducer';
import { Applications } from '../../models/applications';
import { Observable, Subscription } from 'rxjs';
import { AboutPopupComponent } from '../top-nav/navbar-buttons/help-button/about-popup/about-popup.component';
import { HelpGuideComponent } from '../top-nav/navbar-buttons/help-button/help-guide/help-guide.component';
import { EmailSettingsComponent } from '../top-nav/navbar-buttons/user-profile-button/email-settings/email-settings.component';
import { GetUserNotificationHistoryList, UpdateRecalculate, UpdateSelectedUserNotificationList, UpdateUserNotificationList, GetHelpApplicationUrl } from 'src/app/store/user.actions';
import { NotificationItem, NotificationItemHistory, UpdateNotification, UserSession, UserSettings } from 'src/app/models/user';
import { UserState } from 'src/app/store/user.reducer';
import { BaseMhService } from 'src/app/service/basemh.service';
import { HttpClient } from '@angular/common/http';
import { InfoShow } from 'src/app/store/ui-state.actions';
import * as Bowser from 'bowser'; // TypeScript
import { Router } from '@angular/router';
import { Pages } from "../../app-pages";

import { TooltipPosition } from '@angular/material/tooltip';
import { FormControl } from '@angular/forms';
import { PageSelected } from 'src/app/store/application-list.actions';
import { AppInsightsService } from './../../service/appinsights.service'
import { NotificationHistoryDialogComponent } from '../top-nav/navbar-buttons/bell-button/notification-history-dialog/notification-history-dialog.component';
import { delay, startWith, take, tap, withLatestFrom } from 'rxjs/operators';
import { MessageDialogComponent } from '../../shared/message-dialog/message-dialog.component';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'mh-navbar-buttons',
  templateUrl: './navbar-buttons.component.html',
  styleUrls: ['./navbar-buttons.component.scss']
})
export class NavbarButtonsComponent extends BaseMhService implements OnInit, OnDestroy {
  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    if(!this.notificationHistoryopen)
    {
      this.notificationCollapsed = true;
    }
 
  }
  public errVisible$: Observable<boolean>;
  public errMessage$: Observable<string>;
  public applications$: Observable<Applications>;
  public helpGuideUrl: string;

  notificationList: NotificationItem[];
  notificationHistoryList: NotificationItemHistory[];
  notificationCollapsed = true;
  notificationlist:any;
  dialogRef: any;
  notificationLength:number = 0;
  notificationhistorylength: number =0;
  notificationHistoryopen:boolean;
  tableRow;
  userSessionInfo: UserSession;
  userSettingsInfo: UserSettings;
  recalFinish;

  positionOptions: TooltipPosition[] = ['below', 'above', 'left', 'right'];
  position = new FormControl(this.positionOptions[0]);

  // Step1:
  name = 'Angular';
  userAgent = Bowser.parse(window.navigator.userAgent);
  browser = Bowser.getParser(window.navigator.userAgent);
  userAgentDetails = JSON.stringify(this.userAgent, null, 4);
  browserDetails = JSON.stringify(this.browser.getBrowser(), null, 4);

  browserName = this.browser.getBrowser().name;
  subs: Subscription = new Subscription;

  constructor(protected http: HttpClient,
    private dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private appInsights: AppInsightsService,
    public store: Store<{ uiState: UiState, applications: Applications, userState: UserState }>) {
    super(http, store);
    this.matIconRegistry.addSvgIcon(
      'recalculateimg',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/img/recalculate.svg'),
    )
  }

  ngOnInit(): void {
    this.store.dispatch(GetUserNotificationHistoryList());
    this.store.dispatch(GetHelpApplicationUrl());
    this.applications$ = this.store.select(state => state.applications);
    this.errVisible$ = this.store.select(state => state.uiState.ErrorVisible );
    this.errMessage$ = this.store.select(state => state.uiState.ErrorMessage );

    this.subs.add(
      this.store.select(state => state.userState.userHelpUrl).subscribe((val) => {
        if (val && val.helpUrl) {
          this.helpGuideUrl = val.helpUrl;
        }
        else {
          this.helpGuideUrl = "https://helpjuice.com/users/sign_in";
        }
      })
    )
    this.subs.add(
      this.store.select(state => state.userState).subscribe((data) => {
        if (data.settings)
          this.userSettingsInfo = data.settings;
        if (data.session)
          this.userSessionInfo = data.session;
      })
    )
    this.subs.add(
      this.store.select(state => state.userState.notificationList).subscribe( (data) => {
        if (data != null) {
          this.notificationList = data.filter(n => n.notificationID);
          this.notificationLength= this.notificationList.length;
        }
      })
    )
    this.subs.add(
      this.store.select(state => state.userState.notificationhistoryList).subscribe( (data) => {
        if (data != null) {
          this.notificationhistorylength = data.length;
        }
      })
    )
    this.subs.add(
      this.store.select(state => state.userState.recalculate).subscribe((data) => {
        this.recalFinish = data
      })
    )
  }

  recalculate() {
    this.store.dispatch(UpdateRecalculate({ sessionData: { uid: this.userSessionInfo.uid, pid: this.userSessionInfo.pid } }));
    this.store.dispatch(InfoShow({ message: "Your recalculation request has been submitted. We’ll notify you when it’s complete." }));
  }

  openHelpGuideWindow() {
    const dialogRef = this.dialog.open(HelpGuideComponent, {
      disableClose: true,
      panelClass: 'mh-dialog'
    })
  };
    
  openGeneralSettingsDialog() {
    const dialogRef = this.dialog.open(GeneralSettingsComponent, {
      disableClose: true,
      panelClass: ['mh-dialog', 'general-settings-dialog']
    })
  };

  openSubmitSalesForceTicketDialog() {
    const dialogRef = this.dialog.open(SubmitTicketPopupComponent, {
      disableClose: true,
      panelClass: ['mh-dialog', 'submit-ticket-dialog']
    })
  };

  openAboutDialog() {
    const dialogRef = this.dialog.open(AboutPopupComponent, {
      disableClose: true,
      panelClass: ['mh-dialog', 'about-dialog']
    })
  };

  openEmailSettingsDialog() {
    const dialogRef = this.dialog.open(EmailSettingsComponent, {
      disableClose: true,
      panelClass: 'mh-dialog'
    })
  };

  openViewProfile() {
    const urlString = 'viewProfile';
    this.store.dispatch(PageSelected({ page: Pages[urlString] }));
    this.router.navigate([urlString], { state: { menuItem: Pages[urlString], application: null } });
  }

  openHelpGuideInNewWindow() {
    if (this.browserName === 'Internet Explorer') {
      let params = 'scrollbars=yes, fullscreen=yes, resizable=yes, status=no, location=no, toolbar=no, menubar=no, width=800,height=800,left=' + (window.screenX + 300) + ',top=' + (window.screenY + 100);
      window.open(this.helpGuideUrl, 'Macro Helix Support', params);
    } else if (this.browserName === 'Chrome') {
      let params = 'scrollbars=yes, fullscreen=yes, resizable=yes, status=no, location=no, toolbar=no, menubar=no, width=800,height=800,left=' + (window.screenX + 100) + ',top=600';
      window.open(this.helpGuideUrl, 'Macro Helix Support', params);
    } else {
      let params = 'scrollbars=yes, fullscreen=yes, resizable=yes, status=no, location=no, toolbar=no, menubar=no, width=800,height=800,left=' + (window.screenX + 300) + ',top=' + (window.screenY + 100);
      window.open(this.helpGuideUrl, 'Macro Helix Support', params);
    }
  }

  bellButtonClicked() {
    this.notificationCollapsed = !this.notificationCollapsed;
  }

  markAsRead() {
    if (this.notificationLength > 0){
      this.store.dispatch(UpdateUserNotificationList());
    }
  };

  markSelectedRead(nid:number) {
    const updateItem = new UpdateNotification();
    updateItem.updateNoteSentID = nid;
    updateItem.updatedNotificationList = this.notificationList;
    this.store.dispatch(UpdateSelectedUserNotificationList({saveNotificationList: updateItem}));

  }

  historyPage(){
    if (this.notificationhistorylength > 0){
      this.notificationHistoryopen = true;
      this.store.dispatch(GetUserNotificationHistoryList());
      const dialogRef = this.dialog.open(NotificationHistoryDialogComponent,{
      disableClose: false,
      restoreFocus: false,
      panelClass: ['mh-dialog','notification-history-dialog']
      })
      dialogRef.afterClosed().subscribe(() => this.notificationHistoryopen = false);
    }
  }

  ngOnDestroy(){
    this.subs.unsubscribe();
  }


}
