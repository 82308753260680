import { PharmacyColumnDTO } from "src/app/models/hidpharmacy/pharmacy-list/pharmacy-column-dto";
import { PharmacyOutputDTO } from "src/app/models/hidpharmacy/pharmacy-list/pharmacy-output-dto";
import { PharmacyListActionTypes } from "./pharmacy-list-actions";

export interface pharmacyListState{
    pharmacyList: PharmacyOutputDTO,
    columns: PharmacyColumnDTO[]
}


export const initialState = 
{
    pharmacyList: null,
    columns: null,
    validationErrors: null,
    pharmacyDetail: null
}



export function PharmacyListReducer(state = initialState, action: any): pharmacyListState {

    switch(action.type){

        case PharmacyListActionTypes.GET_PHARMACY_LIST_RES: {
            return {...state, pharmacyList: action.pharmacyList}
        }

        case PharmacyListActionTypes.SET_PHARMACY_LIST_COLUMNS: {
            return {...state, columns: action.columns}
        }

        default:{
            return state;
        }
    }
}

