import { Component, Inject, OnInit, Optional } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import {UserState} from '../../store/user.reducer';
import { UserSession } from '../../models/user';
import {TooltipPosition} from '@angular/material/tooltip';
import { FormControl } from '@angular/forms';
import { Applications } from 'src/app/models/applications';
import { Observable } from 'rxjs';
import { ShowClientSelection } from 'src/app/store/user.actions';
import { UamState } from 'src/app/store/uam/uam.reducer';
import { MatTableComponent } from '../mat-table/mat-table.component';


@Component({
  selector: 'app-pidselector',
  templateUrl: './pidselector.component.html',
  styleUrls: ['./pidselector.component.scss']
})
export class PidselectorComponent implements OnInit {
  pid: number;
  userSession: UserSession;
  isCorp: boolean;
  
  positionOptions: TooltipPosition[] = ['below', 'above', 'left', 'right'];
  position = new FormControl(this.positionOptions[0]);
  public applications$: Observable<Applications>;
  showClientSelectionBtn: boolean;

  constructor(public dialog: MatDialog, private store: Store<{ applications: Applications, userState: UserState, uamState: UamState }>){
    this.store.select(state => state.uamState.initialClientList).subscribe(data => {
      if (data) {
        this.showClientSelectionBtn = data.chains.length + data.corps.length > 1;
      }
    });
  }

  ngOnInit() {
    this.store.select(state => state.userState.session).subscribe(val => {
      this.userSession = val;
      this.isCorp = this.userSession.corpID > 0 ? true : false;
    });
    this.applications$ = this.store.select(state => state.applications);

  }

  openDialog(): void {
    const dialogRef = this.dialog.open(MatTableComponent, {
      panelClass: ['mh-dialog','pid-selector-dialog'],
      disableClose: true,
      data: {
        appChange: 0
      }
    });
  }

  handleClientSelection() {
    this.store.dispatch(ShowClientSelection());
  }

}
