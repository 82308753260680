import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/service/user.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  @Input() forgotEmail: string;
  emailPattern = '[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,4}';

  forgotPasswordForm: FormGroup;
  errorMessage = "";
  submitMessage = "";
  submitted = false;

  constructor(private formBuilder: FormBuilder, private userService: UserService,) { }

  ngOnInit(): void {
    this.forgotPasswordForm = this.formBuilder.group(
      {
        forgotEmail: [this.forgotEmail, [Validators.required, Validators.email]]
      }
    );
  }

  onSubmit(){
    this.userService.resetPassword(this.forgotPasswordForm.controls.forgotEmail.value)
      .pipe(first())
      .subscribe(
        data => {
          this.submitted = true;
          this.submitMessage = "If " + this.forgotPasswordForm.controls.forgotEmail.value + " is associated with a 340B Architect ID, you should receive an email with a link to reset your password.";
        },
        error => {
          //console.log(error);
          this.errorMessage = error.error.error || error.statusText;
        });
  }

}
