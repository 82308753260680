import { QualificationFilterTypes } from './qualification-filter.actions';
import { GetPharmacyResponse } from 'src/app/models/QualificationFilter'

export interface QualificationFilterState {
  getPharmacyResponse: GetPharmacyResponse[],
}

export const initialState = {
  getPharmacyResponse: null,
};

export function qualificationFilterStateReducer(state = initialState, action: any): QualificationFilterState {
  switch (action.type) {
    case QualificationFilterTypes.PHARMACY_GET_RES: {
      return { ...state, getPharmacyResponse: action.getPharmacyResponse };
    }
    default: {
      return state;
    }
  }
}