import { Component, OnInit, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/store/user.reducer';
import { B2cLoginService } from 'src/app/service/b2clogin.service';
import { GetUserLogout } from 'src/app/store/user.actions';

@Component({
  selector: 'timeout-popup',
  templateUrl: './timeout-popup.component.html',
  styleUrls: ['./timeout-popup.component.scss']
})
export class TimeoutPopupComponent implements OnInit {
  public countdownInterval;
  public timerInterval;
  public TIMEOUT_INTERVAL_MINUTES: number = 17;
  public idleStartMinutes: number;
  public hideCountdown: boolean = true;
  public counter: number = 118000;
  public timerRunning: boolean = false;
  
  @HostListener('document:click', ['$event'])
  @HostListener('document:mousemove', ['$event']) 
  @HostListener('document:keydown', ['$event'])
  onMouseMove(e) {
    if (this.hideCountdown)
      this.idleStartMinutes = Date.now() / 60000;
  }
  

  constructor(private store: Store<{ userState: UserState }>, private b2cService: B2cLoginService) { }

  ngOnInit(): void {
    this.idleStartMinutes = Date.now() / 60000;
    this.timerInterval = setInterval(() => { this.checkTimeout(); }, 60000)

  }

  countDownClock() {
    if (this.counter > 0)
      this.counter = this.counter - 1000;
  };

  checkTimeout() {
    let minutesPassed = (Date.now() / 60000) - this.idleStartMinutes;
    //console.log(minutesPassed + ' minutes idle');
    if (minutesPassed > this.TIMEOUT_INTERVAL_MINUTES - 3)
    {
        this.hideCountdown = false;
        if (!this.timerRunning){
          this.countdownInterval = setInterval(() => { this.countDownClock(); }, 1000);
          this.timerRunning = true;
        }
    }
    
    if (minutesPassed > this.TIMEOUT_INTERVAL_MINUTES - 1) {
      //blacklist access token and trigger other logout actions
      this.store.dispatch( GetUserLogout() );
      document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      window.location.href = environment.loginUrl;
    }
    
  };

  continueClick() {
    this.idleStartMinutes = Date.now() / 60000;
    this.hideCountdown = true;
    clearInterval(this.countdownInterval);
    this.timerRunning = false;
    this.counter = 118000;
  }

  ngOnDestroy() {
    if (this.timerInterval)
      clearInterval(this.timerInterval);
    if (this.countdownInterval)
      clearInterval(this.countdownInterval);
  }

}

