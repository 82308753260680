import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { UserState } from 'src/app/store/user.reducer';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  public file: File;
  public errorFlag: number = 0;
  public headersRow: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialogRef<any>, public store: Store<{ userState: UserState }>, private actions$: Actions) { }



  ngOnInit(): void {
  }

  public onChange($event) {
    this.file = $event.target.files[0];
  }

  public validateFile() {
    if (!this.file)
      return
    if (this.file?.name.toLowerCase().endsWith(".csv"))
      this.validateFileHeaders();
    else {
      this.errorFlag = 1;
      this.dialog.updateSize('480px', '250px');
    }

  }

  public validateFileHeaders() {
    let reader = new FileReader();
    reader.readAsText(this.file);

    reader.onload = () => {
      let csvData = reader.result;
      let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
      this.headersRow = this.getHeaderArray(csvRecordsArray);
      //console.log(this.headersRow);
      //console.log(this.headersRow.length);
      if (this.headersRow.length === this.data?.columnCount)
        this.closeDialog();
      else {
        this.errorFlag = 2;
        this.dialog.updateSize('480px', '250px');
      }
    }
  }

  public getHeaderArray(csvRecordsArr: any) {
    let headers = (<string>csvRecordsArr[0]).split(',');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  public closeDialog() {
    this.dialog.close({ event: 'close', file: this.file })
  }
}
