import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { CSGID, UserSession } from "src/app/models/user";
import { UserState } from "src/app/store/user.reducer";
import {
  GetCSGIDList,
  SetSelectedCSGID,
} from "src/app/store/user.actions";
import { combineLatest, Observable, Subscription } from "rxjs";
import {
  GetGatewayChain
} from "src/app/store/gateway/gateway.actions";
import { GatewayState } from "src/app/store/gateway/gateway.reducer";
import { filter, map, shareReplay, switchMap, tap } from "rxjs/operators";
import { HideGatewayCsgids, ShowClientSelection, ShowGatewayCsgids } from 'src/app/store/user.actions';
import { ErrShow } from "src/app/store/ui-state.actions";

@Component({
  selector: "app-csgid-selector",
  templateUrl: "./csgid-selector.component.html",
  styleUrls: ["./csgid-selector.component.scss"],
})
export class CsgidSelectorComponent {
  private subscriptions = new Subscription();
  
  session$: Observable<UserSession> = this.store.select(state => state.userState.session);

  userAssignedCSGIDs$: Observable<CSGID[]> = this.store.select(state => state.userState.csgids).pipe(
    tap(csgids => {      
      //Get assigned CSGIDs  
      if (!csgids) this.store.dispatch(GetCSGIDList());
    }),    
    filter(csgids => !!csgids),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  selectedCSGID$: Observable<CSGID> = this.store.select(state => state.userState.selectedCSGID).pipe(
    tap(stateSelectedCSGID => {
      if (!stateSelectedCSGID) this.setSelectedCSGID();
    }),
    filter(stateSelectedCSGID => !!stateSelectedCSGID),
    switchMap(stateSelectedCSGID => this.handleSelectedCSGID(stateSelectedCSGID))
  );

  constructor(private store: Store<{ userState: UserState, gatewayState: GatewayState }>) {}

  handleSelectedCSGID(selectedCSGID: CSGID): Observable<CSGID> {
    return this.userAssignedCSGIDs$.pipe(
      map(csgids => {
        this.store.dispatch(HideGatewayCsgids());

        // Scenario where user chooses anything but a valid GW CSGID
        if (selectedCSGID.csgid == '-1')
          this.showError();
        else 
          this.validateGatewayCSGID(csgids, selectedCSGID.csgid);  
        
        return selectedCSGID;
      })
    ) 
  }

  //Set selectedCSGID from session/csgids
  setSelectedCSGID() {
    this.subscriptions.add(
      combineLatest([this.session$, this.userAssignedCSGIDs$])
        .subscribe(([session, csgids]) => {
          let sessionCSGID: CSGID;

          //If user only has 1 csg, select it by default
          if (csgids.length === 1 && csgids[0].csgid) {
            const csgid: CSGID = csgids[0];
            sessionCSGID = { csgid: csgids[0].csgid, name: `${csgid.name} (${csgid.csgid})` };
          } else if (session.chainGroupId) {
            //Scenario where user logs back in with a token and didn't select anything from client-selection screen 
            const chainGroupId: string = session.chainGroupId.toString();
            sessionCSGID = { csgid: chainGroupId, name: `${session.chainGroupName} (${chainGroupId})` };
          }
          
          if (sessionCSGID) {
            this.store.dispatch(SetSelectedCSGID({
              csgId: sessionCSGID.csgid,
              name: sessionCSGID.name
            }));
          } else {
            this.showError();
          }
        })
    );
  }

  validateGatewayCSGID(csgIDs: CSGID[], selectedCSGID: string) {
    //Make sure CSGID is Gateway
    let isGatewayCSGID: boolean = false;
    csgIDs.find(CSGID => {
      if (CSGID.csgid == selectedCSGID) {
        isGatewayCSGID = true;
      }
    });

    if (isGatewayCSGID) {
      //Set current selected chain for left menu
      this.store.dispatch(GetGatewayChain({ CSGID: parseInt(selectedCSGID) }));
    }
    else {
      this.showError();
    }
  }

  showError() {
    this.store.dispatch(ErrShow({ message: "No Gateway PIDs are assigned to the User." })); 
  }

  openClientSelection() {
    this.store.dispatch(ShowGatewayCsgids());
    this.store.dispatch(ShowClientSelection());
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}