import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-bulkupdatequalrules',
  templateUrl: './bulkupdatequalrules.component.html',
  styleUrls: ['./bulkupdatequalrules.component.scss']
})
export class BulkupdatequalrulesComponent implements OnInit {

  constructor() { }
  public childName = "qualRules";

  public steps = [{ name: 'Select Qualification Rules', }, { name: 'Select PIDs to apply rules' }, { name: 'Review' }];
  ngOnInit() {
    // this.firstFormGroup = this._formBuilder.group({
    //   firstCtrl: ['', Validators.required]
    // });
    // this.secondFormGroup = this._formBuilder.group({
    //   secondCtrl: ['', Validators.required]
    // });
  }

}
