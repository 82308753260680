<div id="top-nav">

  <div id="logo">
    <img id="mh-logo" src="../../../assets/img/MH_Logo-Color.png" alt="Macro Helix" />
  </div>

  <div id="pid-selector-wrap">
    <app-pidselector *ngIf="(applications$ | async).Selected && (applications$ | async).Selected.pharmacySelectorVisible" ></app-pidselector>
  </div>

  <div id="csgid-selector-wrap">
    <app-csgid-selector *ngIf="(applications$ | async).Selected && (applications$ | async).Selected.appID==7"></app-csgid-selector>
  </div>
  
  <div  *ngIf="(errVisible$ | async)" class="err-display-container">
    <mat-card class="err-display">
      {{ errMessage$ | async }}
      <button mat-button (click)="dismissError()" class="right-close"><mat-icon aria-hidden="false" aria-label="Close">close</mat-icon></button>
    </mat-card>
  </div>

  <div id="col-right">
   <!-- <button mat-flat-button color="primary" class="btn-txt" (click)="signOut()"> Log Out</button>
-->



    <!-- Tri demo git commit and push changes -->

  <button mat-flat-button class="btn-txt" (click)="signOut()"> Log Out</button>

</div>
