import {createAction, props} from '@ngrx/store';
import {PbiEmbedConfig, PBIDashboard, PBIReport, PBITile, ReportPage} from '../models/analytics';

export enum AnalyticsActionTypes {

  REPORTS_GET = '[Rest Call] [Analytics] ReportsGet',
  REPORTS_RES = '[Rest Result] [Analytics] ReportsRes',
  REPORTS_ERR = '[Rest Error] [Analytics] ReportsErr',

  REPORT_PAGES_GET = '[Analytics] ReportPagesGet',
  REPORT_PAGES_RES = '[Analytics] ReportPagesRes',
  REPORT_PAGES_ERR = '[Analytics] ReportPagesErr',

  DASHBOARDS_GET = '[Rest Call] [Analytics] DashbardsGet',
  DASHBOARDS_RES = '[Rest Result] [Analytics] DashbardsRes',
  DASHBOARDS_ERR = '[Rest Error] [Analytics] DashbardsErr',

  TILES_GET = '[Rest Call] [Analytics] TilesGet',
  TILES_RES = '[Rest Result] [Analytics] TilesRes',
  TILES_ERR = '[Rest Error] [Analytics] TilesErr',

  REPORT_EMBED = '[Rest Call] [Analytics] ReportEmbed',
  REPORT_EMBED_RES = '[Rest Result] [Analytics] ReportEmbedResult',
  REPORT_EMBED_ERR= '[Silent Rest Error] [Analytics] ReportEmbedError',
  REPORT_EMBED_SILENT = '[Silent Rest Call] [Analytics] ReportEmbedSilent',
  REPORT_EMBED_SILENT_RES = '[Silent Rest Result] [Analytics] ReportEmbedSilentResult',

  DASHBOARD_EMBED = '[Rest Call] [Analytics] DashboardEmbed',
  DASHBOARD_EMBED_RES = '[Rest Result] [Analytics] DashboardEmbedResult',
  DASHBOARD_EMBED_ERR = '[Rest Error] [Analytics] DashboardEmbedError',

  TILE_EMBED = '[Rest Call] [Analytics] TileEmbed',
  TILE_EMBED_RES = '[Rest Result] [Analytics] TileEmbedResult',
  TILE_EMBED_ERR = '[Rest Error] [Analytics] TileEmbedError',

  CHANGE_REPORT_PAGE = '[Analytics] ChangeReportPage',
  CHANGE_REPORT = '[Analytics] ChangeReport',

  CLEAR_REPORTS = '[Analytics] ClearReports'

}


export const GetReports = createAction(AnalyticsActionTypes.REPORTS_GET);
export const GetReportsError = createAction(AnalyticsActionTypes.REPORTS_ERR, props<{errorMessage: string}>());
export const GetReportsResults = createAction(AnalyticsActionTypes.REPORTS_RES, props<{data: PBIReport[]}>());

export const GetReportPages = createAction(AnalyticsActionTypes.REPORT_PAGES_GET, props<{id: string, pid: number}>());
export const GetReportPagesError = createAction(AnalyticsActionTypes.REPORT_PAGES_ERR, props<{errorMessage: string}>());
export const GetReportPagesResults = createAction(AnalyticsActionTypes.REPORT_PAGES_RES, props<{data: ReportPage[]}>());

export const GetDashboards = createAction(AnalyticsActionTypes.DASHBOARDS_GET);
export const GetDashboardsError = createAction(AnalyticsActionTypes.DASHBOARDS_ERR, props<{errorMessage: string}>());
export const GetDashboardsResults = createAction(AnalyticsActionTypes.DASHBOARDS_RES, props<{data: PBIDashboard[]}>());

export const GetTiles = createAction(AnalyticsActionTypes.TILES_GET);
export const GetTilesError = createAction(AnalyticsActionTypes.TILES_ERR, props<{errorMessage: string}>());
export const GetTilesResults = createAction(AnalyticsActionTypes.TILES_RES, props<{data: PBITile[]}>());

export const EmbedReport = createAction(AnalyticsActionTypes.REPORT_EMBED, props<{id: string, pid: number}>());
export const EmbedReportResult = createAction(AnalyticsActionTypes.REPORT_EMBED_RES, props<{data: PbiEmbedConfig[]}>());
export const EmbedReportError = createAction(AnalyticsActionTypes.REPORT_EMBED_ERR, props<{errorMessage: string}>());
export const EmbedReportSilent = createAction(AnalyticsActionTypes.REPORT_EMBED_SILENT, props<{id: string, pid: number}>());
export const EmbedReportSilentResult = createAction(AnalyticsActionTypes.REPORT_EMBED_SILENT_RES, props<{data: PbiEmbedConfig[]}>());

export const EmbedDashboard = createAction(AnalyticsActionTypes.DASHBOARD_EMBED, props<{id: string}>());
export const EmbedDashboardResult = createAction(AnalyticsActionTypes.DASHBOARD_EMBED_RES, props<{data: PbiEmbedConfig}>());
export const EmbedDashboardError = createAction(AnalyticsActionTypes.DASHBOARD_EMBED_ERR, props<{errorMessage: string}>());

export const EmbedTile = createAction(AnalyticsActionTypes.TILE_EMBED, props<{dashid: string, id: string}>());
export const EmbedTileResult = createAction(AnalyticsActionTypes.TILE_EMBED_RES, props<{data: PbiEmbedConfig}>());
export const EmbedTileError = createAction(AnalyticsActionTypes.TILE_EMBED_ERR, props<{errorMessage: string}>());

export const ChangeReportPage = createAction(AnalyticsActionTypes.CHANGE_REPORT_PAGE, props<{pageName: string}>());
export const ChangeReport = createAction(AnalyticsActionTypes.CHANGE_REPORT, props<{config: PbiEmbedConfig}>());

export const ClearReports = createAction(AnalyticsActionTypes.CLEAR_REPORTS);