<form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()" class="forgot-pwd-form">
    <div *ngIf="!submitted">
        <p>Enter the email address linked to your account below. We'll send you a link to reset your password.</p>
        <mat-form-field appearance="fill">
            <mat-label>Email</mat-label>
            <input matInput placeholder="pat@example.com" formControlName="forgotEmail" [pattern]="emailPattern" required>
        </mat-form-field>
        <div>
            <button class="forgot-pwd-button" mat-stroked-button type="submit" [disabled]="!forgotPasswordForm.controls.forgotEmail.valid || submitted">
                <span class="spinner-border spinner-border-sm mr-1"></span>
                Email Reset Link
            </button>
        </div>
        <p class="error-message">{{errorMessage}}</p>
    </div>
    <div *ngIf="submitted" >
        <p class="submit-message">{{submitMessage}}</p>
        <p>The reset link will expire in 1 hour.</p>
        <p>Check your spam folder for an email coming from support@macrohelix.com.</p>
    </div>
</form>
