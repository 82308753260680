
import { Component, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-batch-bin-exclusion',
  templateUrl: './batch-bin-exclusion.component.html',
  styleUrls: ['./batch-bin-exclusion.component.scss']
})


export class BatchBinExclusionComponent implements OnInit {

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    
  }
  public childName = "bulkBinUpdateRules";

  public steps = [{ name: 'Specify Bins to exclude', }, { name: 'Select PIDs to apply excluded BINs' }, { name: 'Review' }];

  ngOnInit() {

  }

}
