import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap } from "rxjs/operators";
import { HospitalListService } from "src/app/service/hid-pharmacy/hospital-list.service";
import { GetHospitalList, GetHospitalListError, GetHospitalListResults } from "./hospital-list.actions";
import { of as observableOf } from 'rxjs';



@Injectable()
export class hospitalStoreEffects {

    constructor(private hospitalListSerivce: HospitalListService, private actions$: Actions ){

    }


    GetHospitalListEffect$ = createEffect( () => this.actions$.pipe(
        ofType(GetHospitalList),
        switchMap(action => 
            this.hospitalListSerivce.getHospitalList(action.Active)
            .pipe(
                map(
                    data => {
                        return GetHospitalListResults( {hospitalList: data} ) 
                    }
                ),
                catchError(
                    error => observableOf(GetHospitalListError({message: error}))
                )
            )
        )
    ));



}