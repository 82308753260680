import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef,MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-cancel-button',
  templateUrl: './cancel-button.component.html',
  styleUrls: ['./cancel-button.component.scss']
})
export class CancelButtonComponent implements OnInit {

  constructor(private fb: FormBuilder,
    private dialog: MatDialog,
                  private dialogRef: MatDialogRef<CancelButtonComponent>) { }

  ngOnInit(): void {
  }

  public cancel(): void { // To cancel the dialog window
    this.dialogRef.close();
    }

  public cancelN(): void {
      this.dialog.closeAll();
  }
}
