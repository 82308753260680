import { catchError, map, switchMap } from "rxjs/operators";
import { Injectable } from '@angular/core';
import { act, Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { healthSystemViewService } from "src/app/service/health-system-view.service";
import { GetCorporateParentTreeList, GetCorporateParentTreeListError, GetCorporateParentTreeListResults, UpdateCorporateParentValidationStatus, UpdateCorporateParentValidationStatusError, UpdateCorporateParentValidationStatusResults } from "./health-system-view.actions";

@Injectable()
export class HealthSystemViewStoreEffects {

  constructor(private healthSystemService: healthSystemViewService, private actions$: Actions) {
  }

  getCorporateParentTree$ = createEffect(() => this.actions$.pipe(
    ofType(GetCorporateParentTreeList),
    switchMap(action =>
      this.healthSystemService.getCorporateParentTreeview(action.corpId)
        .pipe(
          map(
            data => {
              return GetCorporateParentTreeListResults({ treeview: data })
            }

          ),
          catchError(
            error => observableOf(GetCorporateParentTreeListError({ message: error }))
          )

        )
    )
  ));
  updateCorporateParentValidationStatus$ = createEffect(() => this.actions$.pipe(
    ofType(UpdateCorporateParentValidationStatus),
    switchMap(action =>
      this.healthSystemService.UpdateCorporateParentValidationStatus()
        .pipe(
          map(
            data => {
              return UpdateCorporateParentValidationStatusResults({ result: data })
            }

          ),
          catchError(
            error => observableOf(UpdateCorporateParentValidationStatusError({ message: error }))
          )

        )
    )
  ));
}