<div [ngClass]="browserName === 'Chrome'?'style-for-chrome':'style-for-ie'">
  
  <!--- Notification Bell -->
  <div id="notifications" *ngIf="(applications$ | async).Selected.appID !== 5" (click)="$event.stopPropagation()">
    <button mat-icon-button (click)="bellButtonClicked()" class="notifications-button">
      <mat-icon matBadge="{{notificationLength}}" *ngIf="notificationLength > 0 ;else  matBadgeHidden"> notifications
      </mat-icon>
      <ng-template #matBadgeHidden>
        <mat-icon class="icon" style="color:rgba(226, 11, 0, 0.5);">notifications_off</mat-icon>
      </ng-template>
    </button>
    <div *ngIf="!notificationCollapsed" class="notification-menu-panel">
      <div class="notification-header">
        <p>Notifications
          <button mat-button (click)="historyPage()" class="history-data-button"> View Notification History </button>
          <button mat-button (click)="markAsRead()" class="mark-as-read"> Mark ALL Read </button>
        </p>
      </div>
      <div *ngFor="let notification of notificationList; let i = index">
        <mat-card class="notification-cards">
          <mat-card-header>
            <mat-card-title>
              <p> <button mat-button (click)="markSelectedRead(notification.noteSentID)" class="x-out"> X </button> </p>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p class="notification-title"> {{notification.notificationAlertType}} </p>
            <p class="notification-data" [innerHtml]="notification.notification | linkify"></p>
            <p class="notification-posted"> {{notification.posted | date:'MMMM dd'}}</p>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>

  <button mat-icon-button (click)="recalculate()" #tooltip="matTooltip" matTooltip="Request Recalculation"
    [matTooltipPosition]="position.value" matTooltipHideDelay="100"
    *ngIf="(applications$ | async).Selected.appID !== 5 && (applications$ | async).Selected && (applications$ | async).Selected.recalculateVisible">
    <mat-icon class="recalculation-button" svgIcon="recalculateimg"></mat-icon>
  </button>

  <button mat-icon-button (click)="openGeneralSettingsDialog()" #tooltip="matTooltip" matTooltip="Settings"
    [matTooltipPosition]="position.value" matTooltipHideDelay="100"
    *ngIf="(applications$ | async).Selected.appID !== 5 && (applications$ | async).Selected.appID !== 8">
    <mat-icon>settings</mat-icon>
  </button>

  <mh-menu-group>
    <!-- *ngIf="(applications$ | async).Selected.appID !== 5"-->
    <button mat-icon-button [matMenuTriggerFor]="helpBtn" #tooltip="matTooltip" matTooltip="Help Resources"
      [matTooltipPosition]="position.value" matTooltipHideDelay="100">
      <mat-icon>help</mat-icon>
    </button>

    <mat-menu #helpBtn="matMenu">
      <button *ngIf="(applications$ | async).Selected.appID !== 5" mat-menu-item
        (click)="openSubmitSalesForceTicketDialog()">Submit Ticket</button>
      <button (click)="openHelpGuideInNewWindow()" mat-menu-item>Helix Helper</button>
      <button *ngIf="(applications$ | async).Selected.appID !== 5" mat-menu-item
        (click)="openAboutDialog()">About</button>
    </mat-menu>

    <button *ngIf="(applications$ | async).Selected.appID !== 5 && (applications$ | async).Selected.appID !== 8"
      mat-icon-button (click)="openViewProfile()" #tooltip="matTooltip" matTooltip="User Profile"
      [matTooltipPosition]="position.value" matTooltipHideDelay="100">
      <mat-icon>account_circle</mat-icon>
    </button>
  </mh-menu-group>
</div>