<h1 class=" mat-dialog-title">{{data?.title}}
</h1>
<mat-toolbar-row class="actionBar">
    <span *ngIf="errorFlag == 0">
        <button mat-stroked-button type="button" (click)="validateFile()" class="group-btn">Upload File</button>
        <span class="vl"></span>
    </span>
    &nbsp;
    <div style="float: right;" *ngIf="errorFlag != 2">
        <button mat-stroked-button type="button" [mat-dialog-close]="true" class="group-btn">Close</button>
        <span class="vl"></span>
    </div>
    <div style="float: right;" *ngIf="errorFlag == 2">
        <a [href]="data?.downloadTemplate"> <button mat-stroked-button type="button" class="group-btn"
                [mat-dialog-close]="true">Download Template </button></a>
        <span class="vl"></span>
    </div>
</mat-toolbar-row>

<div style="margin-left: 10px;" mat-dialog-content cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div *ngIf="errorFlag == 0">
        <div class="font16" style="margin-top: 8px;"><b>Date Format:</b> </div>
        <div class="font14" style="margin-top: 8px;">MM/DD/YYYY</div>
        <br>
        <div class="font16" style="margin-top: 8px;"><b>File Format:</b> </div>
        <div class="font14" style="margin-top: 8px;">CSV</div>
        <br>
        <div class="font16" style="margin-top: 8px;"><b>Import File:</b> </div>
        <input type="text" [value]="file?.name" class="font14">
        <button (click)="uploadFile.click()" class="btn">Browse</button>
    </div>
    <div class="message-text" *ngIf="errorFlag == 1">
        <mat-label [innerHTML]="data?.errorMessage1"> </mat-label>
    </div>
    <div class="message-text" *ngIf="errorFlag == 2">
        <mat-label [innerHTML]="data?.errorMessage2"> </mat-label>
    </div>
</div>
<input hidden type="file" (change)="onChange($event)" #uploadFile />