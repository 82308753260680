import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, map } from 'rxjs/operators';
import { HospitalOutputDTO } from 'src/app/models/hidpharmacy/hospital-list/hospital-output-dto';
import { UserSession } from 'src/app/models/user';
import { UserState } from 'src/app/store/user.reducer';
import { environment } from 'src/environments/environment';
import { BaseMhService } from '../basemh.service';

@Injectable({
  providedIn: 'root'
})
export class HospitalListService extends BaseMhService{

  constructor(protected http: HttpClient, protected store: Store<{ userState: UserState }>) { 
    super(http, store);
  }
 
  public getHospitalList(Active: number){
    const session: UserSession = this.getStoreUserSession();
    return this.http
    .get(environment.apiBaseUrl + 'v1/Hospital/GetHospitalList/?Active=' + Active, { headers: this.getHeaders() })
    .pipe(
      map((response: HospitalOutputDTO) => response),
      catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Hospital List Data" }))
    );
  }
}