<div *ngIf="!(this.showClientPage || this.showAccessDenied)" style="height: 100%;"> 
  <app-top-nav *ngIf="appInitialized && !this.hasMessage"></app-top-nav>
  <horizontal-menu *ngIf="appInitialized && !this.hasMessage"></horizontal-menu>
    <div style="width: 100%;" *ngIf="appInitialized && !this.hasMessage" [style.height.%]=setAppHeight()>
      <left-menu></left-menu>
    </div>
</div>

<timeout-popup *ngIf="(appInitialized || !clientIdCookie) && !this.hasMessage"></timeout-popup>
<auth-token-refresher style="display: none;"></auth-token-refresher>
<div *ngIf="!(this.showClientPage || this.showAccessDenied)">
  <div id="loadingdiv" *ngIf="(!appInitialized && clientIdCookie) && !this.hasMessage" style="width:100%; height: 100%; text-align:center">
    <h1 style="position: relative; top: 50%; color: darkgrey">Loading...</h1>
  </div>
  <div id="overlay" *ngIf="(callInProgress || (!appInitialized && clientIdCookie)) && !this.hasMessage">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</div>


<div *ngIf="(this.showAccessDenied || this.showClientPage) && !this.hasMessage">
  <blank-page [showAccessDenied]="this.showAccessDenied" [showClientPage]="this.showClientPage"> </blank-page>
</div>

<!-- b2c login message -->
<div id="messageContainer" *ngIf="this.hasMessage">
  <img id="background_background_image" style="width: 100%; height: auto;" src="../assets/img/Pharmacy.svg"
       alt="Illustration" />
  <div class="messagecontainer-overlay"></div>
  <div class="message-container">
    <div id="message-data" style="display: block;">
      <div class="message-text" style="padding: 20px;;">{{this.b2cMessage}}</div>
      <mat-card-actions style="margin-left: 0px;">
        <button mat-button type="button" class="message-button" (click)="forceLogin(2)">{{this.msgButtonLabel}}</button>
      </mat-card-actions>
    </div>
  </div>
</div>

<!-- Password Expiry message -->
<div id="messageContainer" *ngIf="this.hasPwdMessage">
  <img id="background_background_image" style="width: 100%; height: auto;" src="../assets/img/Pharmacy.svg"
       alt="Illustration" />
  <div class="messagecontainer-overlay"></div>
  <div class="message-container">
    <div id="message-data" style="display: block;">
      <div class="message-text" style="padding: 20px;;">{{this.pwdMessage}}</div>
      <mat-card-actions style="margin-left: 0px;">
        <button mat-button type="button" class="message-button" (click)="closeMsg()">Ok</button>
      </mat-card-actions>
    </div>
  </div>
</div>


<!-- 340B Validate Message -->
<div id="messageContainer" *ngIf="this.hasValidateMessage">
  <img id="background_background_image" style="width: 100%; height: auto;" src="../assets/img/Pharmacy.svg"
       alt="Illustration" />
  <div class="messagecontainer-overlay"></div>
  <div class="message-container">
    <div id="message-data" style="display: block;">
      <div class="message-text" style="padding: 20px;">Introducing 340B Validate<sup>SM</sup> - A cooperative solution between Covered Entities and Manufacturers created to preserve the 340B Program while protecting our Covered Entities & patient data.</div>
      <mat-card-actions style="margin-left: 0px;">
        <button mat-button type="button" class="message-button" (click)="NotInterested()">Not Interested</button> &nbsp;
        <button mat-button type="button" class="message-button" (click)="RemindinWeek()">Remind Me in 1 Week</button>&nbsp;
        <button mat-button type="button" class="message-button" (click)="OpenHelpJuice()">Tell Me More</button>
      </mat-card-actions>
    </div>
  </div>
</div>
