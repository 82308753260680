import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { BranchCodeListUploadErrorReponseOutputDto, BranchCodeOutputDto } from "../models/branchcode-list/branchcode-output-dto";
import { HosiptalBranchCodeByIdDto } from "../models/branchcode-list/hosiptal-branch-code-dto";
import { HosiptalBranchCodeIdOutputDto, HosiptalBranchCodeByIdOutputDto, BranchCodeListUploadOutputDto } from "../models/branchcode-list/hosiptal-branch-output-dto";
import { PrescriberListUploadOutputDto } from "../models/prescriber-list/prescriber-list-upload-output";
import { SubmitResponse } from "../models/submit-response";
import { UserSession } from "../models/user";
import { UserState } from "../store/user.reducer";
import { BaseMhService } from "./basemh.service";

@Injectable({
  providedIn: 'root'
})

export class BranchCodeService extends BaseMhService {

  constructor(protected http: HttpClient, protected store: Store<{ userState: UserState }>) {
    super(http, store);
  }


  public getBranchCodeList() {
    const session: UserSession = this.getStoreUserSession();
    return this.http
      .get(environment.apiBaseUrl + `v1/Hospital/GetBranchCodesFromList?HID=${session.hid}`, { headers: this.getHeaders() })
      .pipe(
        map((response: BranchCodeOutputDto) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Branch Code List Data" }))
      );
  }

  public getBranchCodeToList(id: number, branchCodeType: string) {
    const session: UserSession = this.getStoreUserSession();
    return this.http
      .get(environment.apiBaseUrl + 'v1/Hospital/GetBranchCodesToList?ID=' + id + '&&BranchCodeType=' + branchCodeType, { headers: this.getHeaders() })
      .pipe(
        map((response: BranchCodeOutputDto) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Branch Codes to List Data" }))
      );
  }


  public getHospitalOrPharmacyNameList(Id: number, type: string) {
    const session: UserSession = this.getStoreUserSession();
    return this.http
      .get(environment.apiBaseUrl + 'v1/Hospital/GetBranchCodesIdList?ID=' + Id + '&BranchCodeType=' + type, { headers: this.getHeaders() })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving branch code List Data" }))
      );
  }

  public getHospitalOrPharmacyBranchCodeList(Id: number, type: string, skip?: any, take?: any) {
    const session: UserSession = this.getStoreUserSession();
    const url = `v1/Hospital/GetBranchCodesPaginationById?ID=${Id}&BranchCodeType=${type}&Skip=${skip}&Take=${take}`;
    return this.http
      .get(environment.apiBaseUrl + url,
        { headers: this.getHeaders() })
      .pipe(
        map((response: HosiptalBranchCodeByIdOutputDto) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving branch code pagination List by Id" }))
      );
  }

  public getHospitalOrPharmacyBranchCodeSearchList(Id: number, type: string, skip?: any, take?: any, searchText?: string) {
    const session: UserSession = this.getStoreUserSession();
    const url = `v1/Hospital/GetBranchCodesPaginationSearch?ID=${Id}&BranchCodeType=${type}&Skip=${skip}&Take=${take}&searchText=${searchText}`;
    return this.http
      .get(environment.apiBaseUrl + url,
        { headers: this.getHeaders() })
      .pipe(
        map((response: HosiptalBranchCodeByIdOutputDto) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving branch code List by Pagination Search" }))
      );
  }

  public addUpdateBranchCode(newBranchCodeById: HosiptalBranchCodeByIdDto) {
    const session: UserSession = this.getStoreUserSession();
    return this.http
      .post(environment.apiBaseUrl + 'v1/Hospital/AddUpdateBranchCode', newBranchCodeById, { headers: this.getHeaders() })
      .pipe(
        map((response: any) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while adding new branch code data." }))
      );
  }

  public exportData(Id: number, type: string) {
    const session: UserSession = this.getStoreUserSession();
    const url = `v1/Hospital/ExportBranchCodeData?ID=${Id}&BranchCodeType=${type}`;
    return this.http
      .get(environment.apiBaseUrl + url,
        { headers: this.getHeaders(), responseType: 'blob' })
      .pipe(
        map((response: any) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving branch code pagination List by Id" }))
      );
  }

 public getBranchCodeListUploadDetails() {
    const session: UserSession = this.getStoreUserSession();
    return this.http
      .get(environment.apiBaseUrl + 'v1/branchCodeFileUpload/getbranchcodeuploaddetails?HID=' + session.hid, { headers: this.getHeaders() })
      .pipe(
        map((response: BranchCodeListUploadOutputDto) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving BranchCode List Upload Data" }))
      );
  }

  public uploadBranchCodeList(branchcodeFile: FormData) {
    const session: UserSession = this.getStoreUserSession();
    //Take content type out of header
    var formHeaders = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Ocp-Apim-Subscription-Key': environment.subcriptionKey,
      Authorization: 'Bearer ' + this.getUserToken(),
    });
    return this.http
      .post(environment.apiBaseUrl + `v1/branchCodeFileUpload/upload?HID=` + session.hid + '&PID=' + session.pid, branchcodeFile, { headers: formHeaders })
      .pipe(
        map((response: SubmitResponse) => {
          if (response.status === '200')
            return response;
          else
            throwError(response.message);
        }),
        catchError(error => this.errorHandler({ error, operation: "Error while uploading branchcode file" }))
      );
  }

  public getBranchCodeListUploadErrorResponse(importId: String) {
    const session: UserSession = this.getStoreUserSession();
    return this.http
      .get(environment.apiBaseUrl + 'v1/branchCodeFileUpload/getBranchCodesUploadErrorResponse?HID=' + session.hid +'&importID=' + importId, { headers: this.getHeaders() })
      .pipe(
        map((response: BranchCodeListUploadErrorReponseOutputDto) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving BranchCode List Upload Error Data" }))
      );
  }

  public getBranchCodesPharmacyListResponse(importFileType: String, PID: number) {
    const session: UserSession = this.getStoreUserSession();
    return this.http
      .get(environment.apiBaseUrl + 'v1/Hospital/PharmacyBranchCodesByID?importFileType=' + importFileType +'&UID=' + session.uid +'&CorpID=' + session.corpID +'&PID=' + PID, { headers: this.getHeaders() })
      .pipe(
        map((response: any) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving BranchCode Pharmacy List Data" }))
      );
  }
}
