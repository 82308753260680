import { HIdColumnDto } from "src/app/models/hId-list/hid-column-dto"
import { HIdOutputDto } from "src/app/models/hId-list/hId-output-dto"
import { HIdActionTypes } from "./hid-list.actions"

export interface HIdState {
  hIdList: HIdOutputDto,
  columns: HIdColumnDto[],
}

export const initialState =
{
  hIdList: null,
  columns: null,
}

export function hIdListReducer(state = initialState, action: any): HIdState {
  switch (action.type) {
    case HIdActionTypes.GET_HID_LIST_RES: {
      return { ...state, hIdList: action.hIdList }
    }

    case HIdActionTypes.SET_HID_LIST_COLUMNS: {
      console.log( action.columns )
      return { ...state, columns: action.columns }
    }
    
    default:{
      return state;
    }
  }
}