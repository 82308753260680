import { VERSION } from "./version";

export const environment = {
  MacroHelixSupportUrl: 'https://help.mhiapps.com/',
  production: true,
  appVersion: VERSION.version + "." + VERSION.raw + "-stage",
  apiBaseUrl: 'https://stagingapi.mhiapps.com/',
  baseUrl: 'https://stagingapp.mhiapps.com',
  loginUrl: 'https://staginglogin.mhiapps.com/',
  ssoLoginUrl: 'https://fedsvc.mckesson.com/adfs/ls/',
  subcriptionKey: '493d7b0da57148b08ad8f1cddca2ab62',
  UAMUserApiEndpoint: "user/",
  appInsights: {
    instrumentationKey: '5d542ea3-515d-402e-bfa8-a20aa71594c8',
    applicationName: 'MHIApps'
  },
  b2c: {
    TenantName: "mhiappsb2cst",
    SignInUrl: "https://mhiappsb2cst.b2clogin.com/mhiappsb2cst.onmicrosoft.com/b2c_1a_mhiappsst_emailonlysignin/oauth2/v2.0/authorize",
    ClientId: "304a7a77-d82e-4e7b-9fa7-20f4db11e329",
    RedirectUrl: "https://stagingweb.mhiapps.com/",
    LogoutUrl: "https://staginglogin.mhiapps.com/",
    SignInPolicy: "B2C_1A_mhiappsst_EmailOnlySignIn",
    OldSignInPolicy: "B2C_1A_mhiappsst_JITMigrationSignIn",
    ResetPasswordPolicy: "B2C_1A_mhiappsst_PasswordReset",
    LoggedInPasswordResetPolicyId: "B2C_1A_mhiappsst_PasswordChange",
    UserChangeMFAPolicyId: "B2C_1A_mhiappsst_MFAChange",
    UserApiEndpoint: "user/v1/login",
    SignUpPolicy: "B2C_1A_mhiappsst_OnboardingSignUp",
    ConnectSignInPolicy: "B2C_1A_mhiappsst_ConnectSignIn"
  },
  analyticsReportNames: '340BI Reporting,340BI Optimization,340BI Account Table',
  embeddingPageUrl: 'https://stagingapp.mhiapps.com/EmbeddingPage.aspx'
};
