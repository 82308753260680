import { createAction, props } from '@ngrx/store';
import { Application, ApplicationMenuItem } from '../models/applications';


export enum AppListActionTypes {

  SELECTED = '[Rest Call] [Application] Selected',
  SELECTED_RES = '[Rest Result] [Application] SelectedResults',

  GET_APPLICATIONS = '[Silent Rest Call] [Application] GetList',
  GET_APPLICATIONS_RES = '[Silent Rest Result] [Application] GetListResult',

  PAGE_SELECTED = '[Page] Selected',

  GET_BACKUP_PAGE = '[Rest Call] [User] GetBackupPage',
  GET_BACKUP_PAGE_RES = '[Rest Result] [User] GetBackupPageResults',
  GET_BACKUP_PAGE_ERR = '[Rest Error] [User] GetBackupPageError'
}

export const PageSelected = createAction(AppListActionTypes.PAGE_SELECTED, props<{ page: ApplicationMenuItem }>());

export const AppSelected = createAction(AppListActionTypes.SELECTED, props<{ app: Application }>());
export const AppSelectedResults = createAction(AppListActionTypes.SELECTED_RES, props<{ applicationPageList: ApplicationMenuItem[] }>());

export const GetApplications = createAction(AppListActionTypes.GET_APPLICATIONS);
export const GetApplicationsResults = createAction(AppListActionTypes.GET_APPLICATIONS_RES, props<{ applications: Application[] }>());

export const GetBackupPage = createAction(AppListActionTypes.GET_BACKUP_PAGE, props<{ appId: number, pageId: number }>());
export const GetBackupPageResults = createAction(AppListActionTypes.GET_BACKUP_PAGE_RES, props<{ page: ApplicationMenuItem }>());
export const GetBackupPageError = createAction(AppListActionTypes.GET_BACKUP_PAGE_ERR, props<{ message: string }>());
