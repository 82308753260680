import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { UserEmailSettings } from 'src/app/models/user';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/store/user.reducer';
import { Actions, ofType } from '@ngrx/effects';
import { Observable, Subscription } from 'rxjs';
import { InfoShow } from 'src/app/store/ui-state.actions';
import { UpdateUserEmailSettings, UpdateUserEmailSettingsResults, UpdateUserEmailSettingsError } from 'src/app/store/user.actions';


@Component({
  selector: 'app-email-settings',
  templateUrl: './email-settings.component.html',
  styleUrls: ['./email-settings.component.scss']
})
export class EmailSettingsComponent implements OnInit, OnDestroy {
  public userStore$: Observable<UserState>;
  checkedAll = false;
  saveUserEmailSettings: UserEmailSettings = new UserEmailSettings();
  subs: Subscription = new Subscription;

  constructor(private dialog: MatDialog,
              private dialogRef: MatDialogRef<EmailSettingsComponent>,
              private store: Store<{ userState: UserState }>,
              private actions$: Actions){}

  ngOnInit(): void {
    this.userStore$ = this.store.select(state => state.userState);

    this.subs.add(
      this.userStore$.subscribe((val) => {
        this.saveUserEmailSettings = val.userEmailSettings;
        this.isAllChecked();
      })
    )

    this.subs.add(
      this.actions$.pipe( ofType( UpdateUserEmailSettingsResults ) ).subscribe( (data) => {
        this.store.dispatch(InfoShow({ message: 'User Email Settings is updated!'}));
      })
    )

    this.subs.add(
      this.actions$.pipe(ofType( UpdateUserEmailSettingsError )).subscribe((data) => {
        alert(data.message);
      // this.store.dispatch( ErrShow({ message: data.message}) );
      })
    )
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }

  onSaveClick() {
    this.store.dispatch( UpdateUserEmailSettings( { saveData: this.saveUserEmailSettings} ) );
    this.dialogRef.close();
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  formChanged(){
  }

  isAllChecked() {
    if (this.saveUserEmailSettings.email850MyOrders && this.saveUserEmailSettings.email850AutoOrders &&
        this.saveUserEmailSettings.email850FileDropOrders && this.saveUserEmailSettings.email850OrderBuilder) {
      this.checkedAll = true;
    } else {
      this.checkedAll = false;
    }
  }

  checkAll() {
    this.saveUserEmailSettings.email850MyOrders =
    this.saveUserEmailSettings.email850AutoOrders =
    this.saveUserEmailSettings.email850OrderBuilder =
    this.saveUserEmailSettings.email850FileDropOrders = this.checkedAll;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
