<form #updateUserNotificationsForm="ngForm">
  <div class="vendor-label">
    <mat-toolbar>
      <mat-toolbar-row class="tab-label">
        <span>My Notifications</span>
      </mat-toolbar-row>
      <mat-toolbar-row class='actionBar' style="font-size: 14pt;">
        <div style="border-right: 1px solid white;margin-top: 5px; margin-bottom: 5px;
        line-height: 22px; padding-right: 8px; ">
          <button class="save-profile-btn" mat-button
          (click)="SaveProfileClick()">
          Save Profile
          </button>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>
  <div class="info-display">
    <table class="user-notification">
      <tr>
        <th>Notification</th>
      </tr>
      <tr *ngFor="let publication of usersPublicationSelectionList; let i = index">
        <td>
          <mat-checkbox
          class="notification-checkbox"
          [checked]="isChecked(publication)"
          (change)="onChange(publication)">
            {{publication.name}}
          </mat-checkbox>
          <span *ngIf="publication.publicationID === 60 || publication.publicationID === 61">
            &nbsp; &nbsp; &nbsp; &nbsp;
          </span>
          
          <mat-form-field *ngIf="publication.publicationID === 60">
            <mat-label>Options</mat-label>
            <mat-select [formControl]="orderSubmittedToWholeSalerControl" multiple>
              <mat-option *ngFor="let option of orderSubmittedToWholeSalerOptions" [value]="option">{{option}}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr>
    </table>
  </div>
</form>

