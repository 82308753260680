import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})

export class ConfirmDialogComponent implements OnInit {
  title: string;
  /* If multiple lines are needed in the message use /n in your message string for it.  The css 'white-space: pre-line;' will convert it to a new line. */
  message: string;
  confirmValue: string = "Confirm";
  cancelValue: string = "Cancel";
  isUAMComponent: boolean = false;
  

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }


  ngOnInit() {

    /* Check to see if the buttons were passed custom text.  Use defaults if not*/
    if(this.data.confirmValue) {this.confirmValue = this.data.confirmValue};
    if(this.data.cancelValue) {this.cancelValue = this.data.cancelValue};
    if(this.data.isUAMComponent) {this.isUAMComponent = this.data.isUAMComponent};


  }
}

