   <!-- USER => EMAIL SETTINGS -->
   <div class="email-settings-dialog">
    <div mat-dialog-title class="dialog-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
      <h3 cdkDragHandle>Email Settings</h3>
    </div>
    <div>
      <form #updateEmailSettingsForm="ngForm" (change)="formChanged()">
        <div class="dialog-body">
          <div class="order-submitted-to-wholesaler">
            <mat-label>Order Submitted to Wholesaler: </mat-label>
              <mat-checkbox
                class="checkbox-margin"
                name="email850MyOrders"
                #email850MyOrders="ngModel"
                [(ngModel)]="saveUserEmailSettings.email850MyOrders"
                (change)="isAllChecked()">
                  My Orders Only
              </mat-checkbox><br />

              <mat-checkbox
                class="checkbox-margin"
                name="email850AutoOrders"
                #email850AutoOrders="ngModel"
                [(ngModel)]="saveUserEmailSettings.email850AutoOrders"
                (change)="isAllChecked()">
                  Auto Orders
              </mat-checkbox><br />

              <mat-checkbox
                class="checkbox-margin"
                name="email850OrderBuilder"
                #email850OrderBuilder="ngModel"
                [(ngModel)]="saveUserEmailSettings.email850OrderBuilder"
                (change)="isAllChecked()">
                  Order Builder Orders
              </mat-checkbox><br />

              <mat-checkbox
                class="checkbox-margin"
                name="email850FileDropOrders"
                #email850FileDropOrders="ngModel"
                [(ngModel)]="saveUserEmailSettings.email850FileDropOrders"
                (change)="isAllChecked()">
                  Submitted File Orders
              </mat-checkbox><br />

              <mat-checkbox class="checkbox-margin"
                (checked)="checkedAll"
                (change)="checkAll()"
                [(ngModel)]="checkedAll"
                name="checkedAll">
                  Select All
              </mat-checkbox><br />

          </div>
          <br />

          <div class="wholesaler-confirmation-received">
            <mat-label>Wholesaler Confirmation Received: </mat-label>
            <mat-radio-group name="email855Confirmation"
            [(ngModel)]="saveUserEmailSettings.email855Confirmation"
            (checked)="saveUserEmailSettings.email855Confirmation">
              <mat-radio-button class="checkbox-margin" [value]="false">Do Not Send Email</mat-radio-button><br/>
              <mat-radio-button class="checkbox-margin" [value]="true">Send Email</mat-radio-button>
            </mat-radio-group>
          </div>

        </div>
        <div class="dialog-footer">
            <button mat-flat-button type="submit" color="primary" class="save-btn" (click)="onSaveClick()">Save</button>
            <button mat-stroked-button type="button" class="cancel-btn" (click)="onCancelClick()">Cancel</button>
        </div>
      </form>
    </div>
  </div>
