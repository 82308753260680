import { EducationActionTypes } from './education.actions';
import { Holiday } from '../models/education';

export interface EducationState {
  holidays: Holiday[],
}

export const initialState = {
  holidays: null,
};

export function educationStateReducer(state = initialState, action: any ): EducationState {
  switch (action.type) {
    case EducationActionTypes.HOLIDAYS_GET_RES: {
      return { ...state, holidays: action.data };
    }
    default: {
      return state;
    }
  }
}