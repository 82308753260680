import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RegenerateUserTokenOnAppChange } from 'src/app/store/user.actions';
import { UserState } from 'src/app/store/user.reducer';
import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'auth-token-refresher',
  templateUrl: './auth-token-refresher.component.html',
  styleUrls: ['./auth-token-refresher.component.scss']
})
export class AuthTokenRefresherComponent implements OnInit {

  userToken;
  tokenExp;
  intervalTime: number = 10000;
  minutesToExpireThreshold: number = 17;
  refreshTimer;

  constructor(private store: Store<{ userState: UserState }>) {
    this.store.select(state => state.userState.userAccessToken).subscribe(accessToken => {
      if (accessToken)
        this.userToken = jwt_decode(accessToken.token);
    });
  }

  ngOnInit(): void {

    // interval to check if token needs to be refreshed
    this.refreshTimer = setInterval(() => {
      if (this.userToken){
        const currentTime = Date.now()
        const expTime = this.userToken.exp * 1000;
    
        // Time until token expiration in milliseconds
        const timeUntilExpiration = expTime - currentTime;
        const timeToUpdate = this.minutesToExpireThreshold * 60 * 1000;
    
        // Update the token if it is about to expired
        if (timeUntilExpiration <= timeToUpdate)
        {
          this.store.dispatch( RegenerateUserTokenOnAppChange({ pid: null }) );
        }
      }
    }, this.intervalTime);
  }

}
