import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ContactSearch } from "../models/corpid-list/contactsearch";
import { CorpIdOutputDto } from "../models/corpid-list/corpId-output-dto";
import { AccountManagerOutput, CorporateParent, NameValidate } from "../models/corpid-list/corporateparent";
import { UserSession } from "../models/user";
import { UserState } from "../store/user.reducer";
import { BaseMhService } from "./basemh.service";

@Injectable({
  providedIn: 'root'
})

export class corpIdListService extends BaseMhService {

  constructor(protected http: HttpClient, protected store: Store<{ userState: UserState }>) {
    super(http, store);
  }

  public getCorporateParentList() {
    const session: UserSession = this.getStoreUserSession();
    return this.http
      .get(environment.apiBaseUrl + 'v1/CorporateParent/GetCorporateParentList', { headers: this.getHeaders() })
      .pipe(
        map((response: CorpIdOutputDto) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving corporate parent List Data" }))
      );
  }

  public addUpdateCorporateParent(newCorporateParent: CorporateParent) {
    const session: UserSession = this.getStoreUserSession();
    return this.http
      .post(environment.apiBaseUrl + 'v1/CorporateParent/addupdatecorporateparent', newCorporateParent, { headers: this.getHeaders() })
      .pipe(
        map((response: any) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while adding new corporate parent data" }))
      );
  }
  public viewCorporateParent(corpId: number) {
    const session: UserSession = this.getStoreUserSession();
    return this.http
      .get(environment.apiBaseUrl + `v1/CorporateParent/GetCorporateParentByCorpId?corpId=${corpId}`, { headers: this.getHeaders() })
      .pipe(
        map((response: any) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving corporate parent List Data" }))
      );
  }

  public getContactSearch(keyword: string, corpId: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('keyword', keyword);
    httpParams = httpParams.append('corpId', String(corpId));
    return this.http.get(`${environment.apiBaseUrl}v1/CorporateParent/ContactSearch`, { headers: this.getHeaders(), params: httpParams })
      .pipe(
        map((response: ContactSearch) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving contact data" }))
      );

  }

  public isValidCorpName(nameValidate: NameValidate) {
    return this.http
      .post(environment.apiBaseUrl + 'v1/Common/ValidateName', nameValidate, { headers: this.getHeaders() })
      .pipe(
        map((response: any) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving Name data" }))
      );
  }

  public getAccountManager() {
    return this.http
      .get(environment.apiBaseUrl + 'v1/CorporateParent/GetSAMList', { headers: this.getHeaders() })
      .pipe(
        map((response: AccountManagerOutput) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving account manager data" }))
      );

  }

  public exportData(corpId: number, pageName: string) {
    const url = `v1/CorporateParent/ExportCorporateParentData?CorpId=${corpId}&pageName=${pageName}`;
    return this.http
      .get(environment.apiBaseUrl + url,
        { headers: this.getHeaders(), responseType: 'blob' })
      .pipe(
        map((response: any) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving corporate parent data" }))
      );
  }

}
