<div class="vendor-label">
  <mat-toolbar>
    <mat-toolbar-row class="tab-label">
      <span>My Support Tickets</span>
    </mat-toolbar-row>
    <mat-toolbar-row class='actionBar' style="font-size: 14pt;">
      <div style="border-right: 1px solid white;margin-top: 5px; margin-bottom: 5px;
      line-height: 22px; padding-right: 8px; ">
        <button class="add-new-ticket-btn" mat-button
        (click)="AddNewTicketClicked()">
        New Support Ticket
        </button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>
<div class="info-display">
  <div class="example-container mat-elevation-z8">
    <table mat-table [dataSource]="userSubmittedTicketsDataSource" class="mat-elevation-z8" #mhtable matSort matSortActive="ticketID" matSortDirection="asc">
      <ng-container matColumnDef="ticketID">
        <th mat-header-cell *matHeaderCellDef class="text-align-right"> Ticket ID </th>
        <td mat-cell *matCellDef="let ticket" class="text-align-right"> {{ticket.ticketID}} </td>
      </ng-container>

      <ng-container matColumnDef="submittedDate">
        <th mat-header-cell *matHeaderCellDef class="text-align-right"> Submitted Date </th>
        <td mat-cell *matCellDef="let ticket" class="text-align-right"> {{ticket.submittedDate | date:"MM/dd/yyyy" }} </td>
      </ng-container>

      <ng-container matColumnDef="pid">
        <th mat-header-cell *matHeaderCellDef class="text-align-right"> PID </th>
        <td mat-cell *matCellDef="let ticket" class="text-align-right"> {{ticket.pid}} </td>
      </ng-container>

      <ng-container matColumnDef="pharmacy">
        <th mat-header-cell *matHeaderCellDef> Pharmacy </th>
        <td mat-cell *matCellDef="let ticket" class="pharm-width-control"> {{ticket.pharmacy}} </td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef> Category </th>
        <td mat-cell *matCellDef="let ticket"> {{ticket.category }} </td>
      </ng-container>

      <ng-container matColumnDef="subject">
        <th mat-header-cell *matHeaderCellDef> Subject </th>
        <td mat-cell *matCellDef="let ticket"> {{ticket.subject}} </td>
      </ng-container>

      <ng-container matColumnDef="ticketDescription">
        <th mat-header-cell *matHeaderCellDef class="text-align-right"> Ticket Description </th>
        <td mat-cell *matCellDef="let ticket" class="text-align-right"> {{ticket.ticketDescription}} </td>
      </ng-container>

      <ng-container matColumnDef="resolutionNotes">
        <th mat-header-cell *matHeaderCellDef class="text-align-right"> Resolution Notes </th>
        <td mat-cell *matCellDef="let ticket" class="text-align-right"> {{ticket.resolutionNotes}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="ticketHeaders; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: ticketHeaders"></tr>
    </table>
    <div class="no-records" *ngIf="userSubmittedTicketsDataSource?.filteredData?.length===0">No Match Found</div>
  </div>
  <mat-paginator class="table-pagination" [pageSizeOptions]="[10, 20, 75, 100, 150]" showFirstLastButtons></mat-paginator>
</div>
