import { AutoRefActionTypes } from './automated-referral.actions';
import { ReferralSettings, SaveReferralSettings } from '../models/automated-referrals/referral-setting';
import { SafeUrl } from '@angular/platform-browser';

export interface GetListResponse {
  data: Referral[],
  columns: Column[],
}

export interface ReferralState {
  automatedReferralList: ReferralOutputDto,
  referralSettings: ReferralSettings,
  updateSettings: SaveReferralSettings,
  columns: ReferralColumnDTO[],
  notes: ReferralNotesDTO[],
  referralElements: ReferralElementsDTO,
  referralAttachments: ReferralAttachments,
  referralMenu: boolean,
  dismissReferral: DismissReferralDTO,
  qualifyReferral: QualifyReferralDTO,
}

export interface Column {
  position: number;
  name: string;
}

export interface Attachment {
  byteArray: Uint8Array,
  fileName: string,
  guid: string
}

export class Attachment {
  constructor(
    public byteArray: Uint8Array,
    public fileName: string,
    public guid: string,
    public href: SafeUrl
  ) { }
}

export interface ReferralAttachments {
  files: Array<Attachment>,
  length: number;
}

export interface Referral {
  value: number;
  confidenceLevel: string;
  ptLastName: string;
  ptFirstName: string;
  mrn: number;
  visitDate: string;
  refProvider: string;
  refToProvider: string;
  prescriber: string;
  prescriberNPI: number;
  written: string;
  serviceDate: string;
}

export interface ReferralOutputDto {
  columns: ReferralColumnDTO[];
  data: Referral[];
}

export interface ReferralColumnDTO {
  position: number;
  name: string;
  toolTip: string;
  header: string;
}

export interface ReferralNotesDTO {
  notes: string;
  dateAdded: Date;
}

export interface ReferralElementsDTO {
  testingElements: TestingElementsDTO;
  matchingElements: MatchingElementDTO;
}

export interface TestingElementsDTO {
  on340B: boolean | undefined;
  medicaid: boolean | undefined;
  orphan: boolean | undefined;
  expOrphan: boolean | undefined;
  ndcExclude: boolean | undefined;
  codeMatch: boolean | undefined;
  otherMatch: boolean | undefined;
}

export interface MatchingElementDTO {
  prescriberMatch: boolean | undefined;
  visitDate: boolean | undefined;
  scriptValue: boolean | undefined;
}

export interface DismissReferralDTO {
  mhafid: number;
  sid: number;
}

export interface QualifyReferralDTO {
  mhafid: number;
  sid: number;
  refPid: number;
  filltype: number;
  rxnumber: string;

}

export const initialState =
{
  automatedReferralList: null,
  referralSettings: null,
  updateSettings: null,
  columns: null,
  notes: null,
  referralElements: null,
  referralAttachments: null,
  referralMenu: null,
  dismissReferral: null,
  qualifyReferral: null,
}


export function automatedReferralReducer(state = initialState, action: any): ReferralState {
  switch (action.type) {
    case AutoRefActionTypes.GET_REFERRAL_LIST_RES: {
      return { ...state, automatedReferralList: action.automatedReferralList }
    }
    case AutoRefActionTypes.GET_REFFERAL_SETTINGS_RES: {
      return { ...state, referralSettings: action.referralSettings }
    }
    case AutoRefActionTypes.UPDATE_REFFERAL_SETTINGS_RES: {
      return { ...state, updateSettings: action.saveData }
    }
    case AutoRefActionTypes.SET_REFERRALS_COLUMNS_RES: {
      return { ...state, columns: action.columns }
    }
    case AutoRefActionTypes.GET_REFERRALS_NOTES_RES: {
      return { ...state, notes: action.notes }
    }
    case AutoRefActionTypes.SET_REFERRALS_NOTE_RES: {
      return { ...state, notes: action.notes }
    }
    case AutoRefActionTypes.GET_REFERRAL_ELEMENTS_RES: {
      return { ...state, referralElements: action.referralElements }
    }
    case AutoRefActionTypes.GET_REFERRAL_ATTACHMENTS_RES: {
      return { ...state, referralAttachments: action.attachments }
    }
    case AutoRefActionTypes.CHECK_REFERRAL_MENU_RES: {
      return { ...state, referralMenu: action.result }
    }
    case AutoRefActionTypes.DISMISS_REFERRAL_RES: {
      return { ...state, dismissReferral: action.result }
    }
    case AutoRefActionTypes.QUALIFY_REFERRAL_RES: {
      return { ...state, qualifyReferral: action.result }
    }
    default: {
      return state;
    }
  }
}
