export class SalesForceTicket {
    uid: number;
    pid: number;
    categoryId: number;
    phoneNumber: string;
    subject: string;
    body: string;
}

export class SubmittedSupportTicket {
  submittedDate: Date;
  pid: number;
  pharmacy: string;
  category: string;
  subject: string;
  ticketDescription: string;
  resolutionNotes: string;
  ticketID: number;
}
