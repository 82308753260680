import { UiStateTypes } from './ui-state.actions';

export interface UiState {
  MenuVisible: boolean;
  InfoVisible: boolean;
  InfoMessage: string | null;
  ErrorVisible: boolean;
  ErrorMessage: string | null;
  CallInProgress: boolean;
  InitDone: boolean;
  ActionType: string | null;
}

export const initialState = {
  MenuVisible: false,
  InfoVisible: false,
  InfoMessage: null,
  ErrorVisible: false,
  ErrorMessage: null,
  CallInProgress: false,
  InitDone: false,
  ActionType: null
};

export function uiSteteReducer(state = initialState, action: any): UiState {
  if( action.type.includes('[Rest Call]') )
    return { ...state, CallInProgress: true };
  else if( action.type.includes('[Rest Result]') )
    return { ...state, CallInProgress: false };
  else if( action.type.includes('[Rest Error]') )
    return { ...state, CallInProgress: false, ErrorVisible: true, ErrorMessage: action.message || action.errorMessage, ActionType: action.type };
  else switch (action.type) {
    case UiStateTypes.MENU_EXPAND: {
      return { ...state, MenuVisible: true };
    }
    case UiStateTypes.MENU_COLAPSE: {
      return { ...state, MenuVisible: false };
    }
    case UiStateTypes.CALL_START: {
      return { ...state, CallInProgress: true };
    }
    case UiStateTypes.CALL_END: {
      return { ...state, CallInProgress: false };
    }
    case UiStateTypes.INFO_SHOW: {
      return { ...state, InfoVisible: true, InfoMessage: action.message };
    }
    case UiStateTypes.INFO_HIDE: {
      return { ...state, InfoVisible: false, InfoMessage: null };
    }
    case UiStateTypes.ERROR_SHOW: {
      return { ...state, ErrorVisible: true, ErrorMessage: action.message };
    }
    case UiStateTypes.ERROR_HIDE: {
      return { ...state, ErrorVisible: false, ErrorMessage: null };
    }
    case UiStateTypes.INIT_DONE: {
      return { ...state, InitDone: true };
    }
    default: {
      return state;
    }
  }
}







