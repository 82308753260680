import { catchError, map, switchMap } from "rxjs/operators";
import { Injectable } from '@angular/core';
import { act, Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { QualRulesListService } from "src/app/service/qual-rules.service"
import { ApplyQualRule, ApplyQualRuleError, ApplyQualRuleResults, GetPharmaciesByUser, GetPharmaciesByUserError, GetPharmaciesByUserResults, GetQualRulesList, GetQualRulesListError, GetQualRulesListResults } from "./qual-rules.actions"

@Injectable()
export class QualRulesStoreEffects {

  constructor(private qualRulesService: QualRulesListService, private actions$: Actions) {
  }

  GetqualRulesListEffects$ = createEffect(() => this.actions$.pipe(
    ofType(GetQualRulesList),
    switchMap(() =>
      this.qualRulesService.getQualRulesList()
        .pipe(
          map(
            data => {
              return GetQualRulesListResults({ qualRules: data })

            }
          ),
          catchError(
            error => observableOf(GetQualRulesListError({ message: error }))
          )
        )

    )


  ))

  getPharmaciesByUser$ = createEffect(() => this.actions$.pipe(
    ofType(GetPharmaciesByUser),
    switchMap(() =>
      this.qualRulesService.getPharmaciesByUser()
        .pipe(
          map(
            data => {
              return GetPharmaciesByUserResults({ qualRulePharmacy: data })
            }
          ),
          catchError(
            error => observableOf(GetPharmaciesByUserError({ errorMessage: error }))
          )
        )
    )
  ))

  applyQualRule$ = createEffect(() => this.actions$.pipe(
    ofType(ApplyQualRule),
    switchMap((action) =>
      this.qualRulesService.applyQualRule(action.qualRules)
        .pipe(
          map(
            data => {
              return ApplyQualRuleResults({ applyQualRuleResult: data })
            }
          ),
          catchError(
            error => observableOf(ApplyQualRuleError({ errorMessage: error }))
          )
        )
    )
  ))

}