<div class="clientSelectionContainer">
  <div class="searchBar">
    <mat-form-field appearance="outline" class="search-field">
      <input matInput type="search" placeholder="Search by ID or Name" [(ngModel)]="searchString" class="search-input" (keyup.enter)="search()" (ngModelChange)="checkSearchEmpty()">
      <mat-icon class="closeIcon" matSuffix (click)="clearSearch()">close</mat-icon>
    </mat-form-field>

    <mat-form-field class="clientTypeSelection">
      <mat-select [formControl]="clientTypesControl" required>
        <mat-option *ngFor="let clientType of clientTypes" [value]="clientType">
          {{clientType}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="clientTypesControl.hasError('required')">Please choose a type</mat-error>
    </mat-form-field>

    <button mat-flat-button type="submit" class="search-btn" (click)="search()">Search</button>
  </div>
  <div>
    <button mat-flat-button type="submit" [disabled]="selection.isEmpty()" (click)="regenerateToken()" color="primary" class="submit-btn">View Selected</button>
  </div>

  <div class="treeForm">
    <mat-tree #tree *ngIf="!showNoResults" [dataSource]="dataSource" [treeControl]="treeControl" class="client-tree">
      <!-- This is the tree node template for leaf nodes -->
      <!-- There is inline padding applied to this node using styles.
            This padding value depends on the mat-icon-button width. -->
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        <ng-container [ngSwitch]="node.level">
          <div *ngSwitchCase='1'>
            <div class="client-tree-not-selected" [class.client-tree-selected]="selection.isSelected(node)" (click)="selectNode(node)"> {{ node.name }} </div>
          </div>
          <div *ngSwitchDefault>
            {{node.name}}
          </div>
        </ng-container>
      </mat-tree-node>
      <!-- This is the tree node template for expandable nodes -->
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <div class="mat-tree-node">
          <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          <ng-container [ngSwitch]="node.level">
            <div *ngSwitchCase='1'>
              <div class="client-tree-not-selected" [class.client-tree-selected]="selection.isSelected(node)" (click)="selectNode(node)"> {{ node.name }} </div>
            </div>
            <div *ngSwitchDefault>
              {{node.name}}
            </div>
          </ng-container>
  
        </div>
        <!-- There is inline padding applied to this div using styles.
                  This padding value depends on the mat-icon-button width.  -->
        <div [class.client-tree-invisible]="!treeControl.isExpanded(node)" role="group">
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>
    </mat-tree>

    <div class="no-records" *ngIf="showNoResults" >No results found. Please select the correct filter option and try again</div>
  </div>
</div>

<div class="loading-overlay" *ngIf="callInProgress">
  <mat-spinner class="spinner"></mat-spinner>
</div>