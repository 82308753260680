export interface Application {
  appName: string;
  appID: number;
  appDesc: string;
  active: number;
  sortOrder: number;
  pharmacySelectorVisible: boolean;
  recalculateVisible: boolean;
  routePath: string;
  favoriteButtonVisible: boolean;
}

export class ApplicationMenuItem {
  pageID: number;
  appID: number;
  active: number;
  sectionName: string;
  pageName: string;
  ngPageURL: string;
  aspPageURL: string;
  pageOrder: number;
  sectionOrder: number;
  permissionKey: string;
}

export interface ApplicationMenuSection {
  name: string;
  items: ApplicationMenuItem[];
}

export interface ApplicationMenu {
  Sections: ApplicationMenuSection[];
  Selected: ApplicationMenuItem | null;
}

export interface Applications {
  Items: Application[];
  Selected: Application | null;
  Menu: ApplicationMenu;
  restoredFromUrl: boolean;
  backupPage: ApplicationMenuItem;
}

export class ApplicationIds {
  public static MacroManager = 1;
  public static Architect340B = 2;
  public static ArchitectNDC = 3;
  public static BIAnalytics = 5;
  public static GatewayDashboard340B = 7;
  public static UserAccessManagement = 8;
};
