import { createAction, props } from "@ngrx/store";
import { HospitalColumnDTO } from "src/app/models/hidpharmacy/hospital-list/hospital-column-dto";
import { HospitalOutputDTO } from "src/app/models/hidpharmacy/hospital-list/hospital-output-dto";




export enum HospitalListActionTypes{

    GET_HOSPITAL_LIST = '[Rest Call] [HospitalList] GetHospitalList',
    GET_HOSPITAL_LIST_RES = '[Rest Result] [HospitalList] GetHospitalListResponse',
    GET_HOSPITAL_LIST_ERR = '[Rest Error] [HospitalList] GetHospitalListError',

    SET_HOSPITAL_LIST_COLUMNS = '[Rest Call] [HospitalList] SetHospitalListColumns',
    SET_HOSPITAL_LIST_COLUMNS_RES = '[Rest Result] [HospitalList] SetHospitalListColumnsResponse',
    SET_HOSPITAL_LIST_COLUMNS_ERR = '[Rest Error] [HospitalList] SetHospitalListColumnsError',

}

export const GetHospitalList = createAction(HospitalListActionTypes.GET_HOSPITAL_LIST, props<{Active: number}>());
export const GetHospitalListResults = createAction(HospitalListActionTypes.GET_HOSPITAL_LIST_RES, props<{hospitalList: HospitalOutputDTO}>());
export const GetHospitalListError = createAction(HospitalListActionTypes.GET_HOSPITAL_LIST_ERR, props<{message: string}>())

export const SetHospitalListColumns = createAction(HospitalListActionTypes.SET_HOSPITAL_LIST_COLUMNS, props<{columns: HospitalColumnDTO[]}>());
export const SetHospitalListColumnsResults = createAction(HospitalListActionTypes.SET_HOSPITAL_LIST_COLUMNS_RES, props<{ columns: HospitalColumnDTO[] }>())
export const SetHospitalListColumnsError = createAction(HospitalListActionTypes.SET_HOSPITAL_LIST_COLUMNS_ERR, props<{ message: string }>())


