import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SaveUserSettings } from 'src/app/models/user';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/store/user.reducer';
import { Observable, Subscription } from 'rxjs';
import { UpdateUserSettings, UpdateUserSettingsError, UpdateUserSettingsResults } from 'src/app/store/user.actions';
import { Actions, ofType } from '@ngrx/effects';
import { InfoShow } from 'src/app/store/ui-state.actions';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss']
})
export class GeneralSettingsComponent implements OnInit, OnDestroy {
  public userStore$: Observable<UserState>;
  displayHeightOptions = [{value: 450, viewValue: '450'},
                         {value: 500, viewValue: '500'},
                         {value: 550, viewValue: '550'},
                         {value: 600, viewValue: '600'},
                         {value: 650, viewValue: '650'},
                         {value: 700, viewValue: '700'},
                         {value: 750, viewValue: '750'},
                         {value: 800, viewValue: '800'},];
  virtualScrollingOptions = [{value: 0, viewValue: 'Disable'},
                            {value: 1, viewValue: 'Enable'}];
  packageSettingOptions = [{value: 0, viewValue: '0'},
                           {value: 1, viewValue: '1'},
                           {value: 2, viewValue: 'max'},];
  saveValues: SaveUserSettings = new SaveUserSettings();
  wasFormChanged = false;
  breakpoint: number;
  subs: Subscription = new Subscription;

  constructor(private dialogRef: MatDialogRef<GeneralSettingsComponent>, private store: Store<{ userState: UserState }>, private actions$: Actions  ) {
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.userStore$ = this.store.select(state => state.userState );
    this.subs.add(
      this.userStore$.subscribe( (val) => {
        this.saveValues.defaultPkgs = val.settings.defaultPkgs;
        this.saveValues.displayHeight = val.settings.displayHeight;
        this.saveValues.virtualScrolling = val.settings.virtualScrolling;
      })
    )
    this.subs.add(
      this.actions$.pipe(ofType(UpdateUserSettingsResults)).subscribe(data => {
        this.store.dispatch(InfoShow({ message: 'General settings updated!'}));
      })
    )
    this.subs.add(
      this.actions$.pipe(ofType(UpdateUserSettingsError)).subscribe(data => {
        alert(data.message);
      })
    )
  }

  // tslint:disable-next-line:no-any
  onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }

  formChanged() {
    this.wasFormChanged = true;
  }

  onSaveClick() {
    this.saveValues.virtualScrolling = 0;
    this.store.dispatch( UpdateUserSettings( { saveData: this.saveValues} ) );
    this.dialogRef.close();
  }

  onCloseClick(){
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
