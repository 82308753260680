import { Injectable } from '@angular/core';
import {BaseMhService} from './basemh.service';
import {Observable, pipe, throwError, of} from 'rxjs';
import {SaveVersionTracker, VersionTracker} from '../models/version-tracker';
import {catchError, map, tap, retry} from 'rxjs/operators';
import {SalesForceCategory} from '../models/sales-force-category';
import {SalesForceTicket} from '../models/sales-force-ticket';
import {UserSession} from '../models/user';
import { HttpClient, HttpHandler, HttpResponse, HttpHeaders, HttpRequest, HttpParams} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {UserState} from '../store/user.reducer';
import {
  vmCD_Group_View, TableGetOptions, vmCD_View_Pid_Data, CD_PidList,
  vmCD_Group_View_Details, vmCD_Group_Vendor, vmCD_Group_Sequence,
  vmCD_Group_TerminalAccount, saveCDGroup, CD_GroupName, vmCD_Contact
} from '../models/received-detail';
import { SubmitResponse } from '../models/submit-response';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class OrderService extends BaseMhService {

  public cdData: any;
  public loginResponse: string;
  result: boolean;

  constructor(protected http: HttpClient, protected store: Store<{ userState: UserState }>) {
    super(http, store);
  }

  public getReceivedAll(options: TableGetOptions) {

    let params: HttpParams = new HttpParams();
    if (options.pageNumber)
      params = params.append('pageNumber', '' + options.pageNumber);
    if (options.rowsPerPage)
      params = params.append('rowsPerPage', '' + options.rowsPerPage);
    if (options.searchText)
      params = params.append('searchText', options.searchText);
    if (options.sortColumn)
      params = params.append('sortColumn', options.sortColumn);
    if (options.sortOrder)
      params = params.append('sortOrder', options.sortOrder);

    return this.http
      .get(environment.apiBaseUrl + 'orderapi/v1/group/cd/viewcdgroup',
      { headers: this.getHeaders(), params }) 
      .pipe(
        map((response: vmCD_Group_View[]) => response),        
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Groups" }))
      );
  }

  public getCentralSplitGroups(options: TableGetOptions) {

    let params: HttpParams = new HttpParams();
    if (options.pageNumber)
      params = params.append('pageNumber', '' + options.pageNumber);
    if (options.rowsPerPage)
      params = params.append('rowsPerPage', '' + options.rowsPerPage);
    if (options.searchText)
      params = params.append('searchText', options.searchText);
    if (options.sortColumn)
      params = params.append('sortColumn', options.sortColumn);
    if (options.sortOrder)
      params = params.append('sortOrder', options.sortOrder);

    return this.http
      .get(environment.apiBaseUrl + 'orderapi/v1/group/cd/viewcentralsplit',
        { headers: this.getHeaders(), params })
      .pipe(
        map((response: vmCD_Group_View[]) => response),        
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Groups" }))
      );
  }

  public getCDGrpDetailsByID(groupID: number, options: TableGetOptions) {

    let params: HttpParams = new HttpParams();
    if (options.pageNumber)
      params = params.append('pageNumber', '' + options.pageNumber);
    if (options.rowsPerPage)
      params = params.append('rowsPerPage', '' + options.rowsPerPage);
    if (options.searchText)
      params = params.append('searchText', options.searchText);
    if (options.sortColumn)
      params = params.append('sortColumn', options.sortColumn);
    if (options.sortOrder)
      params = params.append('sortOrder', options.sortOrder);

    //console.log('Group ID passed in is ' + groupID);
    return this.http
      .get(environment.apiBaseUrl + 'orderapi/v1/group/cd/viewcdgroupdetails/' +  groupID, { headers: this.getHeaders(), params })
      .pipe(
        map((response: vmCD_Group_View_Details) => response),       
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Group Details" }))
      );
  }

  public getVendorName(wacaccount: vmCD_Group_TerminalAccount) {

    //console.log('wac Account passed in for vendor is  ' + wacaccount);
    return this.http
      .post(environment.apiBaseUrl + 'orderapi/v1/group/cd/getvendor', wacaccount, { headers: this.getHeaders() })
      .pipe(
        map((response: vmCD_Group_Vendor) => response),       
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Vendor" }))
      );
  }

  public IsValidTerminalAccount(wacaccount: vmCD_Group_TerminalAccount) {

    //console.log('wac Account passed in is ' + wacaccount);
    return this.http
      .post(environment.apiBaseUrl + 'orderapi/v1/group/cd/validTerminalAcnt', wacaccount, { headers: this.getHeaders() })
      .pipe(
        map((response: number) => response),       
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Terminal Account" }))
      );
  }

  public IsValidGroupName(groupname: CD_GroupName) {
    //console.log('wac Account passed in is ' + groupname.CD_GroupDescription);
    return this.http
      .post(environment.apiBaseUrl + 'orderapi/v1/group/cd/validategroupname', groupname, { headers: this.getHeaders() })
      .pipe(
        map((response: number) => response),        
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Group Name Validity" }))
      );
  }


  public getSequences() {
    //console.log('Called get Sequences');
    return this.http
      .get(environment.apiBaseUrl + 'orderapi/v1/group/cd/getsequence', { headers: this.getHeaders() })
      .pipe(
        map((response: vmCD_Group_Sequence[]) => response),        
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Sequences" }))
      );
  }

  public updateCDGroup(saveData: saveCDGroup) {    
    return this.http
      .post(environment.apiBaseUrl + 'orderapi/v1/group/cd/updatecdgroup', saveData, { headers: this.getHeaders() })
      .pipe(
        map((response: SubmitResponse) => {
          if (response.status === '200')
            return saveData;
          else
            throwError(response.message);
        }),       
        catchError(error => this.errorHandler({ error, operation: "Error while Saving Group" }))
        );
  }

  public addCDGroup(saveData: saveCDGroup) {
    return this.http
      .post(environment.apiBaseUrl + 'orderapi/v1/group/cd/addcdgroup', saveData, { headers: this.getHeaders() })
      .pipe(
        map((response: SubmitResponse) => {
          if (response.status === '200')
            return saveData;
          else
            throwError(response.message);
        }),       
        catchError(error => this.errorHandler({ error, operation: "Error while Adding Group" }))
      );
  }

  public getCDContacts(pidList: number[], options: TableGetOptions) {

    //console.log('Called get cd contacts ');

    let params: HttpParams = new HttpParams();
    if (options.pageNumber)
      params = params.append('pageNumber', '' + options.pageNumber);
    if (options.rowsPerPage)
      params = params.append('rowsPerPage', '' + options.rowsPerPage);
    if (options.searchText)
      params = params.append('searchText', options.searchText);
    if (options.sortColumn)
      params = params.append('sortColumn', options.sortColumn);
    if (options.sortOrder)
      params = params.append('sortOrder', options.sortOrder);

    //console.log('PID List passed in is ' + pidList);
    return this.http
      .post(environment.apiBaseUrl + 'orderapi/v1/group/cd/pids' , pidList, { headers: this.getHeaders(), params })
      .pipe(
        map((response: vmCD_Contact[]) => response),        
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Group Contacts" }))
      );
  }

  public getCDContactsID(groupID: number, options: TableGetOptions) {

    let params: HttpParams = new HttpParams();
    if (options.pageNumber)
      params = params.append('pageNumber', '' + options.pageNumber);
    if (options.rowsPerPage)
      params = params.append('rowsPerPage', '' + options.rowsPerPage);
    if (options.searchText)
      params = params.append('searchText', options.searchText);
    if (options.sortColumn)
      params = params.append('sortColumn', options.sortColumn);
    if (options.sortOrder)
      params = params.append('sortOrder', options.sortOrder);

    //console.log('Group ID passed in is ' + groupID);
    return this.http
      .get(environment.apiBaseUrl + 'orderapi/v1/group/cd/getgroupsubscriptions/' + groupID, { headers: this.getHeaders(), params })
      .pipe(
        map((response: vmCD_Contact[]) => response),        
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Group Subscription Details" }))
      );
  }

  public getCDContactsbySearchVal(searchData: CD_PidList, options: TableGetOptions) {

    let params: HttpParams = new HttpParams();
    if (options.pageNumber)
      params = params.append('pageNumber', '' + options.pageNumber);
    if (options.rowsPerPage)
      params = params.append('rowsPerPage', '' + options.rowsPerPage);
    if (options.searchText)
      params = params.append('searchText', options.searchText);
    if (options.sortColumn)
      params = params.append('sortColumn', options.sortColumn);
    if (options.sortOrder)
      params = params.append('sortOrder', options.sortOrder);

    //console.log('search val passed in is ' + searchData);
    return this.http
      .post(environment.apiBaseUrl + 'orderapi/v1/group/cd/contactdata/', searchData, { headers: this.getHeaders(), params })
      .pipe(
        map((response: vmCD_Contact[]) => response),
       // tap(returnedData => console.log(returnedData)),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving subscriptions by search Value" }))
      );
  }
}
