import { Component, OnInit, HostListener, OnDestroy, Inject } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { UserState } from 'src/app/store/user.reducer';
import { AddUserAlternateLoginName, UserSettings, Vendor } from 'src/app/models/user';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { GetVendorList, AddNewUserAlternateLogin, AddNewUserAlternateLoginResults, AddNewUseralternateLoginError } from 'src/app/store/user.actions';
import { take } from 'rxjs/operators';
import { InfoShow } from 'src/app/store/ui-state.actions';
import { MessageDialogComponent } from 'src/app/shared/message-dialog/message-dialog.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'add-new-user-login-name-dialog',
  templateUrl: './add-new-user-login-name-dialog.component.html',
  styleUrls: ['./add-new-user-login-name-dialog.component.scss']
})
export class AddNewUserLoginNameDialogComponent implements OnInit, OnDestroy {
  public userState$: Observable<UserState>;
  public newUserAlternateLoginName: AddUserAlternateLoginName = new AddUserAlternateLoginName();
  defaultSelection = 2;
  errorMessage =  '';
  loginNameIsNullOrWhiteSpace = false;
  userSettings: UserSettings = new UserSettings();
  subs: Subscription = new Subscription;
  public isMHIEmployee: boolean;
  public vendorList: Vendor[] = [];


  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }

  constructor(private dialogRef: MatDialogRef<AddNewUserLoginNameDialogComponent>, public dialog1: MatDialog,
              private store : Store<{ userState: UserState }>,
              private actions$: Actions) { 
                this.store.dispatch(GetVendorList());
              }

  ngOnInit(): void {
    this.userState$ = this.store.select(state => state.userState)
    this.newUserAlternateLoginName.vid = 2;
    this.newUserAlternateLoginName.vendorId = 2;
    this.subs.add(
    this.userState$.subscribe(res => {
      if (res) {
        this.vendorList = res.vendorList ?? [];
      }
    })
  );

    this.subs.add(
      this.store.select(state => state.userState.settings).pipe(take(1)).subscribe(data => {
        if (data)
          this.userSettings = data;
          this.isMHIEmployee = data.isMHIUser;

      })
    )
    this.subs.add(
      this.actions$.pipe(ofType(AddNewUserAlternateLoginResults)).subscribe((data) => {
        if (data.response.status === '200') {
          this.store.dispatch(InfoShow({message: 'Alternate LoginID saved successfully for selected vendor.'}));
          this.dialogRef.close();
        } else {
          this.errorMessage = 'Error ' + data.response.status + ': ' + data.response.message;
        }
      })
    )
    
    this.subs.add(
      this.actions$.pipe( ofType(AddNewUseralternateLoginError)).subscribe((data) => {
        alert(data.response.message);
        // this.store.dispatch( ErrShow({ message: data.message}) );
      })
    )
  }

  onSubmitClick() {

    this.newUserAlternateLoginName.loginName = this.newUserAlternateLoginName.loginName.replace(/[-)(\s]/g, '');
    this.newUserAlternateLoginName.vendorId = this.newUserAlternateLoginName.vid;
    this.newUserAlternateLoginName.uid = this.newUserAlternateLoginName.addedBy = this.userSettings.uid;
    this.newUserAlternateLoginName.active = 1;

    if(!this.isMHIEmployee && this.vendorList.find(v => v.vendorID == this.newUserAlternateLoginName.vendorId).vendorName.toLowerCase().includes('mckesson')){
      const dialog =  this.dialog1.open(MessageDialogComponent, {
        data: {
          title: "Alternate Logins",
          message: "McKesson Connect Integration requires a ticket to support@macrohelix.com. Please contact support to complete your Integration with McKesson Connect, without this step order integration will not work. Please include your McKesson Connect User ID and account information in the email.",
          btnok: true,
          btntext: "Continue",
          showCancelBtn: true,
          btnCancelText: "Cancel",
        },
        width: '40%',
        panelClass: 'no-scroll'
      });
      dialog.afterClosed().subscribe(result => {
        if(result === true){
          this.store.dispatch(AddNewUserAlternateLogin( {newUserAlternateLogin: this.newUserAlternateLoginName}));
        }
    });
  
  }
  else {
    this.store.dispatch(AddNewUserAlternateLogin( {newUserAlternateLogin: this.newUserAlternateLoginName}));
  }
  }

  onCloseClick(){
    this.dialogRef.close();
  }

  isNullOrWhitespace() {
    if (typeof this.newUserAlternateLoginName.loginName === 'undefined' || this.newUserAlternateLoginName.loginName == null) {
      this.loginNameIsNullOrWhiteSpace = true;
      return true;
    }
    return  this.newUserAlternateLoginName.loginName.replace(/\s/g, '').length < 1;
  }

  ngOnDestroy(){
    this.subs.unsubscribe();
  }
}
