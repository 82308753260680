import { Component, OnInit, HostListener } from '@angular/core';
import { MatDialog, MatDialogRef  } from '@angular/material/dialog';
import {environment} from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-help-guide',
  templateUrl: './help-guide.component.html',
  styleUrls: ['./help-guide.component.scss']
})
export class HelpGuideComponent implements OnInit {

  //url = environment.baseUrl + '/App_Help/Architect/Index.htm';
  url = "https://macrohelix-test.helpjuice.com/";
  helpGuideUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);

  constructor(private dialogRef: MatDialogRef<HelpGuideComponent>,
    private sanitizer: DomSanitizer) { }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }

   ngOnInit(): void {
  }

  onCloseClick(){
    this.dialogRef.close();
  }
}
