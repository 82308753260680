import { catchError, map, switchMap } from "rxjs/operators";
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { AddHId, AddHIdError, AddHIdResults, GetChainError, GetChainList, GetChainResults, GetHIdList, GetHIdListError, GetHIdListResults,  ValidateName, ValidateNameError, ValidateNameResults,UpdateHospitalsCorporateID, UpdateHospitalsCorporateIDError, UpdateHospitalsCorporateIDResults, GetImpUserAccess, GetImpUserAccessError, GetImpUserAccessResults, GetMgmtUserAccess, GetMgmtUserAccessError, GetMgmtUserAccessResults, ViewHid, ViewHidResults,  GetChainListByHid, GetChainListByHidResults, GetChainListByHidError, VerifyLocations, VerifyLocationsError, VerifyLocationsResults, PrescriberListUpload, PrescriberListUploadResults, PrescriberListUploadError } from "./hid-list.actions";
import { hIdListService } from "src/app/service/hid-list.service";

@Injectable()
export class hIdStoreEffects {
  constructor(private hIdService: hIdListService, private actions$: Actions) {
  }
  
  GethIdListEffects$ = createEffect(() => this.actions$.pipe(
      ofType(GetHIdList),
      switchMap(action =>
      this.hIdService.getHIdList(action.corpId)
      .pipe(
        map(
          data=>{
            return GetHIdListResults({hIdList:data})
          }
        ),
        catchError(
            error=>observableOf(GetHIdListError({message:error}))
        )
      )
    )
  ));

  ViewhIdEffects$ = createEffect(() => this.actions$.pipe(
    ofType(ViewHid),
    switchMap(action =>
      this.hIdService.getHIdById(action.hid)
      .pipe(
        map(
            data=>{
              return ViewHidResults({result:data})
            }
        ),
        catchError(
            error=>observableOf(GetHIdListError({message:error}))
        )
      )
    )
  ));

  AddHId$ = createEffect(() => this.actions$.pipe(
    ofType(AddHId),
    switchMap(action =>
      this.hIdService.addHId(action.saveData)
        .pipe(
          map(
            data => AddHIdResults({ addHidResponse : data })
          ),
          catchError(
            error => observableOf(AddHIdError({ message: error }))
          )
        )
    )
  ));

  PrescriberListUpload$ = createEffect(() => this.actions$.pipe(
    ofType(PrescriberListUpload),
    switchMap(action =>
      this.hIdService.prescriberListUpload(action.prescriberListUpload)
        .pipe(
          map(
            data => PrescriberListUploadResults({ result : data })
          ),
          catchError(
            error => observableOf(PrescriberListUploadError({ message: error }))
          )
        )
    )
  ));

  ValidateName$ = createEffect(() => this.actions$.pipe(
    ofType(ValidateName),
    switchMap(action =>
      this.hIdService.validateName(action.validateNameData)
        .pipe(
          map(
            data => ValidateNameResults({ validateNameResponse: data })
          ),
          catchError(
            error => observableOf(ValidateNameError({ message: error }))
          )
        )
    )
  ));

  GetChainListEffects$ = createEffect(() => this.actions$.pipe(
    ofType(GetChainList),
    switchMap(action =>
      this.hIdService.getChainStoreList()
        .pipe(
          map(
            data => {
                  return GetChainResults({ chainList: data })
            }
          ),
          catchError(
            error => observableOf(GetChainError({ message: error }))
          )
        )
    )
  ));
  
  GetVerifyLocationsEffects$ = createEffect(() => this.actions$.pipe(
    ofType(VerifyLocations),
    switchMap(action =>
      this.hIdService.verifyWrittenLocation(action.hid)
        .pipe(
          map(
            data => {
                  return VerifyLocationsResults({ result: data })
            }
          ),
          catchError(
            error => observableOf(VerifyLocationsError({ message: error }))
          )
        )
    )
  ));
    
  GetMgmtUserAccessEffects$ = createEffect(() => this.actions$.pipe(
    ofType(GetMgmtUserAccess),
    switchMap(action =>
      this.hIdService.getUserAcess(action.uId,action.deptID,action.pageId,action.permId)
        .pipe(
          map(
            data => {
                  return GetMgmtUserAccessResults({ userAccessResponse: data })
            }
          ),
          catchError(
            error => observableOf(GetMgmtUserAccessError({ message: error }))
          )
        )
    )
  ));

  GetImpUserAccessEffects$ = createEffect(() => this.actions$.pipe(
    ofType(GetImpUserAccess),
    switchMap(action =>
      this.hIdService.getUserAcess(action.uId,action.deptID,action.pageId,action.permId)
        .pipe(
          map(
            data => {
                  return GetImpUserAccessResults({ userAccessResponse: data })
            }
          ),
          catchError(
            error => observableOf(GetImpUserAccessError({ message: error }))
          )
        )
    )
  ));
  
  GetChainListByHidEffects$ = createEffect(() => this.actions$.pipe(
    ofType(GetChainListByHid),
    switchMap(action =>
      this.hIdService.getChainStoreListByHid(action.hid)
        .pipe(
          map(
            data => {
                  return GetChainListByHidResults({ chainListByHId: data })
            }
          ),
          catchError(
            error => observableOf(GetChainListByHidError({ message: error }))
          )
        )
    )
  ));

  UpdateHospitalsCorporateIDEffect$ = createEffect(() => this.actions$.pipe(
    ofType(UpdateHospitalsCorporateID),
    switchMap(action =>
      this.hIdService.UpdateHospitalsCorpID(action.moveHIDRequest)
        .pipe(
          map(
            data => {
              return UpdateHospitalsCorporateIDResults({moveHIDResponse: data })
            }
          ),
          catchError(
            error => observableOf(UpdateHospitalsCorporateIDError({ message: error }))
          )
        )
    )
  ));
}