import {HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest, HttpHandler, HttpResponse} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {UserState} from '../store/user.reducer';
import {UiUser, UserSession, UserSettings, UserApplications, UserAccessToken, PermissionType} from '../models/user';
import {throwError, Observable, of} from 'rxjs';
import {environment} from '../../environments/environment';
import { Http2ServerRequest } from 'http2';
import { withLatestFrom } from 'rxjs/internal/operators/withLatestFrom';
import { UiState } from '../store/ui-state.reducer';
import * as jwt_decode from 'jwt-decode';

export class BaseMhService {

  private user : UiUser;
  private settings : UserSettings;
  private session : UserSession;
  private apps: UserApplications;
  private accessToken: UserAccessToken;

  constructor(protected http: HttpClient, protected store: Store<{ userState: UserState}>) {
    this.store.select(state => state.userState.user ).subscribe( (val) => this.user = val );
    this.store.select(state => state.userState.settings ).subscribe( (val) => this.settings = val );
    this.store.select(state => state.userState.session ).subscribe( (val) => this.session = val );
    this.store.select(state => state.userState.userApps).subscribe( (val) => this.apps = val );
    this.store.select(state => state.userState.userAccessToken).subscribe( (val) => this.accessToken = val);
  }

  protected getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Ocp-Apim-Subscription-Key': environment.subcriptionKey,
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.getUserToken(),
    });
  }

  protected getIdHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Ocp-Apim-Subscription-Key': environment.subcriptionKey,
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.getUserIdToken(),
    });
  }

  protected errorHandler({ error, operation }: { error: any; operation: string; }): Observable<never> {
    let message = operation;//'Something bad happened; please try again later.';

    if (error instanceof HttpErrorResponse) {
      message = error.message + " : " + JSON.stringify(error.error);
      if (error.error)
        message = error.error.message != null ? error.error.message : message;
      else if (error.error && error.error.error)
        message = error.error.error.message != null ? error.error.error.message : message;
      else
        message = error.message != null ? error.message : message
    } else if (error instanceof Error) {
      message = error.message != null ? error.message : message;
    } else if (error instanceof ErrorEvent) {
      message = error.error.message != null ? error.error.message : message;
    } else {
      message = `Backend returned code ${error}`;
    }
    return throwError(operation + " : " + message);
  }

  protected getStoreUiUser(): UiUser {
    return this.user;
  }

  protected getStoreUserSettings(): UserSettings {
    return this.settings;
  }

  protected getStoreUserSession(): UserSession {
    return this.session;
  }

  protected getStoreUserApplications(): UserApplications {
    return this.apps;
  }

  protected getUserToken(): string {
    return this.accessToken.token
  }

  protected getUserIdToken(): string {
    return this.user.jwtString;
  }

  public getPermissionType(permissionKey): PermissionType {
    // Could possibly store decoded token somewhere to be faster?
    let jwtToken = jwt_decode(this.getUserToken());

    if (jwtToken.Permissions != null && jwtToken.Permissions.length > 1) {
      var permissionList = JSON.parse(jwtToken.Permissions);

      if(!(permissionKey in permissionList)) {
        return PermissionType.NoAccess
      }

      return permissionList[permissionKey] == 1 ? PermissionType.CanChange : PermissionType.CanRead;
    }
    else {
      return PermissionType.NoAccess;
    }

  }
}
