import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { NotificationItemHistory } from 'src/app/models/user';
import { UiState } from 'src/app/store/ui-state.reducer';
import { Applications } from 'src/app/models/applications';
import { UserState } from 'src/app/store/user.reducer';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs/internal/Subscription';


@Component({
  selector: 'app-notification-history-dialog',
  templateUrl: './notification-history-dialog.component.html',
  styleUrls: ['./notification-history-dialog.component.scss']
})
export class NotificationHistoryDialogComponent implements OnInit, OnDestroy {
  notificationHistoryList: NotificationItemHistory[];
  dataSource;
  displayedColumns: string[] = ['notification','posted'];
  subs: Subscription = new Subscription;

  constructor(
    private dialogRef: MatDialogRef<NotificationHistoryDialogComponent>,
    private store: Store<{ uiState: UiState, 
      applications: Applications,
      userState: UserState }>,
  ) { }


  ngOnInit(): void {
    this.subs.add(
      this.store.select(state => state.userState.notificationhistoryList).subscribe( (data) => {
        if (data != null) {
          let filteredData = data.filter(n => n.notificationID)
          this.notificationHistoryList = filteredData;
          this.dataSource = new MatTableDataSource(filteredData);
        }
      })
    )
  }

  onCloseNotificationClick(){
    this.dialogRef.close();
  }

  ngOnDestroy(){
    this.subs.unsubscribe();
  }
 
}
