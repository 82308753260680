import { AnalyticsActionTypes } from './analytics.actions';
import { AnalyticsState } from '../models/analytics';

export const initialState = {
  Reports: [],
  Dashboards: [],
  Tiles: [],
  ReportEmbedConfigs: null,
  SelectedReportEmbedConfig: null,
  DashboardEmbedConfig: null,
  TileEmbedConfig: null,
  Pages: [],
  SelectedPage: null
};

export function analyticsStateReducer(state = initialState, action: any ): AnalyticsState {
  switch (action.type) {
    case AnalyticsActionTypes.REPORTS_RES: {
      return { ...state, Reports: action.data };
    }
    case AnalyticsActionTypes.DASHBOARDS_RES: {
      return { ...state, Dashboards: action.data };
    }
    case AnalyticsActionTypes.TILES_RES: {
      return { ...state, Tiles: action.data };
    }
    case AnalyticsActionTypes.REPORT_EMBED_RES: {
      return { ...state, ReportEmbedConfigs: action.data };
    }
    case AnalyticsActionTypes.REPORT_EMBED_SILENT_RES: {
      return { ...state, ReportEmbedConfigs: action.data };
    }
    case AnalyticsActionTypes.DASHBOARD_EMBED_RES: {
      return { ...state, DashboardEmbedConfig: action.data };
    }
    case AnalyticsActionTypes.TILE_EMBED_RES: {
      return { ...state, TileEmbedConfig: action.data };
    }
    case AnalyticsActionTypes.CHANGE_REPORT_PAGE: {
      return { ...state, SelectedPage: action.pageName };
    }
    case AnalyticsActionTypes.CHANGE_REPORT: {
      return { ...state, SelectedReportEmbedConfig: action.config };
    }
    case AnalyticsActionTypes.REPORT_PAGES_RES: {
      return { ...state, Pages: action.data };
    }
    case AnalyticsActionTypes.CLEAR_REPORTS: {
      return { ...state, ReportEmbedConfigs: null, Pages: null}
    }
    default: {
      return state;
    }
  }
}







