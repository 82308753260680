import { createAction, props } from '@ngrx/store';
import { FileUploadResponse, User, UserRequest, ValidateUserDetailRequest } from 'src/app/models/User-Access-Management/user';
import { EntityGroupRequest, EntityGroupResponse, EntityGroupsDTO, UpdateDeleteResponse } from 'src/app/models/User-Access-Management/entity-groups';
import { Role, RoleRequest } from 'src/app/models/User-Access-Management/roles';
import { PermissionResponse } from 'src/app/models/User-Access-Management/permissions';
import { ClientResponse } from 'src/app/models/User-Access-Management/client';
import { EntityGroupsHospitalDTO } from 'src/app/models/User-Access-Management/entity-group-hospital';
import { EntityGroupsPharmacyDTO } from 'src/app/models/User-Access-Management/entity-group-pharmacy';
import { UserDetails, UserGroupRequest, UserGroupsDTO } from 'src/app/models/User-Access-Management/user-groups';
import { UserEntityRoleItemResponse, UserEntityRoleRequest } from 'src/app/models/User-Access-Management/user-entity-role';
import { CorporateParentUAMDTO } from 'src/app/models/User-Access-Management/corporate-parent';
import { ChainGroupUAMDTO } from 'src/app/models/User-Access-Management/chain-group';
import { UserClientAccess, UserClientAccessRequest } from 'src/app/models/User-Access-Management/user-client-access';
import { ClientFeatureRequestById, ClientProductFeatureRequest, ProductClientFeature } from 'src/app/models/User-Access-Management/product-client-features';
import { BulkUserErrorDetailResponse, BulkUserUploadListResponse } from '../../models/User-Access-Management/bullk-user-upload';
import { TempAccess, TempAccessRequest } from 'src/app/models/temp-access';
import { UserGroupHistoryMember } from 'src/app/models/User-Access-Management/user-group-history-response';
import { EntityGroupHistory } from 'src/app/models/User-Access-Management/entity-group-history';
import { UserHistory } from 'src/app/models/User-Access-Management/user-history-response';
import { RolesHistory } from 'src/app/models/User-Access-Management/roles-history';

export enum UAMActionTypes {
  ROLE_GET = '[Rest Call] [UAM] RolesGet',
  ROLE_GET_RES = '[Rest Result] [UAM] RolesRes',
  ROLE_GET_ERR = '[Rest Error] [UAM] RolessErr',

  ROLE_UPDATE = '[Rest Call] [UAM] UpdateRole',
  ROLE_UPDATE_RES = '[Rest Result] [UAM] UpdateRoleRes',
  ROLE_UPDATE_ERR = '[Rest Error] [UAM] UpdateRoleErr',

  ROLE_DELETE = '[Rest Call] [UAM] RolesDelete',
  ROLE_DELETE_RES = '[Rest Result] [UAM] RolesDeleteRes',
  ROLE_DELETE_ERR = '[Rest Error] [UAM] RolesDeleteErr',

  PERMISSIONS_GET = '[Rest Call] [UAM] PermissionsGet',
  PERMISSIONS_GET_RES = '[Rest Result] [UAM] PermissionsRes',
  PERMISSIONS_GET_ERR = '[Rest Error] [UAM] PermissionsErr',

  ENTITY_GROUPS_GET = '[Rest Call] [UAM] EntityGroupsGet',
  ENTITY_GROUPS_GET_RES = '[Rest Result] [UAM] EntityGroupsRes',
  ENTITY_GROUPS_GET_ERR = '[Rest Error] [UAM] EntityGroupsErr',

  NONPARENTAL_ENTITY_GROUPS_GET = '[Rest Call] [UAM] NonparentalEntityGroupsGet',
  NONPARENTAL_ENTITY_GROUPS_GET_RES = '[Rest Result] [UAM] NonparentalEntityGroupsRes',
  NONPARENTAL_ENTITY_GROUPS_GET_ERR = '[Rest Error] [UAM] NonparentalEntityGroupsErr',

  ENTITY_GROUPS_DELETE = '[Rest Call] [UAM] EntityGroupsDelete',
  ENTITY_GROUPS_DELETE_RES = '[Rest Result] [UAM] EntityGroupsDeleteRes',
  ENTITY_GROUPS_DELETE_ERR = '[Rest Error] [UAM] EntityGroupsDeleteErr',

  USER_GET = '[Rest Call] [UAM] GetUsers',
  USER_GET_RES = '[Rest Result] [UAM] GetUsersResults',
  USER_GET_ERR = '[Rest Error] [UAM] GetUsersError',

  USER_ADDUPDATE = '[Rest Call] [UAM] AddUpdateUser',
  USER_ADDUPDATE_RES = '[Rest Result] [UAM] AddUpdateUserRes',
  USER_ADDUPDATE_ERR = '[Rest Error] [UAM] AddUpdateUserErr',

  ROLE_PERMISSIONS_GET = '[Rest Call] [UAM] GetPermissionsByRoleGet',
  ROLE_PERMISSIONS_GET_RES = '[Rest Result] [UAM] GetPermissionsByRoleRes',
  ROLE_PERMISSIONS_GET_ERR = '[Rest Error] [UAM] GetPermissionsByRoleErr',

  CLIENT_GET = '[Rest Call] [UAM] ClientsGet',
  CLIENT_GET_RES = '[Rest Result] [UAM] ClientsGetRes',
  CLIENT_GET_ERR = '[Rest Error] [UAM] ClientsGetErr',

  ENTITY_GROUPS_HOSPITAL_GET = '[Rest Call] [UAMAction] EntityGroupsHospitalGet',
  ENTITY_GROUPS_HOSPITAL_GET_RES = '[Rest Result] [UAMAction] EntityGroupsHospitalRes',
  ENTITY_GROUPS_HOSPITAL_GET_ERR = '[Rest Error] [UAMAction] EntityGroupsHospitalErr',

  ADD_ENTITY_GROUP_MEMBER = '[Rest Call] [UAMAction] AddEntityGroupMembers',
  ADD_ENTITY_GROUP_MEMBER_RES = '[Rest Result] [UAMAction] AddEntityGroupMembersRes',
  ADD_ENTITY_GROUP_MEMBER_ERR = '[Rest Error] [UAMAction] AddEntityGroupMembersErr',

  ENTITY_GROUPS_PHARMACY_GET = '[Rest Call] [UAMAction] EntityGroupsPharmacyGet',
  ENTITY_GROUPS_PHARMACY_GET_RES = '[Rest Result] [UAMAction] EntityGroupsPharmacyRes',
  ENTITY_GROUPS_PHARMACY_GET_ERR = '[Rest Error] [UAMAction] EntityGroupsPharmacyErr',

  USER_GROUPS_GET = '[Rest Call] [UAMAction] GetUserGroups',
  USER_GROUPS_RES = '[Rest Result] [UAMAction] GetUserGroupsResult',
  USER_GROUPS_ERR = '[Rest Error] [UAMAction] GetUserGroupsError',

  USER_GROUPS_DELETE = '[Rest Call] [UAMAction] DeleteUserGroups',
  USER_GROUPS_DELETE_RES = '[Rest Result] [UAMAction] DeleteUserGroupsRes',
  USER_GROUPS_DELETE_ERR = '[Rest Error] [UAMAction] DeleteUserGroupsErr',

  USER_GROUPS_UPDATE = '[Rest Call] [UAMAction] UpdateUserGroups',
  USER_GROUPS_UPDATE_RES = '[Rest Result] [UAMAction] UpdateUserGroupsRes',
  USER_GROUPS_UPDATE_ERR = '[Rest Error] [UAMAction] UpdateUserGroupsErr',

  ENTITY_NAME_VALIDATION = '[Rest Call] [UAMAction] EntityNameValidation',
  ENTITY_NAME_VALIDATION_RES = '[Rest Result] [UAMAction] EntityNameValidationRes',
  ENTITY_NAME_VALIDATION_ERR = '[Rest Error] [UAMAction] EntityNameValidationErr',

  GET_ENTITY_GROUP_BY_ID = '[Rest Call] [UAMAction] GetEntityGroupByID',
  GET_ENTITY_GROUP_BY_ID_RES = '[Rest Result] [UAMAction] GetEntityGroupByIDRes',
  GET_ENTITY_GROUP_BY_ID_ERR = '[Rest Error] [UAMAction] GetEntityGroupByIDErr',

  Search_Corps_Chain_Groups_GET = '[Rest Call] [UAMAction] SearchCorpsChainGroups',
  Search_Corps_Chain_Groups_GET_RES = '[Rest Result] [UAMAction] SearchCorpsChainGroupsRes',
  Search_Corps_Chain_Groups_GET_ERR = '[Rest Error] [UAMAction] SearchCorpsChainGroupsErr',

  GET_USER_GROUP_BY_ID = '[Rest Call] [UAMAction] GetUserGroupByID',
  GET_USER_GROUP_BY_ID_RES = '[Rest Result] [UAMAction] GetUserGroupByIDRes',
  GET_USER_GROUP_BY_ID_ERR = '[Rest Error] [UAMAction] GetUserGroupByIDErr',

  ROLE_NAME_VALIDATION = '[Rest Call] [UAMAction] RoleNameValidation',
  ROLE_NAME_VALIDATION_RES = '[Rest Result] [UAMAction] RoleNameValidationRes',
  ROLE_NAME_VALIDATION_ERR = '[Rest Error] [UAMAction] RoleNameValidationErr',

  GET_USER_DETAILS = '[Rest Call] [UAMAction] GetUserDetails',
  GET_USER_DETAILS_RES = '[Rest Result] [UAMAction] GetUserDetailsRes',
  GET_USER_DETAILS_ERR = '[Rest Error] [UAMAction] GetUserDetailsErr',

  USER_GROUP_NAME_VALIDATION = '[Rest Call] [UAMAction] UserGroupNameValidation',
  USER_GROUP_NAME_VALIDATION_RES = '[Rest Result] [UAMAction] UserGroupNameValidationRes',
  USER_GROUP_NAME_VALIDATION_ERR = '[Rest Error] [UAMAction] UserGroupNameValidationErr',

  GET_USER_ENTITY_ROLES = '[Rest Call] [UAMAction] GetUserEntityRoles',
  GET_USER_ENTITY_ROLES_RES = '[Rest Result] [UAMAction] GetUserEntityRolesRes',
  GET_USER_ENTITY_ROLES_ERR = '[Rest Error] [UAMAction] GetUserEntityRolesErr',

  USER_GROUP_MEMBER_DELETE = '[Rest Call] [UAMAction] DeleteUserGroupMember',
  USER_GROUP_MEMBER_DELETE_RES = '[Rest Result] [UAMAction] DeleteUserGroupMemberRes',
  USER_GROUP_MEMBER_DELETE_ERR = '[Rest Error] [UAMAction] DeleteUserGroupMemberErr',

  ADD_USER_ENTITY_ROLE = '[Rest Call] [UAMAction] AddUserEntityRole',
  ADD_USER_ENTITY_ROLE_RES = '[Rest Result] [UAMAction] AddUserEntityRoleRes',
  ADD_USER_ENTITY_ROLE_ERR = '[Rest Error] [UAMAction] AddUserEntityRoleErr',

  DELETE_USER_ENTITY_ROLE = '[Rest Call] [UAMAction] DeleteUserEntityRole',
  DELETE_USER_ENTITY_ROLE_RES = '[Rest Result] [UAMAction] DeleteUserEntityRoleRes',
  DELETE_USER_ENTITY_ROLE_ERR = '[Rest Error] [UAMAction] DeleteUserEntityRoleErr',

  GET_CORPORATE_PARENT = '[Rest Call] [UAMAction] GetCorporateParent',
  GET_CORPORATE_PARENT_RES = '[Rest Result] [UAMAction] GetCorporateParentRes',
  GET_CORPORATE_PARENT_ERR = '[Rest Error] [UAMAction] GetCorporateParentErr',

  GET_CHAIN_GROUP = '[Rest Call] [UAMAction] GetChainGroup',
  GET_CHAIN_GROUP_RES = '[Rest Result] [UAMAction] GetChainGroupRes',
  GET_CHAIN_GROUP_ERR = '[Rest Error] [UAMAction] GetChainGroupErr',

  GET_USER_CLIENT_ACCESS = '[Rest Call] [UAMAction] GetUserClientAccess',
  GET_USER_CLIENT_ACCESS_RES = '[Rest Result] [UAMAction] GetUserClientAccessRes',
  GET_USER_CLIENT_ACCESS_ERR = '[Rest Error] [UAMAction] GetUserClientAccessErr',

  ADD_USER_CLIENT_ACCESS = '[Rest Call] [UAMAction] AddUserClientAccess',
  ADD_USER_CLIENT_ACCESS_RES = '[Rest Result] [UAMAction] AddUserClientAccessRes',
  ADD_USER_CLIENT_ACCESS_ERR = '[Rest Error] [UAMAction] AddUserClientAccessErr',

  VALIDATE_USER_DETAILS = '[Rest Call] [UAMAction] ValidateUserDetails',
  VALIDATE_USER_DETAILS_RES = '[Rest Result] [UAMAction] ValidateUserDetailsRes',
  VALIDATE_USER_DETAILS_ERR = '[Rest Error] [UAMAction] ValidateUserDetailsErr',

  GET_PRODUCT_CLIENT_FEATURES = '[Rest Call] [UAMAction] GetProductClientFeatures',
  GET_PRODUCT_CLIENT_FEATURES_RES = '[Rest Result] [UAMAction] GetProductClientFeaturesRes',
  GET_PRODUCT_CLIENT_FEATURES_ERR = '[Rest Error] [UAMAction] GetProductClientFeaturesErr',

  RESEND_USER_INVITE = '[Rest Call] [UAMAction] ResendUserInvite',
  RESEND_USER_INVITE_RES = '[Rest Result] [UAMAction] ResendUserInviteRes',
  RESEND_USER_INVITE_ERR = '[Rest Error] [UAMAction] ResendUserInviteErr',

  USER_ENTITY_ROLE_VALIDATION = '[Rest Call] [UAMAction] UserEntityRoleValidation',
  USER_ENTITY_ROLE_VALIDATION_RES = '[Rest Result] [UAMAction] UserEntityRoleValidationRes',
  USER_ENTITY_ROLE_VALIDATION_ERR = '[Rest Error] [UAMAction] UserEntityRoleValidationErr',

  ADD_DELETE_PRODUCT_FEATURES = '[Rest Call] [UAMAction] AddorDeleteProductFeatures',
  ADD_DELETE_PRODUCT_FEATURES_RES = '[Rest Result] [UAMAction] AddorDeleteProductFeaturesRes',
  ADD_DELETE_PRODUCT_FEATURES_ERR = '[Rest Error] [UAMAction] AddorDeleteProductFeaturesErr',

  UPDATE_USER_ENTITY_ROLE = '[Rest Call] [UAMAction] UpdateUserEntityRole',
  UPDATE_USER_ENTITY_ROLE_RES = '[Rest Result] [UAMAction] UpdateUserEntityRoleRes',
  UPDATE_USER_ENTITY_ROLE_ERR = '[Rest Error] [UAMAction] UpdateUserEntityRoleErr',

  ALL_USER_GET = '[Rest Call] [UAM] GetAllUsers',
  ALL_USER_GET_RES = '[Rest Result] [UAM] GetAllUsersResults',
  ALL_USER_GET_ERR = '[Rest Error] [UAM] GetAllUsersError',

  BULK_USER_UPLOAD_FILE = '[Rest Call] [UAM] BulkUserUploadFile',
  BULK_USER_UPLOAD_FILE_RES = '[Rest Result] [UAM] BulkUserUploadFileRes',
  BULK_USER_UPLOAD_FILE_ERR = '[Rest Error] [UAM] BulkUserUploadFileErr',

  CLEAR_USER_ACCESS_SEARCH = '[UAM] ClearUserAccessSearch',

  BULK_USER_UPLOAD_LIST = '[Rest Call] [UAM] GetBulkUserUploadList',
  BULK_USER_UPLOAD_LIST_RES = '[Rest Result] [UAM] GetBulkUserUploadListResults',
  BULK_USER_UPLOAD_LIST_ERR = '[Rest Error] [UAM] GetBulkUserUploadListError',

  BULK_USER_ERROR_DETAIL = '[Rest Call] [UAM] GetBulkUserErrorDetail',
  BULK_USER_ERROR_DETAIL_RES = '[Rest Result] [UAM] GetBulkUserErrorDetailRes',
  BULK_USER_ERROR_DETAIL_ERR = '[Rest Error] [UAM] GetBulkUserErrorDetailErr',

  BULK_USER_GROUPS_DELETE = '[Rest Call] [UAMAction] BulkDeleteUserGroups',
  BULK_USER_GROUPS_DELETE_RES = '[Rest Result] [UAMAction] BulkDeleteUserGroupsRes',
  BULK_USER_GROUPS_DELETE_ERR = '[Rest Error] [UAMAction] BulkDeleteUserGroupsErr',

  BULK_ENTITY_GROUPS_DELETE = '[Rest Call] [UAMAction] BulkDeleteEntityGroups',
  BULK_ENTITY_GROUPS_DELETE_RES = '[Rest Result] [UAMAction] BulkDeleteEntityGroupsRes',
  BULK_ENTITY_GROUPS_DELETE_ERR = '[Rest Error] [UAMAction] BulkDeleteEntityGroupsErr',

  USER_GROUPS_BY_CORP_ID_GET = '[Rest Call] [UAMAction] GetUserGroupsByCorpId',
  USER_GROUPS_BY_CORP_ID_RES = '[Rest Result] [UAMAction] GetUserGroupsByCorpIdResult',
  USER_GROUPS_BY_CORP_ID_ERR = '[Rest Error] [UAMAction] GetUserGroupsByCorpIdError',

  GET_TEMP_ACCESS_DATA_GET = '[Rest Call] [UAMAction] GetTempAccessData',
  GET_TEMP_ACCESS_DATA_RES = '[Rest Result] [UAMAction] GetTempAccessDataResult',
  GET_TEMP_ACCESS_DATA_ERR = '[Rest Error] [UAMAction] GetTempAccessDataError',

  REQUEST_TEMP_ACCESS_GET = '[Rest Call] [UAMAction] RequestTempAccess',
  REQUEST_TEMP_ACCESS_RES = '[Rest Result] [UAMAction] RequestTempAccessResult',
  REQUEST_TEMP_ACCESS_ERR = '[Rest Error] [UAMAction] RequestTempAccessError',

  BULK_ROLES_DELETE = '[Rest Call] [UAMAction] BulkDeleteRoles',
  BULK_ROLES_DELETE_RES = '[Rest Result] [UAMAction] BulkDeleteRolesRes',
  BULK_ROLES_DELETE_ERR = '[Rest Error] [UAMAction] BulkDeleteRolesErr',

  USER_DELETE = '[Rest Call] [UAMAction] DeleteUser',
  USER_DELETE_RES = '[Rest Result] [UAMAction] DeleteUserRes',
  USER_DELETE_ERR = '[Rest Error] [UAMAction] DeleteUserErr',

  GET_USER_GROUP_HISTORY = '[Rest Call] [UAMAction] GetUserGroupHistory',
  GET_USER_GROUP_HISTORY_RES = '[Rest Result] [UAMAction] GetUserGroupHistoryRes',
  GET_USER_GROUP_HISTORY_ERR = '[Rest Error] [UAMAction] GetUserGroupHistoryErr',

  VIEW_ENTITY_GROUP_HIS = '[Rest Call] [UAMAction] ViewEntityGroupHistory',
  VIEW_ENTITY_GROUP_HIS_RES = '[Rest Result] [UAMAction] ViewEntityGroupHistoryRes',
  VIEW_ENTITY_GROUP_HIS_ERR = '[Rest Error] [UAMAction] ViewEntityGroupHistoryErr',

  GET_USER_HISTORY = '[Rest Call] [UAMAction] GetUserHistory',
  GET_USER_HISTORY_RES = '[Rest Result] [UAMAction] GetUserHistoryRes',
  GET_USER_HISTORY_ERR = '[Rest Error] [UAMAction] GetUserHistoryErr',
  
  VIEW_ROLES_HIS = '[Rest Call] [UAMAction] ViewRolesHistory',
  VIEW_ROLES_HIS_RES = '[Rest Result] [UAMAction] ViewRolesHistoryRes',
  VIEW_ROLES_HIS_ERR = '[Rest Error] [UAMAction] ViewRolesHistoryErr',
}


export const GetRoles = createAction(UAMActionTypes.ROLE_GET);
export const GetRolesResults = createAction(UAMActionTypes.ROLE_GET_RES, props<{ roles: Role[] }>());
export const GetRolesError = createAction(UAMActionTypes.ROLE_GET_ERR, props<{ errorMessage: string }>());

export const DeleteRoles = createAction(UAMActionTypes.ROLE_DELETE, props<{ id: number }>());
export const DeleteRolesResults = createAction(UAMActionTypes.ROLE_DELETE_RES, props<{ response: UpdateDeleteResponse }>());
export const DeleteRolesError = createAction(UAMActionTypes.ROLE_DELETE_ERR, props<{ errorMessage: string }>());

export const UpdateRole = createAction(UAMActionTypes.ROLE_UPDATE, props<{ role: RoleRequest }>());
export const UpdateRoleResults = createAction(UAMActionTypes.ROLE_UPDATE_RES, props<{ response: number }>());
export const UpdateRoleError = createAction(UAMActionTypes.ROLE_UPDATE_ERR, props<{ errorMessage: string }>());

export const GetPermissionsByRole = createAction(UAMActionTypes.ROLE_PERMISSIONS_GET, props<{ roleId: number }>());
export const GetPermissionsByRoleResults = createAction(UAMActionTypes.ROLE_PERMISSIONS_GET_RES, props<{ response: PermissionResponse }>());
export const GetPermissionsByRoleError = createAction(UAMActionTypes.ROLE_PERMISSIONS_GET_ERR, props<{ errorMessage: string }>());

export const GetPermissions = createAction(UAMActionTypes.PERMISSIONS_GET);
export const GetPermissionsResults = createAction(UAMActionTypes.PERMISSIONS_GET_RES, props<{ response: PermissionResponse }>());
export const GetPermissionsError = createAction(UAMActionTypes.PERMISSIONS_GET_ERR, props<{ errorMessage: string }>());

export const GetEntityGroups = createAction(UAMActionTypes.ENTITY_GROUPS_GET);
export const GetEntityGroupsResults = createAction(UAMActionTypes.ENTITY_GROUPS_GET_RES, props<{ entityGroups: EntityGroupsDTO }>());
export const GetEntityGroupsError = createAction(UAMActionTypes.ENTITY_GROUPS_GET_ERR, props<{ errorMessage: string }>());

export const GetNonparentalEntityGroups = createAction(UAMActionTypes.NONPARENTAL_ENTITY_GROUPS_GET, props<{ entityGroupId: number, IsInternal: boolean }>());
export const GetNonparentalEntityGroupsResults = createAction(UAMActionTypes.NONPARENTAL_ENTITY_GROUPS_GET_RES, props<{ entityGroups: EntityGroupsDTO }>());
export const GetNonparentalEntityGroupsError = createAction(UAMActionTypes.NONPARENTAL_ENTITY_GROUPS_GET_ERR, props<{ errorMessage: string }>());

export const DeleteEntityGroups = createAction(UAMActionTypes.ENTITY_GROUPS_DELETE, props<{ entityGroupId: number }>());
export const DeleteEntityGroupsResults = createAction(UAMActionTypes.ENTITY_GROUPS_DELETE_RES, props<{ response: UpdateDeleteResponse }>());
export const DeleteEntityGroupsError = createAction(UAMActionTypes.ENTITY_GROUPS_DELETE_ERR, props<{ errorMessage: string }>());

export const GetUsers = createAction(UAMActionTypes.USER_GET);
export const GetUsersResults = createAction(UAMActionTypes.USER_GET_RES, props<{ data: User[] }>());
export const GetUsersError = createAction(UAMActionTypes.USER_GET_ERR, props<{ errorMessage: string }>());

export const AddUpdateUser = createAction(UAMActionTypes.USER_ADDUPDATE, props<{ newUser: UserRequest }>());
export const AddUpdateUserResults = createAction(UAMActionTypes.USER_ADDUPDATE_RES, props<{ newUser: UserRequest }>());
export const AddUpdateUserError = createAction(UAMActionTypes.USER_ADDUPDATE_ERR, props<{ errorMessage: string }>());

export const GetClients = createAction(UAMActionTypes.CLIENT_GET);
export const GetClientsResults = createAction(UAMActionTypes.CLIENT_GET_RES, props<{ response: ClientResponse }>());
export const GetClientsError = createAction(UAMActionTypes.CLIENT_GET_ERR, props<{ errorMessage: string }>());

export const GetEntityGroupsHospital = createAction(UAMActionTypes.ENTITY_GROUPS_HOSPITAL_GET);
export const GetEntityGroupsHospitalResults = createAction(UAMActionTypes.ENTITY_GROUPS_HOSPITAL_GET_RES, props<{ entityGroupsHospitalList: EntityGroupsHospitalDTO }>());
export const GetEntityGroupsHospitalError = createAction(UAMActionTypes.ENTITY_GROUPS_HOSPITAL_GET_ERR, props<{ errorMessage: string }>());

export const AddEntityGroupMembers = createAction(UAMActionTypes.ADD_ENTITY_GROUP_MEMBER, props<{ entityGroupsHospitalRequest: EntityGroupRequest }>());
export const AddEntityGroupMembersResults = createAction(UAMActionTypes.ADD_ENTITY_GROUP_MEMBER_RES, props<{ result: any }>());
export const AddEntityGroupMembersError = createAction(UAMActionTypes.ADD_ENTITY_GROUP_MEMBER_ERR, props<{ errorMessage: string }>());

export const GetEntityGroupsPharmacy = createAction(UAMActionTypes.ENTITY_GROUPS_PHARMACY_GET);
export const GetEntityGroupsPharmacyResults = createAction(UAMActionTypes.ENTITY_GROUPS_PHARMACY_GET_RES, props<{ entityGroupPharmacyList: EntityGroupsPharmacyDTO }>());
export const GetEntityGroupsPharmacyError = createAction(UAMActionTypes.ENTITY_GROUPS_PHARMACY_GET_ERR, props<{ errorMessage: string }>());

export const GetUserGroups = createAction(UAMActionTypes.USER_GROUPS_GET);
export const GetUserGroupsResults = createAction(UAMActionTypes.USER_GROUPS_RES, props<{ userGroupsList: UserGroupsDTO }>());
export const GetUserGroupsError = createAction(UAMActionTypes.USER_GROUPS_ERR, props<{ errorMessage: string }>());

export const DeleteUserGroup = createAction(UAMActionTypes.USER_GROUPS_DELETE, props<{ userGroupId: number }>());
export const DeleteUserGroupResult = createAction(UAMActionTypes.USER_GROUPS_DELETE_RES, props<{ response: UpdateDeleteResponse }>());
export const DeleteUserGroupError = createAction(UAMActionTypes.USER_GROUPS_DELETE_ERR, props<{ errorMessage: string }>());


export const UpdateUserGroups = createAction(UAMActionTypes.USER_GROUPS_UPDATE, props<{ userGroupRequest: UserGroupRequest }>());
export const UpdateUserGroupsResults = createAction(UAMActionTypes.USER_GROUPS_UPDATE_RES, props<{ result: any }>());
export const UpdateUserGroupsError = createAction(UAMActionTypes.USER_GROUPS_UPDATE_ERR, props<{ errorMessage: string }>());

export const EntityNameValidation = createAction(UAMActionTypes.ENTITY_NAME_VALIDATION, props<{ entityName: string }>());
export const EntityNameValidationResults = createAction(UAMActionTypes.ENTITY_NAME_VALIDATION_RES, props<{ result: boolean }>());
export const EntityNameValidationError = createAction(UAMActionTypes.ENTITY_NAME_VALIDATION_ERR, props<{ errorMessage: string }>());

export const SearchCorpsChainGroups = createAction(UAMActionTypes.Search_Corps_Chain_Groups_GET, props<{ keyword: string, searchType: number }>());
export const SearchCorpsChainGroupsResults = createAction(UAMActionTypes.Search_Corps_Chain_Groups_GET_RES, props<{ response: ClientResponse }>());
export const SearchCorpsChainGroupsError = createAction(UAMActionTypes.Search_Corps_Chain_Groups_GET_ERR, props<{ errorMessage: string }>());

export const GetEntityGroupByID = createAction(UAMActionTypes.GET_ENTITY_GROUP_BY_ID, props<{ entityGroupID: number }>());
export const GetEntityGroupByIDResults = createAction(UAMActionTypes.GET_ENTITY_GROUP_BY_ID_RES, props<{ entityGroupResponse: EntityGroupResponse }>());
export const GetEntityGroupByIDError = createAction(UAMActionTypes.GET_ENTITY_GROUP_BY_ID_ERR, props<{ errorMessage: string }>());

export const GetUserGroupByID = createAction(UAMActionTypes.GET_USER_GROUP_BY_ID, props<{ userGroupID: number }>());
export const GetUserGroupByIDResults = createAction(UAMActionTypes.GET_USER_GROUP_BY_ID_RES, props<{ userGroupsResponse: UserGroupRequest }>());
export const GetUserGroupByIDError = createAction(UAMActionTypes.GET_USER_GROUP_BY_ID_ERR, props<{ errorMessage: string }>());

export const RoleNameValidation = createAction(UAMActionTypes.ROLE_NAME_VALIDATION, props<{ roleName: string }>());
export const RoleNameValidationResults = createAction(UAMActionTypes.ROLE_NAME_VALIDATION_RES, props<{ result: boolean }>());
export const RoleNameValidationError = createAction(UAMActionTypes.ROLE_NAME_VALIDATION_ERR, props<{ errorMessage: string }>());

export const GetUserDetails = createAction(UAMActionTypes.GET_USER_DETAILS, props<{ userId: number }>());
export const GetUserDetailsResults = createAction(UAMActionTypes.GET_USER_DETAILS_RES, props<{ userDetails: UserDetails }>());
export const GetUserDetailsError = createAction(UAMActionTypes.GET_USER_DETAILS_ERR, props<{ errorMessage: string }>());

export const UserGroupNameValidation = createAction(UAMActionTypes.USER_GROUP_NAME_VALIDATION, props<{ userGroupName: string }>());
export const UserGroupNameValidationResults = createAction(UAMActionTypes.USER_GROUP_NAME_VALIDATION_RES, props<{ result: boolean }>());
export const UserGroupNameValidationError = createAction(UAMActionTypes.USER_GROUP_NAME_VALIDATION_ERR, props<{ errorMessage: string }>());

export const GetUserEntityRoles = createAction(UAMActionTypes.GET_USER_ENTITY_ROLES);
export const GetUserEntityRolesResults = createAction(UAMActionTypes.GET_USER_ENTITY_ROLES_RES, props<{ response: UserEntityRoleItemResponse }>());
export const GetUserEntityRolesError = createAction(UAMActionTypes.GET_USER_ENTITY_ROLES_ERR, props<{ errorMessage: string }>());

export const DeleteUserGroupMember = createAction(UAMActionTypes.USER_GROUP_MEMBER_DELETE, props<{ userGroupMemberId: number }>());
export const DeleteUserGroupMemberResult = createAction(UAMActionTypes.USER_GROUP_MEMBER_DELETE_RES, props<{ response: UpdateDeleteResponse }>());
export const DeleteUserGroupMemberError = createAction(UAMActionTypes.USER_GROUP_MEMBER_DELETE_ERR, props<{ errorMessage: string }>());

export const AddUserEntityRole = createAction(UAMActionTypes.ADD_USER_ENTITY_ROLE, props<{ userEntityRole: UserEntityRoleRequest }>());
export const AddUserEntityRoleResults = createAction(UAMActionTypes.ADD_USER_ENTITY_ROLE_RES, props<{ response: any }>());
export const AddUserEntityRoleError = createAction(UAMActionTypes.ADD_USER_ENTITY_ROLE_ERR, props<{ errorMessage: string }>());

export const DeleteUserEntityRole = createAction(UAMActionTypes.DELETE_USER_ENTITY_ROLE, props<{ userEntityRole: UserEntityRoleRequest[] }>());
export const DeleteUserEntityRoleResults = createAction(UAMActionTypes.DELETE_USER_ENTITY_ROLE_RES, props<{ response: UpdateDeleteResponse }>());
export const DeleteUserEntityRoleError = createAction(UAMActionTypes.DELETE_USER_ENTITY_ROLE_ERR, props<{ errorMessage: string }>());

export const GetCorporateParent = createAction(UAMActionTypes.GET_CORPORATE_PARENT);
export const GetCorporateParentResults = createAction(UAMActionTypes.GET_CORPORATE_PARENT_RES, props<{ corporateParentList: CorporateParentUAMDTO }>());
export const GetCorporateParentError = createAction(UAMActionTypes.GET_CORPORATE_PARENT_ERR, props<{ errorMessage: string }>());

export const GetChainGroup = createAction(UAMActionTypes.GET_CHAIN_GROUP);
export const GetChainGroupResults = createAction(UAMActionTypes.GET_CHAIN_GROUP_RES, props<{ chainGroupList: ChainGroupUAMDTO }>());
export const GetChainGroupError = createAction(UAMActionTypes.GET_CHAIN_GROUP_ERR, props<{ errorMessage: string }>());

export const GetUserClientAccessData = createAction(UAMActionTypes.GET_USER_CLIENT_ACCESS, props<{ userId: number }>());
export const GetUserClientAccessDataResults = createAction(UAMActionTypes.GET_USER_CLIENT_ACCESS_RES, props<{ userClientAccessResponse: UserClientAccess }>());
export const GetUserClientAccessDataError = createAction(UAMActionTypes.GET_USER_CLIENT_ACCESS_ERR, props<{ errorMessage: string }>());

export const AddUserClientAccessData = createAction(UAMActionTypes.ADD_USER_CLIENT_ACCESS, props<{ userClientAccessRequest: UserClientAccessRequest }>());
export const AddUserClientAccessDataResults = createAction(UAMActionTypes.ADD_USER_CLIENT_ACCESS_RES, props<{ response: any }>());
export const AddUserClientAccessDataError = createAction(UAMActionTypes.ADD_USER_CLIENT_ACCESS_ERR, props<{ errorMessage: string }>());

export const ValidateUserDetails = createAction(UAMActionTypes.VALIDATE_USER_DETAILS, props<{ userdetails: ValidateUserDetailRequest }>());
export const ValidateUserDetailsResults = createAction(UAMActionTypes.VALIDATE_USER_DETAILS_RES, props<{ response: any }>());
export const ValidateUserDetailsError = createAction(UAMActionTypes.VALIDATE_USER_DETAILS_ERR, props<{ errorMessage: string }>());

export const GetProductClientFeatures = createAction(UAMActionTypes.GET_PRODUCT_CLIENT_FEATURES, props<{ clientFeatureRequestById: ClientFeatureRequestById }>());
export const GetProductClientFeaturesResults = createAction(UAMActionTypes.GET_PRODUCT_CLIENT_FEATURES_RES, props<{ productClientFeatureResp: ProductClientFeature }>());
export const GetProductClientFeaturesError = createAction(UAMActionTypes.GET_PRODUCT_CLIENT_FEATURES_ERR, props<{ errorMessage: string }>());

export const ResendUserInvite = createAction(UAMActionTypes.RESEND_USER_INVITE, props<{ userId: number }>());
export const ResendUserInviteResults = createAction(UAMActionTypes.RESEND_USER_INVITE_RES, props<{ response: string }>());
export const ResendUserInviteError = createAction(UAMActionTypes.RESEND_USER_INVITE_ERR, props<{ errorMessage: string }>());

export const UserEntityRoleValidation = createAction(UAMActionTypes.USER_ENTITY_ROLE_VALIDATION, props<{ userEntityRole: UserEntityRoleRequest }>());
export const UserEntityRoleValidationResults = createAction(UAMActionTypes.USER_ENTITY_ROLE_VALIDATION_RES, props<{ result: number }>());
export const UserEntityRoleValidationError = createAction(UAMActionTypes.USER_ENTITY_ROLE_VALIDATION_ERR, props<{ errorMessage: string }>());

export const AddorDeleteProductFeatures = createAction(UAMActionTypes.ADD_DELETE_PRODUCT_FEATURES, props<{ productFeatureResp: ClientProductFeatureRequest[] }>());
export const AddorDeleteProductFeaturesResults = createAction(UAMActionTypes.ADD_DELETE_PRODUCT_FEATURES_RES, props<{ response: any }>());
export const AddorDeleteProductFeaturesError = createAction(UAMActionTypes.ADD_DELETE_PRODUCT_FEATURES_ERR, props<{ errorMessage: string }>());

export const UpdateUserEntityRole = createAction(UAMActionTypes.UPDATE_USER_ENTITY_ROLE, props<{ userEntityRole: UserEntityRoleRequest }>());
export const UpdateUserEntityRoleResults = createAction(UAMActionTypes.UPDATE_USER_ENTITY_ROLE_RES, props<{ response: any }>());
export const UpdateUserEntityRoleError = createAction(UAMActionTypes.UPDATE_USER_ENTITY_ROLE_ERR, props<{ errorMessage: string }>());

export const GetAllUsers = createAction(UAMActionTypes.ALL_USER_GET);
export const GetAllUsersResults = createAction(UAMActionTypes.ALL_USER_GET_RES, props<{ data: User[] }>());
export const GetAllUsersError = createAction(UAMActionTypes.ALL_USER_GET_ERR, props<{ errorMessage: string }>());

export const BulkUserUpload = createAction(UAMActionTypes.BULK_USER_UPLOAD_FILE, props<{ file: FormData }>());
export const BulkUserUploadResults = createAction(UAMActionTypes.BULK_USER_UPLOAD_FILE_RES, props<{ data: FileUploadResponse }>());
export const BulkUserUploadError = createAction(UAMActionTypes.BULK_USER_UPLOAD_FILE_ERR, props<{ errorMessage: string }>());

export const ClearUserAccessSearch = createAction(UAMActionTypes.CLEAR_USER_ACCESS_SEARCH);



export const GetBulkUserUploadList = createAction(UAMActionTypes.BULK_USER_UPLOAD_LIST);
export const GetBulkUserUploadListResults = createAction(UAMActionTypes.BULK_USER_UPLOAD_LIST_RES, props<{ data: BulkUserUploadListResponse }>());
export const GetBulkUserUploadListError = createAction(UAMActionTypes.BULK_USER_UPLOAD_LIST_ERR, props<{ errorMessage: string }>());

export const GetBulkUserErrorDetail = createAction(UAMActionTypes.BULK_USER_ERROR_DETAIL, props<{ importId: string }>());
export const GetBulkUserErrorDetailResults = createAction(UAMActionTypes.BULK_USER_ERROR_DETAIL_RES, props<{ data: BulkUserErrorDetailResponse }>());
export const GetBulkUserErrorDetailError = createAction(UAMActionTypes.BULK_USER_ERROR_DETAIL_ERR, props<{ errorMessage: string }>());

export const BulkDeleteUserGroups = createAction(UAMActionTypes.BULK_USER_GROUPS_DELETE, props<{ userGroupId: number[] }>());
export const BulkDeleteUserGroupsResults = createAction(UAMActionTypes.BULK_USER_GROUPS_DELETE_RES, props<{ response: UpdateDeleteResponse }>());
export const BulkDeleteUserGroupsError = createAction(UAMActionTypes.BULK_USER_GROUPS_DELETE_ERR, props<{ errorMessage: string }>());

export const BulkDeleteEntityGroups = createAction(UAMActionTypes.BULK_ENTITY_GROUPS_DELETE, props<{ entityGroupId: number[] }>());
export const BulkDeleteEntityGroupsResults = createAction(UAMActionTypes.BULK_ENTITY_GROUPS_DELETE_RES, props<{ response: UpdateDeleteResponse }>());
export const BulkDeleteEntityGroupsError = createAction(UAMActionTypes.BULK_ENTITY_GROUPS_DELETE_ERR, props<{ errorMessage: string }>());

export const GetUserGroupsByCorpId = createAction(UAMActionTypes.USER_GROUPS_BY_CORP_ID_GET, props<{ corpId: number, csgId: number }>());
export const GetUserGroupsByCorpIdResults = createAction(UAMActionTypes.USER_GROUPS_BY_CORP_ID_RES, props<{ userGroupsList: UserGroupsDTO }>());
export const GetUserGroupsByCorpIdError = createAction(UAMActionTypes.USER_GROUPS_BY_CORP_ID_ERR, props<{ errorMessage: string }>());

export const GetTempAccessData = createAction(UAMActionTypes.GET_TEMP_ACCESS_DATA_GET, props<{ userGroupId: number }>());
export const GetTempAccessDataResults = createAction(UAMActionTypes.GET_TEMP_ACCESS_DATA_RES, props<{ tempAccessData: TempAccess[] }>());
export const GetTempAccessDataError = createAction(UAMActionTypes.GET_TEMP_ACCESS_DATA_ERR, props<{ errorMessage: string }>());

export const RequestTempAccess = createAction(UAMActionTypes.REQUEST_TEMP_ACCESS_GET, props<{ tempAccessModel: TempAccessRequest }>());
export const RequestTempAccessResults = createAction(UAMActionTypes.REQUEST_TEMP_ACCESS_RES, props<{ tempAccessResponse: string }>());
export const RequestTempAccessError = createAction(UAMActionTypes.REQUEST_TEMP_ACCESS_ERR, props<{ errorMessage: string }>());

export const BulkDeleteRoles = createAction(UAMActionTypes.BULK_ROLES_DELETE, props<{ roleIds: number[] }>());
export const BulkDeleteRolesResults = createAction(UAMActionTypes.BULK_ROLES_DELETE_RES, props<{ response: UpdateDeleteResponse }>());
export const BulkDeleteRolesError = createAction(UAMActionTypes.BULK_ROLES_DELETE_ERR, props<{ errorMessage: string }>());

export const DeleteUser = createAction(UAMActionTypes.USER_DELETE, props<{ userId: number }>());
export const DeleteUserResult = createAction(UAMActionTypes.USER_DELETE_RES, props<{ response: UpdateDeleteResponse }>());
export const DeleteUserError = createAction(UAMActionTypes.USER_DELETE_ERR, props<{ errorMessage: string }>());

export const GetUserGroupHistory = createAction(UAMActionTypes.GET_USER_GROUP_HISTORY, props<{ userGroupId: number }>());
export const GetUserGroupHistoryResult = createAction(UAMActionTypes.GET_USER_GROUP_HISTORY_RES, props<{ response: UserGroupHistoryMember[] }>());
export const GetUserGroupHistoryError = createAction(UAMActionTypes.GET_USER_GROUP_HISTORY_ERR, props<{ errorMessage: string }>());

export const ViewEntityGroupHistory = createAction(UAMActionTypes.VIEW_ENTITY_GROUP_HIS, props<{ entityGroupId: number }>());
export const ViewEntityGroupHistoryResult = createAction(UAMActionTypes.VIEW_ENTITY_GROUP_HIS_RES, props<{ viewHistoryresponse: EntityGroupHistory[] }>());
export const ViewEntityGroupHistoryError = createAction(UAMActionTypes.VIEW_ENTITY_GROUP_HIS_ERR, props<{ errorMessage: string }>());

export const GetUserHistory = createAction(UAMActionTypes.GET_USER_HISTORY, props<{ userId: number }>());
export const GetUserHistoryResult = createAction(UAMActionTypes.GET_USER_HISTORY_RES, props<{ response: UserHistory[] }>());
export const GetUserHistoryError = createAction(UAMActionTypes.GET_USER_HISTORY_ERR, props<{ errorMessage: string }>());

export const ViewRolesHistory = createAction(UAMActionTypes.VIEW_ROLES_HIS, props<{ roleId: number }>());
export const ViewRolesHistoryResult = createAction(UAMActionTypes.VIEW_ROLES_HIS_RES, props<{ roleHistoryresponse: RolesHistory[] }>());
export const ViewRolesHistoryError = createAction(UAMActionTypes.VIEW_ROLES_HIS_ERR, props<{ errorMessage: string }>());
