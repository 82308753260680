import { createAction, props } from "@ngrx/store";
import { ApplyQualRuleResponse, QualificationRulePharmacy, QualificationRulesResponse, SelectedQualRules } from "src/app/models/qualification-rules/qualification-rules-reponse";

export enum QualRulesActionTypes {
    GET_QUAL_RULES_LIST = "[Rest Call] [qualRulesList] GetQualRulesList",
    GET_QUAL_RULES_LIST_RES = "[Rest Result] [qualRulesList] GetQualRulesListResults",
    GET_QUAL_RULES_LIST_ERR = "[Rest Error] [qualRulesList] GetQualRulesListError",

    PHARMACY_GET = '[Rest Call] [qualRulesList] GetPharmaciesByUser',
    PHARMACY_GET_RES = '[Rest Result] [qualRulesList] GetPharmaciesByUserRes',
    PHARMACY_GET_ERR = '[Rest Error] [qualRulesList] GetPharmaciesByUserErr',

    SET_APPLY_RULE = '[Rest Call] [qualRulesList] ApplyQualRule',
    SET_APPLY_RULE_RES = '[Rest Result] [qualRulesList] ApplyQualRuleRes',
    SET_APPLY_RULE_ERR = '[Rest Error] [qualRulesList] ApplyQualRuleErr',
}

export const GetQualRulesList = createAction(QualRulesActionTypes.GET_QUAL_RULES_LIST, props<{}>());
export const GetQualRulesListResults = createAction(QualRulesActionTypes.GET_QUAL_RULES_LIST_RES, props<{ qualRules: QualificationRulesResponse[] }>());
export const GetQualRulesListError = createAction(QualRulesActionTypes.GET_QUAL_RULES_LIST_ERR, props<{ message: string }>());

export const GetPharmaciesByUser = createAction(QualRulesActionTypes.PHARMACY_GET);
export const GetPharmaciesByUserResults = createAction(QualRulesActionTypes.PHARMACY_GET_RES, props<{ qualRulePharmacy: QualificationRulePharmacy[] }>());
export const GetPharmaciesByUserError = createAction(QualRulesActionTypes.PHARMACY_GET_ERR, props<{ errorMessage: string }>());

export const ApplyQualRule = createAction(QualRulesActionTypes.SET_APPLY_RULE, props<{ qualRules: SelectedQualRules[] }>());
export const ApplyQualRuleResults = createAction(QualRulesActionTypes.SET_APPLY_RULE_RES, props<{ applyQualRuleResult: ApplyQualRuleResponse }>());
export const ApplyQualRuleError = createAction(QualRulesActionTypes.SET_APPLY_RULE_ERR, props<{ errorMessage: string }>());
