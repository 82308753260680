import { createAction, props } from '@ngrx/store';
import { AccumulationsResponse } from 'src/app/models/Pending-Packages/accumulations-by-pid';
import { DrugCatalogResponse } from 'src/app/models/Pending-Packages/drugcatalog-by-pid';
import { AssignPackagesNdcRequest, AssignPackagesNdcResponse, AssignPackagesPidRequest, AssignPackagesPidResponse, PackagePidListResponse, PendingPackageByHidResponse, PendingPackageByNdcResponse } from 'src/app/models/Pending-Packages/pending-package-expiration';

export enum PendingPackagesActionTypes {
  PACKAGES_BY_HID = '[Rest Call] [PendingPackages] PackagesByHid',
  PACKAGES_BY_HID_RES = '[Rest Result] [PendingPackages] PackagesByHidRes',
  PACKAGES_BY_HID_ERR = '[Rest Error] [PendingPackages] PackagesByHidErr',

  PACKAGES_BY_NDC = '[Rest Call] [PendingPackages] PackagesByNdc',
  PACKAGES_BY_NDC_RES = '[Rest Result] [PendingPackages] PackagesByNdcRes',
  PACKAGES_BY_NDC_ERR = '[Rest Error] [PendingPackages] PackagesByNdcErr',

  ACCUMULATIONS_BY_PID = '[Rest Call] [PendingPackages] AccumulationsByPID',
  ACCUMULATIONS_BY_PID_RES = '[Rest Result] [PendingPackages] AccumulationsByPIDRes',
  ACCUMULATIONS_BY_PID_ERR = '[Rest Error] [PendingPackages] AccumulationsByPIDErr',

  DRUG_CATALOG_BY_PID = '[Rest Call] [PendingPackages] DrugCatalogByPID',
  DRUG_CATALOG_BY_PID_RES = '[Rest Result] [PendingPackages] DrugCatalogByPIDRes',
  DRUG_CATALOG_BY_PID_ERR = '[Rest Error] [PendingPackages] DrugCatalogByPIDErr',

  PACKAGES_SHOW_PID = '[Rest Call] [PendingPackages] PackagesShowPid',
  PACKAGES_SHOW_PID_RES = '[Rest Result] [PendingPackages] PackagesShowPidRes',
  PACKAGES_SHOW_PID_ERR = '[Rest Error] [PendingPackages] PackagesShowPidErr',

  APPLY_PACKAGES_PID = '[Rest Call] [PendingPackages] ApplyPackagesPid',
  APPLY_PACKAGES_PID_RES = '[Rest Result] [PendingPackages] ApplyPackagesPidRes',
  APPLY_PACKAGES_PID_ERR = '[Rest Error] [PendingPackages] ApplyPackagesPidErr',

  APPLY_PACKAGES_NDC = '[Rest Call] [PendingPackages] ApplyPackagesNdc',
  APPLY_PACKAGES_NDC_RES = '[Rest Result] [PendingPackages] ApplyPackagesNdcRes',
  APPLY_PACKAGES_NDC_ERR = '[Rest Error] [PendingPackages] ApplyPackagesNdcErr',

  DELETE_PACKAGES = '[Rest Call] [PendingPackages] DeletePackageById',
  DELETE_PACKAGES_RES = '[Rest Result] [PendingPackages] DeletePackageByIdRes',
  DELETE_PACKAGES_ERR = '[Rest Error] [PendingPackages] DeletePackageByIdErr'
}

export const GetPackagesByHid = createAction(PendingPackagesActionTypes.PACKAGES_BY_HID);
export const GetPackagesByHidResults = createAction(PendingPackagesActionTypes.PACKAGES_BY_HID_RES, props<{ packagesByHid: PendingPackageByHidResponse }>());
export const GetPackagesByHidError = createAction(PendingPackagesActionTypes.PACKAGES_BY_HID_ERR, props<{ errorMessage: string }>());

export const GetPackagesByNdc = createAction(PendingPackagesActionTypes.PACKAGES_BY_NDC);
export const GetPackagesByNdcResults = createAction(PendingPackagesActionTypes.PACKAGES_BY_NDC_RES, props<{ packagesByNdc: PendingPackageByNdcResponse }>());
export const GetPackagesByNdcError = createAction(PendingPackagesActionTypes.PACKAGES_BY_NDC_ERR, props<{ errorMessage: string }>());

export const GetAccumulationsByPID = createAction(PendingPackagesActionTypes.ACCUMULATIONS_BY_PID, props<{ pageSize: number, pageIndex: number }>());
export const GetAccumulationsByPIDResults = createAction(PendingPackagesActionTypes.ACCUMULATIONS_BY_PID_RES, props<{ accumulations: AccumulationsResponse }>());
export const GetAccumulationsByPIDError = createAction(PendingPackagesActionTypes.ACCUMULATIONS_BY_PID_ERR, props<{ errorMessage: string }>());

export const GetDrugCatalogByPID = createAction(PendingPackagesActionTypes.DRUG_CATALOG_BY_PID, props<{ pageSize: number, pageIndex: number }>());
export const GetDrugCatalogByPIDResults = createAction(PendingPackagesActionTypes.DRUG_CATALOG_BY_PID_RES, props<{ drugCatalog: DrugCatalogResponse }>());
export const GetDrugCatalogByPIDError = createAction(PendingPackagesActionTypes.DRUG_CATALOG_BY_PID_ERR, props<{ errorMessage: string }>());

export const PackagesShowPid = createAction(PendingPackagesActionTypes.PACKAGES_SHOW_PID);
export const PackagesShowPidResults = createAction(PendingPackagesActionTypes.PACKAGES_SHOW_PID_RES, props<{ pidList: PackagePidListResponse }>());
export const PackagesShowPidError = createAction(PendingPackagesActionTypes.PACKAGES_SHOW_PID_ERR, props<{ errorMessage: string }>());

export const ApplyPackagesPid = createAction(PendingPackagesActionTypes.APPLY_PACKAGES_PID, props<{ assignPackagesPidRequest: AssignPackagesPidRequest }>());
export const ApplyPackagesPidResults = createAction(PendingPackagesActionTypes.APPLY_PACKAGES_PID_RES, props<{ applyPackagesPidResponse: AssignPackagesPidResponse }>());
export const ApplyPackagesPidErr = createAction(PendingPackagesActionTypes.APPLY_PACKAGES_PID_ERR, props<{ errorMessage: string }>());

export const ApplyPackagesNdc = createAction(PendingPackagesActionTypes.APPLY_PACKAGES_NDC, props<{ assignPackagesNdcRequest: AssignPackagesNdcRequest }>());
export const ApplyPackagesNdcResults = createAction(PendingPackagesActionTypes.APPLY_PACKAGES_NDC_RES, props<{ applyPackagesNdcResponse: AssignPackagesNdcResponse }>());
export const ApplyPackagesNdcErr = createAction(PendingPackagesActionTypes.APPLY_PACKAGES_NDC_ERR, props<{ errorMessage: string }>());

export const DeletePackagesById = createAction(PendingPackagesActionTypes.DELETE_PACKAGES, props<{ pendingPackageId: number }>());
export const DeletePackagesByIdResults = createAction(PendingPackagesActionTypes.DELETE_PACKAGES_RES, props<{ successMessage: string }>());
export const DeletePackagesByIdError = createAction(PendingPackagesActionTypes.DELETE_PACKAGES_ERR, props<{ errorMessage: string }>());