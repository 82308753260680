import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { UserState } from "../store/user.reducer";
import { BaseMhService } from "./basemh.service";
import { AccumulationsResponse } from "../models/Pending-Packages/accumulations-by-pid";
import { DrugCatalogResponse } from "../models/Pending-Packages/drugcatalog-by-pid";
import { AssignPackagesNdcRequest, AssignPackagesNdcResponse, AssignPackagesPidRequest, AssignPackagesPidResponse, PackagePidListResponse, PendingPackageByHidResponse, PendingPackageByNdcResponse } from "../models/Pending-Packages/pending-package-expiration";

@Injectable({
    providedIn: 'root'
})

export class PendingPackagesService extends BaseMhService {

    constructor(protected http: HttpClient, protected store: Store<{ userState: UserState }>) {
        super(http, store);
    }

    getPendingPackagesByHid() {
        const userSession = this.getStoreUserSession();
        return this.http
            .get(`${environment.apiBaseUrl}pendingpackages/v1/PendingPackages/getPendingPkgsByHid?pid=${userSession.pid}`, { headers: this.getHeaders() })
            .pipe(
                map((response: PendingPackageByHidResponse) => response),
                catchError(this.errorHandler)
            );
    }

    getPendingPackagesByNdc() {
        const userSession = this.getStoreUserSession();
        return this.http
            .get(`${environment.apiBaseUrl}pendingpackages/v1/PendingPackages/getPendingPkgsByNdc?pid=${userSession.pid}`, { headers: this.getHeaders() })
            .pipe(
                map((response: PendingPackageByNdcResponse) => response),
                catchError(this.errorHandler)
            );
    }

    getAccumulationsByPID(pageSize: number, pageIndex: number) {
        const userSession = this.getStoreUserSession();
        return this.http
            .get(`${environment.apiBaseUrl}pendingpackages/v1/PendingPackages/getAccumulationData?pid=${userSession.pid}&pageSize=${pageSize}&pageIndex=${pageIndex}`, { headers: this.getHeaders() })
            .pipe(
                map((response: AccumulationsResponse) => response),
                catchError(this.errorHandler)
            );
    }

    getDrugCatalogByPID(pageSize: number, pageIndex: number) {
        const userSession = this.getStoreUserSession();
        return this.http
            .get(`${environment.apiBaseUrl}pendingpackages/v1/PendingPackages/getDrugCatalogData?pid=${userSession.pid}&pageSize=${pageSize}&pageIndex=${pageIndex}`, { headers: this.getHeaders() })
            .pipe(
                map((response: DrugCatalogResponse) => response),
                catchError(this.errorHandler)
            );
    }

    packagesShowPid() {
        const userSession = this.getStoreUserSession();
        return this.http
            .post(`${environment.apiBaseUrl}pendingpackages/v1/PendingPackages/GetPharmaciesByCorpId?userId=${userSession.uid}&corpId=${userSession.corpID}`, null, { headers: this.getHeaders() })
            .pipe(
                map((response: PackagePidListResponse) => response),
                catchError(this.errorHandler)
            );
    }

    applyPackageHoursPid(requestBody: AssignPackagesPidRequest) {
        return this.http
            .post(`${environment.apiBaseUrl}pendingpackages/v1/PendingPackages/applyPendingPackagesPids`, requestBody, { headers: this.getHeaders() })
            .pipe(
                map((response: AssignPackagesPidResponse) => response),
                catchError(this.errorHandler)
            );
    }

    applyPackageHoursNdc(requestBody: AssignPackagesNdcRequest) {
        return this.http
            .post(`${environment.apiBaseUrl}pendingpackages/v1/PendingPackages/applyPendingPackagesNdcs`, requestBody, { headers: this.getHeaders() })
            .pipe(
                map((response: AssignPackagesNdcResponse) => response),
                catchError(this.errorHandler)
            );
    }

    deletePendingPackage(id: number) {
        return this.http
            .delete(`${environment.apiBaseUrl}pendingpackages/v1/PendingPackages/delete/${id}`, { headers: this.getHeaders() })
            .pipe(
                map((response: string) => response),
                catchError(this.errorHandler)
            );
    }
}
