<div>
    <div id="top-nav">

        <div id="logo">
          <img id="mh-logo" src="../../../assets/img/MH_Logo-Color.png" alt="Macro Helix" />
        </div>
      
        <div id="col-right">
            <button mat-flat-button class="btn-txt" (click)="signOut()"> Log Out </button>
            <button *ngIf="this.clientIdCookie && clientPage" mat-flat-button class="btn-txt" (click)="closeScreen()"> Close </button>
        </div>
    </div>
    
    <mat-toolbar color="primary">
      <div *ngIf="!accessDenied">
        Select the Health System or Chain you would like to view
      </div>
    </mat-toolbar>
      
    <mat-drawer-container style="height: 100%" autosize>
      <div [style.height.%]='450' autosize>
        <div *ngIf="clientPage">
          <clientID-selection></clientID-selection>
        </div>
        <div *ngIf="accessDenied">
          <access-denied></access-denied>
        </div>
      </div>
    </mat-drawer-container>
</div>
