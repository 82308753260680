<!-- Help => Submit Ticket Dialog -->
<div class="add-new-login-name-dialog">
  <div mat-dialog-title class="dialog-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
    <h3 cdkDragHandle>Add New Alternate Login Name</h3>
  </div>
  <div>
    <form #saveNewLoginNameForm="ngForm">
      <div class="dialog-body">
        <!-- HTML code for category selection dropdown -->
        <mat-form-field appearance="" class="vendor-options" required floatLabel="always">
          <mat-label>Vendor</mat-label>
          <mat-select
            class="form-control" [class.has-error]="vendorOptions.invalid || (vendorOptions.invalid && vendorOptions.touched)"
            name="vendorOptions"
            [(ngModel)]="newUserAlternateLoginName.vid"
            [(value)]="newUserAlternateLoginName.vid"
            #vendorOptions="ngModel"
            placeholder="Select a vendor..."
            required
            matInput >
              <mat-option *ngFor="let vendor of ( userState$ | async ).vendorList" [value]="vendor.vendorID">
                {{vendor.vendorName}}
              </mat-option>
          </mat-select>
          <mat-error *ngIf="vendorOptions.hasError('required')">Vendor is required, please choose a vendor</mat-error>
        </mat-form-field>

        <mat-form-field appearance="" class="login-name" floatLabel="always">
          <mat-label>Login Name</mat-label>
          <input
          type="text"
                matInput
                placeholder="Your New Login Name"
                class="form-control"
                name="login-name"
                #newLoginName="ngModel"
                [(ngModel)]="newUserAlternateLoginName.loginName"
                required
                size="40">
          <mat-error *ngIf="newLoginName.hasError('required')">New Vendor Login Name is <strong>required</strong></mat-error>
        </mat-form-field>

        <mat-error class="error-msg">
          {{errorMessage}}
        </mat-error>
      </div>
      <div class="dialog-footer">
        <button mat-flat-button type="submit" color="primary" class="submit-btn" (click)="onSubmitClick()" [disabled]="saveNewLoginNameForm.form.invalid || isNullOrWhitespace()">Submit</button>
        <button mat-stroked-button type="button" class="discard-btn" (click)="onCloseClick()">Cancel</button>
      </div>
    </form>
  </div>
</div>
