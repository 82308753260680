import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/service/user.service';
import { ClientIDSelectionComponent } from '../clientId-selection/clientID-selection.component';
import { CloseAccessDeniedPage, GetUserLogout, ShowClientSelection } from 'src/app/store/user.actions';
import { Store } from '@ngrx/store';
import { B2cLoginService } from 'src/app/service/b2clogin.service';
import { UserState } from 'src/app/store/user.reducer';
import { UamState } from 'src/app/store/uam/uam.reducer';


@Component({
  selector: 'access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss'],
})  

export class AccessDeniedComponent implements OnInit {

  @ViewChild(ClientIDSelectionComponent) clientIDSelectionComponent: ClientIDSelectionComponent;
  clientName: string = "";
  clientCount: Number;

  constructor(public userService : UserService, private store: Store<{ userState: UserState, uamState: UamState }>, private b2cService: B2cLoginService) {
      this.store.select(state => state.uamState.initialClientList).subscribe((data) => {
        if (data != null) {
          this.clientCount = data.chains.length + data.corps.length;
        }
      });
  }

  ngOnInit() {
    // not sure what scenario would get us to a place where a user has access to one or more corpids
    // and gets sent to this page, maybe theres a future use for it. for now, only reason user would
    // land on this page is if they had 0 "clientId"s, so not sure where we'd get a value for clientname
    this.clientName = this.userService.readCookie("ClientId");
    this.clientName = this.clientName?.substring(0, this.clientName.indexOf('(') - 1);

  }

  openClientSelection() {
    this.store.dispatch(CloseAccessDeniedPage());
    this.store.dispatch(ShowClientSelection());
  }

  signOut() {
    //blacklist access token
    this.store.dispatch( GetUserLogout() );
    //cookie, identity token cleanup
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    this.b2cService.LogoutCleanup();
    this.b2cService.logout();
  }


}

