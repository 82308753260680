import {createAction, props} from '@ngrx/store';

export enum UiStateTypes {
  MENU_EXPAND = '[UiState] MenuExpand',
  MENU_COLAPSE = '[UiState] MenuCollapse',
  INFO_SHOW = '[UiState] InfoShow',
  INFO_HIDE = '[UiState] InfoHide',
  ERROR_SHOW = '[UiState] ErroShow',
  ERROR_HIDE = '[UiState] ErrHide',
  CALL_START = '[UiState] CallStart',
  CALL_END = '[UiState] CallEnd',
  INIT_DONE = '[UiState] InitDone',
  REFRESH_ASP = '[UiState] RefreshAsp'
}

export const MenuExpand = createAction(UiStateTypes.MENU_EXPAND);

export const MenuColapse = createAction(UiStateTypes.MENU_COLAPSE);

export const InfoShow = createAction(UiStateTypes.INFO_SHOW, props<{message: string}>());

export const InfoHide = createAction(UiStateTypes.INFO_HIDE);

export const ErrShow = createAction(UiStateTypes.ERROR_SHOW, props<{message: string}>());

export const ErrHide = createAction(UiStateTypes.ERROR_HIDE);

export const CallStart = createAction(UiStateTypes.CALL_START);

export const CallEnd = createAction(UiStateTypes.CALL_END);

export const InitDone = createAction(UiStateTypes.INIT_DONE);

export const RefreshAsp = createAction(UiStateTypes.REFRESH_ASP);

