import {
  vmCD_Group_View, vmCD_View_Pid_Data, vmCD_Group_View_Details, CD_GroupName, CD_PidList,
  vmCD_Group_Vendor, vmCD_Group_Sequence, vmCD_Group_TerminalAccount, saveCDGroup, vmCD_Contact
} from '../models/received-detail';
import { OrderActionTypes} from './order.actions';

export interface OrderState {
  receivedDetail: vmCD_Group_View[],
  PidData: vmCD_View_Pid_Data[],
  viewDetails: vmCD_Group_View_Details
  vendor: vmCD_Group_Vendor,
  sequences: vmCD_Group_Sequence[],
  saveData: saveCDGroup,
  groupname: CD_GroupName,
  contacts: vmCD_Contact[],
  contactsBySearch: vmCD_Contact[],
  conatactdata: CD_PidList,
  centralsplitgrps: vmCD_Group_View[],
  isValidGroupName: number
}

export const initialState = {
  receivedDetail: null,
  PidData : null,
  viewDetails: null,
  vendor: null,
  sequences: null,
  saveData: null,
  groupname: null,
  contacts: null,
  contactsBySearch: null,
  conatactdata: null,
  centralsplitgrps: null,
  isValidGroupName: null
}

export function OrderActionsReducer(state = initialState, action: any): OrderState {
  switch (action.type) {
    case OrderActionTypes.GET_DATA_RES: {
      return { ...state, receivedDetail: action.centraldistribution};
    }
    case OrderActionTypes.GET_CDDETAILS_BYID_RES: {
      return { ...state, viewDetails: action.viewdetailsdata };
    }
    case OrderActionTypes.GET_CDVENDOR_RES: {
      return { ...state, vendor: action.vendorname };
    }
    case OrderActionTypes.UPDATE_CDGROUP_RES: {
      return { ...state, saveData: action.saveData };
    }
    case OrderActionTypes.ADD_CDGROUP_RES: {
      return { ...state, saveData: action.saveData };
    }
    case OrderActionTypes.GET_CENTRALSPLIT_DATA_RES: {
      return { ...state, centralsplitgrps: action.centralsplit };
    }
    case OrderActionTypes.GET_CD_CONTACTS_BY_SEARCHVAL_RES: {
      return { ...state, contactsBySearch: action.contactsBySearch };
    }
    case OrderActionTypes.GET_CD_CONTACTS_BY_ID_RES: {
      return { ...state, contacts: action.contacts };
    }
    case OrderActionTypes.GET_CD_CONTACTS_RES: {
      return { ...state, contactsBySearch: action.contactsBySearch };
    }
    case OrderActionTypes.GET_CD_SEQUENCE_RES: {
      return { ...state, sequences: action.sequences };
    }
    case OrderActionTypes.IS_VALID_GROUPNAME_RES: {
      return { ...state, isValidGroupName: action.countval };
    }
    default:
      return state;
  }
  }


